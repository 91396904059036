import React, { useContext, useEffect, useRef, useState } from "react";
import style from "./index.module.scss";

import Task from "@/views/pc/Guide/Task";
import Schedule from "@/views/pc/Guide/Schedule";

import { rootContext } from "@/context/rootContext";
import { getDomTop } from "@/common/utils";

function AdminTask(props) {

  const { setHeadFixed, langState } = useContext(rootContext);
  const [finishGuide, setFinishGuide] = useState(false);
  const isFirefox = navigator.userAgent.indexOf("Firefox") > 0;
  
  const taskRef = useRef();

  useEffect(() => {
    setHeadFixed(true);

    props.setTaskHeight(getDomTop(taskRef.current) + taskRef.current.clientHeight);

    return () => { setHeadFixed(false); };
  }, []);

  const finTip = () => {
    setTimeout(() => {
      setFinishGuide(true);
    }, 2000);
    setTimeout(() => {
      props.endTask();
    }, 8000);
  };

  return (
    <div className={`${style.task_box} ${isFirefox ? style.fire : ""}`} ref={taskRef}>
      <Schedule task1Active={true} task2Active={true} task3Active={true} />
      <Task finFun={finTip} />
      
      <div className={style.gudie_fin_box} style={{ display: finishGuide ? "block" : "none" }} >
        <div className={style.guide_fin_light}></div>
        <div className={style.gudie_fin_txt}>
          {langState.guideData.finishTip.map((item, index) => <p key={index}>{item}</p>)}
        </div>
      </div>
    </div>
  );
}

const AdminTaskMemo = React.memo(AdminTask);

export default AdminTaskMemo;