import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import PubSub from "pubsub-js";
import style from "./index.module.scss";

import Api from "@/common/Api/accountManage";

import { Modal } from "antd";
import { rootContext, globalData } from "@/context/rootContext";
import { mainOfGame, mainOfRegion } from "@/common/utils/envData";
import { getRegionData } from "@/common/utils/reginUtil";
import { defaultErrorMessage, locationTipMessage, RegEmail } from "@/common/utils";
import filterGameData from "@/assets/data/gameData";
import errorCode from "@/assets/data/errorCode";

function SignUp() {

  const [isRequire, setIsRequire] = useState(false);
  const [require, setRequire] = useState([false, false]);
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [emailCode, setEmailCode] = useState("");
  const [isAccept, setIsAccept] = useState("");
  const [countDown, setCountDown] = useState(0);
  const region = filterGameData("region");
  const extraSingUpRule = filterGameData("extraSingUpRule");
  const ExtraApplyLink = filterGameData("extraApplyLink");

  // const [multEnterNDA, setMultEnterNDA] = useState(false);
  const { globalLang, setEnterRegion, langState } = useContext(rootContext);

  // 监听 globalLang 触发render
  useEffect(() => {}, globalLang);

  useEffect(() => {
    if (globalData.region === "fl84") {
      window.gtag("event", "pc_fl84_sign");
    }
    if (mainOfGame || mainOfRegion) {
      return;
    }
    if (globalData.region !== "en" && !mainOfGame && !mainOfRegion) {
      // navigate("/signuplanguage");
      // 0227 合并3区 默认英文区
      initRegionCode("en");
    }
  }, []);

  const initRegionCode = async (region) => {
    getRegionData(region);

    const res = await Api.getRegionData();
    if (res.status === 200) {
      globalData.regionConfigId = res.data.regionConfigId;
      setEnterRegion(region);
    }
  };

  useEffect(() => {
    const newRequire = [false, false];
    if (!mainOfRegion) {
      extraSingUpRule.forEach(() => newRequire.push(false));
    }
    if (region === "mult") { newRequire.push(false); }
    setRequire(newRequire);
  }, [extraSingUpRule, region]);

  const communityDialog = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    let dom = langState.communityTip.map(item => `<p>${item}</p>`).join("");
    PubSub.publish("showDialog", {
      index: 3,
      data: dom
    });
  };

  const enterNDA = (e) => {
    // setMultEnterNDA(true);
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    window.gtag("event", "enterNDA");
  };

  const requireRule = (index) => {
    // if (region === "mult" && index === (2 + extraSingUpRule.length)) {
    //   if (!multEnterNDA) {
    //     Modal.info({
    //       title: "Tip",
    //       content: "Please click the underlined link on the right to submit your NDA application first."
    //     });
    //     return;
    //   }
    // }
    const newRequire = [...require];
    newRequire[index] = !newRequire[index];
    setRequire(newRequire);
  };

  const openSingUp = () => {
    if (require.indexOf(false) < 0) {
      setIsRequire(true);
    }
  };

  const setSignUpData = (type, e) => {
    const val = e.target.value.trim();
    switch (true) {
      case type === "email":
        setEmail(val);
        break;
      case type === "userName":
        setUserName(val);
        break;
      case type === "password":
        setPassword(val);
        break;
      case type === "emailCode": {
        const value = val.split("").filter(item => /^\d+$/.test(item)).join("");
        setEmailCode(value);
      }
    }
  };

  const checkPassword = () => {
    const rule1 = /[0-9]/g;
    const rule2 = /[a-zA-Z]/g;
    const rule3 = /[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/im;
    return rule1.test(password) && rule2.test(password) && rule3.test(password);
  };

  const checkNext = () => {
    return email && userName && checkPassword() && emailCode && isAccept;
  };

  // 倒计时
  const beginCountDown = () => {
    // console.log(email, countDown);
    if (email && !countDown) {
      setCountDown(59);
    } else if (!email) {
      locationTipMessage(400102);
    } else {
      locationTipMessage(400106);
    }
  };

  useEffect(() => {
    if (countDown > 0) {
      setTimeout(() => {
        setCountDown(countDown - 1);
      }, 1000);
    }
  }, [countDown]);

  // 发送验证码
  const sendCode = async () => {
    if (!email || !RegEmail.test(email)) {
      locationTipMessage(400102);
      return;
    }
    const res = await Api.sendMailVerifyCode({ email: email });
    if (res.status === 200) {
      beginCountDown();
    } else {
      defaultErrorMessage(res, "Code");
    }
  };

  const checkUserName = (val) => {
    return val.length > 1 && val.length < 25;
  };

  const checkPasswordLength = () => {
    return password.length > 7 && password.length < 21;
  };

  const errorTip = () => {
    let tip = "";
    if (!email) {
      tip = errorCode(400102);
    } else if (!RegEmail.test(email)) {
      tip = errorCode(400102);
    } else if (!userName) {
      tip = errorCode(200025);
    } else if (!checkUserName(userName)) {
      tip = errorCode(200025);
    } else if (!password) {
      tip = errorCode(400104);
    } else if (!checkPassword() || !checkPasswordLength()) {
      tip = errorCode(400104);
    } else if (!emailCode) {
      tip = errorCode(400105);
    } else if (!isAccept) {
      tip = errorCode(400107);
    }
    if (tip) {
      Modal.error({
        title: "ERROR",
        content: tip
      });
      return false;
    }
    return true;
  };

  const signup = async () => {
    if (errorTip() && checkNext()) {
      const data = {
        loginName: userName.trim(),
        email: email.trim(),
        password: password.trim(),
        mailVerifyCode: emailCode.trim()
      };
      const res = await Api.register(data);
      if (res.status === 200) {
        // console.log("sign success");
        window.gtag("event", "pc_signup");
        PubSub.publish("singupsuccess", {
          email: data.email,
          loginName: data.userName,
          password: password.trim()
        });
      } else {
        Modal.error({
          title: "ERROR",
          content: res.data && res.data.message,
        });
      }
    }
  };

  return (
    <section>
      {
        isRequire ?
          (
            // 注册
            <div className={`sing_up_main ${globalLang} ${style.sing_up_main}`}>
              <p className={`title2 ${style.title}`}>{langState.signData.title2}</p>
              <div className={`sing_box ${style.sing_box}`}>
                <input className={style.slide1} placeholder={langState.signData.signPlace1} value={email} onChange={(e) => { setSignUpData("email", e); }}></input>
                <div className={style.input_box}>
                  <input className={style.slide1} maxLength={24} placeholder={langState.signData.signPlace2}  value={userName} onChange={(e) => { setSignUpData("userName", e); }}></input>
                  <span className={style.num}>({userName.length}/24)</span>
                </div>
                <div className={`${style.password_box} ${showPassword ? style.active : ""}`}>
                  <input placeholder={langState.signData.signPlace3}  value={password} type={showPassword ? "text" : "password"} onChange={(e) => { setSignUpData("password", e); }}></input>
                  <div className={style.hideIcon} onClick={() => { setShowPassword(!showPassword); }}></div>
                  <div className={style.password_require}>
                    <p>{langState.signData.signNeed1} </p>
                    <p>
                      <span><i className={`${password.length > 7 && password.length < 21 ? style.active : ""}`}></i>{langState.signData.signNeed2}</span>
                      <span><br /></span>
                      <span><i className={`${checkPassword() ? style.active : ""}`}></i>{langState.signData.signNeed3}</span>
                    </p>
                  </div>
                </div>
                <div className={style.email_box}>
                  <input className={style.slide1} placeholder={langState.signData.codeTip} value={emailCode} onChange={(e) => { setSignUpData("emailCode", e); }}></input>
                  <div className={style.code_btn}>
                    <p>{ langState.signData.send }</p>
                  </div>
                  {
                    countDown > 0 ? (
                      <div className={style.count_down}>
                        <p>{countDown}s</p>
                      </div>
                    ) : (
                      <div className={style.code_btn} onClick={sendCode}>
                        <p>{ langState.signData.send }</p>
                      </div>
                    )
                  }
                </div>
                <p className={style.accept}>
                  <i className={`${isAccept ? style.active : ""}`} onClick={() => { setIsAccept(!isAccept); }}></i>
                  <span>{langState.signData.signNeed4_1}&nbsp;
                    <em onClick={() => { window.gtag("event", "pc_sign_termsofservice"); }}>
                      <NavLink to="/termsofuse" target="_blank">{langState.signData.signNeed4_2}</NavLink>
                    </em>
                    &nbsp;{langState.signData.signNeed4_3}&nbsp;
                    <em onClick={() => { window.gtag("event", "pc_sign_privacypolicy"); }}>
                      <NavLink to="/privacy" target="_blank">{langState.signData.signNeed4_4}</NavLink>
                    </em>
                    &nbsp;{langState.signData.signNeed4_5}
                  </span>
                </p>
                <div className={`${style.next_btn} ${checkNext() ? style.active : ""}`} onClick={signup}>
                  { langState.signData.next }
                </div>
                <p className={style.sign_in_tip} onClick={() => PubSub.publish("showDialog", { index: 1 })}>
                  {langState.signData.accountTip1}&nbsp;
                  <NavLink to={"/"}>{langState.signData.accountTip2}{langState.signData.accountTip3}</NavLink>
                </p>
                {
                  mainOfRegion && (
                    <>
                      <div className="line1"></div>
                      <div className="line2"></div>
                      <div className="line3"></div>
                      <div className="line4"></div>
                      <div className="star1"></div>
                      <div className="star2"></div>
                      <div className="star3"></div>
                      <div className="star4"></div>
                    </>
                  )
                }
              </div>
            </div>
          ) :
          (
            // 协议
            <div className={`sing_up_main ${globalLang} ${style.sing_up_main}`}>
              <p className={`title ${style.title}`}>{langState.signData.title}</p>
              <p className={`desc ${style.desc}`}>{langState.signData.desc1}</p>
              <p className={`desc ${style.desc}`}>{langState.signData.desc2}</p>
              <div className={`require_box ${style.require_box}`}>
                <ul>
                  <li onClick={() => requireRule(0)}>
                    <i className={`${require[0] ? `active ${style.active}` : ""}`}></i>
                    <span>{langState.signData.signRule1_1} <span onClick={communityDialog}>{langState.signData.signRule1_link}</span> {langState.signData.signRule1_2}</span>
                  </li>
                  <li onClick={() => requireRule(1)}>
                    <i className={`${require[1] ? `active ${style.active}` : ""}`}></i>
                    <span>{langState.signData.signRule2}</span>
                  </li>
                  {
                    extraSingUpRule.length > 0 && !mainOfRegion && (
                      extraSingUpRule.map((item, index) => (
                        <li onClick={() => requireRule(2 + index)} key={index}>
                          <i className={`${require[2 + index] ? `active ${style.active}` : ""}`}></i>
                          {item}
                        </li>
                      ))
                    )
                  }
                  {
                    region === "mult" && (
                      <li onClick={() => requireRule(2 + extraSingUpRule.length)}>
                        <i className={`${require[2 + extraSingUpRule.length] ? `active ${style.active}` : ""}`}></i>
                        <span>Agrees to <a onClick={enterNDA} target="_blank" rel="noreferrer" href="https://forms.gle/tHbSDo1jDGdLZaqT9">sign NDA</a> to protect information provided by the program</span>
                      </li>

                    )
                  }
                </ul>
                <div
                  className={`${require.indexOf(false) < 0 ? style.active : ""} ${style.got_btn}`}
                  onClick={openSingUp}
                >{ langState.signData.accept }</div>
                {
                  ExtraApplyLink && <div className={`sign_other ${style.sign_other}`}>{ExtraApplyLink}</div>
                }
                {
                  mainOfRegion && (
                    <>
                      <div className="line1"></div>
                      <div className="line2"></div>
                      <div className="line3"></div>
                      <div className="line4"></div>
                      <div className="star1"></div>
                      <div className="star2"></div>
                      <div className="star3"></div>
                      <div className="star4"></div>
                    </>
                  )
                }
              </div>
            </div>
          )
      }
    </section>
  );
}

export default SignUp;
