// 过滤分流对应的区域/游戏数据
// 基于游戏进行分离
// 组件直接调用filterGameData(key)获取需要的数据

import { defaultRegionData, regionStoreEn } from "@/assets/data/gameDataEn";
import { regionStoreMult } from "@/assets/data/gameDataMult";
import { regionStoreSea } from "@/assets/data/gameDataSea";
import { regionStoreJp } from "@/assets/data/gameDataJp";
import { regionStoreTw } from "@/assets/data/gameDataTw";
import { regionStoreFl84 } from "@/assets/data/gameDataFl84";
import { globalData } from "@/context/rootContext";
import { filterDiscordLine } from "@/common/filter/index";

const regionContextBase = {
  blockTask: [], // 屏蔽的新手任务
  extraApplyLink: null, // 显示额外的注册链接
  extraSingUpRule: [], // 额外的注册规则
  discordLink: {}, // 覆盖的discord链接
  commitTitle: "", //申请表单页 联系方式标题
  commitDesc: "", //申请表单页 联系方式描述
  explainedList: {}, // 收益解释
  tierExplainedList: {}, // 星级描述
  earningTip: {}, // 收入提示
};

const defaultFilter = (gameTag, key) => {
  switch (key) {
    case "explainedList": return defaultRegionData.explainedList[gameTag];
    case "earningTip": return defaultRegionData.earningTip[gameTag];
    case "tierExplainedList": return defaultRegionData.tierExplainedList[gameTag];
    case "discordLink": return defaultRegionData.discordLink[gameTag];
    case "gameIcons": return defaultRegionData.gameIcons; 
    case "gameTag": return defaultRegionData.gameTag; 
    case "blockTask": return [];
    default: return regionContextBase[key] || null;
  }
};

const regionFilter = (gameTag, key, regionData) => {
  let res = null;
  switch (key) {
    case "explainedList": res = regionData.explainedList && regionData.explainedList[gameTag]; break;
    case "earningTip": res = regionData.earningTip && regionData.earningTip[gameTag]; break;
    case "tierExplainedList": res = regionData.tierExplainedList && regionData.tierExplainedList[gameTag]; break;
    case "discordLink": res = filterDiscordLine(gameTag) || (regionData.discordLink && regionData.discordLink[gameTag]); break;
    case "extraApplyLink": res = regionData.extraApplyLink; break;
    case "extraSingUpRule": res = regionData.extraSingUpRule; break;
    case "blockTask": res = regionData.blockTask; break;
    case "commitTitle": res = regionData.commitTitle; break;
    case "commitDesc": res = regionData.commitDesc; break;
    case "gameTag": res = regionData.gameTag; break;
  }
  return res || null;
};

const filterEn = (gameTag, key) => {
  const regionData = regionFilter(gameTag, key, regionStoreEn);
  if (regionData) { return regionData; }
  switch (key) {
    case "region": return "en";
    default: return defaultFilter(gameTag, key);
  }
};

const filterMult = (gameTag, key) => {
  const regionData = regionFilter(gameTag, key, regionStoreMult);
  if (regionData) { return regionData; }
  switch (key) {
    case "region": return "mult";
    default: return defaultFilter(gameTag, key);
  }
};

const filterSea = (gameTag, key) => {
  const regionData = regionFilter(gameTag, key, regionStoreSea);
  if (regionData) { return regionData; }
  switch (key) {
    case "region": return "sea";
    default: return defaultFilter(gameTag, key);
  }
};

const filterJp = (gameTag, key) => {
  const regionData = regionFilter(gameTag, key, regionStoreJp);
  if (regionData) { return regionData; }
  switch (key) {
    case "region": return "sea";
    default: return defaultFilter(gameTag, key);
  }
};

const filterTw = (gameTag, key) => {
  const regionData = regionFilter(gameTag, key, regionStoreTw);
  if (regionData) { return regionData; }
  switch (key) {
    case "region": return "tw";
    default: return defaultFilter(gameTag, key);
  }
};

const filterFl84 = (gameTag, key) => {
  const regionData = regionFilter(gameTag, key, regionStoreFl84);
  if (regionData) { return regionData; }
  switch (key) {
    case "region": return "farlight84";
    default: return defaultFilter(gameTag, key);
  }
};

const filterGameData = (key) => {
  if (!globalData.activeGameData) { return null; }
  const gameTag = globalData.activeGameData.tag;
  const region = (globalData.activeGameData.regionName || globalData.region).toLowerCase();
  switch(region) {
    case "en": return filterEn(gameTag, key);
    case "mult": return filterMult(gameTag, key);
    case "sea": return filterSea(gameTag, key);
    case "ja": return filterJp(gameTag, key);
    case "jp": return filterJp(gameTag, key);
    case "tw": return filterTw(gameTag, key);
    case "farlight84": return filterFl84(gameTag, key);
    default: return filterEn(gameTag, key);
  }
};

export default filterGameData;