import { mainOfGame } from "../utils/envData";
import instance from "./webApi";

/**
 * 获取社媒登录URL { mInfoTag }
 */
const getMediaURL = async function(data) {
  return instance({
    url: "/c/account/mInfoUrl",
    method: "GET",
    params: {
      mInfoTag: data.mInfoTag,
      formId: data.formId
    },
  });
};

/**
 * 获取社媒绑定数据
 */
const getBindMediaData = async function(data) {
  return instance({
    url: `/c/mInfo/queue/${data.formId}`,
  });
};

/**
 * 申请成为创作者
 */
const applyToCreator = async function(data, header) {
  return instance({
    url: "/c/apply/account",
    method: "POST",
    data,
    headers: {
      gametag: header.gametag
    }
  });
};

/**
 * 绑定游戏
 */
const bindGame = async function(games, realInfo) {
  const data = { games };
  if (realInfo) { data.realInfo = realInfo; }
  return instance({
    url: "/c/account/game",
    method: "POST",
    data,
    extraParams: {
      needRegionId: mainOfGame,
    }
  });
};

/**
 * 查询游戏的签署情况
 */
const getGameSignType = async function(gametag) {
  return instance({
    url: "c/account/platformCheck",
    method: "GET",
    headers: {
      gametag: gametag
    },
  });
};

/**
 * 绑定社媒
 */
const bindMediaInfo = async function(data, gametag) {
  return instance({
    url: "/c/account/mInfo",
    method: "POST",
    headers: {
      gametag: gametag
    },
    data: {
      mInfos: data
    },
    extraParams: {
      needRegionId: mainOfGame,
    }
  });
};

/**
 * 重复注册校验
 */
const multipleRegister = async function(data) {
  return instance({
    url: "/c/validateLoginName",
    method: "POST",
    data: data
  });
};

/**
 * 发生fl84绑定验证码
 */
const sendFl84Code = async function(uid) {
  return instance({
    url: "/c/uid/captcha",
    method: "GET",
    headers: {
      gametag: "farlight84"
    },
    params: {
      uid
    }
  });
};

/**
 * 检查fl84验证码
 */
const checkFl84Code = async function(uid, code) {
  return instance({
    url: "/c/uid/valid",
    method: "GET",
    params: {
      uid,
      code
    },
    headers: {
      gametag: "farlight84"
    },
    extraParams: {
      needRegionId: mainOfGame,
    }
  });
};

/**
 * 重新绑定fl84UID
 */
const reBindFl84UID = async function(uid) {
  return instance({
    url: "/c/uid/bind",
    method: "GET",
    params: {
      uid
    },
    headers: {
      gametag: "farlight84"
    },
    extraParams: {
      needRegionId: true,
    }
  });
};

/**
 * fl84 根据uid查询昵称
 */
const getFl84NickName2UID = async function(uid) {
  return instance({
    url: "/c/uid/info",
    method: "GET",
    params: {
      uid
    },
    headers: {
      gametag: "farlight84"
    },
    extraParams: {
      needRegionId: true,
    }
  });
};

export default {
  getMediaURL,
  getBindMediaData,
  applyToCreator,
  bindGame,
  bindMediaInfo,
  getGameSignType,
  multipleRegister,
  sendFl84Code,
  checkFl84Code,
  reBindFl84UID,
  getFl84NickName2UID
};