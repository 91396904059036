import React, { useContext, useEffect, useRef, useState } from "react";
import style from "../index.module.scss";
import Api from "@/common/Api/content";
// import bindApi from "@/common/Api/bindCreator";
import PubSub from "pubsub-js";

import { rootContext, globalData } from "@/context/rootContext";
import { locationUtil, Abbreviation, fl84Check, defaultErrorMessage, locationTipMessage } from "@/common/utils";
import { Upload, Spin, message, Modal } from "antd";
import { upLoadPath } from "@/common/Api/webApi";
import errorCode from "@/assets/data/errorCode";

// let loadNum = 0;

function UploadConent() {

  const { token, setDialogIndex, langState } = useContext(rootContext);
  const [handleFill, setHandleFill] = useState(false);
  const [analyze, setAnalyze] = useState(false);
  const [viewNum, setViewNum] = useState(0);
  const [likeNum, setLikeNum] = useState(0);
  const [commNum, setCommNum] = useState(0);
  const [snapshot, setSnapshot] = useState([]);
  const [upLoadFileList, setUpLoadFileList] = useState([]);
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [isAnalyze, setIsAnalyze] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [mTag, setMTag] = useState("");
  const [videoId, setVideoId] = useState("");
  const [urlContent, setUrlContent] = useState("");
  // const [formId, setFormId] = useState("");
  const [uid, setUid] = useState("");
  const [publishTime, setPublishTime] = useState(null);
  // const [tikTokToken, setTikTokToken] = useState("");
  const [homePageURL, setHomePageURL] = useState("");

  const urlRef = useRef();

  // const getMediaURL = async (formId) => {
  //   const res = await bindApi.getMediaURL({
  //     mInfoTag: "tiktok",
  //     formId: formId
  //   });
  //   if (res.status === 200) {
  //     loadNum = 0;
  //     Modal.info({
  //       title: "TIP",
  //       content: (
  //         <div>
  //           <p>{errorCode(200024)}</p>
  //         </div>
  //       ),
  //       onOk() {
  //         setTimeout(getMediaUseData, 2500);
  //       },
  //     });
  //     setTimeout(() => {
  //       const enter = document.getElementsByClassName("ant-btn-primary")[0];
  //       const a = document.createElement("a");
  //       a.setAttribute("href", res.data);
  //       a.setAttribute("target", "_blank");
  //       a.setAttribute("rel", "opener");
  //       enter.appendChild(a);
  //     }, 100);
  //   } else {
  //     defaultErrorMessage(res, "uploadAuthorize");
  //     setShowLoading(false);
  //   }
  // };

  // const getMediaUseData = async () => {
  //   if (!formId) { return; }
  //   const res = await bindApi.getBindMediaData({
  //     formId: formId
  //   });
  //   if (res && res.status === 200 && res.data) {
  //     refreshTikTokToken(res.data.refreshToken);
  //   } else if (loadNum++ < 5) {
  //     setTimeout(getMediaUseData, 1000);
  //   } else {
  //     defaultErrorMessage(res, "videoData");
  //     setShowLoading(false);
  //   }
  // };

  // const tiktokAuthorize = () => {
  //   const randomNum = [0, 0, 0].map(() => Math.floor(Math.random() * 10)).join("");
  //   const formId = `${new Date().getTime() % 1E3}${randomNum}`;
  //   setFormId(formId);
  //   getMediaURL(formId);
  // };

  const analyzeURL = async (url) => {
    window.gtag("event", "m_upload_analyze");
    if (new RegExp("[\\u4E00-\\u9FFF]+", "g").test(url)) {
      locationTipMessage(400236);
      return;
    }
    setShowLoading(true);
    const res = await Api.analyzeURL({
      url,
      auto: 0,
      gametag: globalData.activeGameData.tag
    });
    setShowLoading(false);
    if (res && res.status === 200) {
      setViewNum(res.data.viewCount);
      setLikeNum(res.data.starCount);
      setCommNum(res.data.commentCount);
      setMTag(res.data.mTag);
      setTitle(res.data.title || "");
      setSubTitle(res.data.subTitle);
      setVideoId(res.data.videoId);
      setIsAnalyze(true);
      setSnapshot(res.data.snapshot.split(","));
      setUid(res.data.uid);
      setAnalyze(true);
      setPublishTime(res.data.publishTime);
      setHomePageURL(res.data.contentUrl);
    } else {
      Modal.error({
        title: "ERROR",
        content: (res.data && res.data.message) || "Data cannot be retrieved. Please select to manually enter data."
      });
      // setHandleFill(!handleFill);
    }
  };

  // const analyzeTikTok = async (accessToken) => {
  //   window.gtag("event", "pm_upload_analyze");
  //   const url = urlRef.current.value.trim() || urlContent.trim();
  //   if (new RegExp("[\\u4E00-\\u9FFF]+", "g").test(url)) {
  //     locationTipMessage(400236);
  //     return;
  //   }
  //   setShowLoading(true);
  //   const data = {
  //     url,
  //     gametag: globalData.activeGameData.tag,
  //     auto: 0,
  //     tikTokAccessToken: accessToken
  //   };
  //   const res = await Api.analyzeURL(data);
  //   setShowLoading(false);
  //   if (res && res.status === 200) {
  //     setViewNum(res.data.viewCount);
  //     setLikeNum(res.data.starCount);
  //     setCommNum(res.data.commentCount);
  //     setMTag(res.data.mTag);
  //     setTitle(res.data.title || "");
  //     setSubTitle(res.data.subTitle);
  //     setVideoId(res.data.videoId);
  //     setIsAnalyze(true);
  //     setSnapshot(res.data.snapshot.split(","));
  //     setAnalyze(true);
  //     setPublishTime(res.data.publishTime);
  //     setTikTokToken(accessToken);
  //     setHomePageURL(res.data.contentUrl);
  //   } else {
  //     Modal.error({
  //       title: "Error",
  //       content: (res.data && res.data.message) || "Data cannot be retrieved. Please select to manually enter data."
  //     });
  //     // setHandleFill(!handleFill);
  //   }
  // };

  // const refreshTikTokToken = async (refreshToken) => {
  //   const res = await Api.refreshTikTokToken(refreshToken);
  //   // console.log(res);
  //   if (res.status === 200) {
  //     analyzeTikTok(res.data.accessToken);
  //   } else {
  //     defaultErrorMessage(res, "tiktokToken");
  //     setShowLoading(false);
  //   }
  // };

  const submit = () => {
    window.gtag("event", "m_upload_content");
    const id_val = document.getElementById("url-content").value;
    const url = id_val || urlRef.current.value.trim() || urlContent.trim();
    console.log(url);
    if (!url) {
      locationTipMessage(400210);
      return;
    }
    if (new RegExp("[\\u4E00-\\u9FFF]+", "g").test(url)) {
      locationTipMessage(400236);
      return;
    }
    if (isAnalyze || handleFill) {
      upLoadContent();
      return;
    }
    if (!isAnalyze) {
      if (allowAnalyze()) {
        // if (url.includes("tiktok")) {
        //   tiktokAuthorize();
        // } else {
        //   analyzeURL(url);
        // }
        analyzeURL(url);
      } else {
        setDialogIndex(4);
      }
    }
  };

  const upLoadContent = async () => {
    const data = {
      title: title.trim(),
      snapshot: snapshot.join(","),
      contentUrl: homePageURL || urlRef.current.value.trim() || urlContent.trim(),
      gameTag: globalData.activeGameData.tag,
      viewCount: viewNum,
      starCount: likeNum,
      commentCount: commNum,
      autoParse: false,
      subTitle: subTitle,
      mTag: mTag,
      videoId: videoId || "",
      publishTime
    };
    if (data.contentUrl === "") {
      locationTipMessage(400210);
      return;
    }
    // if (!data.title || !data.snapshot || data.viewCount === "" || !data.starCount === "" || !data.commentCount === "") {
    //   message.error("Please enter all of the your content data.");
    //   return;
    // }
    if (!data.mTag) {
      const url = data.contentUrl;
      if (url.includes("youtube")) {
        data.mTag = "youtube";
      } else if (url.includes("tiktok")) {
        data.mTag = "tiktok";
      } else if (url.includes("twitch")) {
        data.mTag = "twitch";
      }
    }
    if (data.mTag.includes("youtube") || data.mTag.includes("twitch")) {
      data.uid = uid;
    }
    // data.uid = uid;
    if (globalData.activeGameData.tag === "farlight84") {
      const tit = data.title.toLowerCase();
      if (!fl84Check(tit)) {
        locationTipMessage(200010);
        return;
      }
    }
    const res = await Api.savaContents({ gametag: globalData.activeGameData.tag, data });
    // const res = await Api.savaContents({ gametag: globalData.activeGameData.tag, data }, tikTokToken);
    // console.log(res);
    if (res.status === 200) {
      locationTipMessage(200028, "success");
      setCommNum(0);
      setLikeNum(0);
      setViewNum(0);
      setSnapshot([]);
      setTitle("");
      setAnalyze(false);
      setIsAnalyze(false);
      setHandleFill(false);
      setUrlContent("");
      setUpLoadFileList([]);
    } else {
      if (res.data && +res.data.code === 400218) {
        Modal.error({
          title: "ERROR",
          content: <p dangerouslySetInnerHTML={{ __html: errorCode(400218) }}></p>
        });
        return;
      }
      defaultErrorMessage(res, "uploadContent");
    }
  };

  const allowAnalyze = () => {
    const lastAllow = locationUtil.getLocationStorage("authorize");
    if (lastAllow && lastAllow.time && new Date().toLocaleString().slice(0, 8) === lastAllow.time.slice(0, 8)) {
      return true;
    } else {
      return false;
    }
  };

  const upLoadStatus = {
    action: upLoadPath,
    accept: "image/png, image/jpeg",
    beforeUpload: (file) => {
      const isIMG = file.type === "image/png" || file.type === "image/jpeg";
      if (!isIMG) {
        locationTipMessage(400121);
        return false;
      }
      return true;
    },
    headers: {
      Authorization: `Bearer ${token}`
    },
    fileList: upLoadFileList,
    listType: "picture-card",
    onChange({ file, fileList }) {
      const isIMG = file.type === "image/png" || file.type === "image/jpeg";
      // console.log(file, fileList);

      if (!fileList.length) {
        setSnapshot([]);
        setUpLoadFileList([]);
        return;
      }
      if (fileList[fileList.length - 1].status === "error") {
        const t = fileList.splice(fileList.length - 1, 1);
        message.error((t.response && t.response.data && t.response.data.message) || "Upload error, please try again");
      } else if (!isIMG) {
        fileList.splice(fileList.length - 1, 1);
      }

      const list = [];
      for (const item of fileList) {
        if (item.response && item.response.url) {
          list.push(item.response.url);
        }
      }
      setSnapshot(list);
      setUpLoadFileList(fileList);
    },
    defaultFileList: [],
  };

  const enterUrl = (e) => {
    if (isAnalyze) {
      setUrlContent(e.target.value.replace(urlContent, ""));
      setCommNum(0);
      setLikeNum(0);
      setViewNum(0);
      setSnapshot([]);
      setTitle("");
      setAnalyze(false);
      setIsAnalyze(false);
      setHandleFill(false);
    } else {
      setUrlContent(e.target.value);
    }
  };

  // const setPlatFormSataus = (msg) => {
  //   if (msg && msg.data) {
  //     try {
  //       const data = JSON.parse(msg.data);
  //       if (data.type === "authorization") {
  //         setTimeout(() => { getMediaUseData(); }, 1000);
  //         setShowLoading(true);
  //       }
  //     } catch (e) { null; }
  //   }
  // };

  useEffect(() => {
    allowAnalyze();
    PubSub.subscribe("allowAuthorize", () => {
      const id_val = document.getElementById("url-content").value;
      const url = id_val || urlRef.current.value.trim() || urlContent.trim();
      // if (url.includes("tiktok")) {
      //   tiktokAuthorize(url);
      // } else {
      //   analyzeURL(url);
      // }
      analyzeURL(url);
    });
  }, []);

  // useEffect(() => {
  //   const messageCallback = setPlatFormSataus;
  //   window.addEventListener("message", messageCallback);
  //   return () => { window.removeEventListener("message", messageCallback); };
  // }, [formId]);

  return (
    <div className={`upload_box ${style.upload_box}`}>
      <p className={style.title1}>{langState.upLoadContentData.uploadTitle1}</p>
      {
        globalData.activeGameData.tag === "farlight84" && (
          <p className={style.upLoadTip} dangerouslySetInnerHTML={{ __html: langState.upLoadContentData.uploadDesc1 }}></p>
        )
      }
      <div className={style.url_upload}>
        <input placeholder={langState.upLoadContentData.upLoadPlace1} value={urlContent} ref={urlRef} onChange={(e) => {enterUrl(e);}} id="url-content"></input>
        <div className={style.submit} onClick={submit}>
          {analyze ? langState.upLoadContentData.submitBtn[0] : (handleFill ? langState.upLoadContentData.submitBtn[1] : langState.upLoadContentData.submitBtn[2])}
        </div>
      </div>
      {
        !analyze ? (
          <div>
            {/* <div className={style.fill_chose}>
              <i className={`${handleFill ? style.active : ""}`} onClick={() => { setHandleFill(!handleFill); }}></i>
              <span>Fill in data manually</span>
            </div> */}
            {
              handleFill ? (
                <div>
                  <div className={style.total_box}>
                    <div className={style.total_item2}>
                      <p>TITLE</p>
                      <input value={title} onChange={e => setTitle(e.target.value)}></input>
                    </div>
                  </div>
                  <div className={style.total_box}>
                    <div className={style.total_item}>
                      <p>TOTAL VIEWS</p>
                      <input value={viewNum} onChange={e => setViewNum(e.target.value)}></input>
                    </div>
                    <div className={style.total_item}>
                      <p>TOTAL LIKES</p>
                      <input value={likeNum} onChange={e => setLikeNum(e.target.value)}></input>
                    </div>
                    <div className={style.total_item}>
                      <p>TOTAL COMMENTS</p>
                      <input value={commNum} onChange={e => setCommNum(e.target.value)}></input>
                    </div>
                  </div>

                  <div className={style.upload}>
                    <Upload {...upLoadStatus}>
                      <div className={style.upload_default}>
                        <i className={style.upload_icon1}></i>
                        <i className={style.upload_icon2}></i>
                        <p>upload a screenshoot</p>
                      </div>
                    </Upload>
                  </div>
                </div>
              ) : ""
            }
          </div>
        ) : (
          <div className={style.analyze_data}>
            {
              publishTime && (
                <p className={style.time}>{langState.upLoadContentData.time}:{ publishTime.slice(0, 10) }</p>
              )
            }
            <div className={style.total_num}>
              <ul>
                <li>
                  <p className={style.right}>{langState.upLoadContentData.rightNow}</p>
                </li>
                <li>
                  <p>{langState.upLoadContentData.itemTitle1}</p>
                  <p>{ Abbreviation(viewNum || 0) }</p>
                </li>
                <li>
                  <p>{langState.upLoadContentData.itemTitle2}</p>
                  <p>{ mTag === "twitch" ? "—" : Abbreviation(likeNum || 0) }</p>
                </li>
                <li>
                  <p>{langState.upLoadContentData.itemTitle3}</p>
                  <p>{ mTag === "twitch" ? "—" : Abbreviation(commNum || 0) }</p>
                </li>
              </ul>
            </div>
            <div className={style.screenshot}>
              {
                snapshot.map((item, index) => {
                  return <img key={index} src={item} ></img>;
                })
              }
            </div>
          </div>
        )
      }
      {
        showLoading && (
          <div className={style.load_mask}>
            <Spin size="large" />
          </div>
        )
      }
    </div>
  );
}

export default UploadConent;
