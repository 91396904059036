import { mainOfGame } from "../utils/envData";
import instance from "./webApi";

/**
 * 刷新tiktok token
 */
const refreshTikTokToken = (token) => {
  return instance({
    url: "auth/tiktok/refreshToken",
    method: "GET",
    params: {
      refreshToken: token
    }
  });
};

/**
 * 分析url
 */
const analyzeURL = async(data) => {
  return instance({
    url: "/c/contents/analysis",
    method: "GET",
    headers: {
      gametag: data.gametag
    },
    params: {
      url: encodeURIComponent(data.url),
      auto: data.auto || 0,
      tikTokAccessToken: data.tikTokAccessToken || null
    }
  });
};

/**
 * 检测视频归属是否为本人
 */
const checkVideoBelong = async(gametag, videoData) => {
  return instance({
    url: "/common/validContent",
    method: "GET",
    headers: {
      gametag
    },
    params: videoData
  });
};

/**
 * 上传
 */
const savaContents = async (data, token = null) => {
  return instance({
    url: "/c/contents",
    method: "POST",
    headers: {
      gametag: data.gametag
    },
    data: data.data,
    params: {
      tikTokAccessToken: token
    },
    extraParams: {
      needRegionId: mainOfGame,
    }
  });
};

/**
 * 查询作品列表
 */
const getContentList = async function(data) {
  return instance({
    url: "/c/contents",
    method: "GET",
    headers: {
      gametag: data.gametag
    },
    params: data.data,
    extraParams: {
      needRegionId: mainOfGame,
    }
  });
};

/**
 * 删除作品
 */
const deleteContent = async function(gametag, id) {
  return instance({
    url: "/c/contents/" + id,
    method: "DELETE",
    headers: {
      gametag: gametag
    }
  });
};

/**
 * 更新作品
 */
const updateContent = async function(id, gameTab, accrssToken,) {
  return instance({
    url: "/c/contents/refresh/" + id,
    method: "PUT",
    headers: {
      gametag: gameTab
    },
    params: {
      tikTokAccessToken: accrssToken || null
    }
  });
};

/**
 * 更新全部作品
 */
const updateAllContent = async function() {
  return instance({
    url: "/c/contents/refreshAll",
    method: "PUT",
  });
};


/**
 * 数据总览
 */
const getMediaData = async function(mTag, publishTimeRange, gametag, activityId) {
  return instance({
    url: "/c/contents/ss",
    method: "GET",
    headers: {
      gametag
    },
    params: {
      publishTimeRange,
      mTag,
      activityId
    },
    extraParams: {
      needRegionId: mainOfGame,
    }
  });
};



/**
 * 查询最新游戏推送
 */
const getGameFeed = async function(data) {
  return instance({
    url: "/c/resource/info",
    method: "GET",
    headers: {
      gametag: data.gametag
    },
    params: {
      infoType: "",
      key: data.key || "",
      page: data.page,
      limit: data.limit,
      gametag: data.gametag,
    },
    extraParams: {
      needRegionId: mainOfGame,
    }
  });
};


/**
 * 查询游戏素材
 */
const getArtAsset = async function(data) {
  return instance({
    url: "/c/resource/fodder",
    method: "GET",
    headers: {
      gametag: data.gametag
    },
    params: {
      fodderType: "",
      key: data.key || "",
      page: data.page,
      limit: data.limit,
    },
    extraParams: {
      needRegionId: mainOfGame,
    }
  });
};

/**
 * 申请下载游戏素材
 */
const applyDownLoad = async function(data) {
  return instance({
    url: "/c/apply/resource/fodder",
    method: "POST",
    headers: {
      gametag: data.gametag
    },
    data: {
      ids: [data.id]
    },
    extraParams: {
      needRegionId: mainOfGame,
    }
    // responseType: "blob"
  });
};

/**
 * 下载素材
 */
const downArt = async function(data) {
  return instance({
    url: `/c/apply/download/fodder/${data.id}`,
    method: "GET",
    extraParams: {
      needRegionId: mainOfGame,
    }
    // onDownloadProgress: function(progressEvent) {
    //   if (progressEvent.event.lengthComputable) {
    //     PubSub.publish("downloading", { load: progressEvent.event.loaded / progressEvent.event.total * 100, id: data.id });
    //   }
    // }
  });
};

/**
 * 查询游戏推送数据
 */
const getGameFeedDetail = async function(data) {
  return instance({
    url: `/c/resource/info/${data.id}`,
    method: "GET",
    headers: {
      gametag: data.gametag
    }
  });
};

/**
 * 查询游戏素材数据
 */
const getArtAssetDetail = async function(data) {
  return instance({
    url: `/c/resource/fodder/${data.id}`,
    method: "GET",
    headers: {
      gametag: data.gametag
    }
  });
};

export default {
  analyzeURL,
  savaContents,
  getContentList,
  getMediaData,
  deleteContent,
  getArtAsset,
  applyDownLoad,
  downArt,
  getGameFeed,
  getGameFeedDetail,
  getArtAssetDetail,
  updateContent,
  refreshTikTokToken,
  updateAllContent,
  checkVideoBelong
};