import instance from "./webApi";
import { encryptCode } from "@/common/utils";
import { globalData } from "@/context/rootContext";
import { mainOfGame, mainOfRegion, isFl84 } from "../utils/envData";
import { getRegionSettingData } from "../utils/reginUtil";

/**
 * 注册 { loginName, email, password }
 * retutrn { code: 200 }
 */
const register = async function (data) {
  data.password = encryptCode(data.password);
  return instance({
    url: "/c/account/register",
    method: "POST",
    data: {
      ...data,
      regionConfigId: globalData.regionConfigId || getRegionSettingData(globalData.region).regionConfigId
    }
  });
};


/**
 * 账户密码登录 { loginName, password }
 * return { code: 200 }
 */
const login = async function (data) {
  data.password = encryptCode(data.password);
  return instance({
    url: "/c/login",
    method: "POST",
    data: data
  });
};

/**
 * 邮箱 密码/验证码 登录 { email, password, mailVerifyCode }
 */
const login_email = async function (data) {
  if (mainOfGame) {
    if (isFl84) { return fl84Login(data); }
  }
  if (data.password) { data.password = encryptCode(data.password); }
  return instance({
    url: "/c/emailLogin",
    method: "POST",
    data: {
      ...data,
      // regionConfigId: globalData.regionConfigId
    },
    extraParams: {
      needGameHost: mainOfRegion,
    }
  });
};

/**
 * fl84专区登录
 */
const fl84Login = async function(data) {
  if (data.password) { data.password = encryptCode(data.password); }
  return instance({
    url: "/globalRegion/clientLogin",
    method: "POST",
    data: {
      ...data,
      regionId: 6
    }
  });
};

/**
 * 黑名单确认
 */
const checkBlackList = async function(email) {
  return instance({
    url: "/common/blackList",
    method: "GET",
    params: {
      email
    }
  });
};

/**
 * 修改密码 { email, password, mailVerifyCode, regionConfigId }
 */
const forgotPassword = async function (data) {
  return instance({
    url: "/c/account/forgot",
    method: "PUT",
    data: {
      email: data.email,
      password: encryptCode(data.password),
      mailVerifyCode: data.mailVerifyCode,
      regionConfigId: data.regionConfigId || globalData.regionConfigId
    },
  });
};


/**
 * 发送验证码 { email }
 */
const sendMailVerifyCode = async function (data) {
  return instance({
    url: "/c/sendMailCode",
    method: "POST",
    data: data
  });
};


/**
 * 注销
 */
const logout = async function () {
  return instance({
    url: "/logout",
    method: "POST",
  });
};

/**
 * 修改用户数据 { nickName, birthday, discord, region }
 */
const modifyInformation = async function (data) {
  const bodyData = {};
  if (data.nickName) { bodyData.nickName = data.nickName; }
  if (data.birthday) { bodyData.birthday = data.birthday; }
  if (data.discord) { bodyData.discord = data.discord; }
  if (data.region) { bodyData.region = data.region; }
  return instance({
    url: "/c/account/profile",
    method: "PUT",
    data: bodyData,
  });
};

/**
 * bug上报
 */
const submitBug = async function (data) {
  return instance({
    url: "/c/account/feedback",
    method: "POST",
    headers: {
      gametag: data.gametag
    },
    data: data.data,
    extraParams: {
      needRegionId: mainOfGame,
    }
  });
};

/**
 * token刷新
 */
const refreshToken = async function(token) {
  return instance({
    url: "/refreshToken",
    method: "GET",
    params: {
      refreshToken: token
    }
  });
};

/**
 * 查询地区配置
 */
const getRegionData = async function() {
  return instance({
    url: "/c/region",
    method: "GET"
  });
};


export default {
  register,
  login,
  login_email,
  sendMailVerifyCode,
  forgotPassword,
  logout,
  submitBug,
  modifyInformation,
  refreshToken,
  getRegionData,
  fl84Login,
  checkBlackList
};