import { globalData } from "@/context/rootContext";
import { mainOfGame, isFl84, fl84RegionId } from "../utils/envData";

// 过滤可用的gameTap游戏 (已绑定+对应平台已实名)
export const filterGameTagList = (cData, protocols) => {
  const gamePlatformStatus = {};
  const gameSingList = [];
  const realPlatform = cData.realInfo.map(item => item.platform);
  for (const item of protocols) {
    for (const subItem of globalData.baseGameList) {
      if (item.gameTag === subItem.tag && item.sign) {
        gamePlatformStatus[subItem.platform] = true;
        gameSingList.push(item.gameTag);
        break;
      }
    }
  }
  const userGameList = [];
  const newCreatorGamesList = [];
  for (const item of cData.games) {
    // 已实名
    if (realPlatform.includes(item.platform)) {
      if (gameSingList.includes(item.tag)) {
        userGameList.unshift(item.tag);
        newCreatorGamesList.unshift(item);
      } else {
        userGameList.push(item.tag);
        newCreatorGamesList.push(item);
      }
    }
  }
  return { gamePlatformStatus, userGameList, newCreatorGamesList };
};

// 筛选加入时间(多区合一)
export const filterJoinTime = () => {
  const item = globalData.activeGameData;
  const beginTime = (item.createdTime || item.becomeTime || new Date().toLocaleDateString()).slice(0, 10);
  return Math.floor((new Date() - new Date(beginTime)) / (1000 * 60 * 60 * 24));
};

// 筛选discord链接
const filteredDiscord = {};
export const filterDiscordLine = (tag) => {
  if (!tag) { return ""; }
  if (filteredDiscord[tag]) { return filteredDiscord[tag]; }
  for (const item of globalData.regionGameList) {
    if (item.tag === tag && +item.regionId === +globalData.activeGameData.regionId) {
      filteredDiscord[tag] = item.discord;
      return item.discord;
    }
  }
  for (const item of globalData.baseGameList) {
    if (item.tag === tag) {
      filteredDiscord[tag] = item.discord;
      return item.discord;
    }
  }
  return "";
};

/**
 * 处理fl84专区下，用户在其他多个区域重复注册导致的数据合并混乱
 * (后续后台会针对新用户迭代处理，这是针对老用户的过滤逻辑)
 * callback { gameInfo, index }
 */
export const filterGameTagInfo = (tag, games, regionId) => {
  const res = { gameItem: games[0], gameIndex: 0 };
  for (let i = 0; i < games.length; i++) {
    if (games[i].tag === tag) {
      res.gameItem = games[i];
      res.gameIndex = i;
      if (mainOfGame) {
        // 转入以游戏为主
        if (!regionId) { return res; } // 没有regionId数据 旧通知 返回
        if (+regionId === +games[i].regionId) { return res; } //符合 返回
        continue;
      }
      break;
    }
  }
  return res;
};

// 筛选导航栏列表
const filteredGameNavList = [];
export const filterGameNavList = (games) => {
  if (!games.length) {
    return [];
  }
  if (filteredGameNavList.length) {
    return filteredGameNavList;
  }
  const gameData = {};
  const alreadyJoinDiscord = {};
  for (let i = 0; i < games.length; i++) {
    games[i].index = i;
    if (!gameData[games[i].tag]) {
      gameData[games[i].tag] = [];
      alreadyJoinDiscord[games[i].tag] = games[i].alreadyJoinDiscord;
    }
    // 合并alreadyJoinDiscord
    if (alreadyJoinDiscord[games[i].tag]) {
      games[i].alreadyJoinDiscord = true;
    } else if (games[i].alreadyJoinDiscord) {
      alreadyJoinDiscord[games[i].tag] = true;
    }
    // fl84 游戏数据合并
    if (games[i].tag === "farlight84" && gameData[games[i].tag].length > 0) {
      const gamesItem = games[i];
      const locationItem = gameData[gamesItem.tag][0];
      // 合并等级
      if (gamesItem.accountLv > locationItem.accountLv) {
        locationItem.accountLv = gamesItem.accountLv;
      }
      // 合并加入时间
      const t1 = new Date((gamesItem.createdTime || gamesItem.becomeTime || "").slice(0, 10));
      const t2 = new Date((locationItem.createdTime || locationItem.becomeTime || "").slice(0, 10));
      if (t1 < t2) {
        locationItem.createdTime = gamesItem.createdTime;
        locationItem.becomeTime = gamesItem.becomeTime;
      }
      // 合并是否触发加入discord
      if (gamesItem.alreadyJoinDiscord && !locationItem.alreadyJoinDiscord) {
        locationItem.alreadyJoinDiscord = true;
      }
      continue;
    }
    gameData[games[i].tag].push(games[i]);
  }
  const needSubList = Object.keys(gameData).map(item => gameData[item].length).filter(item => item > 1).length;
  if (!needSubList) { return []; }
  const subList = [];
  Object.keys(gameData).forEach(key => {
    const subItem = { value: gameData[key][0].tag, label: key.toUpperCase() };
    if (gameData[key].length > 1) {
      const children = [];
      for (const item of gameData[key]) {
        children.push({ value: `${key}-${item.regionName}`, label: `${key}(${item.regionName})`, data: item });
      }
      subItem.children = children;
    } else {
      subItem.value = key;
      subItem.data = gameData[key][0];
    }
    subList.push(subItem);
  });
  filteredGameNavList.push(...subList);
  return subList;
};

//筛选游戏专区请求用regionId
export const filterRequestRegionId = () => {
  if (isFl84 && globalData.activeGameData.tag === "farlight84") { return fl84RegionId; }
  return globalData.activeGameData.regionId || +globalData.regionConfigId;
};