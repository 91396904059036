import React from "react";
import style from "./index.module.scss";
import NonDisclosure from "../Guide/NonDisclosure";

function Sing() {
  return (
    <div className={style.guide_main}>
      <NonDisclosure task2Box={true}></NonDisclosure>
    </div>
  );
}

export default Sing;