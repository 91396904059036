import React, { useContext, useEffect } from "react";
import { getRegionData } from "@/common/utils/reginUtil";
import { globalData, rootContext } from "@/context/rootContext";
import Api from "@/common/Api/accountManage";
import { locationTipMessage, locationUtil } from "@/common/utils";

const setAbleLang = ["th", "id", "vi", "en"];
let loadTime = 0;

// 定制化数据对应 assets/data/gameDataSea
function SeaEnter(props) {
  const { setEnterRegion, creatorData, setGlobalLang, globalLang, loginStatus } = useContext(rootContext);

  const setRegionData = async () => {
    getRegionData("sea", false);
    const res = await Api.getRegionData();
    if (res.status === 200) {
      globalData.regionConfigId = res.data.regionConfigId;
      globalData.region = res.data.abbreviation;
      setEnterRegion(res.data.abbreviation);
    } else if (loadTime++ < 5) {
      setTimeout(() => { () => setRegionData(); }, 1000);
    }
  };

  // 设置默认语言
  const setDefaultRegion = () => {
    const locationLang = locationUtil.getLocationStorage("lilacSeaLang"); // 用户选择语言倾向
    const lang1 = (navigator.language || navigator.browserLanguage || window.localStorage.getItem("lilacLocale") || ""); // 环境语言倾向
    const lang2 = (localStorage.getItem("lilacRegion") || ""); // IP下语言倾向
    const lang3 = ((creatorData && creatorData.language) || ""); // 注册时语言倾向
    const globalLang = ( locationLang || lang1 || lang2 || lang3 || "en").toLowerCase();
  
    for (const lang of setAbleLang) {
      if (globalLang.indexOf(lang) > -1) {
        globalData.lang = lang;
        setGlobalLang(lang);
        locationUtil.setLocationStorage("lilacSeaLang", lang);
        return;
      }
    }
  };

  useEffect(() => {
    setRegionData();
  }, []);

  useEffect(setDefaultRegion, [creatorData]);

  useEffect(() => {
    if (globalLang === "th") {
      document.documentElement.classList.add("lang_th");
    } else {
      document.documentElement.classList.remove("lang_th");
    }
    // 有用户数据 -> 已登录情况下 保存用户选择语言
    if (creatorData) {
      locationUtil.setLocationStorage("lilacSeaLang", globalLang);
    }
  }, [creatorData, globalLang]);

  // 初始化登录数据
  useEffect(() => {
    // 加载区域数据
    if (!loginStatus.length) { return; } // 无数据 结束
    const regionData = loginStatus.filter(item => item.region.regionAbbreviation.toLowerCase() === "sea")[0];
    if (regionData) {
      getRegionData("sea", true);
      props.savaLoginState(regionData || loginStatus[0]);
    } else {
      locationTipMessage(400202);
    }
  }, [loginStatus]);

  return <>{props.children}</>;
}

export default SeaEnter;