import React, { useContext, useEffect, useMemo, useState } from "react";
import style from "./index.module.scss";
import { Popover } from "antd";

import Api from "@/common/Api/message";
import { rootContext, globalData } from "@/context/rootContext";
import { paddingNum, getWeekDay } from "@/common/utils";
import filterGameData from "@/assets/data/gameData";
import moment from "moment";

function TotalEarnings() {

  const [show, setShow] = useState(false);
  const [resources, setResources] = useState("");
  const [cash, setCash] = useState(0);
  const [month, setMonth] = useState("");
  const { token, creatorData, gameTab, activeGame, langState } = useContext(rootContext);
  const inFl84Game = globalData.activeGameData.tag === "farlight84";
  const earningTipData = useMemo(() => filterGameData("earningTip") || [], [activeGame, creatorData]);
  
  const getDay = () => {
    if (inFl84Game) {
      return getWeekDay(3);
    } else {
      return `${new Date().getFullYear()}-${paddingNum(new Date().getMonth() + 1, 2)}`;
    }
  };

  const getData = async() => {
    const res = await Api.getIncomeData({
      gametag: globalData.activeGameData.tag,
      month: getDay()
    });
    if (res.status === 200) {
      if (+res.data.cash === 0 && !res.data.other && (globalData.activeGameData.tag !== "farlight84")) {
        getLastMonth();
      } else {
        const resourceNone = (globalData.activeGameData.tag === "farlight84" && ((res.data.resources === null && langState.adminHomeData.earning.settionLoad) || "0")) || "";
        setResources(res.data.other || res.data.resources || resourceNone);
        setCash(+res.data.cash || 0);
        setMonth(getDay());
      }
    }
  };

  const getTipDom = () => {
    if (gameTab.length && globalData.activeGameData.tag) {
      const data = earningTipData;
      let tipData = Array.isArray(data) ? data : data[globalData.lang];
      return (
        <div>
          {
            tipData.map((item, index) => <p key={index} dangerouslySetInnerHTML={{ __html: item }}></p>)
          }
        </div>
      );
    }
    return null;
  };

  const getLastMonth = async () => {
    const last = new Date();
    last.setMonth(last.getMonth(), 0);
    const getLastMonth = () => {
      if (inFl84Game) {
        return moment(getDay()).subtract(1, "weeks").format("YYYY-MM-DD");
      } else {
        return `${last.getFullYear()}-${paddingNum(last.getMonth() + 1, 2)}`;
      }
    };
    const lastMonth = getLastMonth();
    const res = await Api.getIncomeData({
      gametag: globalData.activeGameData.tag,
      month: lastMonth
    });
    if (res.status === 200) {
      const resourceNone = (globalData.activeGameData.tag === "farlight84" && ((res.data.resources === null && langState.adminHomeData.earning.settionLoad) || "0")) || "";
      setResources(res.data.other || res.data.resources || resourceNone);
      setCash(+res.data.cash || 0);
      setMonth(lastMonth);
    }
  };

  useEffect(() => {
    if (token && creatorData) {
      getData();
    }
  }, [token, creatorData]);

  return (
    <div className={style.total_earnings_box} id="earning_box">
      <p className={`total_earnings_title ${style.total_earnings_title}`}>
        { langState.adminHomeData.earning.title }
        <Popover content={getTipDom()} trigger="hover" overlayClassName="earning_desc">
          <i className={`question-icon ${style.question}`}>?</i>
        </Popover>
        <i className={`${style.hide} ${show ? style.active : ""}`} onClick={()=>{window.gtag("event", "pc_home_eanings");setShow(!show);}}></i>
      </p>
      <ul>
        <li>
          <p className={`total_earnings_subtitle ${style.total_earnings_subtitle}`}>{ inFl84Game ? langState.adminHomeData.earning.fl84Item1Name : langState.adminHomeData.earning.item1Name }</p>
          <p className={style.num}>
            {month}
          </p>
        </li>
        <li>
          <p className={`total_earnings_subtitle ${style.total_earnings_subtitle}`}>{ langState.adminHomeData.earning.item2Name }</p>
          <p className={`earnings_none ${style.none}`}>{resources}</p>
        </li>
        <li>
          <p className={`total_earnings_subtitle ${style.total_earnings_subtitle}`}>{ langState.adminHomeData.earning.item3Name }</p>
          <p className={style.num}>
            <span className={style.subtxt1}>{ langState.adminHomeData.earning.item3Name }&nbsp;</span>
            {show ? cash : "****"}
            <span className={style.subtxt2} style={{display: show ? "inline" : "none"}}>$</span>
          </p>
        </li>
      </ul>
    </div>
  );
}

const TotalEarningsMemo = React.memo(TotalEarnings);

export default TotalEarningsMemo;
