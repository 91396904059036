import React from "react";
import tierStyle from "@/components/Dialog/TiersExplained/index.module.scss";
import salaryStyle from "@/components/Dialog/RewardsExplained/index.module.scss";

// COD-多语言区
const CODTierExplainedMult = () => (
  <div className={tierStyle.desc}>
    <p className={tierStyle.t1}>Summary:</p>
    <p>As a content creator, you will receive in-game rewards, have the potential to have your content featured on our channels and in-game, access to the Creator Discord Channel, exclusive sneak peeks, early update previews, and monetary compensation. </p>
    <p className={tierStyle.t1}>Creator (Lvl.1)</p>
    <p>Access to the Creator Discord Channel</p>
    <p>Access to Exclusive UGC event for creators</p>
    <p>Access to art assets</p>
    <p>Official support</p>
    <p>Monthly and weekly rewards</p>
    <p className={tierStyle.t1}>Advanced Creator (Lvl.2)</p>
    <p>All of the benefits of the previous tiers</p>
    <p>Cash compensation</p>
    <p>Official promotion of your channel</p>
    <p className={tierStyle.t1}>Contract Creator (Lvl.3):</p>
    <p>All of the benefits of the previous tiers</p>
    <p>Chance to become an officially recognized star creator</p>
    <p>Special partnership opportunities</p>
  </div>
);

const CODExplainedMult = () => (
  <div className={salaryStyle.desc}>
    <p>Once you are above the Creator level, you are eligible for monetary compensation based on your tier and performance. </p>
    <p>Each tier has its own range of monetary benefits. </p>
    <p>Once all formal processes are done, monetary benefits will be distributed through a bank transfer to your provided bank account.</p>
  </div>
);

// 星级描述 多语言区
const tierExplainedListMult = {
  COD: <CODTierExplainedMult />
};

// 收益解释 多语言区
const explainedListMult = {
  COD: <CODExplainedMult />
};

export const regionStoreMult = {
  blockTask: [8],
  tierExplainedList: tierExplainedListMult,
  extraSingUpRule: [<span key="exRule1Mult">Creators who work primarily in the following languages: Arabic, Brazilian Portuguese, Germany, French, Portuguese, Spanish, Russian, Turkish.</span>],
  commitTitle: "Your Discord ID",
  commitDesc: "（Format: name#numer）",
  rewardsExplained: explainedListMult,
  region: "mult"
};