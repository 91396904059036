import errorCode from "@/assets/data/errorCode";
import { Modal, message } from "antd";
import MD5 from "crypto-js/md5";
import "jsencrypt/bin/jsencrypt";

const publicKey = "MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAIu/4KskkdCxuaBW+PzRG3l6cLMv7GFYMEd6kfscRoiABLnhDEZdopoA7rLBhkVNkuNkhZBsBrzRBV/FTlSLhx8CAwEAAQ==";
const encrypt = new window.JSEncrypt();      // 实例化JsEncrypt对象
encrypt.setPublicKey(publicKey);      // 设置公钥

// 密码加密逻辑
export const encryptCode = (data) => {
  const md5 = MD5(data).toString().toLowerCase();
  const encryptResult = encrypt.encrypt(md5);
  // console.log("加密：" + data);
  // console.log("加密 md5：" + md5);
  // console.log("加密 md5 encrypt：" + encryptResult);
  return encryptResult;
};

// 防抖
let debounceTimeout = null;
export const debounceFun = (fun, wait) => {
  return function () {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    let callNow = !debounceTimeout;
    debounceTimeout = setTimeout(() => {
      debounceTimeout = null;
    }, wait);
    if (callNow) {
      fun.apply(this);
    }
  };
};

/*
 * 默认封装错误信息体
 * 接收接口返回错误体进行提示
 * @param {request} res 错误信息体
 * @param {string} other 其他标记文案
 * @param {string} defaultText 默认文案
 * @param {boolean} showCode 是否输出错误码
 */
const showTipOther = false;
export const defaultErrorMessage = (res, other = "", defaultText = errorCode(400122), showCode = false) => {
  const msg = (res && res.data && res.data.message) || defaultText;
  const code = (showCode && ((res && res.data && res.data.statusCode) || res.statusCode || res.status || "")) || "";
  message.error(`${msg} ${code && `(${code})`} ${showTipOther ? other && `(${other})` : ""}`.trim());
};

/**
 * 前端本地提示1 message 本地校验/操作错误提示
 * @param {number} code 
 * @param {string} type 
 * err / info / warn / success
 */
export const locationTipMessage = (code, type = "err") => {
  switch(type) {
    case "err": message.error(errorCode(code)); return;
    case "info": message.info(errorCode(code)); return;
    case "warn": message.warn(errorCode(code)); return;
    case "success": message.success(errorCode(code)); return;
    default: message.error(errorCode(code)); return;
  }
};

// 黑名单提示
export const blacklistedTip = (msg) => {
  Modal.error({
    title: "ERROR",
    content: msg || errorCode(400234)
  });
};

// 递归获取节点距离页面顶部距离
export const getDomTop = e => {
  return e.offsetParent ? (e.offsetTop + getDomTop(e.offsetParent)) : e.offsetTop;
};

// locationStorage简单工具
export const locationUtil = {
  getLocationStorage: (key) => {
    const data = localStorage.getItem(key) || "";
    return data ? JSON.parse(data) : null;
  },
  setLocationStorage: (key, data) => {
    localStorage.setItem(key, JSON.stringify(data));
  }
};

// 获取图片base64
export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

// 给定位数填充0
export const paddingNum = (num, length) => {
  return (Array(length).join("0") + num).slice(-length);
};

// 格式化日期 yyyy-mm-dd hh:mm:ss
export const formatTime = (date = new Date()) => {
  const formatNumber = n => {
    n = n.toString();
    return n[1] ? n : `0${n}`;
  };
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();

  return `${[year, month, day].map(formatNumber).join("-")} ${[hour, minute, second].map(formatNumber).join(":")}`;
};

// 获取最近的指定星期日期
export const getWeekDay = (day, date = null) => {
  const now = date || new Date();
  const nowDay = now.getDay();
  if (nowDay === day) {
    return formatTime(now).slice(0, 10);
  }
  // 周日
  if (nowDay === 0) {
    const weekDay = new Date(now.getTime() - (7 - day) * 24 * 3600 * 1000);
    return formatTime(weekDay).slice(0, 10);
  }
  if (nowDay > day) {
    const weekDay = new Date(now.getTime() - (nowDay - day) * 24 * 3600 * 1000);
    return formatTime(weekDay).slice(0, 10);
  }
  if (nowDay < day) {
    const weekDay = new Date(now.getTime() + (-7 + day - nowDay) * 24 * 3600 * 1000);
    return formatTime(weekDay).slice(0, 10);
  }
};

// Email正则表达式
export const RegEmail = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

const intlFormat = (num) => {
  return new Intl.NumberFormat().format(Math.floor(num * 10) / 10);
};

// 大数简写
export const Abbreviation = (num) => {
  // console.log(num);
  if (num >= 1000000)
    return `${intlFormat(num / 1000000)}M`;
  if (num >= 1000)
    return `${intlFormat(num / 1000)}k`;
  return intlFormat(num);
};

const GACode = {
  "en": "G-DPR619J6QM",
  "mult": "G-GYSER4ZZJQ",
  "sea": "G-SCZMGMSMPP",
  "farlight84": "G-TN8B5X7K66"
};

// 重置谷歌GA埋点
export const setGAUtil = (regionCode) => {
  const id = GACode[regionCode];
  if (!id) { return; }
  const ga_link = `https://www.googletagmanager.com/gtag/js?id=${id}`;
  const el = document.getElementById("g_a");
  if (el) {
    const elScr = el.getAttribute("src");
    if (elScr === ga_link) { return; }
    else { el.remove(); }
  }
  const ga_script = document.createElement("script");
  ga_script.onload = function () {
    const preDataLayer = window.dataLayer.map(item => [item[0], item[1]]);
    window.gtag("js", new Date());
    window.gtag("config", id);
    for (const item of preDataLayer) {
      window.gtag(...item);
    }
  };
  ga_script.setAttribute("async", "async");
  ga_script.setAttribute("id", "g_a");
  ga_script.setAttribute("src", ga_link);
  window.dataLayer = window.dataLayer || [];
  document.body.appendChild(ga_script);
};

// fl84关键词检索
export const fl84Check = (val) => {
  const value = val.toLowerCase();
  return /farlight(\s|_|)84/i.test(value) || value.includes("farlight") || value.includes("fcc");
};

// 深度合并对象
export const deepMergeObject = (target, other) => {
  const targetToString = Object.prototype.toString.call(target);
  const otherToString = Object.prototype.toString.call(target);
  if (targetToString === "[object Object]" && otherToString === "[object Object]") {
    for (let [key, val] of Object.entries(other)) {
      if (!target[key]) {
        target[key] = val;
      } else {
        target[key] = deepMergeObject(target[key], val);
      }
    }
  } else if (targetToString === "[object Array]" && otherToString === "[object Array]") {
    for (let [key, val] of Object.entries(other)) {
      if (target[key]) {
        target[key] = deepMergeObject(target[key], val);
      } else {
        target.push(val);
      }
    }
  }
  return target;
};

const mediaName = {
  "youtube": "YouTube",
  "twitch": "Twitch",
  "tiktok": "TikTok",
  // "facebook": "Facebook"
};

// 格式化社媒名称
export const formatMediaName = (name) => {
  return name ? mediaName[name.toLowerCase()] : "";
};
