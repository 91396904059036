import React, { useContext, useEffect, useState } from "react";
import style from "../index.module.scss";

import Api from "@/common/Api/bindCreator";
import { defaultErrorMessage, locationTipMessage } from "@/common/utils";
import { rootContext } from "@/context/rootContext";
import { mainOfGame, isFl84 } from "@/common/utils/envData";
import LoadMask from "@/components/loadMask";

import fl84Logo from "@/assets/images/fl84Logo.jpg";
import fl84LogoTest from "@/assets/images/fl84Logo_test.jpg";
import { fl84TransformTip } from "@/common/utils/fl84TransformTip";

let loopTimeOut = null;

function DefaultGame(props) {
  const { setDialogIndex, langState } = useContext(rootContext);
  const [gameUID, setGameUID] = useState("");
  const [gameUserName, setGameUserName] = useState("");
  const [gameVerify, setGameVerify] = useState("");
  const [activeGame, setActiveGame] = useState("");
  const [loading, setLoading] = useState(false);
  const [countDown, setCountDown] = useState(0);
  const [checkSuccess, setCheckSuccess] = useState(false);

  const clickGame = (index) => {
    if (props.game.length === 1 && props.game[0].isActive) {
      // 只有一个游戏 且游戏已经触发
      return;
    }
    if (!isFl84 && props.game[index].name === "farlight84") {
      fl84TransformTip();
      return;
    }
    const games = props.game.map(item => {
      item.isActive = false;
      return item;
    });
    games[index].isActive = true;
    setActiveGame(games[index].name);
    props.setActiveGame(games[index].name);
    props.setGame([...games]);
  };

  const inputGameUid = (val) => {
    const value = val.split("").filter(item => /^\d+$/.test(item)).join("");
    setGameUID(value);
    props.setGameUID(value);
    if (activeGame === "farlight84" && checkSuccess) {
      setCheckSuccess(false);
      props.setGameUIDCheck(false);
      setGameVerify("");
      setGameUserName("");
    }
  };

  const inputName = (val) => {
    props.setGameUserName(val);
    setGameUserName(val);
  };

  const inputVerify = (val) => {
    const value = val.split("").filter(item => /^\d+$/.test(item)).join("");
    setGameVerify(value);
  };

  const sendFl84Code = async () => {
    if (!gameUID || gameUID.length <= 0) {
      locationTipMessage(200014);
      return;
    }
    setLoading(true);
    const res = await Api.sendFl84Code(gameUID);
    setLoading(false);
    if (res && res.status === 200) {
      setCountDown(59);
      loopTimeOut = setTimeout(() => loopCountDown(59), 1000);
      locationTipMessage(200019, "info");
    } else {
      defaultErrorMessage(res);
    }
  };

  const checkFl84Code = async () => {
    if (!gameUID) {
      locationTipMessage(200014);
      return;
    }
    if (!gameVerify || gameVerify.length !== 6) {
      locationTipMessage(400105);
      return;
    }
    setLoading(true);
    const res = await Api.checkFl84Code(gameUID, gameVerify);
    setLoading(false);
    if (res && res.status === 200) {
      // 成功 获取用户昵称
      getPartName(gameUID);
    } else {
      defaultErrorMessage(res);
      if (res && res.data && +res.data.code === 400237) {
        clearTimeout(loopTimeOut);
        setCountDown(0);
        return;
      }
    }
  };

  const getPartName = async function (uid) {
    const res = await Api.getFl84NickName2UID(uid);
    if (res && res.status === 200) {
      inputName(res.data);
      setCheckSuccess(true);
      props.setGameUIDCheck(true);
    } else {
      defaultErrorMessage(res, "nickName");
    }
  };

  const loopCountDown = (num) => {
    if (num > 0) {
      setCountDown((val) => val > 1 ? val - 1 : 0);
      loopTimeOut = setTimeout(() => loopCountDown(num - 1), 1000);
    }
  };

  useEffect(() => {
    if (props.game.length === 1) {
      setActiveGame(props.game[0].name);
      props.setActiveGame(props.game[0].name);
    }
  }, [props.game]);

  return (
    <>
      {
        activeGame === "farlight84" && (
          <>
            <p className={style.module_title}>
              {langState.applicationData.mainGameFl84Title}
            </p>
            {
              langState.applicationData.mainGameFl84Desc.map(item => (
                <>
                  <p className={`${style.module_desc} ${style.fl84}`}>{item}</p>
                </>
              ))
            }
          </>
        )
      }
      {
        activeGame !== "farlight84" && (
          <>
            <p className={style.module_title}>
              {langState.applicationData.mainGameTitle}
            </p>
            {
              langState.applicationData.mainGameDesc.map(item => (
                <>
                  <p className={style.module_desc}>{item}</p >
                </>
              ))
            }
          </>
        )
      }
      <ul>
        {
          props.game.map((item, index) => {
            return (
              <li key={item.id} className={`${item.isActive ? style.active : ""}`} onClick={() => clickGame(index)} ><img src={item.icon} /></li>
            );
          })
        }
      </ul>
      {
        activeGame === "farlight84" && (
          <div className={style.gameLogoList}>
            <div className={`${style.gameLogoItem} ${style.active}`}>
              <img src={fl84Logo} />
              <p>Farlight 84 <br />Global Official Server</p>
            </div>
            <div className={`${style.gameLogoItem} ${style.disable}`}>
              <img src={fl84LogoTest} />
              <p>Farlight 84 <br />Vanguard Test Server<br /> (Coming Soon)</p>
            </div>
          </div>
        )
      }
      <br />
      <br />
      <input className={style.game_input} value={gameUID} onChange={(e) => { inputGameUid(e.target.value); }} placeholder={langState.applicationData.uidPlace}></input>
      {
        activeGame === "farlight84" ? (
          <>
            <div className={style.game_codeBox}>
              {
                !checkSuccess ?
                  <>
                    <input className={style.game_input} value={gameVerify} onChange={(e) => { inputVerify(e.target.value); }} placeholder={langState.applicationData.verifiCodPlace} maxLength={6}></input>
                    {countDown <= 0 && <button className={style.verifiBtn} onClick={sendFl84Code}>{langState.applicationData.sendCode}</button>}
                    {countDown > 0 && <p className={style.timeLoad}>{countDown}s</p>}
                    <p className={style.codeDesc} onClick={() => { setDialogIndex(21); }}>{langState.applicationData.whatIsUID}</p>
                  </> :
                  <>
                    <input className={style.game_input} value={gameUserName} readOnly></input>
                  </>
              }
            </div>
            {!checkSuccess && <button className={style.verifiSubmit} onClick={checkFl84Code}>{langState.applicationData.submitBtn}</button>}
            {checkSuccess && <p className={style.completed}><i className={style.check}></i>{langState.applicationData.Verified}</p>}
            {!mainOfGame && <div className={style.slice}></div>}
          </>
        ) : (
          <><input className={style.game_input} value={gameUserName} onChange={(e) => { inputName(e.target.value); }} placeholder={langState.applicationData.userNamePlace}></input></>
        )
      }
      {
        loading && <div className={style.loadingBox}><LoadMask /></div>
      }
    </>
  );
}

export default DefaultGame;
