// 所有数据会进入assets/data/pageData进行过滤
// 顶部nav
const topNavData = {
  signUp: "登録",
  logIn: "ログイン",
  application: "応募フォーム",
  home: "ホーム",
  back: "戻る",
  profile: "プロフィールを見る",
  contact: "お問い合わせ",
  logOut: "ログアウト"
};

// 底部版权
const footerData = {
  service: "利用規約",
  policy: "プライバシーポリシー",
  footerText: "クリエイターゾーンは、LilithおよびFarlight Gamesによって作られた公式コンテンツクリエイタープラットフォームです。",
};

// IGame首页文案
const iGameHomeData = {
  enterYourEmail: "メールアドレスを入力 ",
  password: "パスワード",
  forgotPassword: "パスワードをお忘れですか？",
  emailLogin: "クイックログイン情報をメール送信",
  pwdLogin: "パスワードでログイン",
  signUp1: "アカウントをお持ちでない場合は、",
  signUp2: "登録してください",
  send: "コードを送信",
  codeTip: "6桁のコードを入力",
  desc1: "私たちと正式にコラボして、特別プレビューやツール、最高のコンテンツを制作するためのサポートを得る唯一の方法です！",
  desc2_1: "限定特典を受け取り、コンテンツ制作を次のレベルに引き上げるのに必要なツールを手に入れましょう！",
  desc2_2: "当社スタッフと連絡を取り合い、サポートやヒントを得ましょう。あなたのコンテンツを公式プラットフォームで公開し、チャンネル成長のための支援を提供します！",
  desc2_3: "信頼のおけるクリエイターDiscordの一員となり、世界中のコンテンツクリエイターとつながりましょう！",
  tier1_1: "動画コンテンツクリエイター限定Discordサーバーへの招待",
  tier1_2: "クリエイターDiscordサーバーで、スタッフから1対1のヘルプを受けられる特別チケットシステム",
  tier1_3: "コンテンツ制作に役立つ公式アートアセットへのアクセス",
  tier1_4: "ゲーム内報酬や金銭的報酬を得られる可能性",
  tier2_1: "見習いティアの全特典",
  tier2_2: "公式ソーシャルメディアプラットフォームで自分のコンテンツが紹介される可能性",
  tier3_1: "これまでのティアの全特典",
  tier3_2: "視聴者プレゼントに使えるカスタマイズされたギフトコード",
  tier3_3: "AFKジャーニーの公式イベントに招待される可能性",
  tier3_4: "AFKジャーニーチームと共同でコンテンツを制作する可能性",
  updateText: "最終更新日"
};

// 注册页-选择地区
const signUpLanguageData = {
  title: "ジャーニーはここから始まる		",
  desc: "コンテンツのクリエイトに使用する言語をお選びください		",
  langEn: ["English"],
  langMult: [
    "العربية",
    "Deutsch",
    "Español",
    "Français",
    "Português",
    "Русский",
    "Türkçe"
  ],
  langSea: [
    "Tiếng Việt",
    "ภาษาไทย",
    "Bahasa Indonesia",
    "Bahasa Melayu",
    "Pilipino"
  ]
};

// 注册页
const signData = {
  title: "ジャーニーはここから始まる",
  title2: "登録",
  desc1: "クリエイターゾーンのメンバーになる際は、",
  desc2: "次の要件を確認してください",
  signRule1_1: " ",
  signRule1_link: "コミュニティのガイドライン",
  signRule1_2: "を尊重し、他のクリエイターやコミュニティ管理者と友好的に交流すること。",
  signRule2: "18歳以上であること。",
  signOtherText1: "※上記の要件を満たしていない方は、",
  signOtherText2: " ",
  signOtherLink: "こちらからご応募ください",
  signPlace1: "メールアドレスを入力",
  signPlace2: "ユーザー名を設定",
  signPlace3: "パスワードを設定",
  signNeed1: "パスワードの条件は次のとおりです：",
  signNeed2: "8～20文字",
  signNeed3: "アルファベット、数字、および特殊記号",
  signNeed4_1: "クリエイターゾーンの",
  signNeed4_2: "利用規約",
  signNeed4_3: "と",
  signNeed4_4: "プライバシーポリシー",
  signNeed4_5: "を確認したものとします。",
  accept: "承諾",
  next: "次へ",
  send: "コードを送信",
  codeTip: "6桁のコードを入力",
  accountTip1: "すでにアカウントをお持ちの方は",
  accountTip2: "サインイン",
  accountTip3: "してください"
};

// 个人信息页
const informationData = {
  title1: "アカウント情報を編集する",
  title2: "個人情報",
  accountTitle: "アカウント情報",
  mediaTitle: "ソーシャルメディア",
  gameTitle: "ゲーム",
  gameTag: {
    "video content": "動画コンテンツ",
    "shorts": "ショート",
    "stream": "ストリーム",
  },
  birthdayTitle: "誕生日",
  realNameTitle: "本名",
  firstName: "名",
  laseName: "姓",
  countryTitle: "国または地域",
  languageTitle: "言語",
  discordTitle: "Discord ID",
  discordDesc: "お客様との連絡やコミュニケーションに必要となります",
  uid: "UID",
  tag: "コンテンツクリエイタータグ",
};

// 欢迎/引导页
const guideData = {
  welcomeTitle: ["クリエイターゾーンへようこそ！", " "],
  commRuleTitle: "コミュニティルール",
  commRuleDesc: [
    "1. 尊重",
    "私たちは、誰もが歓迎される環境を作りたいと考えています。言論の自由は非常に重視していますが、攻撃的または受動攻撃的な言動は、警告の対象となり、最終的にはクリエイターゾーンから追放される可能性があります。",
    "2. 差別",
    "いじめやあらゆる差別的言動を禁じます。人種、宗教、文化、性的指向、性別、アイデンティティなどに関する侮辱的な発言は許されません。違反者はクリエイターゾーンから追放される可能性があります。",
    "3. アカウント取引",
    "アカウントの売却、取引、（有料）ブースティングサービスは禁止されています。これらの行為に関与したクリエイターは、クリエイターゾーンから追放される可能性があります。",
    "4. ポルノや不快なコンテンツ",
    "ポルノや不快なコンテンツを配信した場合、クリエイターゾーンから追放される可能性があります。",
    "5. 盗作",
    "すべてのコンテンツは自分の創作物でなければなりません。盗作を行った場合、クリエイターゾーンから追放される可能性があります。",
    "6. 最後に",
    "ルールとしてここに記載されていないからといって、クリエイターによるそれらの行為を容認するわけではありません。Lilith GamesおよびFarlight Gamesは、これらのルールの最終的な解釈に関するすべての権利を留保します。",
  ],
  agreeBtn: "同意する",
  commRuleTip: "コミュニティルールをすべてお読みください",
  nonRuleTitle: "秘密保持契約",
  nonRuleTitle2: "プライバシーポリシー",
  nonRuleDescSub: [
    "1. 当プラットフォームを通じて提供するアートアセット、パッチプレビュー、その他のアセットについては、正式リリースまで秘密扱いとします。いかなる場合においても、公式スタッフの許可なく素材を流出させたり、展示したりすることは許されません。",
    "2. 本プラットフォームのいかなる秘密情報やコンテンツも、共有することを禁じます。",
    "&nbsp;",
    "守秘義務",
  ],
  nonRuleDesc: [
    "1.1 コンテンツクリエイターは、コンテンツクリエイターとクライアントの関係が高い信頼と信用に基づくものであり、コンテンツクリエイターがクライアントにサービスを提供する過程で、コンテンツクリエイターが特定の情報（以下「秘密情報」）にアクセスし接触することを認めるものとします。コンテンツクリエイターは、コンテンツクリエイターの過失なくして当該秘密情報が公知となる場合を除き、終了中または終了後のいずれにおいても、クライアントの役員による書面による承認なしに、秘密情報をクライアントの従業員以外の個人または団体に開示したり、いかなる目的（サービスの履行を除く）にも使用したりしないものとします。",
    "1.2 本契約において、秘密情報とは、クライアントの事業、取引関係または財務に関する、クライアントが所有、保有または使用する、私的、秘密または機密の性質を有する、書面によるか否か、特許可能か否か、著作権可能か否かを問わず、例示であり限定ではないすべての情報を意味するものとします。これには、クライアントに対するコンテンツクリエイターとしてのコンテンツクリエイターのサービスの過程で、コンテンツクリエイターが伝達され、知り、開発され、またはその他の方法で取得した発明、処方、ベンダー情報、顧客情報、装置、機器、企業秘密、プロセス、研究、レポート、技術または研究データ、臨床データ、ノウハウ、コンピュータプログラム、ソフトウェア、ソフトウェア文書、ハードウェア設計、技術、製品、プロセス、方法、技術、処方、化合物、プロジェクト、 開発、マーケティングまたは事業計画、予測、未発表の財務諸表、予算、ライセンス、価格、コスト、顧客、サプライヤーまたは人事情報または従業員リストが含まれますが、これらに限定されるものではありません。",
    "1.3 本第6条に基づくコンテンツクリエイターの義務は、（i）コンテンツクリエイターまたはその他の者による本第6条の条項の違反がない状況下で一般に知られている、または知られるようになった情報、（ii）クライアントが第三者に制限なく一般に開示した情報、または（iii）クライアントの役員の書面による承認により公開が承認された情報には適用されないものとします。",
    "1.4 コンテンツクリエイターは、コンテンツクリエイターが作成したか否かを問わず、コンテンツクリエイターが保管または所有するすべてのファイル、文書、手紙、覚書、報告書、記録、データスケッチ、図面、モデル、実験ノート、プログラムリスト、コンピュータ機器または装置、コンピュータプログラム、または機密情報を含むその他の書面、写真、またはその他の有体物が、コンテンツクリエイターがクライアントのためにコンテンツクリエイターの職務を遂行するためにのみ使用されるクライアントの独占的財産であり、クライアントの事業を追求する場合を除き、クライアントの敷地内からコピーまたは持ち出ししないことに同意するものとします。コンテンツクリエイターが保管または所持する当該素材またはそのコピーおよびクライアントのすべての有形財産は、（i）クライアントによる要求または（ii）本契約の終了のいずれか早い時点で、クライアントに引き渡されるものとします。当該引渡し後、コンテンツクリエイターは、当該素材もしくはそのコピーまたは当該有形財産を保持しないものとします。",
    "1.5 コンテンツクリエイターは、コンテンツクリエイターに課される上記（2）および（4）に定める種類の情報および素材を開示または使用しない義務、ならびにコンテンツクリエイターに課される上記（4）に定める素材および有形財産を返却する義務が、クライアントの顧客もしくはクライアントへのサプライヤー、またはクライアントもしくはコンテンツクリエイターに開示もしくは委託した可能性のあるその他の第三者の当該種類の情報、素材および有形財産にも及ぶことに同意するものとします。",
    "1.6 コンテンツクリエイターは、クライアントが随時、他の個人または政府もしくはその機関と、当該契約に基づく業務中になされた発明または当該業務の秘密性に関する責務または制限がクライアントに課される契約を締結する可能性があることを認めるものとします。コンテンツクリエイターは、コンテンツのクリエイターに知られているそのような責務や制限のすべてに拘束されること、およびそのような契約に基づくクライアントの責務を履行するのに必要なすべての行動をとることに同意するものとします。"
  ],
  nonSignText: "私はすでに秘密保持契約を読み、それに同意しています",
  configBtn: "確認",
  completeTitle: "チュートリアルを完了してください",
  finishTip: [
    "チュートリアル完了おめでとうございます！",
    "クリエイターゾーンへようこそ！"
  ],
  guideTask: {
    "1": "クリエイターゾーンのメンバーになる",
    "2": "ログインを完了する",
    "3": "クリエイター通知",
    "4": "秘密保持契約",
    "5": "入力済みの個人情報",
    "6": "ソーシャルメディアアカウントを紐付けする",
    "7": "クリエイターティアについて ",
    "8": "ウェルカム動画",
    "9": "クリエイターDiscordチャンネルに参加する",
  }
};

// 申请表单页
const applicationData = {
  applicationTitle: "応募フォームにご記入ください。",
  welcome: "ようこそ",
  applicationDesc: [
    "応募フォームに必要事項を正確にご記入ください。",
    "申請が承認されると、クリエイターゾーンにアクセスできるようになります！"
  ],
  mediaModeTitle: "1. ソーシャルメディアプラットフォームの選択",
  mediaModeDesc: "少なくとも1つのソーシャルメディアアカウントを紐付けして、自分のものであることを確認してください。",
  mediaUploadText: "ご自身のチャンネルのダッシュボードのスクリーンショットをアップロードしてください",
  createTypeTitle: "2. 制作するコンテンツのタイプ",
  createTypeDesc: "次の中から少なくとも1つ選択してください。",
  mainGameTitle: "3. メインゲームの選択",
  mainGameDesc: [
    "コンテンツ制作を行うゲームを次の中から1つ選んでください。",
    "選択したゲームは、クリエイターゾーンのメンバーになった後で追加したり調整したりできます。"
  ],
  uidPlace: "ご自身のUID（数字のみ）",
  userNamePlace: "ご自身のユーザー名",
  verifiCodPlace: "認証コード",
  userDetailTitle: "お客様についてよりよく知るために",
  userDetailSubTitle1: "誕生日を入力してください",
  userDetailSubDesc1: "※この情報は、承認後に変更することはできません。",
  day: "日",
  month: "月",
  year: "年",
  userDetailSubTitle2: "ご自身の本名を入力してください",
  firstName: "名",
  laseName: "姓",
  userDetailSubTitle3: "言語",
  userDetailSubTitle4: "国または地域",
  userDetailSubTitle5: "国または地域",
  discordTitle: "ご自身のDiscord ID",
  discordDesc: "当方からご連絡させていただくためのものです",
  submitBtn: "送信",
  confirm: "確認",
};

// 管理内页-导航
const adminNavData = {
  home: "ホーム",
  gameFeed: "ゲームフィード",
  artAssets: "アートアセット",
  myContent: "マイコンテンツ",
  uploadContent: "コンテンツのアップロード",
  manageContent: "コンテンツの管理",
  viewEarnings: "収益を見る",

  // m
  growth: "成長と発展",
};

// 管理内页-首页
const adminHomeData = {
  // 数据总浏
  overview: {
    title: "コンテンツ概要",
    navItem1: "データ概要",
    navItem2: "月間コンテンツ概要",
    totalCount: "総コンテンツ数",
    totalView: "総再生回数",
    totalLike: "総高評価数",
    totalComments: "総コメント数"
  },
  // 推送
  message: {
    more: "その他"
  },
  // 收益
  earning: {
    title: "収益状況",
    item1Name: "月",
    item2Name: "ゲーム内報酬",
    item3Name: "現金",

    settionLoad: "精算中"
  },
  userDetail: {
    welcome: " ",
    welcome2: "さん、おかえりなさい",
    timeText: "このプラットフォームのメンバーになってから",
    timeUnit1: "日",
    timeUnit2: "日",
  }
};

// 管理内页-素材推送
const artAssetsData = {
  artAssetsTitle: "アートアセット",
  selectPlace: "タイトル/キーワードで選ぶ",
  googleLinkBtn: "Google Driveリンク",
  request: "ダウンロードのリクエスト",
  download: "downloading",
  requested: "リクエスト済み",
  noTip: "データなし"
};

// 管理内页-游戏资讯
const gameFeedData = {
  gameFeedTitle: "ゲームフィード",
  selectPlace: "タイトル/キーワードで選ぶ",
  noTip: "データなし",

  // fl84
  gameFeedTitle2: "お知らせ"
};

// 管理内页-收益
const earningData = {
  earningTitle: "収益を見る",
  subTitle: "月間収益",
  item1Name: "月",
  item2Name: "ゲーム内報酬",
  item3Name: "現金",
  rewards: "報酬について",


  detailTitle1: "報酬について",
  detailSubTitle1: "動画一覧",
  detailTimeTitle: "精算期間：",
  detailDesc: "この期間に公開された動画のみが対象となります。",
  noData: "データなし",
  detailRewards: "期間ダイヤモンド報酬：",
  detailRewardsUnit: "ダイヤモンド",
  detailRewardsDescTitle: "精算日: ",
  detailRewardsDesc1_1: "再生回数のデータは、",
  detailRewardsDesc1_2: "精算日",
  detailRewardsDesc1_3: "にソーシャルメディアプラットフォームが記録したデータに基づいています。この報酬の詳細について疑問がある場合は、Discordのキャプテンかカスタマーサービスにお問い合わせください。",
  detailUnit1: "コンテンツタイトル",
  detailUnit2: "再生回数",
  detailUnit3: "公開日",
  detailUnit4: "チャンネル",
};

// 管理内页-上传
const upLoadContentData = {
  uploadTitle1: "コンテンツのアップロード",
  upLoadPlace1: "コンテンツのURLをここに挿入してください",
  submitBtn: ["確認", "コンテンツのアップロード", "送信"],
  time: "タイムスタンプ",
  rightNow: "現時点",
  itemTitle1: "総再生回数",
  itemTitle2: "総高評価数",
  itemTitle3: "総コメント数",
  step1: "ステップ1: <span>クリエイタータスクまたは通常アップロードを選択する</span>",
  step2: "ステップ2: <span>コンテンツをアップロードする</span>",
  taskDetail: "詳細",
  taskTitle: ["テーマ", "時間", "報酬"],
  normalUpload: "通常のコンテンツアップロード",
  taskEvent: "現在の投稿イベント：",
  addUrl: "コンテンツのURLを追加",
  // fl84
  uploadDesc1: "注：投稿動画のタイトルにはキーワード「Farlight 84」を、そして説明文には「#Farlight84」と「#FCC」を含める必要があります。",
};

// 管理内页-视频管理
const uploadManageData = {
  title: "マイコンテンツの管理",
  selectPlace: "タイトル/キーワードで選ぶ",
  eventPlace: "動画投稿イベント",
  totalCount: "総コンテンツ数",
  totalView: "総再生回数",
  totalLike: "総高評価数",
  totalComments: "総コメント数",
  delete: "削除"
};

// 提交申请表单成功
const signTip = "おめでとうございます！クリエイターゾーンへの申請が承認されました。弊社プラットフォームへの完全なアクセス権を得る前に、メールに送信されたクリエイター契約に署名してください。";

// 注册协议弹窗
const communityTip = [
  "1. 尊重",
  "私たちは、誰もが歓迎される環境を作りたいと考えています。言論の自由は非常に重視していますが、攻撃的または受動攻撃的な言動は、警告の対象となり、最終的にはクリエイターゾーンから追放される可能性があります。",
  "2. 差別",
  "いじめやあらゆる差別的言動を禁じます。人種、宗教、文化、性的指向、性別、アイデンティティなどに関する侮辱的な発言は許されません。違反者はクリエイターゾーンから追放される可能性があります。",
  "3. アカウント取引",
  "アカウントの売却、取引、（有料）ブースティングサービスは禁止されています。これらの行為に関与したクリエイターは、クリエイターゾーンから追放される可能性があります。",
  "4. ポルノや不快なコンテンツ",
  "ポルノや不快なコンテンツを配信した場合、クリエイターゾーンから追放される可能性があります。",
  "5. 盗作",
  "すべてのコンテンツは自分の創作物でなければなりません。盗作を行った場合、クリエイターゾーンから追放される可能性があります。",
  "6. 最後に",
  "ルールとしてここに記載されていないからといって、クリエイターによるそれらの行為を容認するわけではありません。Lilith GamesおよびFarlight Gamesは、これらのルールの最終的な解釈に関するすべての権利を留保します。",
];

// 弹窗文案
const dialogText = {
  login: "ログイン",
  enterYourEmail: "メールアドレスを入力",
  enterYourPassword: "パスワードを入力してください",
  password: "パスワード",
  forgotPassword: "パスワードをお忘れですか？",
  emailQuickLogin: "クイックログイン情報をメール送信",
  signTip: "アカウントをお持ちでない場合は、",
  signIn: "登録してください",
  codeTip: "6桁のコードを入力",
  send: "コードを送信",
  set: "新しいパスワードを設定する",
  next: "次へ",
  setNew: "新しいパスワードを設定してください",
  confirmPassword: "パスワードを確認してください",
  confirm: "確認",
  allowAuthorize: "データ取得の承認可否",
  allow: "許可する",
  refuse: "拒否する",
  applicationText1: "申請書が送信されました。",
  applicationText2: "5営業日以内にメールでフィードバックをお送りします。",
  verify: "現在のパスワードを確認する",
  signAgreement: "契約に署名する",
  upLoadTip: "ご自身のチャンネルのダッシュボードのスクリーンショットをアップロードしてください",
  save: "保存",
  setNewName: "新しいユーザー名を設定する",
  setNameNeed: "ユーザー名は3カ月に1度変更可能です",
  userNameSet: "ユーザー名が設定されました",
  userNameVerify: "2～24文字で設定してください",
  gameTag: "ゲームタグ",
  mediaTitle: "ソーシャルメディアの紐付け",
  tiersTitle: "クリエイターティアについて",
  bugTitle: "バグの報告",
  bugTitle2: "タイトル",
  bugTitle3: "説明",
  bugMax: "50字以内",
  bugMax2: "1000字以内",
  bugTypeTitle: "種類",
  bugType1: "アカウントの問題",
  bugType2: "クリエイター申請",
  bugType3: "メディアの紐付け",
  bugType4: "ゲームの紐付け",
  bugType5: "アートアセット",
  bugType6: "コンテンツのアップロード",
  bugType7: "収益管理",
  bugType8: "その他",
  bugUpload: "複数の画像を3点までアップロード可",
  submit: "送信",
  explainedTitle: "報酬について",
  googleTip1: "YouTubeアカウントを紐付けする",
  googleTip2: "まずGoogleアカウントにログインしてください",
  waitAppText1: "申請を受け取りました！",
  waitAppText2: "審査には5営業日程度かかります。",
  waitAppText3: "審査結果はメールでお知らせします。",
};

export default {
  topNavData,
  footerData,
  signUpLanguageData,
  signData,
  informationData,
  guideData,
  applicationData,
  adminNavData,
  adminHomeData,
  artAssetsData,
  gameFeedData,
  earningData,
  upLoadContentData,
  uploadManageData,
  signTip,
  communityTip,
  dialogText,
  iGameHomeData
};