import React, { useContext } from "react";

import { rootContext, globalData } from "@/context/rootContext";
import { mainOfGame } from "@/common/utils/envData";

import DefaultUploadContent from "./DefaultUploadContent";
import ActiveUploadContent from "./ActivitiesUploadContent";

function UploadContent() {
  const { activeContentList } = useContext(rootContext);
  const key = mainOfGame ? `${globalData.activeGameData.tag}-${globalData.activeGameData.regionName}` : globalData.activeGameData.tag;
  const isActive = Array.isArray(activeContentList[key]) && activeContentList[key].filter(item => +item.enable).length > 0;
  return isActive ? <ActiveUploadContent /> : <DefaultUploadContent />;
}

export default UploadContent;
