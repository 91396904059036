import React, { useContext, useEffect, useState } from "react";
import style from "./index.module.scss";
// import { Popover } from "antd";
import Api from "@/common/Api/content";
import { rootContext, globalData } from "@/context/rootContext";
import { Menu, Radio, DatePicker } from "antd";
import { formatTime, Abbreviation, formatMediaName } from "@/common/utils";
import moment from "moment";
const { RangePicker } = DatePicker;

const dateFormat = "YYYY-MM-DD";

function ContentOveriew() {

  const { ableMedia, creatorData, langState } = useContext(rootContext);
  const [current, setCurrent] = useState({});
  const [monthCurrent, setMonthCurrent] = useState({});
  const [typeKey, setTypeKey] = useState("total");
  const [choseMedia, setChoseMedia] = useState("");
  const [chooseDay, setChooseDay] = useState([]);

  const getMonthContent = async (m_mTag) => {
    const publishTimeRange = [chooseDay[0].format("YYYY-MM-DD"), chooseDay[1].format("YYYY-MM-DD")].join(",");
    const tag = m_mTag || ableMedia.join(",");
    const res = await Api.getMediaData(tag, publishTimeRange, globalData.activeGameData.tag);
    if (res && res.status === 200) {
      setMonthCurrent(res.data);
    }
  };

  const getTotalContent = async (m_mTag) => {
    const tag = m_mTag || ableMedia.join(",");
    const res = await Api.getMediaData(tag, null, globalData.activeGameData.tag);
    if (res && res.status === 200) {
      setCurrent(res.data);
    }
  };

  const handleMediaChange = (item) => {
    const newTag = item.target.value;
    if (newTag !== choseMedia) {
      setChoseMedia(newTag);
      if (typeKey === "total") {
        getTotalContent(newTag);
        window.gtag("event", "content_total");
      } else {
        getMonthContent(newTag);
        window.gtag("event", "content_month");
      }
    }
  };

  const changeOverview = (key) => {
    setTypeKey(key);
    if (typeKey === "total") {
      getTotalContent();
    } else {
      getMonthContent();
    }
  };

  const dateChange = (date) => {
    if (date && date[0] && date[1]) {
      setChooseDay(date);
    }
  };

  useEffect(() => {
    if (chooseDay[0] && chooseDay[1] && globalData.activeGameData.tag) {
      getMonthContent();
    }
  }, [creatorData, chooseDay]);

  useEffect(() => {
    if (ableMedia.length && creatorData && globalData.activeGameData.tag) {
      setChoseMedia(ableMedia.join(","));
      getTotalContent();
    }
  }, [ableMedia, creatorData]);

  // 获取近30天日期 
  useEffect(() => {
    const day_30 = new Date(new Date() - 1000 * 60 * 60 * 24 * 30);
    setChooseDay([moment(formatTime(day_30).slice(0, 10), dateFormat), moment(formatTime().slice(0, 10), dateFormat)]);
  }, []);

  return (
    <div className={style.content_box}>
      <p className={`content_title ${style.content_title}`}>
        { langState.adminHomeData.overview.title }
        {/* <Popover content={tipTxt} trigger="hover">
          <i>?</i>
        </Popover> */}
      </p>
      <div className={`${style.overview_list} list-menu`}>
        <Menu mode="horizontal" selectedKeys={typeKey} onClick={(e) => { changeOverview(e.key); }}>
          <Menu.Item key="total">{langState.adminHomeData.overview.navItem1}</Menu.Item>
          <Menu.Item key="month">{langState.adminHomeData.overview.navItem2}</Menu.Item>
        </Menu>
        {
          typeKey === "month" && (
            <div className={`${style.date} list-menu-date`}>
              <RangePicker onChange={dateChange} value={chooseDay} allowClear={false} placeholder={[langState.uploadManageData.sTime, langState.uploadManageData.eTime]}/>
            </div>
          )
        }
      </div>
      <div className={`${style.media_list} list-menu-sub`}>
        <Radio.Group size="large" value={choseMedia} onChange={handleMediaChange}>
          <Radio.Button value={ableMedia.join(",")}>ALL</Radio.Button>
          {
            ableMedia.map(item => {
              return (
                <Radio.Button key={item} value={item}>
                  {formatMediaName(item)}
                </Radio.Button>
              );
            })
          }
        </Radio.Group>
      </div>
      <ul className={style.data_list}>
        {
          typeKey === "total" ? (
            <>
              <li>
                <p className={`content_subtitle ${style.content_subtitle}`}>{langState.adminHomeData.overview.totalCount}</p>
                <p className={style.num}>{(current.contents && Abbreviation(+current.contents)) || 0}</p>
              </li>
              <li>
                <p className={`content_subtitle ${style.content_subtitle}`}>{langState.adminHomeData.overview.totalView}</p>
                <p className={style.num}>{(current.viewCount && Abbreviation(+current.viewCount)) || 0}</p>
              </li>
              <li>
                <p className={`content_subtitle ${style.content_subtitle}`}>{langState.adminHomeData.overview.totalLike}</p>
                <p className={style.num}>{(current.starCount && Abbreviation(+current.starCount)) || 0}</p>
              </li>
              <li>
                <p className={`content_subtitle ${style.content_subtitle}`}>{langState.adminHomeData.overview.totalComments}</p>
                <p className={style.num}>{(current.commentCount && Abbreviation(+current.commentCount)) || 0}</p>
              </li>
            </>
          ) : (
            <>
              <li>
                <p className={`content_subtitle ${style.content_subtitle}`}>{langState.adminHomeData.overview.totalCount}</p>
                <p className={style.num}>{(monthCurrent.contents && Abbreviation(+monthCurrent.contents)) || 0}</p>
              </li>
              <li>
                <p className={`content_subtitle ${style.content_subtitle}`}>{langState.adminHomeData.overview.totalView}</p>
                <p className={style.num}>{(monthCurrent.viewCount && Abbreviation(+monthCurrent.viewCount)) || 0}</p>
              </li>
              <li>
                <p className={`content_subtitle ${style.content_subtitle}`}>{langState.adminHomeData.overview.totalLike}</p>
                <p className={style.num}>{(monthCurrent.starCount && Abbreviation(+monthCurrent.starCount)) || 0}</p>
              </li>
              <li>
                <p className={`content_subtitle ${style.content_subtitle}`}>{langState.adminHomeData.overview.totalComments}</p>
                <p className={style.num}>{(monthCurrent.commentCount && Abbreviation(+monthCurrent.commentCount)) || 0}</p>
              </li>
            </>
          )
        }
      </ul>
    </div>
  );
}

const ContentOveriewMemo = React.memo(ContentOveriew);

export default ContentOveriewMemo;