import React, { useContext, useEffect, useState } from "react";
import { Upload, Modal } from "antd";
import { rootContext } from "@/context/rootContext";

import Api from "@/common/Api/bindCreator";
import { upLoadPath } from "@/common/Api/webApi";
import { defaultErrorMessage, formatMediaName } from "@/common/utils";

import style from "./index.module.scss";

import MediaData from "./updateMedia";

import tiktokExample from "@/assets/images/tiktokexample.jpg";
import twitchExample from "@/assets/images/twitchexample.jpg";
import youtubeExample from "@/assets/images/youtubeexample.jpg";
import errorCode from "@/assets/data/errorCode";

let nextClickAble = true;

function Platform(props) {

  const { platform, setPlatform, setPlatformMedia } = props;
  const { token, setTipContent, setDialogIndex, ableMedia, langState } = useContext(rootContext);

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [choosePlatForm, setChoosePlatForm] = useState("");
  const [youtubeFileList, setYoutubeFileList] = useState([]);
  const [tiktokFileList, setTiktokFileList] = useState([]);
  const [twitchFileList, setTwitchFileList] = useState([]);

  const clickPlatForm = (platform, index) => {
    for (const item of platform) { item.isActive = false; }
    platform[index].isActive = true;
    if (!platform[index].isLogin && nextClickAble) {
      nextClickAble = false;
      const randomNum = [0, 0, 0].map(() => Math.floor(Math.random() * 10)).join("");
      platform[index].formId = `${new Date().getTime() % 1E3}${randomNum}`;
      getMediaURL(platform[index].name, platform[index].formId);
      setChoosePlatForm(platform[index].name);
    }
    setPlatform([...platform]);
  };

  const getMediaURL = async (tag, formId) => {
    const res = await Api.getMediaURL({
      mInfoTag: tag,
      formId: formId
    });
    if (res.status === 200) {
      if (tag === "youtube") {
        setDialogIndex(18);
        setTimeout(() => {
          document.getElementById("youtube_google").innerHTML = `<a href="${res.data}" target="_black" rel="opener">`;
        }, 100);
      } else {
        window.open(res.data);
      }
    } else {
      defaultErrorMessage(res, "applicationAuthorizeFl84");
    }
    nextClickAble = true;
  };

  const openBindTip = () => {
    Modal.error({
      title: "ERROR",
      content: errorCode(400108)
    });
  };

  const handleUpload = (info, tag) => {
    // console.log(info);
    const file = info.file;
    const isIMG = file.type === "image/png" || file.type === "image/jpeg";
    let tagIndex = 0;

    if (!isIMG) {
      Modal.error({
        title: "ERROR",
        content: errorCode(400121)
      });
      return false;
    }

    if (tag === "youtube") { tagIndex = 0; }
    else if (tag === "tiktok") { tagIndex = 1; }
    else if (tag === "twitch") { tagIndex = 2; }

    if (!platform[tagIndex].isLogin) {
      Modal.error({
        title: "ERROR",
        content: errorCode(400217)
      });
      return false;
    }

    const list = [...info.fileList];
    const urlList = [];
    for (const item of list) {
      if (item.response && item.response.url) {
        urlList.push(item.response.url);
      }
    }
    setPlatform([...platform]);
    if (tag === "youtube") {
      setYoutubeFileList(list);
      platform[0].prove = urlList.join(",");
    } else if (tag === "tiktok") {
      setTiktokFileList(list);
      platform[1].prove = urlList.join(",");
    } else if (tag === "twitch") {
      setTwitchFileList(list);
      platform[2].prove = urlList.join(",");
    }
    return true;
  };

  const handBeforeload = (file, tagIndex) => {
    const isIMG = file.type === "image/png" || file.type === "image/jpeg";
    if (!isIMG || !platform[tagIndex].isLogin) {
      return false;
    }
    if (!platform[tagIndex].isLogin) {
      return false;
    }
    return true;
  };

  const upLoadStatus = {
    action: upLoadPath,
    accept: "image/png, image/jpeg",
    headers: {
      Authorization: `Bearer ${token}`
    },
    listType: "picture-card",
    defaultFileList: [],
  };

  const youtubeUpLoadStatus = {
    ...upLoadStatus,
    fileList: youtubeFileList,
    beforeUpload: (file) => { return handBeforeload(file, 0); },
    onChange: (info) => { handleUpload(info, "youtube"); }
  };

  const tiktokUpLoadStatus = {
    ...upLoadStatus,
    fileList: tiktokFileList,
    beforeUpload: (file) => { return handBeforeload(file, 1); },
    onChange: (info) => { handleUpload(info, "tiktok"); }
  };

  const twitchUpLoadStatus = {
    ...upLoadStatus,
    fileList: twitchFileList,
    beforeUpload: (file) => { return handBeforeload(file, 2); },
    onChange: (info) => { handleUpload(info, "twitch"); }
  };

  const handleCancel = () => {
    setPreviewVisible(false);
    setPreviewImage("");
  };

  const openImg = (path) => {
    setPreviewVisible(true);
    setPreviewImage(path);
  };

  const handlePreview = (file) => {
    openImg(file.response.url);
    // console.log(file);
  };

  const setPlatFormSataus = (msg) => {
    if (msg && msg.data) {
      try {
        const data = JSON.parse(msg.data);
        if (data.type === "authorization") {
          const p = data.platform || choosePlatForm;
          for (const item of platform) {
            if (item.name === p) {
              item.isLogin = true;
              setPlatform([...platform]);
              return;
            }
          }
        }
      } catch (e) { null; }
    }
  };

  const showYoutubeExanple = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setTipContent("<div><img src='" + youtubeExample + "'}></img></div>");
    setDialogIndex(3);
  };

  const showTikTokExanple = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setTipContent("<div><img src='" + tiktokExample + "'}></img></div>");
    setDialogIndex(3);
  };

  const showTwitchExanple = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setTipContent("<div><img src='" + twitchExample + "'}></img></div>");
    setDialogIndex(3);
  };

  useEffect(() => {
    const messageCallback = setPlatFormSataus;
    window.addEventListener("message", messageCallback);
    return () => { window.removeEventListener("message", messageCallback); };
  }, [platform, choosePlatForm]);

  return (
    <div className={`${style.video_platform} application-media`}>
      <p className={style.module_title}>{langState.applicationData.mediaModeTitle}</p>
      <p className={style.module_desc} dangerouslySetInnerHTML={{ __html: langState.applicationData.mediaModeDesc }}></p>
      <ul>
        {
          platform.map((item, index) => {
            if (ableMedia.includes(item.name)) {
              return (
                <li
                  key={item.id}
                  className={`${style[`icon${item.id}`]} ${item.isActive ? style.active : ""}`}
                  onClick={() => { clickPlatForm(platform, index); }}>
                  <i className={style.media_logo}></i>
                  {formatMediaName(item.name)}
                  <i className={`${style.check} ${item.isLogin ? style.active : ""}`}></i>
                </li>
              );
            }
          })
        }
      </ul>
      {/* youtube */}
      <div className={style.platform_upload} style={{ display: platform[0].isActive ? "block" : "none" }}>
        <div className={style.step1}><span>01</span></div>
        <Upload {...youtubeUpLoadStatus} onPreview={handlePreview}>
          {
            platform[0].prove ? null : (
              <div className={style.upload_default}>
                <div className={style.slice}></div>
                <i className={style.upload_icon1}></i>
                <i className={style.upload_icon2}></i>
                <div className={style.img_box} onClick={(e) => showYoutubeExanple(e)}>
                  <div className={style.border1}></div>
                  <div className={style.border2}></div>
                  <div className={style.border3}></div>
                  <div className={style.border4}></div>
                  <img src={youtubeExample}></img>
                </div>
                <div className={style.youtubeIcon}>
                  <span>{formatMediaName("youtube")}</span>
                </div>
                <p>{ langState.applicationData.mediaUploadText }</p>
              </div>
            )
          }
        </Upload>
      </div>
      <MediaData platform={platform} index={0} saveMedia={setPlatformMedia} setPlatform={setPlatform} />

      {/* tiktok */}
      <div className={style.platform_upload} style={{ display: platform[1].isActive ? "block" : "none" }}>
        <div className={style.step1}><span>01</span></div>
        <Upload {...tiktokUpLoadStatus} onPreview={handlePreview}>
          {
            platform[1].prove ? null : (
              <div className={style.upload_default}>
                <i className={style.upload_icon1}></i>
                <i className={style.upload_icon2}></i>
                <div className={style.img_box} onClick={(e) => showTikTokExanple(e)}>
                  <div className={style.border1}></div>
                  <div className={style.border2}></div>
                  <div className={style.border3}></div>
                  <div className={style.border4}></div>
                  <img src={tiktokExample}></img>
                </div>
                <div className={style.tiktokIcon}>
                  <span>{formatMediaName("tiktok")}</span>
                </div>
                <p>{ langState.applicationData.mediaUploadText }</p>
              </div>
            )
          }
        </Upload>
      </div>
      <MediaData platform={platform} index={1} saveMedia={setPlatformMedia} setPlatform={setPlatform} />

      {/* twitch */}
      <div className={style.platform_upload} style={{ display: platform[2].isActive ? "block" : "none" }}>
        <div className={style.step1}><span>01</span></div>
        <Upload {...twitchUpLoadStatus} onPreview={handlePreview}>
          {
            platform[2].prove ? null : (
              <div className={style.upload_default}>
                <i className={style.upload_icon1}></i>
                <i className={style.upload_icon2}></i>
                <div className={style.img_box} onClick={(e) => showTwitchExanple(e)}>
                  <div className={style.border1}></div>
                  <div className={style.border2}></div>
                  <div className={style.border3}></div>
                  <div className={style.border4}></div>
                  <img src={twitchExample}></img>
                </div>
                <div className={style.twitchIcon}>
                  <span>{formatMediaName("twitch")}</span>
                </div>
                <p>{ langState.applicationData.mediaUploadText }</p>
              </div>
            )
          }
        </Upload>
      </div>
      <MediaData platform={platform} index={2} saveMedia={setPlatformMedia} setPlatform={setPlatform} />

      {/* default */}
      <div className={`${style.platform_upload} ${style.platform_upload_default}`} style={{ display: !platform[0].isActive && !platform[1].isActive && !platform[2].isActive ? "block" : "none" }}>
        {/* <div className={style.step1}><span>01</span></div> */}
        <div className={style.upload_default} onClick={openBindTip}>
          <i className={`${style.upload_icon1} ${style.center}`}></i>
          <i className={`${style.upload_icon2} ${style.center}`}></i>
          <p className={style.default_tip}>{ langState.applicationData.mediaUploadText }</p>
        </div>
      </div>
      <Modal open={previewVisible} footer={null} onCancel={handleCancel}>
        <img style={{ width: "100%", height: "auto", maxHeight: "60vh" }} src={previewImage} />
      </Modal>
    </div>
  );
}

const PlatformMemo = React.memo(Platform);

export default PlatformMemo;
