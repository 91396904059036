import React from "react";
import { useOutletContext } from "react-router-dom";
import style from "./index.module.scss";
import UserDetali from "./UserDetail/index";
import GameMessage from "./GameMessage/index";
import ContentOveriew from "./ContentOveriew/index";
import TotalEarnings from "./TotalEarnings";


function Home() {
  const { isFin } = useOutletContext();
  return (
    <section>
      <div className={`home_contain ${style.home_contain}`}>
        <UserDetali isFin={isFin} />
        <GameMessage />
        <ContentOveriew />
        <TotalEarnings />
      </div>
    </section>
  );
}

export default Home;
