import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import style from "../index.module.scss";
import detailStyle from "@/views/pc/Admin/Detail/index.module.scss";

import ClipboardJS from "clipboard";
import Api from "@/common/Api/content";
import messageApi from "@/common/Api/message";
// import bindApi from "@/common/Api/bindCreator";

import { rootContext, globalData } from "@/context/rootContext";
import { mainOfGame } from "@/common/utils/envData";
import { message, Pagination, DatePicker, Radio, Select, Popover } from "antd";
import { Abbreviation, formatMediaName, locationTipMessage } from "@/common/utils";
const { RangePicker } = DatePicker;

function ManageContent() {
  const {
    langState, gameTab, globalLang,
    ableMedia, setAbleMedia,
    refreshVideo, setRefreshVideo,
    refreshVideoTime, setRefreshVideoTime, activeContentList
  } = useContext(rootContext);
  const navigate = useNavigate();
  const [key, setKey] = useState("");
  const [current, setCurrent] = useState(1);
  const [contentCurrent, setContentCurrent] = useState({});
  const [selectTime, setSelectTime] = useState([]);
  const [total, setTotal] = useState(0);
  const [list, setList] = useState([]);
  const [choseMedia, setChoseMedia] = useState("");
  const [activityList, setActivityList] = useState([]);
  const [checkActivity, setCheckActivity] = useState(null);
  const [activeDetail, setActiveDetail] = useState({});
  const [showDetail, setShowDetail] = useState(false);
  const [activityIdList, setActivityIdList] = useState([]);
  const limit = 5;

  const clipboard = new ClipboardJS(".copy_line");


  const getData = async (item) => {
    if (!ableMedia.length) {
      return;
    }
    const data = {
      key,
      page: current,
      limit,
      mTag: choseMedia || ableMedia.join(","),
      ...item
    };
    if (selectTime && selectTime[0] && selectTime[1]) {
      data.publishTimeRange = [selectTime[0].format("YYYY-MM-DD"), selectTime[1].format("YYYY-MM-DD")].join(",");
    }
    if (checkActivity) {
      data.activityId = +checkActivity;
    }
    const res = await Api.getContentList({ gametag: globalData.activeGameData.tag, data });
    if (res.status === 200) {
      for (item of res.data.items) {
        item.snapshot = item.snapshot ? item.snapshot.split(",") : "";
      }
      setTotal(res.data.meta.totalItems);
      setList(res.data.items);
    }
  };

  // 获取月数据纵览
  const getMonthContent = async (m_mTag) => {
    let publishTimeRange = null;
    if (selectTime && selectTime[0] && selectTime[1]) {
      publishTimeRange = [selectTime[0].format("YYYY-MM-DD"), selectTime[1].format("YYYY-MM-DD")].join(",");
    }
    const tag = m_mTag || choseMedia || ableMedia.join(",");
    const res = await Api.getMediaData(tag, publishTimeRange, globalData.activeGameData.tag, +checkActivity || null);
    if (res && res.status === 200) {
      setContentCurrent(res.data);
    }
  };

  // 更新数据
  const refreshAllVideo = async () => {
    if (checkLastRefresh()) {
      setRefreshVideo(true);
      locationTipMessage(200020, "info");
      await Api.updateAllContent();
      await getData();
      setRefreshVideo(false);

      window.gtag("event", "refresh_all_video");
    }
  };

  // 检测是否高频率刷新数据
  const checkLastRefresh = () => {
    if (!refreshVideoTime) {
      setRefreshVideoTime(new Date().getTime());
      return true;
    }
    if (new Date().getTime() - refreshVideoTime <= 60000) {
      locationTipMessage(400106, "warn");
      return false;
    }
    setRefreshVideoTime(new Date().getTime());
    return true;
  };

  // 删除数据
  const deleteData = async id => {
    window.gtag("event", "pc_manage_delete");
    const res = await Api.deleteContent(globalData.activeGameData.tag.gameTab, id);
    if (res.status === 200) {
      locationTipMessage(200027, "success");
      setTimeout(() => {
        if (current > 1 && list.length === 1) {
          setCurrent(current - 1);
        } else {
          getData();
        }
      }, 1800);
    }
  };

  // 切换时间
  const dateChange = (date) => {
    if (date && date[0] && date[1]) {
      setSelectTime(date);
      return;
    }
    if (date === null) {
      setSelectTime(null);
      return;
    }
  };

  // 获取社媒列表
  const getMediaList = async () => {
    const res = await messageApi.getMediaList();
    if (res.status === 200) {
      const mediaList = res.data.map(item => item.name.toLowerCase()).filter(item => formatMediaName(item));
      setAbleMedia(mediaList);
    }
  };

  // 切换社媒
  const handleMediaChange = (item) => {
    const newTag = item.target.value;
    if (newTag !== choseMedia) {
      setChoseMedia(newTag);
      if (current !== 1) {
        setCurrent(1);
      } else {
        getData({
          mTag: newTag
        });
      }
    }
  };

  // 初始化活动/任务列表
  const initActivityList = (list) => {
    const option1 = list.map(item => ({
      value: item.id,
      label: item.name,
      data: item
    }));
    const option2 = option1.map(item => item.value);
    setActivityList(option1);
    setActivityIdList(option2);
  };

  // 切换选中的活动/任务
  const changeCheckActivity = (value) => {
    console.log(value);
    setCheckActivity(value || null);
  };

  // 查看活动详情
  const chackActivityDetail = (item) => {
    const id = item.activityId;
    const data = activityList.filter(item => item.value === id)[0];
    setActiveDetail(data.data);
    setShowDetail(true);
  };

  // 修改绑定的活动/任务
  // const exchangeActivity = async (value, index) => {
  //   const res = await messageApi.exchangeActivityTask(globalData.activeGameData.tag, { activityId: value, contentId: list[index].id });
  //   console.log(res);
  //   if (res.status === 200) {
  //     const newList = [...list];
  //     newList[index].activityId = value;
  //     setList([...newList]);
  //     message.success("Change Success");
  //   } else {
  //     message.error(res.data.message);
  //   }
  // };

  // 绑定回车键搜索内容
  const keyDown1 = (e) => {
    if (e.keyCode === 13) { getData(); }
  };

  // 查询数据
  const selectData = () => {
    if (current !== 1) {
      setCurrent(1);
    } else {
      getData();
    }
  };

  // 翻页/游戏tab切换/媒体切换/时间切换 时，更新视频数据
  useEffect(() => {
    if (gameTab.length) {
      getData();
    }
  }, [current, checkActivity, gameTab, choseMedia, selectTime]);

  // 游戏tab切换/媒体切换/时间切换 时，更新收益
  useEffect(() => {
    if (gameTab.length) {
      getMonthContent();
    }
  }, [gameTab, checkActivity, choseMedia, selectTime]);

  // 复制标题事件
  useEffect(() => {
    clipboard.on("success", function (e) {
      locationTipMessage(200035, "success");
      e.clearSelection();
    });

    clipboard.on("error", function () {
      message.error("Copy Failed");
    });

    return () => clipboard.destroy();
  }, [clipboard]);

  // 初始化媒体数据
  useEffect(() => {
    if (!ableMedia.length) {
      getMediaList();
    } else {
      setChoseMedia(ableMedia.join(","));
    }
  }, [ableMedia]);

  // 获取活动/任务列表
  useEffect(() => {
    const key = mainOfGame ? `${globalData.activeGameData.tag}-${globalData.activeGameData.regionName}` : globalData.activeGameData.tag;
    const list = activeContentList[key];
    initActivityList(list || []);
  }, [activeContentList]);


  return (
    !showDetail ? (
      <>
        <div className={`manage_content ${style.manage_content}`}>
          <div className={style.title1}>
            <span>{langState.uploadManageData.title}</span>
          </div>
          <div className={style.query}>
            <input value={key} onChange={e => setKey(e.target.value)} onKeyDown={keyDown1} placeholder={langState.uploadManageData.selectPlace}></input>
            <i onClick={selectData}></i>
          </div>
          <div className={style.query_list}>
            <Radio.Group size="large" value={choseMedia} onChange={handleMediaChange}>
              <Radio.Button value={ableMedia.join(",")}>ALL</Radio.Button>
              {
                ableMedia.map(item => {
                  return (
                    <Radio.Button key={item} value={item}>{formatMediaName(item)}</Radio.Button>
                  );
                })
              }
            </Radio.Group>
            <RangePicker
              onChange={dateChange}
              value={selectTime}
              allowClear={true}
              size="large"
              placeholder={[langState.uploadManageData.sTime, langState.uploadManageData.eTime]}
            />
            <Select
              options={activityList}
              style={{ width: 210 }}
              size="large"
              allowClear={true}
              placeholder={langState.uploadManageData.eventPlace}
              value={checkActivity}
              onChange={(value) => changeCheckActivity(value)}
            ></Select>
            <div className={`${style.update} ${refreshVideo ? style.on : ""}`} onClick={refreshAllVideo}></div>
          </div>
          <div className={style.content_data}>
            <ul className={style.data_list}>
              <li>
                <p className={`content_subtitle ${style.content_subtitle}`}>{langState.uploadManageData.totalCount}</p>
                <p className={style.num}>{(contentCurrent.contents && Abbreviation(+contentCurrent.contents)) || 0}</p>
              </li>
              <li>
                <p className={`content_subtitle ${style.content_subtitle}`}>{langState.uploadManageData.totalView}</p>
                <p className={style.num}>{(contentCurrent.viewCount && Abbreviation(+contentCurrent.viewCount)) || 0}</p>
              </li>
              <li>
                <p className={`content_subtitle ${style.content_subtitle}`}>{langState.uploadManageData.totalLike}</p>
                <p className={style.num}>{(contentCurrent.starCount && Abbreviation(+contentCurrent.starCount)) || 0}</p>
              </li>
              <li>
                <p className={`content_subtitle ${style.content_subtitle}`}>{langState.uploadManageData.totalComments}</p>
                <p className={style.num}>{(contentCurrent.commentCount && Abbreviation(+contentCurrent.commentCount)) || 0}</p>
              </li>
            </ul>
          </div>
          <ul className={style.gamefeed_list}>
            {
              list.map((item, index) => {
                return (
                  <li key={index}>
                    <div className={style.img_box}>
                      <img src={item.snapshot && (item.snapshot.length ? item.snapshot[0] : item.snapshot)}></img>
                    </div>
                    <Popover content={item.title} trigger="hover">
                      <p className={`${style.title} ${"copy_line"}`} data-clipboard-text={item.contentUrl || ""}>{item.title || " "}</p>
                    </Popover>
                    <p className={style.desc}>
                      <span>{item.pushTime && item.pushTime.slice(0, 10)}</span>
                      {
                        item.mTag === "twitch" && (
                          <>
                            <span><i className={style.view}></i>{item.viewCount}</span>
                            <span><i className={style.like}></i>—</span>
                            <span><i className={style.comm}></i>—</span>
                          </>
                        )
                      }
                      {
                        item.mTag === "youtube" && (
                          <>
                            <span><i className={style.youtube_view}></i>{item.viewCount} views</span>
                            <span><i className={style.youtube_like}></i>{item.starCount}</span>
                            <span><i className={style.comm}></i>{item.commentCount}</span>
                          </>
                        )
                      }
                      {
                        item.mTag === "tiktok" && (
                          <>
                            <span><i className={style.view}></i>{item.viewCount}</span>
                            <span><i className={style.like}></i>{item.starCount}</span>
                            <span><i className={style.comm}></i>{item.commentCount}</span>
                          </>
                        )
                      }
                    </p>
                    <p className={style.creatieTime}>{item.createdTime && item.createdTime.slice(0, 10)}</p>
                    {/* <Popover content={(<p>refresh data</p>)} trigger="hover">
                  <div className={style.update} onClick={() => { update(item); }}></div>
                </Popover> */}
                    {
                      item.activityId && activityIdList.includes(item.activityId) && (
                        <div className={style.select_box} onClick={() => chackActivityDetail(item)}>
                          <Select
                            options={activityList}
                            showArrow={false}
                            open={false}
                            size="large"
                            style={{ width: 200 }}
                            placeholder={item.activityId ? "" : "Select video submission event"}
                            value={item.activityId || null}
                          // onChange={(value) => exchangeActivity(value, index)}
                          ></Select>
                        </div>
                      )
                    }
                    <div className={`manage_more ${style.more}`}>
                      <div className={style.more_item}>
                        <div onClick={() => { deleteData(item.id); }}>{langState.uploadManageData.delete}</div>
                      </div>
                    </div>
                  </li>
                );
              })
            }
          </ul>
          {
            !list.length && (
              <div className={`${style.upLoad_tip} upLoad_tip ${globalLang}`} onClick={() => { navigate("/admin/mycontent/upload"); }}></div>
            )
          }
          <div className={style.page}>
            <Pagination
              size="small"
              total={total}
              defaultPageSize={limit}
              defaultCurrent={1}
              current={current}
              showSizeChanger={false}
              onChange={(page) => setCurrent(page)}
            />
          </div>
        </div>
      </>
    ) : (
      <>
        <ContentInformation data={activeDetail} back={() => { setShowDetail(false); }} />
      </>
    )
  );
}

function ContentInformation(props) {
  return (
    <div className={detailStyle.detail_box}>
      <div className={detailStyle.back} onClick={props.back}></div>
      <p className={detailStyle.title}>{props.data.name || ""}</p>
      <p className={detailStyle.title}>{props.data.startTime.slice(0, 10)} - {props.data.endTime.slice(0, 10)}</p>
      <div className={detailStyle.contain} dangerouslySetInnerHTML={{ __html: props.data.desc || "" }}></div>
    </div>
  );
}

export default ManageContent;
