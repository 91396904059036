// 按区域过滤分流全页面数据(文案，其他配置等)
// 基于区域进行分离
// 数据转到context/globalContextBox下的langState Reducer 中缓存

import { globalData } from "@/context/rootContext";
import { deepMergeObject } from "@/common/utils";
import pageDataEn from "./pageDataEn";
import pageDataID from "./pageDataID";
import pageDataTH from "./pageDataTh";
import pageDataVI from "./pageDataVI";
import pageDataJP from "./pageDataJP";
import pageDataSK from "./pageDataSK";
import pageDataTW from "./pageDataTW";

export const filterPageData = () => {
  switch (globalData.lang) {
    case "en": return pageDataEn; // 英
    case "vi": return deepMergeObject(pageDataVI, pageDataEn); // 越南
    case "id": return deepMergeObject(pageDataID, pageDataEn); // 印尼
    case "th": return deepMergeObject(pageDataTH, pageDataEn); // 泰
    case "jp": return deepMergeObject(pageDataJP, pageDataEn); // 日
    case "sk": return deepMergeObject(pageDataSK, pageDataEn); // 韩
    case "zh-tw": return deepMergeObject(pageDataTW, pageDataEn); // 台
    default: return pageDataEn;
  }
};