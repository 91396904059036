import React, { useContext, useEffect, useRef, useState } from "react";
import { Route, Routes, Navigate, useNavigate, useLocation } from "react-router-dom";
import style from "./index.module.scss";

import Detail from "@/views/pc/Admin/Detail";
import Api from "@/common/Api/content";
import { rootContext, globalData } from "@/context/rootContext";
import { isFl84 } from "@/common/utils/envData";
import { Pagination } from "antd";
import { defaultErrorMessage } from "@/common/utils";

function GameFeed() {
  const location = useLocation();
  const navigate = useNavigate();
  const inputRef = useRef();
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const [newsList, setNewsList] = useState([]);
  const [isReset, setIsReset] = useState(false);
  const [noTip, setNoTip] = useState(false);
  const { token, gameTab, langState } = useContext(rootContext);
  const limit = 5;

  const keyDown1 = (e) => {
    if (e.keyCode === 13) { getData(); }
  };

  const getData = async (item = {}) => {
    const data = {
      gametag: globalData.activeGameData.tag || "",
      key: inputRef.current.value || "",
      page: current,
      limit,
      ...item
    };
    const res = await Api.getGameFeed(data);
    if (res.status === 200) {
      setTotal(res.data.meta.totalItems);
      setNewsList(res.data.items);
      setNoTip(res.data.meta.totalItems <= 0);
    } else {
      defaultErrorMessage(res, "gameFeed");
    }
  };

  const selectWord = () => {
    window.gtag("event", "pc_gamefeed_select");
    if (current === 1) {
      getData();
    } else {
      setCurrent(1);
    }
  };

  useEffect(() => {
    if (token && gameTab && gameTab.length && isReset) {
      getData();
    }
  }, [token, gameTab, current, isReset]);

  const enterDetail = (id) => {
    navigate(`detail/${id}`, {
      state: {
        current: current,
        key: inputRef.current.value || "",
        newsList,
        total,
        activeId: id
      }
    });
  };

  useEffect(() => {
    if (!isReset) {
      const state = location.state || {};
      inputRef.current.value = state.key || "";
      setCurrent(state.current || 1);
      setNewsList(state.newsList || []);
      setTotal(state.total || 0);
      setIsReset(true);
    }
  }, [location, isReset, current, inputRef]);


  return (
    <div className={`gamefeed_box ${style.gamefeed_box}`}>
      <p className={style.title1}>
        {isFl84 ? <>{langState.gameFeedData.gameFeedTitle2}</> : <>{langState.gameFeedData.gameFeedTitle}</>}
      </p>
      <div className={style.query}>
        <input placeholder={langState.gameFeedData.selectPlace} ref={inputRef} onKeyDown={(e) => keyDown1(e)}></input>
        <i onClick={selectWord}></i>
      </div>
      <ul className={style.gamefeed_list}>
        {
          newsList.map((item, index) => {
            return (
              <li key={index} onClick={() => { enterDetail(item.id); }}>
                <div className={style.img_box}>
                  <img src={item.snapshot}></img>
                </div>
                <p className={style.title}>{item.title}</p>
                <p className={style.time}>{item.releaseTime ? item.releaseTime : item.createdTime}</p>
                <div className={`game_feed_more ${style.more}`}></div>
              </li>
            );
          })
        }
        {
          noTip && (
            <p className={style.none_tip}>
              {langState.gameFeedData.noTip}
            </p>
          )
        }
      </ul>
      <div className={style.page}>
        <Pagination
          size="small"
          total={total}
          defaultPageSize={5}
          defaultCurrent={1}
          current={current}
          showSizeChanger={false}
          onChange={(page) => setCurrent(page)}
        />
      </div>
    </div>
  );
}

function Page() {
  return (
    <Routes>
      <Route path="" element={<GameFeed />}></Route>
      <Route path="/detail/:id" element={<Detail />}></Route>
      <Route path="*" element={<Navigate to="/" />}></Route>
    </Routes>
  );
}

export default Page;
