import React, { useContext, useEffect, useMemo, useState } from "react";
import style from "./index.module.scss";

import { rootContext } from "@/context/rootContext";
import { isFl84 } from "@/common/utils/envData";
import Api from "@/common/Api/message";
import filterGameData from "@/assets/data/gameData";

function Task(props) {
  const [task3List, setTask3List] = useState([]);
  const [doneStatus, setDoneStatue] = useState([]);
  const { guideTask, setGuideTask, setDialogIndex, creatorData, enterRegion, globalLang, langState } = useContext(rootContext);
  const isFirefox = navigator.userAgent.indexOf("Firefox") > 0;
  const blockTask = useMemo(() => filterGameData("blockTask"), [enterRegion, creatorData]);

  const openTaskCard = (doneStatus) => {
    setTimeout(() => {
      task3List[0].done = true;
      setTask3List([...task3List]);
    }, 1000);
    setTimeout(() => {
      task3List[1].done = true;
      task3List[3].done = true;
      setTask3List([...task3List]);
    }, 1200);
    setTimeout(() => {
      task3List[2].done = true;
      task3List[4].done = true;
      task3List[6].done = doneStatus[6];
      setTask3List([...task3List]);
    }, 1400);
    setTimeout(() => {
      task3List[5].done = true;
      task3List[7].done = doneStatus[7];
      setTask3List([...task3List]);
    }, 1600);
    setTimeout(() => {
      task3List[8].done = doneStatus[8];
      setTask3List([...task3List]);
    }, 1800);
    if (doneStatus.reduce((total, item) => total + (item ? 1 : 0)) >= doneStatus.length - blockTask.length) {
      props.finFun();
    }
  };

  useEffect(() => {
    if (guideTask.length) {
      setTask3List(guideTask.map(item => {
        return {
          ...item,
          done: false
        };
      }));
      const doneList = guideTask.map(item => item.done);
      setDoneStatue(doneList);
      if (doneList.reduce((total, item) => total + (item ? 1 : 0)) === doneStatus.length) {
        props.finFun();
      }
    }
  }, [guideTask]);

  useEffect(() => {
    if (guideTask.length && task3List.length) {
      openTaskCard(doneStatus);
    }
  }, [doneStatus]);

  const comfirmTask = (index) => {
    switch (index) {
      case 6:
        tiersExplained(index);
        break;
      case 7:
        openWelcome(index);
        break;
      case 8:
        joinDiscord(index);
        break;
    }
  };

  // 查看星级弹窗
  const tiersExplained = (index) => {
    window.gtag("event", "pc_check_tiers");
    setDialogIndex(14);
    if (!doneStatus[index]) {
      finishTask(guideTask[index].taskId, index, null);
    }
  };

  // Join the Crator Discord Channel
  const joinDiscord = (index) => {
    if (creatorData) {
      window.gtag("event", "pc_discord");
      const link = filterGameData("discordLink");
      if (link) {
        finishTask(guideTask[index].taskId, index, function () {
          window.open(link);
        });
      } else {
        // console.log("no");
      }
    }
  };

  // welcome video
  const openWelcome = (index) => {
    window.gtag("event", "pc_welcome");
    if (!guideTask[index].done) {
      finishTask(guideTask[index].taskId, index, null);
    }
    if (isFl84) {
      window.open("https://www.youtube.com/watch?v=jumeWtPGtEQ");
      return;
    }
    setDialogIndex(15);
  };

  const finishTask = async (id, index, callback) => {
    const res = await Api.finishTask({ id });
    if (res.status === 200) {
      task3List[index].done = true;
      setTask3List([...task3List]);
      guideTask[index].done = true;
      setGuideTask([...guideTask]);
      if (callback) {
        callback();
      }
    }
  };

  return (
    <div className={`${style.community_rules_box2} ${isFirefox ? style.fire : ""}`}>
      {
        task3List.map((item, index) => {
          return (
            <div className={`${style.task_item} ${item.done ? style.active : ""} ${blockTask.includes(+item.taskId) ? style.none : ""}`} key={index} onClick={() => { comfirmTask(index); }}>
              <div className={style.task_data}>
                {
                  (globalLang !== "en") ? (
                    <p>{(langState.guideData.guideTask && langState.guideData.guideTask[item.taskId]) || item.content}</p>
                  ) : (
                    <p>{item.content}</p>
                  )
                }
              </div>
              <div className={style.task_default}>
                {/* <p>{item.content}</p> */}
              </div>
            </div>
          );
        })
      }
    </div>
  );
}

const TaskMemo = React.memo(Task);

export default TaskMemo;