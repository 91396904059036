import React from "react";
import { globalData } from "@/context/rootContext";


import DefaultViewEarnings from "./defaultView";
import Fl84ViewEarnings from "./fl84View";

function ViewEarnings() {

  return (
    <>
      {
        globalData.activeGameData.tag === "farlight84" ? <Fl84ViewEarnings /> : <DefaultViewEarnings />
      }
    </>
  );
}

export default ViewEarnings;
