import { createContext } from "react";

export const rootContext = createContext(null);

// 全局通用数据
export const globalData = {
  region: "",
  XCreatorRegion: "",
  gamehost: "",
  token: "",
  regionConfigId: null,
  activeGameData: {},
  baseGameList: [],
  regionGameList: [],
  activeRegion: "",
  lang: "en"
};
