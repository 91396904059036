import React, { useContext, useEffect, useState, useRef } from "react";
import style from "./index.module.scss";
// import PubSub from "pubsub-js";

import counterList from "@/assets/data/countryCodes";

import { rootContext, globalData } from "@/context/rootContext";
import { mainOfGame, mainOfRegion, isFl84, isJP, isSK, isSea, isAfkjourney, isTW } from "@/common/utils/envData";
import Api from "@/common/Api/accountManage";
import messageApi from "@/common/Api/message";
import { paddingNum, formatMediaName } from "@/common/utils";

import dislyte_dark from "@/assets/images/dislyte_logo_dark.png";
import farlight84_dark from "@/assets/images/farlight84_dark.png";
import filterGameData from "@/assets/data/gameData";
import igame_en_dark from "@/assets/images/afk2/igame_en_dark.png";
import igame_jp_dark from "@/assets/images/afk2/igame_jp_dark.png";
import igame_kr_dark from "@/assets/images/afk2/igame_kr_dark.png";
import igame_tw_dark from "@/assets/images/afk2/igame_tw_dark.png";
import igame_vi_dark from "@/assets/images/afk2/igame_vi_dark.png";
// import igame_zh_dark from "@/assets/images/afk2/igame_zh-ch_dark.png";

import { fl84TransformTip } from "@/common/utils/fl84TransformTip";

const maskDarkIcon = {
  dislyte: dislyte_dark,
  farlight84: farlight84_dark,
  igame: (!mainOfRegion && igame_en_dark) ||
          (isAfkjourney && igame_en_dark) ||
          (isSea && igame_vi_dark) ||
          (isJP && igame_jp_dark) ||
          (isSK && igame_kr_dark) ||
          (isTW && igame_tw_dark)
};
const gameIcons = filterGameData("gameIcons");
const defaultLanguage = (isJP && "日本語") || "English";

function Information() {

  const [dayList, setDayList] = useState([]);
  const [monthList] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
  const [yearList, setYearList] = useState([]);
  const [chooseYear, setChooseYear] = useState(1900);
  const [chooseMonth, setChooseMonth] = useState(1);
  const [chooseDay, setChooseDay] = useState(1);
  const [chooseCounter, setChooseCounter] = useState("");
  const [discode, setDiscode] = useState([]);
  const [isChange, setIsChange] = useState(false);
  const changeTime = useRef(null);
  const [gameList2, setGameList2] = useState([]);
  const [mediaList, setMediaList] = useState([]);
  const [creatorLanguage, setCreatorLanguage] = useState("");
  const { creatorData, token, setCreatorData, setAblePos, gameList, ableLanguage, langState } = useContext(rootContext);

  const firstNameRef = useRef();
  const lastNameRef = useRef();

  useEffect(() => {
    getPosList();

    const yearList = [];
    const dayList = [];
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1;
    const day = now.getDate();
    const dayMax = new Date(year, month, 0).getDate();
    for (let i = 1900; i <= year; i++) {
      yearList.push(i);
    }
    for (let i = 1; i <= dayMax; i++) {
      dayList.push(i);
    }
    setYearList(yearList);
    setDayList(dayList);
    setChooseYear(year);
    setChooseMonth(month);
    setChooseDay(day);
  }, []);

  const changeYear = (e) => {
    const year = e.target.value;
    const indexTime = new Date(year, chooseMonth, 0).getDate();
    setChooseYear(year);
    setChooseDay(indexTime >= chooseDay ? chooseDay : 1);
    reSetDayList(year, chooseMonth);
  };

  const changeMonth = (e) => {
    const month = e.target.value;
    const indexTime = new Date(chooseYear, month, 0).getDate();
    setChooseMonth(month);
    setChooseDay(indexTime >= chooseDay ? chooseDay : 1);
    reSetDayList(chooseYear, month);
  };

  const changeDay = (e) => {
    setChooseDay(e.target.value);
  };

  const reSetDayList = (year, month) => {
    const datMax = new Date(year, month, 0).getDate();
    const dayList = [];
    for (let i = 1; i <= datMax; i++) {
      dayList.push(i);
    }
    setDayList(dayList);
  };

  const enterDiscord = (value) => {
    const rule1 = /[0-9]/g;
    const rule2 = /[a-zA-Z]/g;
    const rule3 = /[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g;
    const str1 = value.replace(rule1, "");
    const str2 = str1.replace(rule2, "");
    const str3 = str2.replace(rule3, "");
    if (!str3.length) {
      setDiscode(value);
    }
  };

  const saveThrottling = async () => {
    if (changeTime.value) {
      clearTimeout(changeTime.value);
      changeTime.value = null;
    }
    changeTime.value = setTimeout(() => {
      const data = {
        token,
        nickName: creatorData.nickName,
        birthday: `${chooseYear}-${paddingNum(chooseMonth, 2)}-${paddingNum(chooseDay, 2)}`,
        discord: discode,
        region: chooseCounter
      };
      saveChange(data);
    }, 1200);
  };

  const saveChange = async (data) => {
    // console.log(data);
    const res = await Api.modifyInformation(data);
    if (res.status === 200) {
      window.gtag("event", "m_information_data");
      // PubSub.publish("resetUserData", null);
      creatorData.nickName = data.nickName;
      creatorData.birthday = data.birthday;
      creatorData.discord = data.discord;
      creatorData.region = data.region;
      setCreatorData({ ...creatorData });
    }
  };

  const getPosList = async () => {
    const res = await messageApi.getActivePosList();
    if (res.status === 200) {
      setAblePos(res.data);
    }
  };

  useEffect(() => {
    if (creatorData && gameList.length) {
      setDiscode(creatorData.discord || "");
      setChooseCounter(creatorData.region || window.localStorage.getItem("lilacRegion"));
      if (creatorData && creatorData.birthday) {
        const time = new Date(creatorData.birthday);
        setChooseYear(+time.getFullYear());
        setChooseMonth(+time.getMonth() + 1);
        setChooseDay(+time.getDate());
      }
      if (creatorData.realInfo && creatorData.realInfo.length) {
        firstNameRef.current.innerHTML = creatorData.realInfo[0].realName.split(" ")[0];
        lastNameRef.current.innerHTML = creatorData.realInfo[0].realName.split(" ")[1];
      }
      if (creatorData.language) {
        const langTag = ableLanguage.filter(item => item.code === creatorData.language)[0];
        if (langTag) {
          setCreatorLanguage(langTag.language);
        }
      }
      if (creatorData.games.length && creatorData.realInfo.length) {
        const activePlatform = creatorData.realInfo.map((item) => item.platform);
        const gameList2 = creatorData.games.filter(item => activePlatform.includes(item.platform));
        if (!mainOfGame) {
          setGameList2(gameList2);
        } else {
          const gameList = {};
          for (const item of creatorData.games) {
            if (!gameList[item.regionName]) { gameList[item.regionName] = []; }
            gameList[item.regionName].push(item);
          }
          setGameList2(gameList);
        }
      }
      if (mainOfGame && creatorData.mInfos && creatorData.mInfos.length) {
        const regionList = {};
        for (const item of creatorData.mInfos) {
          if (!regionList[item.regionName]) { regionList[item.regionName] = []; }
          regionList[item.regionName].push(item);
        }
        setMediaList(regionList);
      }
    }
  }, [creatorData, gameList]);

  useEffect(() => {
    if (isChange) {
      saveThrottling();
    }
  }, [chooseCounter, chooseYear, chooseMonth, chooseDay, discode, changeTime]);

  return (
    <section>
      <div className={`information_content ${style.information_content}`}>
        <div className={style.title}>{langState.informationData.title1}</div>
        <AccountInformation mediaList={mediaList} />
        <GameContent gameList2={gameList2} />
        <div className={style.title}>{langState.informationData.title2}</div>
        <div className={`information_content_box ${style.content_3}`}>
          <div className={style.sub_title}>{langState.informationData.birthdayTitle}</div>
          <ul className="information_birthday">
            <li>
              <select value={chooseDay} onChange={(e) => { setIsChange(true); changeDay(e); }} disabled>
                {
                  dayList.map((item) => {
                    return (
                      <option key={item} value={item} >{item}</option>
                    );
                  })
                }
              </select>
            </li>
            <li>
              <select value={chooseMonth} onChange={(e) => { setIsChange(true); changeMonth(e); }} disabled>
                {
                  monthList.map((item) => {
                    return (
                      <option key={item} value={item} >{item}</option>
                    );
                  })
                }
              </select>
            </li>
            <li>
              <select value={chooseYear} onChange={(e) => { setIsChange(true); changeYear(e); }} disabled>
                {
                  yearList.map((item) => {
                    return (
                      <option key={item} value={item} >{item}</option>
                    );
                  })
                }
              </select>
            </li>
          </ul>
          <ul className={style.real_name}>
            <div className={style.sub_title}>{langState.informationData.realNameTitle}</div>
            <li>
              <p>FIRSE NAME</p>
              <p className="information_name" ref={firstNameRef}></p>
            </li>
            <li>
              <p>LAST NAME</p>
              <p className="information_name" ref={lastNameRef}></p>
            </li>
          </ul>
          <ul className={`information_country ${style.country}`}>
            <div className={style.sub_title}>{langState.informationData.countryTitle}</div>
            <li className={style.down}>
              <select value={chooseCounter} onChange={(e) => { setIsChange(true); setChooseCounter(e.target.value); }}>
                {
                  counterList.country.map((item, index) => {
                    return (
                      <option key={index} value={item} >{item}</option>
                    );
                  })
                }
              </select>
            </li>
          </ul>
          <ul className={style.language}>
            <div className={style.sub_title}>{langState.informationData.languageTitle}</div>
            <p className="information_language">{creatorLanguage || defaultLanguage}</p>
          </ul>
          <div className={style.discord}>
            <div className={style.sub_title}>{langState.informationData.discordTitle}</div>
            <p className={style.module_desc}>{langState.informationData.discordDesc}</p>
            <div className={style.discode_input}>
              <i className={style.discode_icon}></i>
              <input value={discode} className={`${discode ? style.active : ""}`}
                onChange={(e) => { setIsChange(true); enterDiscord(e.target.value); }}></input>
              <i className={style.gou}></i>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function AccountInformationDisplay(props) {
  const { creatorData, setDialogIndex, setVerify, langState } = useContext(rootContext);

  const verifyPassword = () => {
    window.gtag("event", "m_information_password");
    setVerify(true);
    setDialogIndex(6);
  };

  const verifyUserName = () => {
    window.gtag("event", "m_information_username");
    // setNextDialog(10);
    setDialogIndex(10);
  };

  const exangeRegionMedia = (region) => {
    globalData.activeRegion = region;
    setDialogIndex(12);
  };

  const editMedia = () => {
    if (!isFl84 && globalData.activeGameData.tag === "farlight84") {
      fl84TransformTip();
      return;
    }
    setDialogIndex(12);
  };

  return (
    <div className={style.content_1}>
      <div className={`information_base ${style.base_information}`}>
        <div className={style.sub_title}>{langState.informationData.accountTitle}</div>
        <div className={style.input_box}>
          <input readOnly value={creatorData ? (creatorData.email || "") : ""}></input>
        </div>
        <div className={style.input_box}>
          <input readOnly value={creatorData ? (creatorData.nickName || creatorData.loginName || "") : ""}></input>
          <i className={style.next} onClick={verifyUserName}></i>
        </div>
        <div className={style.input_box}>
          <input type="password" readOnly value="********"></input>
          <i className={style.next} onClick={verifyPassword}></i>
        </div>
        <br />
        {
          !mainOfGame && (
            <>
              <div className={style.sub_title}>{langState.informationData.mediaTitle}<i className={style.edit} onClick={editMedia}></i></div>
              {
                creatorData && creatorData.mInfos.map((item, index) => {
                  return (
                    <div className={`information_media_item ${style.input_box} ${style.input_box2}`} key={index}>
                      <i className={style[item.tag.toLowerCase()]}></i>
                      <span>{formatMediaName(item.tag)}</span>
                      <span className={style.name}>{item.nickName}</span>
                    </div>
                  );
                })
              }
            </>
          )
        }
        {
          mainOfGame &&
          <>
            <div className={style.sub_title}>{langState.informationData.mediaTitle}
              {
                !Object.keys(props.mediaList).length ? <i className={style.edit} onClick={editMedia}></i> : ""
              }
            </div>
            {
              <ul className={style.mediaList}>
                {
                  Object.keys(props.mediaList).map((item, index) => {
                    return (
                      <>
                        <p className={style.subTitle}>{item}<i className={style.edit} onClick={() => { exangeRegionMedia(props.mediaList[item][0].regionId); }}></i></p>
                        <li key={index}>
                          {
                            props.mediaList[item].map((subItem) => (
                              <div className={`information_media_item ${style.input_box} ${style.input_box2}`} key={subItem.id}>
                                <i className={style[subItem.tag.toLowerCase()]}></i>
                                <span>{formatMediaName(subItem.tag)}</span>
                                <span className={style.name}>{subItem.nickName}</span>
                              </div>
                            ))
                          }
                        </li>
                        <hr />
                      </>
                    );
                  })
                }
              </ul>
            }
          </>
        }
      </div>
      <div className={`information_level ${style.level_box}`}>
        {
          globalData.activeGameData.tag === "farlight84" && (
            <p className={style.lv_name}>
              {langState.informationData.fl84StarLevel[globalData.activeGameData.accountLv - 1]}
            </p>
          )
        }
        <div className={`${style.level} ${style["level" + (+(globalData.activeGameData.accountLv || 1))]}`}></div>
      </div>
    </div>
  );
}

function GameContentDisplay(props) {
  const { creatorData, setDialogIndex, langState } = useContext(rootContext);


  const exangeRegionGame = (region) => {
    globalData.activeRegion = region;
    setDialogIndex(11);
  };

  return (
    <div className={`information_content_box ${style.content_2}`}>
      {
        !mainOfGame &&
        <>
          <div className={style.sub_title}>
            {langState.informationData.gameTitle}
            {creatorData && creatorData.games.filter(item => item.tag === "farlight84" && !item.inEffect).length > 0 && <i className={style.tip}></i>}
            <i className={style.edit} onClick={() => { setDialogIndex(11); }}></i>
          </div>
          <div className={style.game_list}>
            {
              props.gameList2.map((item) => {
                return <GameItem item={item} key={item.id} />;
              })
            }
          </div>
        </>
      }
      {
        mainOfGame &&
        <>
          <div className={style.sub_title}>{langState.informationData.gameTitle}</div>
          <div className={style.game_list}>
            {
              (
                <ul className={style.gameList}>
                  {
                    Object.keys(props.gameList2).map((item, index) => {
                      return (
                        <>
                          <p className={style.subTitle} onClick={() => { exangeRegionGame(props.gameList2[item][0].regionId); }}>
                            {item}
                            {props.gameList2[item].filter(item => item.tag === "farlight84" && !item.inEffect).length > 0 && <i className={style.tip}></i>}
                            <i className={style.edit}></i>
                          </p>
                          <li key={index}>
                            {
                              props.gameList2[item].map((subItem) => (
                                <GameItem item={subItem} key={subItem.id} />
                              ))
                            }
                          </li>
                          <hr />
                        </>
                      );
                    })
                  }
                </ul>
              )
            }
          </div>
        </>
      }
    </div>
  );
}

function GameItem(props) {
  const { langState } = useContext(rootContext);

  return (
    <div className={`information_game_item ${style.game_item}`}>
      <div className={style.game_logo}>
        <img src={maskDarkIcon[props.item.tag] || gameIcons[props.item.tag] || props.item.icon || ""}></img>
      </div>
      <div className={style.user_desc}>
        <p>{langState.informationData.uid}:&nbsp;{props.item.uid}</p>
        <p>{props.item.partName}</p>
      </div>
      <p className={style.tab_title}>{langState.informationData.tag}</p>
      <ul className={style.tab_list}>
        {
          props.item.activePoz.map((subItem, subIndex) => {
            return <li key={subIndex}>{subItem.activePos}</li>;
          })
        }
      </ul>
    </div>
  );
}

const AccountInformation = React.memo(AccountInformationDisplay);
const GameContent = React.memo(GameContentDisplay);

export default Information;
