import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import BasePlatForm from "./Platform/index";
import Fl84Platform from "./Fl84Platform/index";
import AFKPlatform from "./AFKPlatform/index";
import Game from "./game";
import style from "./index.module.scss";
import PubSub from "pubsub-js";

import counterList from "@/assets/data/countryCodes";
import { rootContext, globalData } from "@/context/rootContext";
import { mainOfRegion, isFl84, isJP } from "@/common/utils/envData";
import Api from "@/common/Api/bindCreator";
import messageApi from "@/common/Api/message";
import contentApi from "@/common/Api/content";
import { defaultErrorMessage, getDomTop, locationTipMessage, paddingNum } from "@/common/utils";
import { Modal } from "antd";

import Farlight84Error from "@/components/Farlight84Error";
import filterGameData from "@/assets/data/gameData";
import LoadMask from "@/components/loadMask";
import errorCode from "@/assets/data/errorCode";

const { confirm } = Modal;
const gameIcons = filterGameData("gameIcons");
const PlatForm = (function() {
  switch (true) {
    case isFl84: return Fl84Platform;
    case mainOfRegion: return AFKPlatform;
    default: return BasePlatForm;
  }
})();
const monthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

let loadNum = 0;

function Application() {

  const { creatorData, setDialogIndex, guideTask, gameList, ableLanguage, globalLang, langState } = useContext(rootContext);
  const navigate = useNavigate();
  const [type, setType] = useState([]);

  const [game, setGame] = useState([]);

  const [dayList, setDayList] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [chooseYear, setChooseYear] = useState(1900);
  const [chooseMonth, setChooseMonth] = useState(1);
  const [chooseDay, setChooseDay] = useState(1);
  const [chooseCounter, setChooseCounter] = useState("UNITED STATES");
  const [discordAccount, setDiscordAccound] = useState("");
  const [gameUID, setGameUID] = useState("");
  const [gameUserName, setGameUserName] = useState("");
  const [gameUIDCheck, setGameUIDCheck] = useState(false);
  const [loading, setLoading] = useState(false);
  const [platformMedia, setPlatformMedia] = useState({});
  const [gameMediaData, setGameMediaData] = useState(null);
  const [platform, setPlatform] = useState([
    { id: 1, name: "youtube", isActive: false, formId: 0, prove: "", isLogin: false },
    { id: 2, name: "tiktok", isActive: false, formId: 0, prove: "", isLogin: false },
    { id: 3, name: "twitch", isActive: false, formId: 0, prove: "", isLogin: false }
  ]);

  const platformRef = useRef();
  const createRef = useRef();
  const gameRef = useRef();
  const userRef = useRef();
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const langRef = useRef();

  /**
   * 初始填充页面数据
   */
  useEffect(() => {
    const yearList = [];
    const dayList = [];
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1;
    const day = now.getDate();
    const dayMax = new Date(year, month, 0).getDate();
    for (let i = 1900; i <= year; i++) {
      yearList.push(i);
    }
    for (let i = 1; i <= dayMax; i++) {
      dayList.push(i);
    }
    setYearList(yearList);
    setDayList(dayList);
    setChooseYear(year - 19);
    setChooseMonth(month);
    setChooseDay(day);

    const applicationGame = gameList.map((item, index) => {
      return {
        id: index,
        name: item.tag,
        isActive: false,
        icon: gameIcons[item.tag] || item.icon
      };
    });
    if (applicationGame.length === 1) {
      applicationGame[0].isActive = true;
    }
    setGame(applicationGame);

    // setChooseCounter(window.localStorage.getItem("lilacRegion"));
    const locationRegion = window.localStorage.getItem("lilacRegion");
    if (locationRegion && creatorData && !creatorData.region) {
      for (let i = 0; i < counterList.code.length; i++) {
        if (locationRegion === counterList.code[i]) {
          setChooseCounter(counterList.country[i]);
          break;
        }
      }
    }

    if (creatorData) {
      setDiscordAccound(creatorData.discord || "");
      if (creatorData.region) {
        setChooseCounter(creatorData.region);
      }
      if (creatorData.birthday) {
        const t = new Date(creatorData.birthday);
        setChooseYear(t.getFullYear() || year - 19);
        setChooseMonth(t.getMonth() + 1);
        setChooseDay(t.getDate() || day);
      }
      if (creatorData.games && creatorData.games.length) {
        const gameList = [...game];
        for (const item of gameList) {
          if (item.name === creatorData.games[0].tag) {
            item.isActive = true;
            setGame(gameList);
            break;
          }
        }
        setGameUserName(creatorData.games[0].partName);
        setGameUID(creatorData.games[0].uid);
      }
      if (creatorData.realInfo && creatorData.realInfo.length) {
        firstNameRef.current.value = creatorData.realInfo[0].realName.split(" ")[0];
        lastNameRef.current.value = creatorData.realInfo[0].realName.split(" ")[1];
      }
    }

    getPosList();
  }, [creatorData]);

  /**
   * 获取受支持的创作者视频定位列表
   */
  const getPosList = async () => {
    const res = await messageApi.getActivePosList();
    if (res.status === 200) {
      const typeList = res.data.map(item => {
        return {
          ...item,
          isActive: false
        };
      });
      if (creatorData && creatorData.games && creatorData.games.length) {
        const createTypeList = creatorData.games[0].activePoz.map((item) => item.activePos);
        const newTypeList = [...typeList];
        for (const item of newTypeList) {
          if (createTypeList.includes(item.value)) {
            item.isActive = true;
          }
        }
        setType(newTypeList);
      } else {
        setType(typeList);
      }
    }
  };

  /**
   * 验证真实姓名格式 纯英文大小写
   * @returns Boolean
   */
  const checkRealName = () => {
    const firstName = firstNameRef.current.value.trim();
    const lastName = lastNameRef.current.value.trim();
    const nameReg = /[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/im;
    return firstName && !nameReg.test(firstName) && lastName && !nameReg.test(lastName);
  };

  /**
   * 检测视频归属
   * @param {url/homePageUrl, contentUrl} data 
   * @returns true 归属 false 不归属/其他错误
   */
  const checkVideoBelong = async (data) => {
    const res = await contentApi.checkVideoBelong(globalData.activeGameData.tag, data);
    if (res.status === 200) {
      if (res.data.isOwn) { return true; }
      else {
        return false;
      }
    } else {
      Modal.error({
        title: "ERROR",
        content: res.data.message,
      });
      return false;
    }
  };

  /**
   * 发送创作者申请表单前数据预检
   * @returns none
   */
  const checkData = async () => {
    const gameIndex = game.filter(item => item.isActive)[0];
    // 初始数据集中
    const sendData = {
      birthday: `${chooseYear}-${paddingNum(chooseMonth, 2)}-${paddingNum(chooseDay, 2)}`,
      region: chooseCounter,
      discord: discordAccount.trim(),
      game: {
        tag: gameIndex ? gameIndex.name : "",
        uid: gameUID.trim(),
        partName: (gameUserName && gameUserName.trim()) || null,
        activePoz: type.map(item => item.isActive ? { activePos: item.value } : "").filter(item => item)
      },
      mInfos: [],
      realInfo: {
        realName: [firstNameRef.current.value.trim(), lastNameRef.current.value.trim()].join(" "),
        country: chooseCounter
      }
    };
    // 支持语言选项时填充语言
    if (ableLanguage.length > 1) {
      sendData.language = langRef.current.value;
    }


    // 获取绑定的社媒数据
    const mediaData = await getMediaUseData(platform);
    // 不存在绑定的社媒数据 结束
    if (!mediaData.length) {
      document.documentElement.scrollTo(0, getDomTop(platformRef.current) + 30);
      locationTipMessage(400108);
      setLoading(false);
      return;
    }
    // 检测并填充社媒数据
    for (const item of mediaData) {
      if (item && item.nickName) {
        const data = {
          tag: item.tag || "",
          nickName: item.nickName || "",
          followers: item.followers || 0,
          videoCount: item.videoCount || 0,
          prove: item.prove,
          uid: item.uid,
          channelUrl: item.channelUrl
        };
        // fl84额外检测内容
        if (isFl84) {
          if (!platformMedia[item.tag]) {
            document.documentElement.scrollTo(0, document.documentElement.clientHeight / 2);
            locationTipMessage(200013);
            setLoading(false);
            return;
          }
          if (platformMedia[item.tag].errFlag) {
            document.documentElement.scrollTo(0, getDomTop(platformRef.current) + 30);
            farlight84Error();
            setLoading(false);
            return;
          }
          data.contentUrl = platformMedia[item.tag].contentUrl;
          // if (item.tag === "tiktok") {
          //   data.accessToken = platformMedia[item.tag].accessToken;
          // }
          const checkData = item.tag === "tiktok" ? {
            // tiktokToken: bindMediaData.accessToken,
            homePageUrl: data.channelUrl,
            contentUrl: data.contentUrl
          } : {
            uid: data.uid,
            contentUrl: data.contentUrl
          };
          const isBelone = await checkVideoBelong(checkData);
          if (!isBelone) {
            document.documentElement.scrollTo(0, document.documentElement.clientHeight / 2);
            locationTipMessage(400217);
            setLoading(false);
            return;
          }
        }
        sendData.mInfos.push(data);
      } else if (loadNum > 5) {
        loadNum = 0;
        defaultErrorMessage({}, "mediaData");
        setLoading(false);
        return;
      } else {
        loadNum++;
        setLoading(true);
        setTimeout(() => { checkData(); }, 2000);
        return;
      }
    }
    // 没有检测通过社媒数据 结束
    if (!sendData.mInfos.length) {
      document.documentElement.scrollTo(0, getDomTop(platformRef.current) + 30);
      locationTipMessage(400108);
      setLoading(false);
      return;
    }
    
    // 检测是否上传社媒截图
    for (const item of sendData.mInfos) {
      if (!item.prove) {
        for (const subItem of platform) {
          if (subItem.name === item.tag) {
            subItem.isActive = true;
          } else {
            subItem.isActive = false;
          }
        }
        setPlatform([...platform]);
        document.documentElement.scrollTo(0, getDomTop(platformRef.current));
        locationTipMessage(400108);
        setLoading(false);
        return;
      }
      // fl84下检测是否额外上传视频
      if (isFl84 && !item.contentUrl) {
        document.documentElement.scrollTo(0, getDomTop(platformRef.current));
        setLoading(false);
        return;
      }
    }

    // 检测选择的创作定位/类型
    if (!sendData.game.activePoz.length) {
      document.documentElement.scrollTo(0, getDomTop(createRef.current) + 30);
      setLoading(false);
      locationTipMessage(400110);
      return;
    }
    // 检测绑定的主要游戏数据
    const gameCheck = await checkGameData(sendData);
    if (!gameCheck) {
      document.documentElement.scrollTo(0, getDomTop(gameRef.current));
      setLoading(false);
      return;
    }
    // 检测真实姓名填写
    const realNameCheck = checkRealName();
    if (!realNameCheck) {
      document.documentElement.scrollTo(0, getDomTop(firstNameRef.current) - 300);
      
      const firstName = firstNameRef.current.value.trim();
      const lastName = lastNameRef.current.value.trim();
      if (!firstName.length) {
        locationTipMessage(200005);
      } else if (!lastName.length) {
        locationTipMessage(200006);
      } else {
        locationTipMessage(200015);
      }
      setLoading(false);
      return;
    }
    // 检测Discord填写
    if (!sendData.discord) {
      document.documentElement.scrollTo(0, getDomTop(userRef.current));
      setLoading(false);
      locationTipMessage(400115);
      return;
    }
    
    // 检测是否为其他区的创作者
    const isMultipleRegister = await checkMultipleRegister(sendData);
    if (isMultipleRegister) {
      // 已在其他区注册
      confirm({
        title: "Tip",
        content: errorCode(200029),
        okText: "NO",
        cancelText: "YES",
        onOk() {
        },
        onCancel() {
          submit(sendData);
        },
      });
      setTimeout(() => {
        const tipElement = document.createElement("p");
        tipElement.innerHTML = errorCode(200030);
        document.getElementsByClassName("ant-modal-confirm-btns")[0].appendChild(tipElement);
      }, 100);
      return;
    }

    // 全部检测成功 提交申请
    submit(sendData);
  };

  /**
   * 检测绑定的游戏数据
   * @param {*} sendData 
   * @returns Boolean
   */
  const checkGameData = async (sendData) => {
    // fl84特有检测
    if (sendData.game.tag === "farlight84") {
      // 非fl84页面下提交绑定fl84检测
      if (!isFl84) {
        if (!gameMediaData) {
          locationTipMessage(200013);
          return false;
        }
        if (gameMediaData.errFlag) {
          farlight84Error();
          return false;
        }
        for (const item of sendData.mInfos) {
          if (item.tag === gameMediaData.mTag) {
            item.contentUrl = gameMediaData.contentUrl;
            // if (item.tag === "tiktok") {
            //   item.accessToken = gameMediaData.accessToken;
            // }
  
            const checkData = item.tag === "tiktok" ? {
              // tiktokToken: item.accessToken,
              homePageUrl: item.channelUrl,
              contentUrl: gameMediaData.contentUrl
            } : {
              uid: item.uid,
              contentUrl: gameMediaData.contentUrl
            };
            setLoading(true);
            const isBelone = await checkVideoBelong(checkData);
            setLoading(false);
            if (!isBelone) {
              locationTipMessage(400217);
              return false;
            }
  
            break;
          }
        }
      }
      // fl84 直接验证绑定游戏账号
      if (!sendData.game.uid || !gameUIDCheck) {
        locationTipMessage(200017);
        return false;
      }
      return true;
    }
    // 日区igame不检测uid/昵称
    if (isJP && sendData.game.tag === "igame") {
      return true;
    } else {
      // 常规游戏检测
      if (!sendData.game.tag || !sendData.game.uid || !sendData.game.partName) {
        locationTipMessage(400111);
        return false;
      }
    }
    return true;
  };

  /**
   * 完成新人九宫格任务
   */
  const finishTask = () => {
    if (!guideTask[4].done) {
      messageApi.finishTask({ id: guideTask[4].taskId });
    }
    if (!guideTask[5].done) {
      messageApi.finishTask({ id: guideTask[5].taskId });
    }
  };

  /**
   * 是否已经是其他区的创作者
   * @param {*} data 
   * @returns true 是 false 否
   */
  const checkMultipleRegister = async (data) => {
    const checkData = {
      loginName: creatorData.loginName,
      email: creatorData.email,
      regionConfigId: +globalData.regionConfigId,
      games: [
        {
          uid: data.game.uid,
          tag: data.game.tag
        }
      ],
      mInfos: data.mInfos.map(item => ({
        tag: item.tag,
        uid: item.uid
      }))
    };
    setLoading(true);
    const res = await Api.multipleRegister(checkData);
    setLoading(false);
    if (res.data && res.data.statusCode === 400) {
      return true;
    }
    return false;
  };

  /**
   * 提交创作者申请表单
   * @param {*} data 
   * @returns none
   */
  const submit = async (data) => {
    // console.log(data);
    const header = {
      gametag: data.game.tag
    };
    setLoading(true);
    const res = await Api.applyToCreator(data, header);
    window.gtag("event", "m_application_submit");
    setLoading(false);
    // console.log(res);
    if (res.status === 200) {
      finishTask();
      // fl84下等待自动审批
      if (data.game.tag === "farlight84") {
        setLoading(true);
        window.gtag("event", "m_fl84_application");
        setDialogIndex(20);
        return;
      }
      // 弹出成功提示并返回首页
      setDialogIndex(5);
      navigate("/");
    } else {
      Modal.error({
        title: "ERROR",
        content: res.data.message,
      });
    }
  };

  /**
   * 查询社媒数据
   * @param {*} platform 
   * @returns Array
   */
  const getMediaUseData = async (platform) => {
    const platFormData = [];
    for (const item of platform) {
      if (item.isLogin) {
        const res = await Api.getBindMediaData({
          formId: item.formId
        });
        if (res && res.status === 200) {
          const bindMediaData = {
            tag: item.name.toLowerCase(),
            nickName: "",
            followers: 0,
            prove: item.prove,
            ...res.data
          };
          platFormData.push(bindMediaData);
          item.bindMediaData = bindMediaData;
        } else {
          item.isLogin = false;
          setPlatform([...platform]);
          getMediaUserDataError();
          return [];
        }
      }
    }
    if (platFormData.length > 0) {
      setPlatform([...platform]);
    }
    return platFormData;
  };

  /**
   * 查询社媒数据失败 提示重新授权
   */
  const getMediaUserDataError = () => {
    Modal.error({
      title: "ERROR",
      content: errorCode(400109)
    });
    document.documentElement.scrollTo(0, getDomTop(platformRef.current) + 30);
  };


  /**
   * 切换选择的社媒
   * @param {*} type 
   * @param {*} index 
   */
  const clickType = (type, index) => {
    type[index].isActive = !type[index].isActive;
    setType([...type]);
  };

  const changeYear = (e) => {
    const year = e.target.value;
    const indexTime = new Date(year, chooseMonth, 0).getDate();
    const Day = indexTime >= chooseDay ? chooseDay : 1;
    if (isOverEighteen(new Date(`${year}/${chooseMonth}/${Day}`))) {
      setChooseYear(year);
      setChooseDay(Day);
      reSetDayList(year, chooseMonth);
    } else {
      locationTipMessage(400206, "info");
    }
  };

  const changeMonth = (e) => {
    const month = e.target.value;
    const indexTime = new Date(chooseYear, month, 0).getDate();
    const Day = indexTime >= chooseDay ? chooseDay : 1;
    if (isOverEighteen(new Date(`${chooseYear}/${month}/${Day}`))) {
      setChooseMonth(month);
      setChooseDay(Day);
      reSetDayList(chooseYear, month);
    } else {
      locationTipMessage(400206, "info");
    }
  };

  const changeDay = (e) => {
    const Day = e.target.value;
    if (isOverEighteen(new Date(`${chooseYear}/${chooseMonth}/${Day}`))) {
      setChooseDay(e.target.value);
    } else {
      locationTipMessage(400206, "info");
    }
  };

  const isOverEighteen = (birthday) => {
    var ageDifMs = Date.now() - birthday.getTime();
    var ageDate = new Date(ageDifMs);
    var age = Math.abs(ageDate.getUTCFullYear() - 1970);
    if (age > 17) {
      return true;
    } else {
      return false;
    }
  };

  const reSetDayList = (year, month) => {
    const datMax = new Date(year, month, 0).getDate();
    const dayList = [];
    for (let i = 1; i <= datMax; i++) {
      dayList.push(i);
    }
    setDayList(dayList);
  };

  const logout = () => {
    PubSub.publish("logout", null);
  };

  // M端自动检测社媒绑定情况 适配浏览器安全控制
  const checkLogin = async (platform, setPlatform) => {
    const uploginLeng = platform.filter(item => !item.isLogin && item.formId).length;
    for (const item of platform) {
      if (!item.isLogin && item.formId) {
        const res = await Api.getBindMediaData({ formId: item.formId });
        if (res && res.status === 200 && res.data.nickName) {
          // console.log(item.tag);
          item.isLogin = true;
        }
      }
    }
    const newLeng = platform.filter(item => !item.isLogin && item.formId).length;
    if (uploginLeng !== newLeng) {
      setPlatform([...platform]);
    } else if (newLeng) {
      setTimeout(() => { checkLogin(platform, setPlatform); }, 2000);
    }
  };

  const farlight84Error = () => {
    Modal.error({
      getContainer: "#application_box",
      title: "ERROR",
      content: (
        <Farlight84Error />
      ),
    });
  };

  useEffect(() => {
    // console.log(platform.filter(item => !item.isLogin && item.formId));
    if (platform.filter(item => !item.isLogin && item.formId).length) {
      setTimeout(() => { checkLogin(platform, setPlatform); }, 5000);
    }
  }, [platform]);

  return (
    <section>
      <div className={`${style.application_box} ${mainOfRegion ? style.afk : ""} ${style.m}`} id="application_box">
        <div className={style.logout} onClick={logout}></div>
        <p className={`${style.title1} ${style[globalLang]}`}>{langState.applicationData.applicationTitle}</p>
        <p className={style.user_account}>{langState.applicationData.welcome} <span>{creatorData ? creatorData.loginName : ""}</span></p>
        <p className={style.application_detail} ref={platformRef}>
          {langState.applicationData.applicationDesc.map(item => <>{item}&nbsp;</>)}
        </p>
        {/* 社交平台 */}
        <PlatForm platform={platform} setPlatform={setPlatform} setPlatformMedia={setPlatformMedia} />
        {/* 创作类型 */}
        <div className={style.create_type} ref={createRef}>
          <p className={style.module_title}>{langState.applicationData.createTypeTitle}</p>
          <p className={style.module_desc} dangerouslySetInnerHTML={{ __html: langState.applicationData.createTypeDesc }} ></p>
          <ul>
            {
              type.map((item, index) => {
                return (
                  <li key={item.id} className={`${item.isActive ? style.active : ""}`} onClick={() => clickType(type, index)}>
                    { langState.informationData.gameTag[item.name.toLowerCase()] || item.name }
                  </li>
                );
              })
            }
          </ul>
        </div>
        {/* 游戏类型 */}
        <div className={style.game_type} ref={gameRef}>
          <Game
            game={game}
            platform={platform}
            setGame={setGame}
            setGameUID={setGameUID}
            setGameUserName={setGameUserName}
            saveGameMedia={setGameMediaData}
            setGameUIDCheck={setGameUIDCheck}
          />
        </div>
        {/* 用户数据 */}
        <div className={style.user_data}>
          <p className={style.module_title}>{langState.applicationData.userDetailTitle}</p><br />
          <p className={style.module_desc}>{langState.applicationData.userDetailSubTitle1}</p>
          <p className={style.module_desc}>{langState.applicationData.userDetailSubDesc1}</p>
          <ul className={style.user_detail}>
            <li>
              <p>{langState.applicationData.day}</p>
              <select value={chooseDay} onChange={(e) => changeDay(e)}>
                {
                  dayList.map((item) => {
                    return (
                      isOverEighteen(new Date(`${chooseYear}/${chooseMonth}/${item}`)) ? (
                        <option key={item} value={item} >{item}</option>
                      ) : (
                        <option key={item} value={item} disabled>{item}</option>
                      )
                    );
                  })
                }
              </select>
            </li>
            <li>
              <p>{langState.applicationData.month}</p>
              <select value={chooseMonth} onChange={(e) => changeMonth(e)}>
                {
                  monthList.map((item) => {
                    return (
                      isOverEighteen(new Date(`${chooseYear}/${item}/${chooseDay}`)) ? (
                        <option key={item} value={item} >{item}</option>
                      ) : (
                        <option key={item} value={item} disabled>{item}</option>
                      )
                    );
                  })
                }
              </select>
            </li>
            <li>
              <p>{langState.applicationData.year}</p>
              <select value={chooseYear} onChange={(e) => changeYear(e)}>
                {
                  yearList.map((item) => {
                    return (
                      isOverEighteen(new Date(`${item}/${chooseMonth}/${chooseDay}`)) ? (
                        <option key={item} value={item} >{item}</option>
                      ) : (
                        <option key={item} value={item} disabled>{item}</option>
                      )
                    );
                  })
                }
              </select>
            </li>
          </ul>
          <ul>
            <p className={style.module_desc}>&nbsp;</p>
            <p className={style.module_desc}>{langState.applicationData.userDetailSubTitle2}</p>
            <div className={style.real_box}>
              <div>
                <p>{langState.applicationData.firstName}</p>
                <input ref={firstNameRef}></input>
              </div>
              <div>
                <p>{langState.applicationData.laseName}</p>
                <input ref={lastNameRef}></input>
              </div>
            </div>
          </ul>
          {
            ableLanguage.length > 1 && (
              <ul className={`language ${style.language}`}>
                <p>{langState.applicationData.userDetailSubTitle3}</p>
                <li>
                  <select ref={langRef}>
                    {
                      ableLanguage.map((item, index) => {
                        return (
                          <option key={index} value={item.code} >{item.language}</option>
                        );
                      })
                    }
                  </select>
                </li>
              </ul>
            )
          }
          <ul className={style.country} ref={userRef}>
            <p>{langState.applicationData.userDetailSubTitle4}</p>
            <li>
              <select value={chooseCounter} onChange={(e) => { setChooseCounter(e.target.value); }}>
                {
                  counterList.country.map((item, index) => {
                    return (
                      <option key={index} value={item} >{item}</option>
                    );
                  })
                }
              </select>
            </li>
          </ul>
          <div className={style.discord}>
            <p className={style.module_title}>{filterGameData("commitTitle") || langState.applicationData.discordTitle}</p>
            <p className={style.module_desc}>{filterGameData("commitDesc") || langState.applicationData.discordDesc}</p>
            <input value={discordAccount} onChange={(e) => { setDiscordAccound(e.target.value); }}></input>
          </div>
          <div className={`${style.submit} ${isFl84 ? style.fl84 : ""}`} onClick={checkData}>
            {langState.applicationData.submitBtn}
          </div>
        </div>
      </div>
      {
        loading && <LoadMask global={true} />
      }
    </section>
  );
}

export default Application;
