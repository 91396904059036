// 所有数据会进入assets/data/pageData进行过滤
// 顶部nav
const topNavData = {
  signUp: "회원가입",
  logIn: "로그인",
  application: "신청서",
  home: "메인 페이지",
  back: "뒤로",
  profile: "개인정보 조회",
  contact: "CONTACT",
  logOut: "로그아웃"
};

// 底部版权
const footerData = {
  service: "서비스 이용약관",
  policy: "개인정보 보호정책",
  footerText: "크리에이터 존은 Lilith Games와 Farlight Games에서 만든 콘텐츠 크리에이터 플랫폼입니다.",
};


// IGame首页文案
const iGameHomeData = {
  enterYourEmail: "이메일을 입력하세요",
  password: "비밀번호",
  forgotPassword: "비밀번호를 잊으셨나요?",
  emailLogin: "이메일 간편 로그인",
  pwdLogin: "비밀번호 로그인",
  signUp1: "아직 계정이 없으신가요? ",
  signUp2: "회원가입하기",
  send: "인증번호 발송",
  codeTip: "6자리 인증코드를 입력하세요",
  desc1: "공식적으로 협력할 수 있는 유일한 채널로 최고의 콘텐츠를 제작하실 수 있도록 자체 찾기 기능, 도구 및 지원을 제공합니다.",
  desc2_1: "완성도 높은 콘텐츠를 제작하실 수 있도록 필요한 도구를 제공합니다.",
  desc2_2: "언제든지 소통하여 도움과 팁을 받으실 수 있습니다. 공식 플랫폼에 콘텐츠를 노출시켜 채널 성장을 도와드릴 수 있도록 노력하겠습니다!",
  desc2_3: "믿을 수 있는 크리에이터 디스코드에 가입하여 다양한 콘텐츠 크리에이터들과 소통하실 수 있습니다!",
  tier1_1: "콘텐츠 크리에이터를 위한 디스코드 채널로 초대합니다.",
  tier1_2: "크리에이터 디스코드 서버에서 담당자와 일대일로 협력할 수 있습니다.",
  tier1_3: "뛰어난 콘텐츠를 제작할 수 있도록 공식 리소스를 제공해 드리 현금 보상, 게임 아이템 보상, 공식 굿즈 보상 등을 받으실 수 있습니다.",
  tier1_4: " ",
  tier2_1: "콘텐츠를 공식 커뮤니티 미디어 플랫폼에 공개할 수 있습니다.",
  tier2_2: " ",
  tier3_1: "공식 크리에이터 전용 쿠폰을 획득하여 구독자들에게 선물할 수 있습니다. ",
  tier3_2: "「AFK: 새로운 여정」의 공식 이벤트에 초대받을 수 있습니다. ",
  tier3_3: "「AFK: 새로운 여정」 팀과 함께 콘텐츠를 제작할 수 있습니다.",
  tier3_4: " ",
  updateText: "마지막 업데이트"
};

// 注册页-选择地区
const signUpLanguageData = {
  title: "당신의 모험이 이곳에서 시작됩니다!",
  desc: "어떤 언어를 사용해 제작하시나요?",
  langEn: ["English"],
  langMult: [
    "العربية",
    "Deutsch",
    "Español",
    "Français",
    "Português",
    "Русский",
    "Türkçe"
  ],
  langSea: [
    "Tiếng Việt",
    "ภาษาไทย",
    "Bahasa Indonesia",
    "Bahasa Melayu",
    "Pilipino"
  ]
};

// 注册页
const signData = {
  title: "당신의 모험이 이곳에서 시작됩니다!",
  title2: "회원가입",
  desc1: "크리에이터 존의 일원이 되시려면",
  desc2: "다음 사항을 확인하셔야 합니다.",
  signRule1_1: " ",
  signRule1_link: "커뮤니티 규정을",
  signRule1_2: " 준수해야 하며,다른 크리에이터 및 커뮤니티 관리자들에게 친절해야 합니다.",
  signRule2: "만18세 이상.",
  signOtherText1: "*위의 조건에 부합하지 않으면",
  signOtherText2: " ",
  signOtherLink: "여기에",
  signOtherText3: "신청서를 제출하세요.",
  signPlace1: "이메일을 입력하세요",
  signPlace2: "닉네임 설정",
  signPlace3: "비밀번호 설정",
  signNeed1: "비밀번호: ",
  signNeed2: "8~20자",
  signNeed3: "대소문자, 숫자, 특수문자",
  signNeed4_1: "크리에이터 존의 ",
  signNeed4_2: "서비스 이용약관",
  signNeed4_3: " 및 ",
  signNeed4_4: "개인정보 보호정책",
  signNeed4_5: " 을읽었습니다.",
  accept: "수락",
  next: "다음 단계",
  send: "인증번호 발송",
  codeTip: "6자리 인증코드를 입력하세요",
  accountTip1: "이미 계정이 있으신가요? ",
  accountTip2: "로그인하기",
  accountTip3: "",
};

// 个人信息页
const informationData = {
  title1: "계정 정보 편집",
  title2: "개인정보",
  accountTitle: "계정 정보",
  mediaTitle: "소셜 미디어",
  gameTitle: "게임",
  gameTag: {
    "video content": "동영상",
    "shorts": "쇼츠",
    "stream": "스트림",
  },
  birthdayTitle: "생일",
  realNameTitle: "실명",
  firstName: "이름",
  laseName: "성",
  countryTitle: "국가 또는 지역",
  languageTitle: "언어",
  discordTitle: "ID",
  discordDesc: "연락 및 소통할 수 있는 방법을 알려주세요",
  uid: "UID",
  tag: "콘텐츠 크리에이터 태그",
};

// 欢迎/引导页
const guideData = {
  welcomeTitle: ["크리에이터", "존에 오신 것을 환영합니다!"],
  commRuleTitle: "커뮤니티 규칙",
  commRuleDesc: [
    "1. 타인 존중",
    "저희는 모든 크리에이터 분들이 환영받을 수 있도록 포용적이면서도 우호적인 커뮤니티 분위기를 조성하기 위해 노력하고 있습니다. 표현의 자유를 매우 중요하게 생각하지만, 직접적이거나 간접적인 침해 행위를 포함한 공격적인 발언이나 행위에 반대합니다. 위와 같은 행위를 하실 경우 경고를 받게 되며, 사안이 중대한 경우 크리에이터 존에 들어오실 수 없습니다.",
    "2. 차별",
    "저희는 일체의 괴롭힘 또는 차별 행위를 금지하고 있습니다. 타인의 인종, 정치, 종교, 문화, 성적 지향, 성별 또는 정체성을 비하하는 발언을 절대 허용하지 않습니다. 이를 위반할 경우 크리에이터 존에 들어오실 수 없습니다.",
    "3. 계정 거래",
    "계정 판매 및 거래, 금전적인 대가 및 사익을 추구하는 계정 부스팅 서비스 제공을 금지하고 있습니다. 해당 행위에 참여하는 크리에이터는 크리에이터 존에 들어오실 수 없습니다.",
    "4. 부적절한 콘텐츠",
    "성적인 내용을 다루거나 불안을 야기하는 콘텐츠는 엄격하게 금지하고 있습니다. 해당 규정을 위반할 경우 크리에이터 존에 들어오실 수 없습니다.",
    "5. 표절 금지",
    "모든 콘텐츠는 순수 창작물이어야 합니다. 표절 행위가 확인되면 크리에이터 존에 들어오실 수 없습니다.",
    "6. 최종 해석권",
    "일부 규정이 언급되지 않았다고 해서 크리에이터의 관련 행위에 대해 용인한다는 뜻은 아닙니다. Lilith Games와 Farlight Games는 해당 규정의 최종 해석권을 가지고 있습니다.",
  ],
  agreeBtn: "동의",
  commRuleTip: "커뮤니티 규정을 읽었는지 확인하세요.",
  nonRuleTitle: "비밀유지 조항",
  nonRuleTitle2: "개인정보 보호정책",
  nonRuleDescSub: [
    "1. 모든 콘텐츠, 소재 및 기타 크리에이터 존을 통해 제공되는 자산은 정식 공개 전에는 기밀로 간주합니다. 관계자의 허락 없이는 어떠한 경우에도 콘텐츠나 소재를 유출하거나 공개할 수 없습니다.",
    "2. 크리에이터 존의 기밀 정보를 공유할 수 없습니다.",
    "&nbsp;",
    "기밀성",
  ],
  nonRuleDesc: [
    "1.1 크리에이터와 크리에이터 존과의 관계는 높은 신뢰성을 바탕으로 합니다. 크리에이터 존에 서비스를 제공하는 과정에서 크리에이터는 일부 정보(이하 ‘기밀 정보’)에 접근하게 되며 알게 됩니다. 서비스 기간 또는 서비스 종료 후를 제외하고 크레에이터 존 관계자의 서면 동의가 없으면 크리에이터는 기밀 정보를 유출할 수 없으며, 서비스 외의 어떠한 목적으로도 사용할 수 없습니다. 단, 해당 기밀 정보가 일반인에게 널리 공개되어 콘텐츠 크리에이터의 과실이 아닌 경우는 제외됩니다.",
    "1.2 본 계약서의 기밀 정보에는 서면, 특허, 판권이 아닌 형식, 사적, 비밀 또는 기밀성 정보 등을 포함합니다. 해당 정보는 크리에이터 존에서 보유 또는 사용하며, 고객의 업무, 상업적인 관계 또는 재무와 관련이 있습니다. 발명, 배합, 공급업체 정보, 고객 정보, 설비, 상업기밀, 프로세스, 연구, 보고, 기술 또는 연구 자료, 임상 데이터, 전문지식, 컴퓨터 프로그램, 소프트웨어, 소프트웨어 파일, 하드웨어 설계, 기술, 제품, 과정, 방법, 기술, 배합, 화합물, 프로젝트, 발전, 시장 또는 비즈니스 계획, 예측, 미발표된 재무제표, 예산, 허가증, 가격, 단가, 고객, 공급업체 또는 직원 정보나 직원 명단 등을 포함합니다. 위의 사항은 크리에이터가 크리에이터 존에 콘텐츠 제작 서비스를 제공하는 과정에서 접근, 이해, 개발 또는 다른 방식으로 획득하게 됩니다.",
    "1.3 제6조에 언급된 크리에이터 의무가 다음의 경우에는 적용되지 않습니다. (i) 크리에이터 또는 타인이 제6조를 위반하지 않은 상황에서 이미 일반인들에게 알려진 경우(ii) 크리에이터 존에서 제삼자에게 공개하여 제삼자에게 제재하지 않은 경우 또는 (iii) 크리에이터 존 관계자의 서면 동의를 받고 공개한 경우",
    "1.4 크리에이터 존의 업무를 위한 경우를 제외하고 모든 문서, 파일, 편지, 메모, 보고서, 기록, 데이터 스케치, 도면, 모형, 실험실 노트, 프로그램 리스트, 컴퓨터 기기 또는 설비, 컴퓨터 프로그램 또는 기타 기밀 정보가 포함된 페이지, 사진 또는 기타 유형 자료는 크리에이터 또는 타인이 만들었어도 크리에이터가 관리하거나 소유하면 크리에이터 존의 고유 자산에 속합니다. 하여, 크리에이터는 크리에이터 존을 위해 작업을 진행할 때만 사용할 수 있으며, 복사하거나 크리에이터 존의 작업실에서 외부로 가지고 나갈 수 없습니다. 해당 유형 자료 및 그 복사본, 크리에이터 존의 유형자산은 크리에이터가 관리하거나 소유하면 크리에이터 존에서 요청하거나 본 계약이 종료될 때 미리 크리에이터 존에 제출해야 합니다. 제출 후 크리에이터는 해당 유형 자료, 복사본 또는 해당 유형자산을 남겨둘 수 없습니다.",
    "1.5 위의 제(2)와 제(4)에 언급된 정보 및 자료 비공개 또는 사용 의무, 제(4)에서 언급된 자료 및 유형자산 반환 의무는 크리에이터 존의 고객, 공급업체 또는 기타 제삼자가 크리에이터 존 또는 크리에이터에게 공개 또는 의뢰한 동일한 유형의 정보, 자료, 유형자산에도 적용됩니다.",
    "1.6 크리에이터 존은 다른 개인, 정부 또는 기관과 계약을 체결할 수 있으며, 해당 계약으로 인해 크리에이터 존에서 이루어진 발명 또는 작업의 기밀성에도 의무 또는 제한이 생길 수 있습니다. 크리에이터는 알려진 모든 의무 및 제한을 준수하며, 해당 계약에 따라 크리에이터 존에서 의무를 다할 수 있도록 필요한 모든 조치를 취합니다. "
  ],
  nonSignText: "비밀유지서약서를 읽었으며 이에 동의합니다.",
  configBtn: "확인",
  completeTitle: "튜토리얼",
  finishTip: [
    "튜토리얼 완료를 축하합니다!",
    "크리에이터 존에 오신 것을 환영합니다!"
  ],
  guideTask: {
    "1": "크리에이터 존의 회원 되기",
    "2": "로그인 완료",
    "3": "크리에이터 주의사항",
    "4": "비밀유지서약서",
    "5": "개인 정보를 입력하세요",
    "6": "SNS 계정을 연동하세요",
    "7": "크리에이터 등급 설명 ",
    "8": "웰컴 영상",
    "9": "크리에이터 디스코드 채널 가입",
  }
};

// 申请表单页
const applicationData = {
  applicationTitle: "신청서를 작성하세요",
  welcome: "환영합니다!",
  applicationDesc: [
    "신청서에 정확한 정보를 기재하세요.",
    "신청이 통과되면 크리에이터 존에 입장하실 수 있습니다!"
  ],
  mediaModeTitle: "1. 본인의 SNS 선택",
  mediaModeDesc: "계정 인증을 위해 하나 이상의 SNS 계정을 연동하세요.",
  mediaUploadText: "본인 채널의 스크린샷을 업로드하세요",
  createTypeTitle: "2. 제작할 콘텐츠의 유형은?",
  createTypeDesc: "최소 하나 이상을 선택하세요",
  mainGameTitle: "3. 주력 게임 선택",
  mainGameDesc: [
    "다음 게임 중 주력 콘텐츠를 선택하세요",
    "크리에이터 존의 회원이 되신 후 게임을 새로 추가하거나 선택한 게임을 조정할 수 있습니다."
  ],
  uidPlace: "UID(숫자)",
  userNamePlace: "닉네임",
  verifiCodPlace: "인증번호",
  userDetailTitle: "자세한 정보를 알려주세요",
  userDetailSubTitle1: "생일을 입력하세요",
  userDetailSubDesc1: "*해당 정보는 신청이 승인된 후 변경할 수 없습니다.",
  day: "일",
  month: "월",
  year: "년",
  userDetailSubTitle2: "실명을 입력하세요",
  firstName: "이름",
  laseName: "성",
  userDetailSubTitle3: "언어",
  userDetailSubTitle4: "국가 또는 지역",
  userDetailSubTitle5: "국가 또는 지역",
  discordTitle: "디스코드 ID",
  discordDesc: "원활한 소통 및 연락을 위해",
  submitBtn: "제출",
  confirm: "확인",

};

// 管理内页-导航
const adminNavData = {
  home: "메인 페이지",
  gameFeed: "게임 새소식",
  artAssets: "아트 자산",
  myContent: "나의 콘텐츠",
  uploadContent: "콘텐츠 업로드",
  manageContent: "콘텐츠 관리",
  viewEarnings: "수익 보기",

  // m
  growth: "성장 및 발전",
};

// 管理内页-首页
const adminHomeData = {
  // 数据总浏
  overview: {
    title: "전체 콘텐츠",
    navItem1: "전체 데이터",
    navItem2: "월간 콘텐츠 개요",
    totalCount: "총 콘텐츠 수량",
    totalView: "총 조회 수",
    totalLike: "총 좋아요 수",
    totalComments: "총 댓글 수"
  },
  // 推送
  message: {
    more: "더보기"
  },
  // 收益
  earning: {
    title: "나의 수익",
    item1Name: "월간",
    item2Name: "게임 내 보상",
    item3Name: "현금",

    settionLoad: "결산 진행 중..."
  },
  userDetail: {
    welcome: " ",
    welcome2: "님, 돌아오신 것을 환영합니다!",
    timeText: "해당 플랫폼의 회원이 되신 지 ",
    timeUnit1: "일이 되었습니다.",
    timeUnit2: "일이 되었습니다.",
  }
};

// 管理内页-素材推送
const artAssetsData = {
  artAssetsTitle: "아트 소재 ",
  selectPlace: "제목/키워드에 따라 선택",
  googleLinkBtn: "구글 드라이브 링크",
  request: "다운로드 요청",
  download: "downloading",
  requested: "요청 완료",
  noTip: "데이터 없음"
};

// 管理内页-游戏资讯
const gameFeedData = {
  gameFeedTitle: "게임 새소식",
  selectPlace: "제목/키워드 찾기",
  noTip: "데이터 없음",
};

// 管理内页-收益
const earningData = {
  earningTitle: "수익 보기",
  subTitle: "월간 수익",
  item1Name: "월간",
  item2Name: "게임 내 보상",
  item3Name: "현금",
  rewards: "보상 설명",

  detailTitle1: "보상 설명",
  detailSubTitle1: "나의 동영상 리스트",
  detailTimeTitle: "결산 시간: ",
  detailDesc: "해당 기간에 공개한 영상만 계산됩니다.",
  noData: "데이터 없음",
  detailRewards: "이번 다이아 보상: ",
  detailRewardsUnit: "다이아",
  detailRewardsDescTitle: "결산일: ",
  detailRewardsDesc1_1: "The views data is based on the data recorded by the social media platform on the",
  detailRewardsDesc1_2: "Settlement day",
  detailRewardsDesc1_3: ". If you have doubts about this reward detail, please contact the captain / customer service on Discord.",
  detailUnit1: "콘텐츠 제목",
  detailUnit2: "조회 수",
  detailUnit3: "공개일",
  detailUnit4: "채널",
};

// 管理内页-上传
const upLoadContentData = {
  uploadTitle1: "콘텐츠 업로드",
  upLoadPlace1: "여기에 콘텐츠 주소를 입력하세요",
  submitBtn: ["확인", "콘텐츠 업로드", "제출"],
  time: "타임 스탬프",
  rightNow: "현재",
  itemTitle1: "총 조회 수",
  itemTitle2: "총 좋아요 수",
  itemTitle3: "총 댓글 수",
  step1: "스텝1: <span>크리에이터 작업 제출 또는 일반 콘텐츠 업로드 중 선택하세요.</span>",
  step2: "스텝 2: <span>콘텐츠 업로드</span>",
  taskDetail: "자세한 내용",
  taskTitle: ["테마", "시간", "보상"],
  normalUpload: "일반 콘텐츠",
  taskEvent: "현재 제출한 이벤트: ",
  addUrl: "콘텐츠 주소 추가",
};

// 管理内页-视频管理
const uploadManageData = {
  title: "콘텐츠 관리",
  selectPlace: "제목/키워드에 따라 선택",
  eventPlace: "창작 모집 이벤트",
  totalCount: "총 콘텐츠 수량",
  totalView: "총 조회 수",
  totalLike: "총 좋아요 수",
  totalComments: "총 댓글 수",
  delete: "삭제"
};

// 提交申请表单成功
const signTip = "축하합니다! 귀하의 크리에이터 존 신청이 통과되었습니다. 저희 플랫폼에 방문하시기 전에 이메일로 보내드린 크리에이터 계약서에 서명해 주세요.";

// 注册协议弹窗
const communityTip = [
  "1. 타인 존중",
  "저희는 모든 크리에이터 분들이 환영받을 수 있도록 포용적이면서도 우호적인 커뮤니티 분위기를 조성하기 위해 노력하고 있습니다. 표현의 자유를 매우 중요하게 생각하지만, 직접적이거나 간접적인 침해 행위를 포함한 공격적인 발언이나 행위에 반대합니다. 위와 같은 행위를 하실 경우 경고를 받게 되며, 사안이 중대한 경우 크리에이터 존에 들어오실 수 없습니다.",
  "2. 차별",
  "저희는 일체의 괴롭힘 또는 차별 행위를 금지하고 있습니다. 타인의 인종, 정치, 종교, 문화, 성적 지향, 성별 또는 정체성을 비하하는 발언을 절대 허용하지 않습니다. 이를 위반할 경우 크리에이터 존에 들어오실 수 없습니다.",
  "3. 계정 거래",
  "계정 판매 및 거래, 금전적인 대가 및 사익을 추구하는 계정 부스팅 서비스 제공을 금지하고 있습니다. 해당 행위에 참여하는 크리에이터는 크리에이터 존에 들어오실 수 없습니다.",
  "4. 부적절한 콘텐츠",
  "성적인 내용을 다루거나 불안을 야기하는 콘텐츠는 엄격하게 금지하고 있습니다. 해당 규정을 위반할 경우 크리에이터 존에 들어오실 수 없습니다.",
  "5. 표절 금지",
  "모든 콘텐츠는 순수 창작물이어야 합니다. 표절 행위가 확인되면 크리에이터 존에 들어오실 수 없습니다.",
  "6. 최종 해석권",
  "일부 규정이 언급되지 않았다고 해서 크리에이터의 관련 행위에 대해 용인한다는 뜻은 아닙니다. Lilith Games와 Farlight Games는 해당 규정의 최종 해석권을 가지고 있습니다.",
];

// 弹窗文案
const dialogText = {
  login: "로그인",
  enterYourEmail: "이메일을 입력하세요",
  enterYourPassword: "비밀번호를 입력하세요",
  password: "비밀번호",
  forgotPassword: "비밀번호를 잊으셨나요?",
  emailQuickLogin: "이메일 빠른 로그인",
  signTip: "아직 계정이 없으신가요? ",
  signIn: "회원가입",
  codeTip: "6자리 인증코드를 입력하세요",
  send: "인증번호 발송",
  set: "새 비밀번호 설정",
  next: "다음 단계",
  setNew: "새 비밀번호를 설정하세요",
  confirmPassword: "비밀번호 확인",
  confirm: "확인",
  allowAuthorize: "데이터 가져오기를 허용하겠습니까?",
  allow: "허용",
  refuse: "거절",
  applicationText1: "신청 제출 완료, ",
  applicationText2: "영업일 기준으로 5일 이내에 이메일로 피드백을 받아보실 수 있습니다.",
  verify: "비밀번호 확인",
  signAgreement: "계약서 서명",
  upLoadTip: "채널 대시보드를 캡처해서 업로드하세요",
  save: "저장",
  setNewName: "새 사용자 이름 설정",
  setNameNeed: "이름은 3개월마다 변경할 수 있습니다",
  userNameSet: "사용자 이름 설정 완료",
  userNameVerify: "2~24자로 설정하세요",
  gameTag: "게임 태그",
  mediaTitle: "SNS 연동",
  tiersTitle: "크리에이터 등급 설명",
  bugTitle: "버그 리포트",
  bugTitle2: "제목",
  bugTitle3: "설명",
  bugMax: "< 50자",
  bugMax2: "< 1000자",
  bugTypeTitle: "버그 유형",
  bugType1: "계정 이슈",
  bugType2: "크리에이터 신청",
  bugType3: "미디어 연동",
  bugType4: "게임 연동",
  bugType5: "아트 자산",
  bugType6: "콘텐츠 업로드",
  bugType7: "수익 관리",
  bugType8: "기타",
  bugUpload: "이미지를 업로드할 수 있습니다(≦3장)",
  submit: "제출",
  explainedTitle: "보상 설명",
  googleTip1: "나의 유튜브 계정 연동",
  googleTip2: "먼저 구글 계정으로 로그인하세요",
  waitAppText1: "We have received your application!",
  waitAppText2: "It'll take around 5 business day for us to look over applications,",
  waitAppText3: "and the result will be sent to you by mail.",
};

export default {
  topNavData,
  footerData,
  signUpLanguageData,
  signData,
  informationData,
  guideData,
  applicationData,
  adminNavData,
  adminHomeData,
  artAssetsData,
  gameFeedData,
  earningData,
  upLoadContentData,
  uploadManageData,
  signTip,
  communityTip,
  dialogText,
  iGameHomeData
};