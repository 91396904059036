import React from "react";
import style from "./index.module.scss";
import { Spin } from "antd";

function LoadMask (props) {
  return (
    <div className={`global-load ${style.mask} ${props && props.global ? style.global : ""}`}>
      <Spin size={(props && props.size) || "large"} />
    </div>
  );
}

const LoadMaskMemo = React.memo(LoadMask);

export default LoadMaskMemo;