import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import { mainOfGame, mainOfRegion, isFl84 } from "@/common/utils/envData";

import Application from "@/views/pc/Application/index"; // 申请表单
import SignUp from "@/views/pc/SignUp/index"; // 注册
// import SignupLanguage from "@/views/pc/SignupLanguage"; // 注册语言选择
import Guide from "@/views/pc/Guide/index"; // 九宫格引导页
import Admin from "@/views/pc/Admin/index"; // 管理首页?
import Information from "@/views/pc/Information/index"; // 个人信息
import Sign from "@/views/pc/Sign/index"; // 签约

import AdminHome from "@/views/pc/Admin/Home/index"; // 主页
import GameFeed from "@/views/pc/Admin/GameFeed/index"; // 游戏推送
import ArtAssets from "@/views/pc/Admin/ArtAssets/index"; // 素材获取
import UploadContent from "@/views/pc/Admin/MyContent/UploadContent"; // 上传
import ManageContent from "@/views/pc/Admin/MyContent/ManageContent"; // 作品管理
import ViewEarnings from "@/views/pc/Admin/ViewEarnings/index"; //收益管理


const BaseHome = lazy(() => import("@/views/pc/Home/Base/index")); // 基础主页
const Fl84Home = lazy(() => import("@/views/pc/Home/Fl84/index")); // fl84主页
const AfkHome = lazy(() => import("@/views/pc/Home/Afk/index")); // afk主页

const homeChack = () => {
  if (!(mainOfGame || mainOfRegion)) { return BaseHome; }
  switch (true) {
    case isFl84: {
      return Fl84Home;
    }
    case mainOfRegion: {
      return AfkHome;
    }
    default: {
      return BaseHome;
    }
  }
};

const Home = homeChack();

const adminRouter = [
  { path: "home", element: <AdminHome /> },
  { path: "gamefeed/*", element: <GameFeed /> },
  { path: "artassets/*", element: <ArtAssets /> },
  { path: "mycontent/upload", element: <UploadContent /> },
  { path: "mycontent/manage", element: <ManageContent /> },
  { path: "viewearnings", element: <ViewEarnings /> },
  { path: "", element: <Navigate to="home" /> },
  { path: "*", element: <Navigate to="home" /> },
];

const routes = [
  { path: "", element: <Home /> },
  { path: "application", element: <Application /> },
  // { path: "signuplanguage", element: <SignupLanguage /> },
  { path: "signup", element: <SignUp /> },
  { path: "guide", element: <Guide /> },
  { path: "sign", element: <Sign /> },
  { path: "admin/*", element: <Admin />, children: adminRouter },
  { path: "information", element: <Information /> },
  { path: "", element: <Navigate to="" /> },
  { path: "*", element: <Navigate to="" /> },
];

export default routes;
