import React, { useContext } from "react";
import style from "./index.module.scss";
import { rootContext } from "@/context/rootContext";

function Schedule(props) {
  const { langState } = useContext(rootContext);

  return (
    <div className={style.task_nav}>
      <div className={`${style.task_num} ${props.task1Active ? style.active : ""}`}>1<span>{ langState.guideData.commRuleTitle }</span></div>
      <div className={`${style.task_line} ${props.task2Active ? style.active : ""}`}></div>
      <div className={`${style.task_num} ${props.task2Active ? style.active : ""}`}>2<span>{ langState.guideData.nonRuleTitle2 }</span></div>
      <div className={`${style.task_line} ${props.task3Active ? style.active : ""}`}></div>
      <div className={`${style.task_num} ${props.task3Active ? style.active : ""}`}>3<span>{ langState.guideData.completeTitle }</span></div>
    </div>
  );
}

export default Schedule;