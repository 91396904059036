import React, { lazy, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { formatMediaName, locationTipMessage, locationUtil, setGAUtil } from "@/common/utils";
import { rootContext, globalData } from "@/context/rootContext";
import { mainOfGame, mainOfRegion, isFl84, isSea, isAfkjourney, isJP, isSK, isTW } from "@/common/utils/envData";
import { getRegionData } from "@/common/utils/reginUtil";
import { message } from "antd";

import EnviromentEnter from "@/views/enviromentEnter";
const Fl84Enter = lazy(() => import("@/views/GameEnter/Fl84Enter")); // fl84定制区主页
const AFKEnter = lazy(() => import("@/views/GameEnter/AFKEnter")); // afk定制主页
import AfkjourneyEnter from "./GameEnter/AfkJourneyEnter"; // 欧美区入口
import SeaEnter from "./GameEnter/SeaEnter"; // 东南亚区入口
import JPEnter from "./GameEnter/JPEnter"; // 日区入口
import SKEnter from "./GameEnter/SKEnter"; // 韩区入口
import TWEnter from "./GameEnter/TWEnter"; // 台区入口

// 最大报错数
message.config({
  maxCount: 2,
});

// 基础原3区标记
const regionAbbreviation = ["en", "mult", "sea"];

// 总入口 登录流程/根据区域划分数据
function CommEnter() {
  const {
    enterRegion, setEnterRegion, loginStatus, setDialogIndex,
    setIsLogin, setToken, setRefreshToken, setGameList, setAbleMedia, setAbleLanguage
  } = useContext(rootContext);
  const navigate = useNavigate();

  const savaLoginState = (data) => {

    // 数据归类初始化
    setIsLogin(true);
    setToken(data.token.accessToken);
    setRefreshToken(data.token.refreshToken);

    const mediaList = data.region.mInfos.map(item => item.name.toLowerCase()).filter(item => formatMediaName(item));
    const ableLanguage = data.region.languages.map(item => ({ language: item.name, code: item.value }));
    setAbleMedia(mediaList);
    setGameList(data.region.games);
    setAbleLanguage(ableLanguage);

    globalData.token = data.token.accessToken;
    globalData.regionConfigId = data.region.regionConfigId;

    navigate("/");

    setDialogIndex(0);
    locationUtil.setLocationStorage("lilacCreatorSataus", {
      time: new Date().toLocaleString(),
      expiration: data.token.expiration,
      token: data.token.accessToken,
      refreshToken: data.token.refreshToken
    });
  };

  // 原3区登录环境判断
  const checkLoginRegion = () => {
    const loginData = loginStatus.filter(item => regionAbbreviation.includes(item.region.regionAbbreviation));
    if (!loginData.length) {
      // 无3区数据 结束
      locationTipMessage(400101);
      return;
    }

    if (loginData.length === 1) {
      // 一个区域有数据， 直接登录
      const loginRegion = loginData[0].region.regionAbbreviation;
      if (globalData.region === loginRegion) {
        // 与当前区域对应成功 载入对应登录数据
        savaLoginState(loginData[0]);
      } else {
        // 对应失败，重新设置当前进入的区域环境 -> useEffect转回登录数据加载过程
        setEnterRegion(loginRegion);
      }
      return;
    }
    if (loginData.length > 1) {
      if (globalData.region) {
        // 存在region数据 已进入对应区域（注册） 直接进入页面
        const regionData = loginData.filter(item => item.region.regionAbbreviation === globalData.region)[0];
        savaLoginState(regionData || loginData[0]);
      } else {
        // 正常登录 存在多个区域，展开列表
        setDialogIndex(19);
      }
      return;
    }
  };

  // 手动登录成功 获取所有注册区域数据
  useEffect(() => {
    if (!loginStatus.length) { return; }// 无数据 结束
    if (mainOfGame && isFl84) { return; }// 游戏专区 结束 转 独立Enter 进行后续处理
    if (mainOfRegion) { return; }// 独立区域 结束 转 独立Enter 进行后续处理
    checkLoginRegion();
  }, [loginStatus]);

  // 选区完成 初始化对应数据
  useEffect(() => {
    // GA初始化
    if (enterRegion) { setGAUtil(enterRegion); }
    // 区域登录 退出
    if (mainOfRegion) { return; }
    // 无数据 结束
    if (!enterRegion || !loginStatus.length) { return; }
    // 加载区域数据
    const regionData = loginStatus.filter(item => item.region.regionAbbreviation === enterRegion)[0];
    savaLoginState(regionData || loginStatus[0]);
    getRegionData(enterRegion || "en");
  }, [enterRegion, loginStatus]);

  return (
    (enterRegion === "farlight84" && <Fl84Enter savaLoginState={savaLoginState}><EnviromentEnter /></Fl84Enter>) ||
    (mainOfRegion &&
      <>
        {
         isSea && <SeaEnter savaLoginState={savaLoginState}><AFKEnter><EnviromentEnter /></AFKEnter></SeaEnter>
        }
        {
          isAfkjourney && <AfkjourneyEnter savaLoginState={savaLoginState}><AFKEnter><EnviromentEnter /></AFKEnter></AfkjourneyEnter>
        }
        {
          isJP && <JPEnter savaLoginState={savaLoginState}><AFKEnter><EnviromentEnter /></AFKEnter></JPEnter>
        }
        {
          isSK && <SKEnter savaLoginState={savaLoginState}><AFKEnter><EnviromentEnter /></AFKEnter></SKEnter>
        }
        {
          isTW && <TWEnter savaLoginState={savaLoginState}><AFKEnter><EnviromentEnter /></AFKEnter></TWEnter>
        }
      </>
    ) ||
    <EnviromentEnter />
  );
}

export default CommEnter;
