import React, { lazy, useContext, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { rootContext, globalData } from "@/context/rootContext";
import { isPC, mainOfGame, isFl84, mainOfRegion, checkRegionLoginData } from "@/common/utils/envData";
import { blacklistedTip, defaultErrorMessage, formatMediaName, locationUtil } from "@/common/utils";
import { getRegionData } from "@/common/utils/reginUtil";
import { filterGameTagList } from "@/common/filter";
import PubSub from "pubsub-js";
import { io } from "socket.io-client";

import Api from "@/common/Api/accountManage";
import messageApi from "@/common/Api/message";
import filterGameData from "@/assets/data/gameData";
import errorCode from "@/assets/data/errorCode";
import LoadMask from "@/components/loadMask";
import { socketURL } from "@/common/Api/webApi";

const PC = lazy(() => import("@/views/pc/index")); // PC
const M = lazy(() => import("@/views/m/index")); // M

let creatorData = null;
let reTokenTime = 0;
let socket = null;

// 环境入口 进入pc/m 登录后初始化各数据
function EnviromentEnter() {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    langState, isLogin, setIsLogin, token, gameTab, setToken, setGameTab, gameList, setGameList, activeGame, setActiveGame,
    setCreatorData, setGamePlatform, refreshToken, setRefreshToken, setGuideTask, setProtocols, setAbleMedia,
    setAbleLanguage, setTipContent, setDialogIndex, setLogionStatus, setEnterRegion, setActiveContentList,
    setReadTapIndex
  } = useContext(rootContext);

  // 打开页面时，若没有用户数据，打开全局遮罩，提示加载中
  const globalMask = useMemo(() => {
    if (location.pathname.includes("/admin") || location.pathname.includes("/information") || location.pathname.includes("/guide")) {
      if (isLogin && creatorData) {
        return false;
      }
      return true;
    }
    return false;
  }, [isLogin, creatorData, location]);

  // 用户名密码登录 注册后自动登录
  const loginUseName = async (data) => {
    // console.log(data);
    const res = await Api.login_email({
      email: data.email,
      password: data.password
    });
    if (res.status === 200) {
      // 登录成功
      setLogionStatus(res.data);
    } else {
      defaultErrorMessage(res, "autoLogin");
    }
  };

  // 获取游戏列表
  const getGameList = async () => {
    const res = await messageApi.gamaList();
    if (res.status && res.status === 200) {
      setGameList(res.data);
      globalData.baseGameList = res.data;
      return true;
    } else {
      const code = (res && res.data && res.data.statusCode) || res.statusCode || res.status || "";
      if (+code === 401) {
        defaultErrorMessage({}, "", errorCode(200101));
        setDialogIndex(1);
      } else {
        defaultErrorMessage(res, "gameList");
      }
      logout();
      return false;
    }
  };

  // 获取全区域游戏列表
  const getRegionGameList = async () => {
    const res = await messageApi.regionGameList();
    if (res.status === 200) {
      globalData.regionGameList = res.data;
    } else {
      defaultErrorMessage(res, "regionGameList");
    }
  };

  // 获取媒体列表
  const getMediaList = async () => {
    const res = await messageApi.getMediaList();
    if (res.status === 200) {
      const mediaList = res.data.map(item => item.name.toLowerCase()).filter(item => formatMediaName(item));
      setAbleMedia(mediaList);
      return true;
    } else {
      defaultErrorMessage(res, "media");
      return false;
    }
  };

  // 获取语言列表
  const getLanguageList = async () => {
    const res = await messageApi.getLanguageList();
    if (res.status === 200) {
      const ableLanguage = res.data.map(item => ({ language: item.name, code: item.value }));
      setAbleLanguage(ableLanguage);
      return true;
    } else {
      defaultErrorMessage(res, "language");
      return false;
    }
  };

  // 获取区域参数
  const resSetRegionData = async () => {
    const res = await Api.getRegionData();
    if (res.status === 200) {
      globalData.regionConfigId = res.data.regionConfigId;
      globalData.region = res.data.abbreviation;
      return true;
    } else {
      defaultErrorMessage(res, "regionID");
      return false;
    }
  };

  // 获取用户数据
  const getUserProfile = async (returnData = false) => {
    const res = await messageApi.getCreatorProfile();
    if (res.status === 200) {
      if (returnData) { return res.data; }
      setCreatorData(res.data);
      creatorData = res.data;
      if (res.data.isCreator) {
        return true;
      } else {
        return false;
      }
    } else {
      if (returnData) { return null; }
      defaultErrorMessage(res, "profile");
      return false;
    }
  };

  // 查询用户协议签署情况
  const initSingData = async (returnData = false) => {
    const res = await messageApi.getSignList();
    if (res.status === 200) {
      const protocols = res.data.protocols;
      if (returnData) { return protocols; }
      setProtocols(protocols);
      initGameTagList(protocols);
      // 只有一个游戏 且游戏未签约，进入引导页
      if (protocols.length === 1 && !protocols[0].sign) {
        return false;
      } else {
        return true;
      }
    } else {
      if (returnData) { return null; }
      defaultErrorMessage(res, "protocol");
      return true;
    }
  };

  // 查询九宫格引导任务
  const getGuideTask = async () => {
    const res = await messageApi.guideTask();
    if (res.status === 200) {
      res.data = res.data.sort((a, b) => +a.sequence > +b.sequence ? 1 : -1);
      if (!res.data[0].done) { messageApi.finishTask({ id: res.data[0].taskId }); }
      if (!res.data[1].done) { messageApi.finishTask({ id: res.data[1].taskId }); }
      if (!res.data[2].done) { messageApi.finishTask({ id: res.data[2].taskId }); }
      const blockTask = filterGameData("blockTask");
      if (blockTask && blockTask.length) {
        for (const item of res.data) {
          if (blockTask.includes(+item.taskId)) {
            item.done = true;
          }
        }
      }
      for (let i = 0; i < 6; i++) {
        res.data[i].done = true;
      }
      setGuideTask(res.data);
      return res.data;
    } else {
      defaultErrorMessage(res, "guideTask");
    }
    return null;
  };

  // 登录超时提示
  const loginTimeOut = async (data) => {
    if (!data) { return; }
    if (data.message || data.code) {
      defaultErrorMessage({}, "", `${data.message || ""} ${data.code || ""} timeout`);
    }
    if (data.needReset) {
      await logout();
      setDialogIndex(1);
    }
  };

  // 获取可用的gametag
  const initGameTagList = (protocols, userProfile = null) => {
    const cData = userProfile || creatorData;
    const { gamePlatformStatus, userGameList, newCreatorGamesList } = filterGameTagList(cData, protocols);
    setGamePlatform(gamePlatformStatus);
    setGameTab(userGameList);
    setCreatorData({
      ...cData,
      games: newCreatorGamesList
    });
    globalData.activeGameData = newCreatorGamesList[0];
  };

  // 确认游戏签约情况
  const checkSing = (data = null) => {
    const cData = data || creatorData;
    const signPlatformList = cData.games.map(item => item.platform); // 已签署协议的平台
    const bingGameList = cData.realInfo.map(item => item.platform); // 账号已绑定的游戏所归属的平台
    for (const item of bingGameList) {
      if (signPlatformList.includes(item)) {
        // 至少一个绑定的游戏所归属的平台已经签署协议 可继续
        return true;
      }
    }
    return false;
  };

  // 黑名单确认
  const getIsBlockUser = async (email) => {
    const res = await Api.checkBlackList(email);
    if (res && res.status === 200 && res.data.blackList) {
      blacklistedTip();
      navigate("/");
      logout();
    }
  };

  // 注销
  const logout = async () => {
    locationUtil.setLocationStorage("lilacCreatorSataus", {});
    locationUtil.setLocationStorage("lilacCreatorRegion", {});
    if (globalData.token) {
      await Api.logout();
    }
    setIsLogin(false);
    setToken("");
    setActiveGame(0);
    setCreatorData(null);
    setGameList([]);
    setLogionStatus([]);
    setActiveContentList({});
    setReadTapIndex([1, 1, 1]);
    globalData.activeGameData = {};
    if (!mainOfGame && !mainOfRegion) {
      setEnterRegion("");
      globalData.token = "";
      globalData.regionConfigId = "";
      globalData.region = "";
    }
    if (socket) {
      socket.disconnect();
      socket = null;
    }
    navigate("/");
    return;
  };

  // 更新token
  const refreshFun = async () => {
    const res = await Api.refreshToken(refreshToken);
    if (res.status === 200) {
      locationUtil.setLocationStorage("lilacCreatorSataus", {
        time: new Date().toLocaleString(),
        expiration: res.data.expiration,
        token: res.data.accessToken,
        refreshToken: res.data.refreshToken
      });
      setToken(res.data.accessToken);
      setRefreshToken(res.data.refreshToken);
      globalData.token = res.data.accessToken;
    }
  };

  // 绑定全局通用通知
  const binGlobalPubSub = () => {
    // 注册成功 主动登录
    PubSub.subscribe("singupsuccess", (topic, data) => {
      loginUseName(data);
    });
    // 登录超时
    PubSub.subscribe("timeout", (topic, data) => { loginTimeOut(data); });
    // 注销
    PubSub.subscribe("logout", () => { logout(); });
    // 更新用户数据
    PubSub.subscribe("resetUserData", () => { getUserProfile(); });
    // 恢复页面
    PubSub.subscribe("reset", () => {
      setIsLogin(false);
      setToken("");
      setCreatorData(null);
      setDialogIndex(0);
      locationUtil.setLocationStorage("lilacCreatorSataus", {});
      globalData.token = "";
      navigate("/");
    });
    // 弹窗
    PubSub.subscribe("showDialog", (tag, data) => {
      setDialogIndex(data.index || 0);
      if (data.index && data.index === 3) {
        setTipContent(data.data);
      }
    });
  };

  // 定位初始数据
  const initPageEnter = async () => {
    await getGuideTask();
    const f1 = await getUserProfile(); // 获取用户个人数据 是否为创作者

    getIsBlockUser(creatorData.email); // 确认黑名单

    if (!f1) { // 不是创作者 直接进申请页
      navigate("/application");
      return;
    }

    if (creatorData.realInfo.length === 0 || !checkSing()) { // 是创作者 未签署协议/绑定的游戏都未签署协议 返回首页
      navigate("/");
      setTipContent(langState.signTip);
      setDialogIndex(3);
      return;
    }

    const f2 = await initSingData(); //  获取用户签约数据 是否不满足进入引导页条件

    // 初始化结束，用户可正常登录
    connectSocketEven(); // soceket事件，监听通知/资讯/活动通知
    enterIndexPage(f1, f2);
  };
  
  // 进入页面
  const enterIndexPage = (f1, f2) => {
    if (!f1 && f2) { // 出错 返回首页
      navigate("/");
      // console.log(1);
    } else if (!f1 && !f2) { // 不是创作者 进入申请页
      navigate("/application");
      // console.log(2);
    } else if (f1 && !f2) { // 是创作者，未签约
      navigate("/guide");
      // console.log(3);
    } else if (f1 && f2 && location.pathname === "/") { // 是创作者 不满足引导页 正在首页 => 进入管理页
      navigate("/admin/");
      // console.log(4);
    }
  };

  // 登录成功 绑定全局事件/定位入口页面
  const initPageData = async () => {
    // 定位登录后页面
    initPageEnter();
  };

  // 检查自动登录数据
  const checkLastLogin = () => {
    const loginRegionStatus = locationUtil.getLocationStorage("lilacCreatorRegion") || {};
    const loginStatus = locationUtil.getLocationStorage("lilacCreatorSataus") || {};
    // 确认登录环境
    const preLoginRegion = loginRegionStatus.region;
    if (!preLoginRegion) {
      // 无上次登录环境 结束
      navigate("/");
      return;
    }
    // 有登录数据
    if (mainOfGame) {
      // fl84定制页下 若上次登录不是fl84 结束
      if ((isFl84 && preLoginRegion !== "farlight84")) {
        return;
      }
    } else {
      // 不是fl84区 之前登录的是fl84 结束
      if ((!isFl84 && preLoginRegion === "farlight84")) {
        return;
      }
    }
    // 检测登录区域数据
    if (Object.keys(loginStatus).length && checkRegionLoginData(preLoginRegion)) {
      // 数据正常，转入验证登录数据
      checkLoginTimeOut(loginStatus, preLoginRegion);
    } else {
      // 无登录数据
      if (location.pathname.includes("/signup")) {
        // 注册页 不处理
        return;
      }
      navigate("/");
    }
  };

  // 检查登录超时
  const checkLoginTimeOut = async (loginStatus, preLoginRegion) => {
    const time = new Date(loginStatus.time);
    const expiration = +loginStatus.expiration;
    if (new Date() - time > expiration * 1000) {
      // 登录超时
      setIsLogin(false);
      setToken("");
      defaultErrorMessage({}, "", errorCode(200101));
      locationUtil.setLocationStorage("lilacCreatorSataus", {});
      globalData.token = "";
      navigate("/");
    } else {
      setEnterRegion(preLoginRegion);
      setToken(loginStatus.token);
      // 自动登录首次网络请求(根据用户token数据)
      // Promise.race
      // 触发resolve -> 网络请求成功 -> 网络可用+保存的用户数据可用(token正确且未过期) -> 继续设置登录参数
      Promise.race([
        new Promise((resolve, rejcet) => { getGameList().then(res => { res ? resolve() : rejcet("gameList"); });}), // 游戏列表
        new Promise((resolve, rejcet) => { resSetRegionData().then(res => { res ? resolve() : rejcet("regionData"); });}), // 区域参数
        new Promise((resolve, rejcet) => { getMediaList().then(res => { res ? resolve() : rejcet("mediaList"); });}), // 可用媒体
        new Promise((resolve, rejcet) => { getLanguageList().then(res => { res ? resolve() : rejcet("languageList"); });}) // 可用语言
      ]).then(
        () => {
          setRefreshToken(loginStatus.refreshToken);
          globalData.token = loginStatus.token;
          getRegionData(preLoginRegion);  // 延续登录态 更新页面数据
          setIsLogin(true);
          if (mainOfGame) {
            getRegionGameList();
          }
        },
        (err) => {
          navigate("/");
          throw Error(`init data error: ${err}`);
        }
      );
    }
  };

  // 检查未触发的资讯/素材/活动
  const checkNewMessage = async () => {
    if (!socket) { return; } // 未创建链接 结束
    if (!globalData.activeGameData.tag) { return; } // 未获取到/无绑定的游戏 结束
    const resList = await Promise.all([
      new Promise((resolve) => { const res = messageApi.checkReminder("info", globalData.activeGameData.tag); resolve(res); }),
      new Promise((resolve) => { const res = messageApi.checkReminder("fodder", globalData.activeGameData.tag); resolve(res); }),
      new Promise((resolve) => { const res = messageApi.checkReminder("activity", globalData.activeGameData.tag); resolve(res); })
    ]);
    const list = resList.map(item => item.data || 1);
    setReadTapIndex(list);
  };

  // socket io 事件 触发资讯更新事件
  const connectSocketEven = () => {
    if (socket) {return;}
    console.log("openSocket");
    socket = io(socketURL);
    socket.on("connect", function() {
      console.log("Connected");
      // 连接成功，获取未读数据
      checkNewMessage();
    });
    socket.on("reminder", function() {
      console.log("reminder-on");
      // 有更新信息，获取未读数据
      checkNewMessage();
    });
    socket.on("disconnect", function() {
      console.log("Disconnected");
    });
  };
  
  // 资讯更新事件
  useEffect(() => {
    checkNewMessage();
  }, [activeGame]);

  // 登录成功
  useEffect(() => {
    // 游戏列表gameList必须有数据
    // 保证有可进入的游戏选项
    if (isLogin && gameList.length > 0) {
      initPageData();
    }
  }, [isLogin, gameList]);

  // 绑定token更新通知
  useEffect(() => {
    if (token && refreshToken) {
      if (reTokenTime) {
        clearTimeout(reTokenTime);
        reTokenTime = 0;
      }
      const loginStatus = locationUtil.getLocationStorage("lilacCreatorSataus") || {};
      const expiration = +loginStatus.expiration || 7200;
      reTokenTime = setTimeout(() => { refreshFun(); }, expiration * 1000 * 0.8);
    }
  }, [token, refreshToken]);

  // 插入区域对应逻辑
  useEffect(() => {
    // fl84弹出绑定提示
    if (location.pathname.includes("application")) {
      return;
    }
    if (isLogin && creatorData && creatorData.isCreator && globalData.activeGameData.tag === "farlight84" && !globalData.activeGameData.inEffect) {
      setDialogIndex(22);
    }
  }, [gameTab, activeGame, isLogin, creatorData]);

  // 页面初始/结束逻辑
  // 判断上次登录是否超时
  // 关闭socket
  useEffect(() => {
    binGlobalPubSub();
    checkLastLogin();
    return () => { if (socket) { socket.disconnect(); } };
  }, []);

  // GA数据上报
  useEffect(() => {
    const path = location.pathname.replace("/main", "");
    if (path == "/admin" || path.includes("detail") || path === "/admin/growth") { return; }
    window.gtag("event", `${isPC ? "pc" : "m"}${path}`);
  }, [location]);

  return (
    <>
      { isPC ? <PC /> : <M /> }
      { globalMask && <LoadMask global={true} /> }
    </>
  );
}

export default EnviromentEnter;