import React from "react";
import style from "./dislyteTable.module.scss";
import { isPC } from "@/common/utils/envData";

function DislyteTable() {
  return (
    <div className={`${style.table_box} ${isPC ? "" : style.m}`}>
      <p className={style.table_title}>Short Video Monthly Total View-based reward 1.1</p>
      <table border={1}>
        <thead>
          <tr>
            <th>Platform</th>
            <th>Monthly Views</th>
            <th>Average Views per Video</th>
            <th>Comments/Views</th>
            <th>Number of Videos per Month</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>YouTube</td>
            <td>≥45000</td>
            <td>≥3000</td>
            <td>0.1%</td>
            <td rowSpan={4}>4</td>
          </tr>
          <tr>
            <td>Twitch</td>
            <td>≥30000</td>
            <td>≥3000</td>
            <td>-</td>
          </tr>
          <tr>
            <td>TikTok</td>
            <td>≥120000</td>
            <td>≥12000</td>
            <td>0.1%</td>
          </tr>
          <tr>
            <td>Facebook</td>
            <td>≥60000</td>
            <td>≥5000</td>
            <td>0.1%</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default DislyteTable;
