// 所有数据会进入assets/data/pageData进行过滤

// 顶部nav
const topNavData = {
  signUp: "SIGN UP",
  logIn: "LOG IN",
  application: "APPLICATION FORM",
  home: "HOME",
  back: "BACK",
  profile: "View profile",
  contact: "Contact US",
  logOut: "log out"
};

// 底部版权
const footerData = {
  service: "TERMS OF SERVICE",
  policy: "PRIVACY POLICY",
  footerText: "The Creator Zone is an official content creator platform created by Lilith & Farlight Games.",
};

// 首页文案
const homeData = {
  helpText1: "Is creating content <em>your passion</em>?<br />We&apos;re here to help you!",
  joinBtn: "Join The Creator Zone",
  helpText2: "The only way to officially collaborate with us to get exclusive sneak peeks, tools, and support to create the best content!",
  exhibition1: {
    title: "EXCLUSIVE PERKS",
    desc: "Receive exclusive perks, we will provide you with the necessary tools to bring your content creation to the next level!"
  },
  exhibition2: {
    title: "SUPPORT & EXPOSURE",
    desc: "Be in touch with our staff for support and tips. We provide assistance to grow your channel by exposing your content on our official platforms!"
  },
  exhibition3: {
    title: "Creator Community",
    desc: "Become part of our trusted Creators Discord, where you can connect with Content Creators from all over the world!"
  },
  tierTitle: "Tier Perks",
  tierDesc: "No matter where you are on your journey, we are here to support you.",
  tierItem1: [
    "Access to our Official Creators Discord",
    "Assistance from our Staff to help answer questions you may have",
    "Access to a wide range of art assets"
  ],
  tierItem2: [
    "All Perks in Apprentice Tier",
    "Unlock access to exclusive sneak peeks",
    "Increase your content's exposure by having it promoted on our official channels Partner"
  ],
  tierItem3: [
    "All Perks in previous Tiers",
    "Enjoy invitations to our official events and content",
    "Receive compensation for your contributions with monetary rewards"
  ],
  tierMultTitle: "Creator Growth System",
  tierMultDesc: "We will support you at every stage of your development.",
  tierMultItem1: [
    "Access to the Creator Discord Channel",
    "Access to Exclusive UGC event for creators",
    "Access to art assets",
    "Official support",
    "Monthly and weekly rewards"
  ],
  tierMultItem2: [
    "All of the benefits of the previous tiers",
    "Cash compensation",
    "Official promotion of your channel",
  ],
  tierMultItem3: [
    "All of the benefits of the previous tiers",
    "Chance to become an officially recognized star creator",
    "Special partnership opportunities",
  ],
  helpText3: "The next milestone in your <br />content creation <em>journey</em> starts here!"
};

// fl84定制页文案
const fl84HomeData = {
  slogan: "Farlight 84 creator zone",
  perkTitle: "Become a creator here and you can get",
  perkDesc: "The only way to officially collaborate with us to get exclusive sneak peeks, tools, and support to create the best content!",
  perkSubTitle1: "EXCLUSIVE PERKS",
  perkSubDesc1: "Receive exclusive perks, we will provide you with the necessary tools to bring your content creation to the next level!",
  perkSubTitle2: "SUPPORT & EXPOSURE",
  perkSubDesc2: "Be in touch with our staff for support and tips. We provide assistance to grow your channel by exposing your content on our official platforms!",
  perkSubTitle3: "CREATOR COMMUNITY",
  perkSubDesc3: "Become part of our trusted Creators Discord, where you can content with Content Creators from all over the world!",
  tierTitle: "Tier Perks",
  tierDesc: "No matter where you are on your journey, we are here to support you.",
  tierItem1: {
    title: "Rising Creator",
    name: "(FRC)",
    desc: [
      "- Exclusive Content Creators Discord Role.",
      "- Chance to win Diamond rewards through FRC Tasks.",
      "- Sharing experiences with other fellow FRC members.",
    ]
  },
  tierItem2: {
    title: "Content Creator",
    name: "(FCC)",
    desc: [
      "- Monthly rewards based on your views.",
      "- Win rewards (diamonds and cash) through FCC Tasks.",
      "- Exclusive in-game Avatar Frame and Discord Roles!",
      "- Access to first-hand game information.",
      "- Official promotion of your channel."
    ]
  },
  tierItem3: {
    title: "Star CC",
    name: "(FSC)",
    desc: [
      "- All the benefits mentioned above.",
      "- Exclusive in-game items for the beginning.",
      "- Exclusive in-game identities (such as customized Clan flags, etc.).",
      "- Access to exclusive FL84 products.",
      "- Exclusive redeem codes for fans (including Custom Room Cards)."
    ]
  },
  starTitle: "star creators",
  joinDesc: [
    "The next milestone in your",
    "content creation",
    "journey starts here!"
  ],
  joinBtn: "Become Farlight 84 Creator"
};

// IGame首页文案
const iGameHomeData = {
  enterYourEmail: "Enter Your Email",
  password: "Password",
  forgotPassword: "FORGOT PASSWORD?",
  emailLogin: "EMAIL QUICK LOGIN",
  pwdLogin: "return to use password",
  signUp1: "Don’t have an account？",
  signUp2: "Sign Up",
  send: "SEND CODE",
  codeTip: "Enter 6--digit code",
  desc1: "The only way to officially collaborate with us to get exclusive sneak peeks, tools, and support to create the best content!",
  desc2_1: "Receive exclusive perks, we will provide you with the necessary tools to bring your content creation to the next level!",
  desc2_2: "Be in touch with our staff for support and tips. We provide assistance to grow your channel by exposing your content on our official platforms!",
  desc2_3: "Become part of our trusted Creators Discord, where you can connect with Content Creators from all over the world!",
  tier1_1: "Invitation to our exclusive video content creator Discord server",
  tier1_2: "Exclusive ticket system on the creator Discord server to receive 1 on 1 help from staff",
  tier1_3: "Access to official art assets to help you create your content",
  tier1_4: "The possibility to get in-game rewards and or monetary compensation",
  tier2_1: "All perks in Apprentice Tier",
  tier2_2: "The possibility to have your content featured on our official social media platforms",
  tier3_1: "All perks in previous tiers",
  tier3_2: "Customized gift codes used for giveaways to your viewers",
  tier3_3: "The possibility to get invited to official AFK Journey events",
  tier3_4: "The possibility of co-creating content with the AFK Journey Team",
  updateText: "Last updated on"
};

// 注册页-选择地区
const signUpLanguageData = {
  title: "Your Journey Begins Here",
  desc: "What language do you create content in",
  langEn: ["English"],
  langMult: [
    "العربية",
    "Deutsch",
    "Español",
    "Français",
    "Português",
    "Русский",
    "Türkçe"
  ],
  langSea: [
    "Tiếng Việt",
    "ภาษาไทย",
    "Bahasa Indonesia",
    "Bahasa Melayu",
    "Pilipino"
  ]
};

// 注册页
const signData = {
  title: "Your Journey Begins Here",
  title2: "SIGN UP",
  desc1: "To become a member of Creator Zone, ",
  desc2: "please check the following requirements",
  signRule1_1: "Respects our ",
  signRule1_link: "community guidelines",
  signRule1_2: " and be friendly to other Creators and Community Managers.",
  signRule2: "Be at least 18 years old.",
  signOtherText1: "*If you don't meet the above requirements,",
  signOtherText2: "please submit your application",
  signOtherLink: "here",
  signOtherText3: " ",
  signPlace1: "Enter yout email",
  signPlace2: "Set User Name",
  signPlace3: "Set Password",
  signNeed1: "Your password must have:",
  signNeed2: "8 to 20 characters",
  signNeed3: "Letters, Numbers, and Special Characters",
  signNeed4_1: "You have read the",
  signNeed4_2: "Terms of Service",
  signNeed4_3: "and",
  signNeed4_4: "Privacy Policy",
  signNeed4_5: "of Creator Zone.",
  accept: "Accept",
  next: "NEXT",
  send: "SEND CODE",
  codeTip: "Enter 6--digit code",
  accountTip1: "Already have an account?",
  accountTip2: "sign in",
  accountTip3: "",
};

// 个人信息页
const informationData = {
  title1: "Edit Account Information",
  title2: "Personal information",
  accountTitle: "ACCOUNT INFORMATION",
  mediaTitle: "Social Media",
  gameTitle: "Game",
  gameTag: {
    "video content": "VIDEO CONTENT",
    "shorts": "SHORTS",
    "stream": "STREAM",
  },
  birthdayTitle: "Birthday",
  realNameTitle: "Real Name",
  firstName: "FIRSE NAME",
  laseName: "LAST NAME",
  countryTitle: "Country or Region",
  languageTitle: "Language",
  discordTitle: "Your Discord ID",
  discordDesc: "For us to contact and communicate with you",
  uid: "UID",
  tag: "CONTENT CREATOR TAG",

  // fl84
  fl84StarLevel: ["FRC", "FCC", "FSC"],
};

// 欢迎/引导页
const guideData = {
  welcomeTitle: ["Welcome to", "the Creator Zone!"],
  commRuleTitle: "Community Rules",
  commRuleDesc: [
    "1. Respect",
    "We would like to create a welcoming environment for everyone. Freedom of speech is highly valued, but offensive language or (passive-)aggressive behavior can lead to a warning of eventually a ban from the Creator Zone.",
    "2. Discrimination",
    "Bullying or any discriminating kind of behavior is not allowed. Any degrading comments about things like race, religion, culture, sexual orientation, gender or identitity will not be tolerated and may result in a ban from the Creator Zone.",
    "3. Account Trading",
    "Account selling, trading or (paid) boosting services are prohibited. Any Creator involved in these type of activities may result in a ban from the Creator Zone.",
    "4. Pornography or disturbing content",
    "Distribution of any pornography or disturbing content may result in a ban from the Creator Zone.",
    "5. Plagiarism",
    "All created content should be original, plagiarism may result in a ban from the Creator Zone.",
    "6. Final Word",
    "Just because a rule is not listed here, does not mean we will tolerate it from our Creators. Lilith Games and Farlight Games reserve all rights to the final interpretation of these rules.",
  ],
  agreeBtn: "I AGREE",
  commRuleTip: "Please finish reading the Community Rules",
  nonRuleTitle: "Non-Disclosure Agreement",
  nonRuleTitle2: "Privacy Policy",
  nonRuleDescSub: [
    "1. All art assets, patch previews, and other assets we provide through the platform are considered confidential until official release. Leaking or display of any material without permission from official staff will not be tolerated under any circumstances.",
    "2. Sharing of any confidential information or content of this platform is forbidden.",
    "&nbsp;",
    "CONFIDENTIALITY",
  ],
  nonRuleDesc: [
    "1.1  Content Creator acknowledges that Content Creator’s relationship with Client is one of high trust and confidence and that in the course of Content Creator&apos;s Services to Client, Content Creator will have access to and contact with certain information (the “Confidential Information”). Content Creator will not disclose any Confidential Information to any person or entity other than employees of Client or use the same for any purposes (other than in the performance of the Services) without written approval by an officer of Client, either during or after the Termination, unless and until such Confidential Information has become public knowledge without fault by Content Creator.",
    "1.2  For purposes of this Agreement, Confidential Information shall mean, by way of illustration and not limitation, all information, whether or not in writing, whether or not patentable and whether or not copyrightable, of a private, secret or confidential nature, owned, possessed or used by Client, concerning Client’s business, business relationships or financial affairs, including, without limitation, any Invention, formula, vendor information, customer information, apparatus, equipment, trade secret, process, research, report, technical or research data, clinical data, know-how, computer program, software, software documentation, hardware design, technology, product, processes, methods, techniques, formulas, compounds, projects, developments, marketing or business plan, forecast, unpublished financial statement, budget, license, price, cost, customer, supplier or personnel information or employee list that is communicated to, learned of, developed or otherwise acquired by Content Creator in the course of Content Creator&apos;s Services as a content creator to Client.",
    "1.3  Content Creator’s obligations under this Section 6 shall not apply to any information that (i) is or becomes known to the general public under circumstances involving no breach by Content Creator or others of the terms of this Section 6, (ii) is generally disclosed to third parties by Client without restriction on such third parties, or (iii) is approved for release by written authorization of an officer of Client.",
    "1.4  Content Creator agrees that all files, documents, letters, memoranda, reports, records, data sketches, drawings, models, laboratory notebooks, program listings, computer equipment or devices, computer programs or other written, photographic, or other tangible material containing Confidential Information, whether created by Content Creator or others, which shall come into Content Creator&apos;s custody or possession, shall be and are the exclusive property of Client to be used by Content Creator only in the performance of Content Creator&apos;s duties for Client and shall not be copied or removed from Client’s premises except in the pursuit of the business of Client. All such Materials or copies thereof and all tangible property of Client in the custody or possession of Content Creator shall be delivered to Client, upon the earlier of (i) a request by Client or (ii) the termination of this Agreement. After such delivery, Content Creator shall not retain any such Materials or copies thereof or any such tangible property.",
    "1.5  Content Creator agrees that Content Creator’s obligation not to disclose or to use information and Materials of the types set forth in paragraphs (2) and (4) above, and Content Creator&apos;s obligation to return Materials and tangible property set forth in paragraph (4) above extends to such types of information, Materials and tangible property of customers of Client or suppliers to Client or other third parties who may have disclosed or entrusted the same to Client or to Content Creator.",
    "1.6  Content Creator acknowledges that Client from time to time may have agreements with other persons or with the government, or agencies thereof, that impose obligations or restrictions on Client regarding inventions made during the course of work under such agreements or regarding the confidential nature of such work. Content Creator agrees to be bound by all such obligations and restrictions that are known to Content Creator and to take all action necessary to discharge the obligations of Client under such agreements."
  ],
  nonSignText: "I've already read and agreed to the Non-Disclosure Agreement",
  configBtn: "CONFIRM",
  completeTitle: "Complete Tutorial",
  finishTip: [
    "Congratulation on completing your tutorial!",
    "Welcome to Creator Zone!"
  ]
};

// 申请表单页
const applicationData = {
  applicationTitle: "Please Fill Out the Application Form",
  welcome: "Welcome",
  applicationDesc: [
    "Please fill out the application form with authentic information,",
    "You'l gain access to our Creator Zone after your application' approved!"
  ],
  mediaModeTitle: "1.Select your Social Media Platform",
  mediaModeDesc: "please bind at least one of your social media accounts to verify it's yours.",
  mediaUploadText: "Upload a screenshot of your channel's dashboard",
  createTypeTitle: "2.What type of Content do you create?",
  createTypeDesc: "Choose at least <span>1</span> of the following",
  mainGameTitle: "3.Select your main game",
  mainGameDesc: [
    "Please choose one of the following games that you create content for,",
    "you can also add or adjust selected game after becoming a member of our Creator Zone"
  ],
  uidPlace: "Your UID (numbers only)",
  userNamePlace: "Your Username",
  verifiCodPlace: "Verificaion code",
  userDetailTitle: "Help us to know you even better",
  userDetailSubTitle1: "Enter your Birthday",
  userDetailSubDesc1: "*This information cannot be adjusted after your application is approved",
  day: "Day",
  month: "Month",
  year: "Year",
  userDetailSubTitle2: "Please fill in your real name",
  firstName: "First name",
  laseName: "Last name",
  userDetailSubTitle3: "Language",
  userDetailSubTitle4: "Country or Region",
  userDetailSubTitle5: "Country or Region",
  discordTitle: "Your Discord ID",
  discordDesc: "For us to contact and communicate with you",
  submitBtn: "SUBMIT",
  confirm: "CONFIRM",

  // fl84
  mainGameFl84Title: "3. ENTER YOUR IN-GAME UID FOR VERIFICATION",
  mainGameFl84Desc: [
    "Please enter your Farlight 84 in-game UID for verification. ",
    "The Verification Code will be sent to you via mail in the game. ",
    "Note: Once UID is registered, it can't be changed."
  ],
  mediaUploadVideoTitle: "Please upload 1 video related to <strong>Farlight 84</strong> on this channel.<br />Submissions that don&apos;t meet the requirements will not be approved.",
  mediaUploadVideoDesc: "(Note: The submitted video title must include the keyword &quot;<strong>Farlight 84</strong>&quot;, and description should contain #Farlight84 and #FCC.)",
  mediaUploadPlace: "Insert content URL here",
  sendCode: "Send Verification Code",
  whatIsUID: "What is UID and Verification Code?",
  Verified: "UID Verified",
  submitCodeTip: "The verification code has been sent to your in-game mailbox. Please check and return to this page to enter it."
};

// 管理内页-导航
const adminNavData = {
  home: "HOME",
  gameFeed: "GAME FEED",
  artAssets: "ART ASSETS",
  myContent: "MY CONTENT",
  uploadContent: "Upload Content",
  manageContent: "Manage Content",
  viewEarnings: "VIEW EARNINGS",

  // m
  growth: "GROWTH & DEVELOPMENT",

  // fl84
  announcement: "ANNOUNCEMENT",
};

// 管理内页-首页
const adminHomeData = {
  // 数据总浏
  overview: {
    title: "Content Overview",
    navItem1: "Data Overview",
    navItem2: "Monthly Content Overview",
    totalCount: "Total Content Count",
    totalView: "Total Views",
    totalLike: "Total Likes",
    totalComments: "Total Comments"
  },
  // 推送
  message: {
    more: "more"
  },
  // 收益
  earning: {
    title: "My Earnings",
    item1Name: "Month",
    item2Name: "In-Game Rewards",
    item3Name: "CASH",

    fl84Item1Name: "Weekly Reward Settlement Day",
    fl84Item2Unit: "Diamonds",

    settionLoad: "Settlement In Progress"
  },
  userDetail: {
    welcome: "Welcome back ",
    welcome2: "",
    timeText: "You have been a member on this platform for ",
    timeUnit1: "day",
    timeUnit2: "days",

    // fl84
    fl84StarLevel: ["FRC", "FCC", "FSC"],
    timeTextFl84: "You have joined Farlight 84 Creator Zone for"
  }
};

// 管理内页-素材推送
const artAssetsData = {
  artAssetsTitle: "ART ASSETS",
  selectPlace: "Select by Title/Key word",
  googleLinkBtn: "google drive link",
  request: "request for download",
  download: "downloading",
  requested: "REQUESTED",
  noTip: "NO DATA"
};

// 管理内页-游戏资讯
const gameFeedData = {
  gameFeedTitle: "GAME FEED",
  selectPlace: "Select by Title/Key word",
  noTip: "NO DATA",

  // fl84
  gameFeedTitle2: "Announcement"
};

// 管理内页-收益
const earningData = {
  earningTitle: "VIEW EARNINGS",
  subTitle: "MONTHLY EARNINGS",
  item1Name: "Month",
  item2Name: "In-Game Rewards",
  item3Name: "CASH",
  rewards: "REWARDS EXPLAINED",

  // fl84
  fl84Item1Name: "Weekly Reward Settlement Day",
  fl84RewardUnit: "Diamonds",
  fl84Rewards: "Reward Details",
  settionLoad: "Settlement In Progress",

  detailTitle1: "REWARDS EXPLAINED",
  detailSubTitle1: "List of Your Videos",
  detailTimeTitle: "Settlement Period:",
  detailDesc: "Only videos published during this period will be counted.",
  noData: "No data",
  detailRewards: "Period Diamond Rewards :",
  detailRewardsUnit: "DIAMOND",
  detailRewardsDescTitle: "Settlement day: ",
  detailRewardsDesc1_1: "The views data is based on the data recorded by the social media platform on the",
  detailRewardsDesc1_2: "Settlement day",
  detailRewardsDesc1_3: ". If you have doubts about this reward detail, please contact the captain / customer service on Discord.",
  detailUnit1: "Content Title",
  detailUnit2: "Views",
  detailUnit3: "Publish Date",
  detailUnit4: "Channel",
};

// 管理内页-上传
const upLoadContentData = {
  uploadTitle1: "UPLOAD CONTENT",
  upLoadPlace1: "Insert content URL here",
  submitBtn: ["CONFIRM", "UPLOAD CONTENT", "SUBMIT"],
  time: "TIMESTAMP",
  rightNow: "RIGHT NOW",
  itemTitle1: "TOTAL VIEWS",
  itemTitle2: "TOTAL LIKES",
  itemTitle3: "TOTAL COMMENTS",
  step1: "STEP 1: <span>Choose Creator Task(s) or Normal Uploads?</span>",
  step2: "STEP 2: <span>UPLOAD CONTENT</span>",
  taskDetail: "more details",
  taskTitle: ["THEME", "TIME", "REWARDS"],
  normalUpload: "Normal Content Uploads",
  taskEvent: "Current Submission Event：",
  addUrl: "Add content URL",
  // fl84
  uploadDesc1: "Note: The submitted video title must include the keyword &quot;<strong>Farlight 84</strong>&quot;, and description should contain #Farlight84 and #FCC.",
};

// 管理内页-视频管理
const uploadManageData = {
  title: "MANAGE MY CONTENT",
  selectPlace: "Select by Title/Key word",
  eventPlace: "video submission event",
  totalCount: "Total Content Count",
  totalView: "Total Views",
  totalLike: "Total Likes",
  totalComments: "Total Comments",
  delete: "DELETE",
  sTime: "Start date",
  eTime: "End date"
};

// 提交申请表单成功
const signTip = "Congratulations! Your application for the Creator Zone has been approved. Please sign the creator agreement sent to your E-mail before you gain full access to our platform.";

// 注册协议弹窗
const communityTip = [
  "1. Respect",
  "We would like to create a welcoming environment for everyone. Freedom of speech is highly valued, but offensive language or (passive-)aggressive behavior can lead to a warning of eventually a ban from the Creator Zone.",
  "2. Discrimination",
  "Bullying or any discriminating kind of behavior is not allowed. Any degrading comments about things like race, religion, culture, sexual orientation, gender or identitity will not be tolerated and may result in a ban from the Creator Zone.",
  "3. Account Trading",
  "Account selling, trading or (paid) boosting services are prohibited. Any Creator involved in these type of activities may result in a ban from the Creator Zone.",
  "4. Pornography or disturbing content",
  "Distribution of any pornography or disturbing content may result in a ban from the Creator Zone.",
  "5. Plagiarism",
  "All created content should be original, plagiarism may result in a ban from the Creator Zone.",
  "6. Final Word",
  "Just because a rule is not listed here, does not mean we will tolerate it from our Creators. Lilith Games and Farlight Games reserve all rights to the final interpretation of these rules.",
];

// 弹窗文案
const dialogText = {
  login: "LOG IN",
  enterYourEmail: "Enter Your Email",
  enterYourPassword: "Enter Your Password",
  password: "Password",
  forgotPassword: "FORGOT PASSWORD?",
  emailQuickLogin: "EMAIL QUICK LOGIN",
  signTip: "Don't have an account?",
  signIn: "Sign Up",
  codeTip: "Enter 6--digit code",
  send: "SEND CODE",
  set: "SET NEW PASSWORD",
  next: "NEXT",
  setNew: "Please set a new password",
  confirmPassword: "Confirm Password",
  confirm: "CONFIRM",
  allowAuthorize: "WHETHER TO AUTHORIZE DATA FETCHING",
  allow: "ALLOW",
  refuse: "REFUSE",
  applicationText1: "Your application's submitted,",
  applicationText2: "You'll receive an E-mail feedback within 5 business days.",
  verify: "VERIFY CURRENT PASSWORD",
  signAgreement: "SIGN AGREEMENT",
  upLoadTip: "Upload a screenshot of your channel's dashboard",
  save: "SAVE",
  setNewName: "SET NEW USERNAME",
  setNameNeed: "Name can be modified once every three months",
  userNameSet: "User name set",
  userNameVerify: "Please set 2-24 characters",
  gameTag: "GAME TAG",
  mediaTitle: "SOCLAL MEDOIA BINDING",
  tiersTitle: "CREATOR TIERS EXPLAINED",
  bugTitle: "REPORT A BUG",
  bugTitle2: "TITLE",
  bugTitle3: "EXPLAIN",
  bugMax: "< 50 words",
  bugMax2: "< 1000 words",
  bugTypeTitle: "TYPE",
  bugType1: "Account problem",
  bugType2: "Creator application",
  bugType3: "Media binding",
  bugType4: "Game binding",
  bugType5: "ART ASSET",
  bugType6: "Upload Content",
  bugType7: "Earnings management",
  bugType8: "Other",
  bugUpload: "Multiple pictures can be uploaded,≦3 sheets",
  submit: "SUBMIT",
  explainedTitle: "REWARDS EXPLAINED",
  googleTip1: "BIND MY YOUTUBE ACCOUNT",
  googleTip2: "Please Log in to your Google account first",
  waitAppText1: "We have received your application!",
  waitAppText2: "It'll take around 5 business day for us to look over applications,",
  waitAppText3: "and the result will be sent to you by mail.",
};

export default {
  topNavData,
  footerData,
  homeData,
  fl84HomeData,
  signUpLanguageData,
  signData,
  informationData,
  guideData,
  applicationData,
  adminNavData,
  adminHomeData,
  artAssetsData,
  gameFeedData,
  earningData,
  upLoadContentData,
  uploadManageData,
  signTip,
  communityTip,
  dialogText,
  iGameHomeData
};