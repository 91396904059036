import axios from "axios";
import PubSub from "pubsub-js";
import errorCode from "@/assets/data/errorCode";
import { debounceFun, locationUtil } from "@/common/utils";
import { filterRequestRegionId } from "@/common/filter";
import { globalData } from "@/context/rootContext";
import { isDev } from "@/common/utils/envData";

const TIMEOUT = 25000;
// const BaseURL = "https://events.lilith.com/creative-be"; // test3
// const BaseURL = "/api1"; // test2
const testURL = "https://creator-zone-qa.creator-zone.com"; // test
const formalURL = "https://creator-be.lilith.com"; // formal

const BaseURL = isDev ? testURL : formalURL;

axios.defaults.timeout = TIMEOUT;
const instance = axios.create({
  baseURL: BaseURL,
  timeout: TIMEOUT
});

instance.interceptors.request.use(config => {
  return resetAxiosRequest(config);
}, error => {
  return error;
});

instance.interceptors.response.use(res => {
  return res;
}, error => {
  const errResponse = error.response;
  if (errResponse && errResponse.data) {
    // 正常错误 重新填充错误数据位置
    if (errResponse.data.message && errResponse.data.message.code) {
      error.response.data.code = errResponse.data.message.code;
      error.response.data.message =  errorCode(errResponse.data.message.code) || errResponse.data.message.msg || "";
    }
  } else if (errResponse && +errResponse.status === 401) {
    // 401 token过期
    locationUtil.setLocationStorage("lilacCreatorSataus", {});
    locationUtil.setLocationStorage("lilacCreatorRegion", {});
    PubSub.publish("timeout", {
      message: errorCode(200101),
      needReset: true,
      code: 401
    });
  } else {
    // 其他情况 网络中断/超时
    debounceFun(pushTimeOutMessage, 500)();
  }
  return error.response || error;
});

const pushTimeOutMessage = () => {
  PubSub.publish("timeout", {
    message: (400122),
    code: -1,
    needReset: false
  });
};

const resetAxiosRequest = (config) => {
  // 统一设置token
  const newHeaders = {};
  
  // token
  const loginStatus = (globalData && globalData.token ? globalData : false) ||
    locationUtil.getLocationStorage("lilacCreatorSataus") || {};
  if (Object.keys(loginStatus).length && loginStatus.token) {
    newHeaders["Authorization"] = `Bearer ${loginStatus.token}`;
  }

  const regionText = (globalData && globalData.XCreatorRegion) ? globalData.XCreatorRegion : window.location.href;
  
  // region
  newHeaders["X-Creator-Region"] = regionText;

  // 设置额外配置项 gamehost
  if (config.extraParams && config.extraParams.needGameHost) {
    newHeaders["gamehost"] = globalData.gamehost || window.location.host;
  }
  
  // 合并新旧Header
  config.headers = Object.assign({}, config.headers, newHeaders);

  // 设置额外配置项 regionId
  if (config.extraParams) {
    const newParams = config.params || {};
    if (config.extraParams.needRegionId) {
      newParams.regionId = filterRequestRegionId();
    }
    config.params = newParams;
  }

  return config;
};


export default instance;

export const upLoadPath = `${BaseURL}/c/account/upload`;

export const socketURL = BaseURL;