import React, { Suspense, useContext, useEffect } from "react";
import { Route, Routes, Navigate, NavLink, useLocation } from "react-router-dom";
import style from "./index.module.scss";
import { globalData, rootContext } from "@/context/rootContext";
import { isFl84 } from "@/common/utils/envData";
import Api from "@/common/Api/message";

import GameFeed from "../GameFeed/index";
import ArtAssets from "../ArtAssets/index";

class Growth extends React.Component {
  render() {
    return (
      <div className={style.growth_content}>
        <div className={`growth_nav ${style.growth_nav}`}>
          <GameFeedTap />
          <ArtAssetsTap />
        </div>
        <div className={style.growth_page}>
          <Suspense>
            <Routes>
              <Route path="gamefeed/*" element={<GameFeed />}></Route>
              <Route path="artassets/*" element={<ArtAssets />}></Route>
              <Route path="/*" element={<Navigate to="gamefeed" />}></Route>
            </Routes>
          </Suspense>
        </div>
      </div>
    );
  }
}

function GameFeedTap() {
  const { readTapIndex, setReadTapIndex, langState } = useContext(rootContext);
  const location = useLocation();

  const readTapEven = async () => {
    if (readTapIndex[0] > 0) { return; }
    const res = await Api.checkedReminder("info", globalData.activeGameData.tag);
    if (res.status === 200) {
      let newList = [...readTapIndex];
      newList[0] = 1;
      setReadTapIndex(newList);
    }
  };

  useEffect(() => {
    const path = location.pathname;
    if (path.indexOf("gamefeed") > -1) {
      readTapEven();
    }
  }, [location]);

  return (
    <NavLink to={"/admin/growth/gamefeed"} onClick={readTapEven}>
      { isFl84 ? <>{langState.adminNavData.announcement}</> : <>{langState.adminNavData.gameFeed}</> }
      { readTapIndex[0] < 0 ? <i></i> : "" }
    </NavLink>
  );
}

function ArtAssetsTap() {
  const { readTapIndex, setReadTapIndex, langState } = useContext(rootContext);
  const location = useLocation();

  const readTapEven = async () => {
    if (readTapIndex[1] > 0) { return; }
    const res = await Api.checkedReminder("fodder", globalData.activeGameData.tag);
    if (res.status === 200) {
      let newList = [...readTapIndex];
      newList[1] = 1;
      setReadTapIndex(newList);
    }
  };

  useEffect(() => {
    const path = location.pathname;
    if (path.indexOf("artassets") > -1) {
      readTapEven();
    }
  }, [location]);

  return (
    <NavLink to={"/admin/growth/artassets"} onClick={readTapEven}>
      {langState.adminNavData.artAssets}
      { readTapIndex[1] < 0 ? <i></i> : "" }
    </NavLink>
  );
}

export default Growth;