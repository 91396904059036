import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import style from "./index.module.scss";
import Api from "@/common/Api/message";
import LoadMask from "@/components/loadMask";

import { rootContext, globalData } from "@/context/rootContext";
import { defaultErrorMessage } from "@/common/utils";

function NonDisclosure(porps) {

  const navigate = useNavigate();
  const {
    protocols, setProtocols,
    guideTask, setGuideTask, gameList,
    setGamePlatform, langState
  } = useContext(rootContext);
  const [isAccept, setIsAccept] = useState(false);
  const [showLoad, setShowLoad] = useState(false);

  const sign = async (protocolIds) => {
    window.gtag("event", "m_non_disclosure");
    // console.log(gameTab);
    if (protocolIds.length) {
      const data = {
        protocolIds,
        gametag: globalData.activeGameData.tag
      };
      setShowLoad(true);
      const res = await Api.singRule(data);
      setShowLoad(false);
      if (res.status === 200) {
        finishTask();
        const newPlotocols = protocols.map(item => {
          item.sign = true;
          return item;
        });
        setProtocols(newPlotocols);
        resetGamePlatform(newPlotocols);
        // navigate("/admin/home");
      } else {
        defaultErrorMessage(res, "protocol");
      }
    } else {
      finishTask();
    }
    navigate("/admin/home");
  };

  const resetGamePlatform = (protocols) => {
    const gamePlatformStatus = {};
    for (const item of protocols) {
      for (const subItem of gameList) {
        if (item.gameTag === (subItem.gameTag || subItem.tag)) {
          gamePlatformStatus[subItem.platform] = true;
        }
      }
    }
    setGamePlatform(gamePlatformStatus);
  };

  const configRule = () => {
    if (isAccept) {
      const activeGameTap = globalData.activeGameData.tag;
      sign(protocols.filter(item => (item.gameTag === activeGameTap) && (!item.sign)).map(item => item.id));
    }
  };

  const finishTask = () => {
    if (!guideTask[3].done) {
      guideTask[3].done = true;
      setGuideTask([...guideTask]);
      Api.finishTask({ id: guideTask[3].taskId });
    }
  };

  return (
    <div className={style.community_rules_box} style={{ "display": porps.task2Box ? "block" : "none" }}>
      <div className={style.community_title}>{langState.guideData.nonRuleTitle}</div>
      <div className={`${style.communit_detail}`}>
        {
          langState.guideData.nonRuleDescSub.map(item => (
            <><p className={style.sub} dangerouslySetInnerHTML={{ __html: item }}></p></>
          ))
        }
        {
          langState.guideData.nonRuleDesc.map(item => (
            <><p>{item}</p></>
          ))
        }
      </div>
      <p className={style.agree_tip}>
        <i className={`${isAccept ? style.active : ""}`} onClick={() => { setIsAccept(!isAccept); }}></i>
        <span>{langState.guideData.nonSignText}</span>
      </p>
      <div className={`${style.agree_btn} ${isAccept ? style.active : ""}`} onClick={configRule}>
        {langState.guideData.configBtn}
      </div>
      {
        showLoad && <LoadMask />
      }

    </div>
  );
}

const NonDisclosureMemo = React.memo(NonDisclosure);

export default NonDisclosureMemo;