// discord链接
const discordLinkJp = {
  igame: "https://discord.gg/EuS7hHdWjB"
};


// 游戏名称
const gameTagJp = {
  igame: "AFK:ジャーニー"
};

export const regionStoreJp = {
  blockTask: [8],
  region: "jp",
  discordLink: discordLinkJp,
  gameTag: gameTagJp
};