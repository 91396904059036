import React, { useContext, useEffect, useState } from "react";
import style from "./index.module.scss";
import { formatMediaName, getWeekDay } from "@/common/utils";
import { rootContext } from "@/context/rootContext";

function RewardDetails(props) {
  const { langState } = useContext(rootContext);
  const [data1, setData1] = useState(null);
  const [data2, setData2] = useState(null);
  const [time1, setTime1] = useState("");
  const [time2, setTime2] = useState("");
  const [sortType1, setSortType1] = useState(1);
  const [sortType2, setSortType2] = useState(1);

  const filterTime = (str) => {
    let time1 = str.slice(0, 10);
    time1 = new Date(time1).toDateString().split(" ").map((item, index) => {
      if (index === 1 || index === 2) { return ` ${item}`; }
      else return "";
    }).join("");
    let time2 = str.slice(13);
    time2 = new Date(time2).toDateString().split(" ").map((item, index) => {
      if (index === 1 || index === 2) { return ` ${item}`; }
      else return "";
    }).join("");
    return `${time1} -${time2}`.trim();
  };

  const getLastWeek = () => {
    const now = new Date();
    const lastWeekNow = new Date(now.getTime() - 7 * 24 * 3600 * 1000);
    const t1 = getWeekDay(1, lastWeekNow);
    const t2 = getWeekDay(0, now);
    return `${t1} - ${t2}`;
  };

  const getLast4Week = () => {
    const now = new Date();
    const last4WeekNow = new Date(now.getTime() - 5 * 7 * 24 * 3600 * 1000);
    const last3WeekNow = new Date(now.getTime() - 4 * 7 * 24 * 3600 * 1000);
    const t1 = getWeekDay(1, last4WeekNow);
    const t2 = getWeekDay(0, last3WeekNow);
    return `${t1} - ${t2}`;
  };

  const sortData = () => {
    // console.log(sortType1);
    const p_data = props;
    if (+sortType1 === 2) {
      // console.log(2);
      if (p_data.data && p_data.data.weekBill && p_data.data.weekBill.contents && p_data.data.weekBill.contents.length) {
        setData1(p_data.data.weekBill.contents.sort((a, b) => {
          return new Date(b.publishTime.slice(0, 10)) - new Date(a.publishTime.slice(0, 10));
        }));
      }
    } else {
      // console.log(1);
      if (p_data.data && p_data.data.weekBill && p_data.data.weekBill.contents && p_data.data.weekBill.contents.length) {
        setData1(p_data.data.weekBill.contents.sort((a, b) => {
          return new Date(a.publishTime.slice(0, 10)) - new Date(b.publishTime.slice(0, 10));
        }));
      }
    }
    if (+sortType2 === 2) {
      if (p_data.data && p_data.data.compensateBill && p_data.data.compensateBill.contents && p_data.data.compensateBill.contents.length) {
        setData2(p_data.data.compensateBill.contents.sort((a, b) => {
          return new Date(b.publishTime.slice(0, 10)) - new Date(a.publishTime.slice(0, 10));
        }));
      }
    } else {
      if (p_data.data && p_data.data.compensateBill && p_data.data.compensateBill.contents && p_data.data.compensateBill.contents.length) {
        setData2(p_data.data.compensateBill.contents.sort((a, b) => {
          return new Date(a.publishTime.slice(0, 10)) - new Date(b.publishTime.slice(0, 10));
        }));
      }
    }
  };

  useEffect(() => {
    sortData();
  }, [sortType1, sortType2]);

  useEffect(() => {
    setTime1((props.data && props.data.weekBill && filterTime(props.data.weekBill.period)) || filterTime(getLastWeek()));
    setTime2((props.data && props.data.compensateBill && filterTime(props.data.compensateBill.period)) || filterTime(getLast4Week()));
  }, [props]);

  return (
    <div className={`detail_content ${style.detail_content}`}>
      <div className={style.back} onClick={props.back}></div>
      <div className={style.title}>{langState.earningData.detailTitle1}</div>
      <div className={style.rewardContent}>
        <div className={`rewardTableBox ${style.rewardTableBox}`}>
          <div className={style.tableBoxText}>
            <p className={style.text1}>{langState.earningData.detailSubTitle1}</p>
            <p className={style.text2}>{langState.earningData.detailTimeTitle} {time1}</p>
          </div>
          <div className={style.tableBoxText2}>
            <p className={style.text3}>{langState.earningData.detailDesc}</p>
          </div>
          {
            (data1 && data1.length) ? (
              <div className={style.rewardTable}>
                <ul className={style.table_head}>
                  <li>{langState.earningData.detailUnit1}</li>
                  <li>{langState.earningData.detailUnit2}</li>
                  <li>
                    {langState.earningData.detailUnit3}
                    <i className={`${style.sort} ${style[`sort${sortType1}`]}`} onClick={() => { setSortType1(sortType1 === 1 ? 2 : 1); }}></i>
                  </li>
                  <li>{langState.earningData.detailUnit4}</li>
                </ul>
                <div className={style.table_body}>
                  {
                    data1.map(item => (
                      <ul key={item.id}>
                        <li>{item.title}</li>
                        <li>{item.viewCount}</li>
                        <li>{(item.publishTime || item.updatedTime).slice(0, 10)}</li>
                        <li>{formatMediaName(item.mTag)}</li>
                      </ul>
                    ))
                  }
                </div>
              </div>
            ) : (
              <div className={style.noData}>
                <p>{langState.earningData.noData}</p>
              </div>
            )
          }
          <div className={style.bounBox}>
            <p>{langState.earningData.detailRewards} <span><em>{(props.data && props.data.weekBill && props.data.weekBill.reward) || 0}</em> {langState.earningData.detailRewardsUnit}</span></p>
          </div>
        </div>
        <div className={style.slice}></div>
        <div className={`rewardTableBox ${style.rewardTableBox}`}>
          <div className={style.tableBoxText}>
            <p className={style.text1}>{langState.earningData.detailSubTitle1}</p>
            <p className={style.text2}>{langState.earningData.detailTimeTitle} {time2}</p>
          </div>
          <div className={style.tableBoxText2}>
            <p className={style.text3}>{langState.earningData.detailDesc}</p>
          </div>
          {
            (data2 && data2.length) ? (
              <div className={style.rewardTable}>
                <ul className={style.table_head}>
                  <li>{langState.earningData.detailUnit1}</li>
                  <li>{langState.earningData.detailUnit2}</li>
                  {/* <li>New Views added</li> */}
                  <li>
                    {langState.earningData.detailUnit3}
                    <i className={`${style.sort} ${style[`sort${sortType2}`]}`} onClick={() => { setSortType2(sortType2 === 1 ? 2 : 1); }}></i>
                  </li>
                  <li>{langState.earningData.detailUnit4}</li>
                </ul>
                <div className={style.table_body}>
                  {
                    data2.map(item => (
                      <ul key={item.id}>
                        <li>{item.title}</li>
                        <li>{item.viewCount}</li>
                        {/* <li>{item.viewInc || 0}</li> */}
                        <li>{(item.publishTime || item.updatedTime).slice(0, 10)}</li>
                        <li>{formatMediaName(item.mTag)}</li>
                      </ul>
                    ))
                  }
                </div>
              </div>
            ) : (
              <div className={style.noData}>
                <p>{langState.earningData.noData}</p>
              </div>
            )
          }
          <div className={style.bounBox}>
            {
              (props.data && props.data.compensateBill) ? (
                <>
                  {
                    props.data.compensateBill.oldCalcReward ? (
                      <>
                        <p>{langState.earningData.detailRewards}
                          <span>
                            <em className={style.pre}>&nbsp;{(props.data.compensateBill.oldCalcReward) || 0}</em> <span className={style.pre}>{langState.earningData.detailRewardsUnit}</span>
                            <em>&nbsp;+&nbsp;{(Math.max((props.data.compensateBill.calcReward || 0), (props.data.compensateBill.oldCalcReward || 0)) - (props.data.compensateBill.oldCalcReward || 0))}</em> {langState.earningData.detailRewardsUnit}
                          </span>
                        </p>
                      </>
                    ) : (
                      <>
                        <p>{langState.earningData.detailRewards} <span><em>{props.data.compensateBill.reward || 0}</em> {langState.earningData.detailRewardsUnit}</span></p>
                      </>
                    )
                  }
                </>
              ) : (
                <>
                  <p>{langState.earningData.detailRewards} <span><em>0</em> {langState.earningData.detailRewardsUnit}</span></p>
                </>
              )
            }
          </div>
        </div>
        <div className={style.question}>
          <p>{langState.earningData.detailRewardsDescTitle} {props.setTime}</p>
          <p>{langState.earningData.detailRewardsDesc1_1} <em>{langState.earningData.detailRewardsDesc1_2}</em>{langState.earningData.detailRewardsDesc1_3}</p>
        </div>
      </div>
    </div>
  );
}

const RewardDetailsMemo = React.memo(RewardDetails);

export default RewardDetailsMemo;
