import React, { lazy } from "react";
import { useRoutes, Navigate } from "react-router-dom";
import { isPC } from "@/common/utils/envData";

import CommEnter from "@/views/commEnter";
import PCRouter from "@/router/pcRoutes";
import MRouter from "@/router/mRoutes";

const Privacy = lazy(() => import("@/views/Agreement/privacy"));
const Termsofuse = lazy(() => import("@/views/Agreement/termsofuse"));

function element() {
  const routes = useRoutes([
    { path: "/termsofuse", element: <Termsofuse /> },
    { path: "/privacy", element: <Privacy /> },
    { path: "/*", element: <CommEnter />, children: isPC ? PCRouter : MRouter },
    { path: "*", element: <Navigate to="/" /> },
  ]);

  return routes;
}

export default element;
