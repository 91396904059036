// 所有数据会进入assets/data/pageData进行过滤
// 顶部nav
const topNavData = {
  signUp: "註冊",
  logIn: "登入",
  application: "申請表格",
  home: "首頁",
  back: "返回",
  profile: "個人資料",
  contact: "聯絡我們",
  logOut: "登出"
};

// 底部版权
const footerData = {
  service: "服務條款",
  policy: "隱私政策",
  footerText: "創作者專區是由奧瑞吉和Farlight Games官方打造的內容創作者平台。",
};

// IGame首页文案
const iGameHomeData = {
  enterYourEmail: "輸入你的信箱",
  password: "密碼",
  forgotPassword: "忘記密碼？",
  emailLogin: "信箱快速登入",
  pwdLogin: "返回上一頁以密碼登入",
  signUp1: "尚未擁有帳號？",
  signUp2: "註冊",
  send: "發送驗證碼",
  codeTip: "輸入6位數驗證碼",
  desc1: "這是唯一與官方合作的管道，可以獲得獨家的尋找功能、工具以及支援，幫助你打造最棒的內容！",
  desc2_1: "在這裡，你可以獲得專屬好康，還可以找到創作時必不可少的工具，讓你的創作更加完美！",
  desc2_2: "與官方保持聯繫，將可以獲得來自官方的幫助與創作秘技哦！我們將在官方平台公開你的作品，為你帶來更多觀眾。",
  desc2_3: "加入我們值得信賴的創作者 Discord，與其他優秀的創作者交流！",
  tier1_1: "邀請進入我們獨家的Discord影片創作者專區",
  tier1_2: "可以使用官方美術素材協助你創作內容",
  tier1_3: "你的作品還有機會在官方社群平台上亮相！",
  tier1_4: " ",
  tier2_1: "上述權益",
  tier2_2: "專屬兌換碼，與你的觀眾共享福利",
  tier2_3: "還有機會受邀參加官方活動",
  tier3_1: "上述權益",
  tier3_2: "有機會獲得遊戲內獎勵或獎金",
  tier3_3: "獲得與官方團隊一同創作的機會",
  tier3_4: " ",
  updateText: "最後更新："
};

// 注册页-选择地区
const signUpLanguageData = {
  title: "你的冒險從這裡開始！",
  desc: "你使用何種語言進行創作？",
};

// 注册页
const signData = {
  title: "你的冒險從這裡開始！",
  title2: "註冊",
  desc1: "要成為創作者專區的一員，請確認以下要求：",
  desc2: " ",
  signRule1_1: "遵守我們的",
  signRule1_link: "社群守則",
  signRule1_2: "，友善對待其他創作者和社群管理員。",
  signRule2: "年滿18歲。",
  signOtherText1: "*如果你不符合上述要求，",
  signOtherText2: "請在",
  signOtherLink: "這裡",
  signOtherText3: "發送你的申請。",
  signPlace1: "輸入你的電子信箱",
  signPlace2: "設定使用者名稱",
  signPlace3: "設定密碼",
  signNeed1: "你的密碼必須包含：",
  signNeed2: "8至20個字符",
  signNeed3: "字母、數字和特殊符號",
  signNeed4_1: "你已閱讀了創作者專區的",
  signNeed4_2: "服務條款",
  signNeed4_3: "和",
  signNeed4_4: "隱私政策",
  signNeed4_5: "。",
  accept: "接受",
  next: "下一步",
  send: "發送驗證碼",
  codeTip: "輸入6位數驗證碼",
  accountTip1: "已經有帳號？ ",
  accountTip2: "登入",
  accountTip3: "",
};

// 个人信息页
const informationData = {
  title1: "編輯帳號資訊",
  title2: "個人資訊",
  accountTitle: "帳號資訊",
  mediaTitle: "社群媒體",
  gameTitle: "遊戲",
  gameTag: {
    "video content": "影音內容",
    "shorts": "短影片",
    "stream": "實況",
  },
  birthdayTitle: "生日",
  realNameTitle: "真實姓名",
  firstName: "名",
  laseName: "姓",
  countryTitle: "國家/地區",
  languageTitle: "語言",
  discordTitle: "你的Discord ID",
  discordDesc: "以便取得聯繫",
  uid: "UID",
  tag: "內容創作者標籤",
};

// 欢迎/引导页
const guideData = {
  welcomeTitle: ["歡迎來到", "創作者專區！"],
  commRuleTitle: "社群守則",
  commRuleDesc: [
    "1. 尊重",
    "為創造出友善的創作環境，我們尊重各位創作者的言論自由，但仍需注意用詞，攻擊性言語或侵犯他人權益的行為將有可能收到警告，情節嚴重者將無法再進入創作者專區。",
    "2. 歧視",
    "不允許任何霸凌或歧視性行為。若出現貶低他人種族、宗教、文化、性取向、性別或身份等留言，一經發現，將無法再進入創作者專區。",
    "3. 帳號交易",
    "禁止出售、交易或進行工商服務。進行上述活動的創作者可能會被禁止進入創作者專區。",
    "4. 色情或不雅內容",
    "發布任何色情或不雅內容將無法再進入創作者專區。",
    "5. 抄襲",
    "創作內容僅限原創，若抄襲他人作品將無法再進入創作者專區。",
    "6. 最終裁決權",
    "若出現上述規則中未包含的違規行為，奧瑞吉 和 Farlight Games 將保留最終解釋權。",
  ],
  agreeBtn: "我同意",
  commRuleTip: "請確認已閱讀社群守則",
  nonRuleTitle: "保密協議",
  nonRuleTitle2: "隱私政策",
  nonRuleDescSub: [
    "1. 平台內提供的所有美術素材、預告內容和其他素材在正式發布前都均為商業機密範疇。不可在任何未經官方許可的情況下泄露或公開任何資料。",
    "2. 禁止分享任何機密資料或關於此平台的內容。",
    "&nbsp;",
    "機密性",
  ],
  nonRuleDesc: [
    "1.1 創作者同意，創作者與客戶之間的關係建立在彼此信任的基礎上，當創作者為客戶提供服務的過程中，創作者將能夠查看並接觸部分未公開資訊（「機密資料」）。非經客戶的書面批准，創作者不得向除客戶員工之外的任何人或實體透露任何機密資料，也不得用於任何目的（除了履行服務之外），",
    "1.2 就本協議而言，機密資料應包括但不限於客戶擁有、擁有或使用的一切屬於私密、秘密或機密性質的資料。無論是否為書面資料，無論是否可專利，無論是否擁有版權，均屬於客戶業務、業務關係或財務事務的私人、秘密或機密性質的資訊，包括但不限於任何發明、配方、供應商資料、客戶資料、設備、商業機密、工藝、研究、報告、技術或研究數據、臨床數據、專業技術、程式、軟體、軟體相關文檔、硬體設計、技術、產品、工藝、方法、技術、公式、化合物、項目、開發、市場營銷或業務企劃、預測、未發表的財務報表、預算、許可證、價格、成本、客戶，亦包含供應商、人員資料、客戶及供應商披露或委托給客戶或創作者的第三方員工清單，無論是否為履行職責期間，創作者均需瞭解上述內容。",
    "1.3 本協議第6條下關於創作者的義務不適用於下列情況：（i）在未涉及創作者或其他人違反本第6條條款的情況下，已經或即將公開的資訊，（ii）未受到客戶對這類第三方的任何條件限制下，由客戶傳達給第三方，或（iii）在客戶的書面授權下發布的資訊，",
    "1.4 創作者同意，一旦創作者在履行創作者為客戶充當內容創作者的職責的過程中接觸到或持有的所有文件、文件、信函、備忘錄、報告、記錄、數據素描、圖紙、模型、實驗草稿、程式清單、電腦設備或設備、電腦程式或其他含有機密資料的書面、照片或其他有形材料，無論是否為創作者均應將其交還客戶，只能在創作者履行職責期間使用，不得擅自複製或移除。創作者代為保管或持有此類材料與其副本等客戶的有形財產均需在（i）客戶的要求或（ii）本協議終止時交還。交還後，創作者不得保留任何此類材料與其副本或任何有形財產，",
    "1.5 創作者同意，創作者不得對客戶、客戶的客戶或供應商披露或委託資訊和材料的其他第三方的此類資訊、材料和有形財產的機密性和使用的義務，創作者的義務和退還材料和有形財產的義務擴展到客戶或創作者在創作者履行創作者為客戶職責的過程中透過客戶或創作者向客戶透漏或委託給創作者的客戶、供應商或其他第三方的此類資訊、材料和有形財產，",
    "1.6 創作者同意，客戶可能會與其他人或政府或其機構簽訂協議，根據該協議對客戶在工作過程中創作的發明或有關該工作的機密性施加義務或限制。創作者同意遵守創作者知道的所有這類義務和限制，並採取一切必要的行動履行客戶在這些協議下的義務。"
  ],
  nonSignText: "我已經閱讀並同意保密協議",
  configBtn: "確認",
  completeTitle: "完整教學",
  finishTip: [
    "恭喜你完成教學！",
    "歡迎來到創作者專區！"
  ],
  guideTask: {
    "1": "成為創作者專區的會員",
    "2": "完成登入",
    "3": "創作者注意事項",
    "4": "保密協議",
    "5": "填寫個人資訊",
    "6": "綁定社群媒體平台帳號",
    "7": "創作者等級說明",
    "8": "歡迎影片",
    "9": "加入創作者 Discord 頻道",
  }
};

// 申请表单页
const applicationData = {
  applicationTitle: "請填寫申請表格",
  welcome: "歡迎",
  applicationDesc: [
    "請使用真實的資料填寫申請表格，",
    "當你的申請通過後，你就可以進入我們的創作者專區啦！"
  ],
  mediaModeTitle: "1. 選擇你的社群媒體平台",
  mediaModeDesc: "請至少綁定一個社群媒體平台帳號以認證你的帳號。",
  mediaUploadText: "上傳你的頻道主頁截圖",
  createTypeTitle: "2. 你創作的內容是哪種類型？",
  createTypeDesc: "請至少選擇<span>一</span>項",
  mainGameTitle: "3. 選擇你的主要遊戲",
  mainGameDesc: [
    "請選擇主要創作方向",
    "成為我們創作者專區的會員後，你也可以新增或調整已選擇的遊戲。"
  ],
  uidPlace: "你的 UID（僅數字）",
  userNamePlace: "你的使用者名稱",
  verifiCodPlace: "驗證碼",
  userDetailTitle: "讓我們更了解你",
  userDetailSubTitle1: "輸入你的生日",
  userDetailSubDesc1: "*申請通過後無法再修改",
  day: "日",
  month: "月",
  year: "年",
  userDetailSubTitle2: "請填寫你的真實姓名",
  firstName: "名字",
  laseName: "姓氏",
  userDetailSubTitle3: "語言",
  userDetailSubTitle4: "國家或地區",
  userDetailSubTitle5: "國家或地區",
  discordTitle: "你的 Discord ID",
  discordDesc: "方便讓我們與你溝通聯繫",
  submitBtn: "送出",
  confirm: "確認",
};

// 管理内页-导航
const adminNavData = {
  home: "首頁",
  gameFeed: "遊戲動態",
  artAssets: "美術素材",
  myContent: "我的內容",
  uploadContent: "上傳內容",
  manageContent: "管理內容",
  viewEarnings: "查看收益",

  // m
  growth: "發展趨勢",
};

// 管理内页-首页
const adminHomeData = {
  // 数据总浏
  overview: {
    title: "內容總覽",
    navItem1: "數據總覽",
    navItem2: "每月內容總覽",
    totalCount: "總內容數量",
    totalView: "總觀看次數",
    totalLike: "總按讚數",
    totalComments: "總留言數"
  },
  // 推送
  message: {
    more: "更多"
  },
  // 收益
  earning: {
    title: "我的收益",
    item1Name: "月份",
    item2Name: "遊戲內獎勵",
    item3Name: "現金",

    settionLoad: "正在進行結算"
  },
  userDetail: {
    welcome: "歡迎回來，",
    welcome2: "",
    timeText: "你已經成為這個平台的會員",
    timeUnit1: "天了。",
    timeUnit2: "天了。",
  }
};

// 管理内页-素材推送
const artAssetsData = {
  artAssetsTitle: "美術素材",
  selectPlace: "按標題/關鍵字選擇",
  googleLinkBtn: "Google Drive 連結",
  request: "下載申請",
  requested: "已申請",
  noTip: "尚未獲得數據"
};

// 管理内页-游戏资讯
const gameFeedData = {
  gameFeedTitle: "遊戲動態",
  selectPlace: "標題/關鍵字搜尋",
  noTip: "尚未獲得數據",
};

// 管理内页-收益
const earningData = {
  earningTitle: "查看收益",
  subTitle: "每月收益",
  item1Name: "月份",
  item2Name: "遊戲內獎勵",
  item3Name: "現金",
  rewards: "獎勵說明",

  detailTitle1: "獎勵說明",
  detailSubTitle1: "你的影片清單",
  detailTimeTitle: "結算期間：",
  detailDesc: "僅計入期間內發布的影片。",
  noData: "尚未獲得任何數據",
  detailRewards: "本期鑽石獎勵：",
  detailRewardsUnit: "鑽石",
  detailRewardsDescTitle: "結算日期：",
  detailRewardsDesc1_1: "點閱率將以社群平台上記錄的數據為主。",
  detailRewardsDesc1_2: " ",
  detailRewardsDesc1_3: "若有相關疑問，請聯繫Discord客服。",
  detailUnit1: "內容標題",
  detailUnit2: "觀看次數",
  detailUnit3: "發布日期",
  detailUnit4: "頻道",
};

// 管理内页-上传
const upLoadContentData = {
  uploadTitle1: "上傳內容",
  upLoadPlace1: "在這裡插入內容網址",
  submitBtn: ["確認", "上傳內容", "送出"],
  time: "結算時間",
  rightNow: "現在",
  itemTitle1: "總觀看次數",
  itemTitle2: "總按讚數",
  itemTitle3: "總留言數",
  step1: "第1步: <span>選擇創作者任務還是普通上傳？</span>",
  step2: "第2步: <span>上傳內容</span>",
  taskDetail: "更多細節",
  taskTitle: ["主題", "時間", "獎勵"],
  normalUpload: "普通內容上傳",
  taskEvent: "目前活動：",
  addUrl: "新增網址",
};

// 管理内页-视频管理
const uploadManageData = {
  title: "管理我的內容",
  selectPlace: "按標題/關鍵字選擇",
  eventPlace: "影片提交活動",
  totalCount: "總內容數量",
  totalView: "總觀看次數",
  totalLike: "總按讚數",
  totalComments: "總留言數",
  delete: "刪除"
};

// 提交申请表单成功
const signTip = "恭喜！你的創作者專區申請已通過。進入平台前，請先簽署創作者協議，現已寄送至你的電子信箱。";

// 注册协议弹窗
const communityTip = [
  "1. 尊重",
    "為創造出友善的創作環境，我們尊重各位創作者的言論自由，但仍需注意用詞，攻擊性言語或侵犯他人權益的行為將有可能收到警告，情節嚴重者將無法再進入創作者專區。",
    "2. 歧視",
    "不允許任何霸凌或歧視性行為。若出現貶低他人種族、宗教、文化、性取向、性別或身份等留言，一經發現，將無法再進入創作者專區。",
    "3. 帳號交易",
    "禁止出售、交易或進行工商服務。進行上述活動的創作者可能會被禁止進入創作者專區。",
    "4. 色情或不雅內容",
    "發布任何色情或不雅內容將無法再進入創作者專區。",
    "5. 抄襲",
    "創作內容僅限原創，若抄襲他人作品將無法再進入創作者專區。",
    "6. 最終裁決權",
    "若出現上述規則中未包含的違規行為，奧瑞吉 和 Farlight Games 將保留最終解釋權。",
];

// 弹窗文案
const dialogText = {
  login: "登入",
  enterYourEmail: "輸入你的電子信箱",
  enterYourPassword: "輸入你的密碼",
  password: "密碼",
  forgotPassword: "忘記密碼？",
  emailQuickLogin: "電子信箱快速登入",
  signTip: "還沒有帳號？",
  signIn: "註冊",
  codeTip: "輸入6位數驗證碼",
  send: "發送驗證碼",
  set: "設定新密碼",
  next: "下一步",
  setNew: "請設定一個新密碼",
  confirmPassword: "確認密碼",
  confirm: "確認",
  allowAuthorize: "是否授權取得資料",
  allow: "允許",
  refuse: "拒絕",
  applicationText1: "你的申請已送出，",
  applicationText2: "你將在5個工作日內收到電子信箱反饋。",
  verify: "驗證當前密碼",
  signAgreement: "簽署協議",
  upLoadTip: "上傳你的頻道主頁截圖",
  save: "儲存",
  setNewName: "設定新用戶名",
  setNameNeed: "名稱每三個月可修改一次",
  userNameSet: "已設定用戶名",
  userNameVerify: "請輸入2-24個字元",
  gameTag: "遊戲標籤",
  mediaTitle: "綁定第三方帳號",
  tiersTitle: "創作者階層解釋",
  bugTitle: "回報Bug",
  bugTitle2: "標題",
  bugTitle3: "解釋",
  bugMax: "< 50個字",
  bugMax2: "< 1000個字",
  bugTypeTitle: "類型",
  bugType1: "帳戶問題",
  bugType2: "創作者申請",
  bugType3: "媒體綁定",
  bugType4: "遊戲綁定",
  bugType5: "美術資產",
  bugType6: "上傳內容",
  bugType7: "收益管理",
  bugType8: "其他",
  bugUpload: "可以上傳多張圖片，≦3張",
  submit: "送出",
  explainedTitle: "獎勵解釋",
  googleTip1: "綁定我的YouTube帳戶",
  googleTip2: "請首先登入你的Google帳戶",
};

export default {
  topNavData,
  footerData,
  signUpLanguageData,
  signData,
  informationData,
  guideData,
  applicationData,
  adminNavData,
  adminHomeData,
  artAssetsData,
  gameFeedData,
  earningData,
  upLoadContentData,
  uploadManageData,
  signTip,
  communityTip,
  dialogText,
  iGameHomeData
};