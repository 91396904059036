import React, { useContext, useEffect, useState } from "react";
import style from "./index.module.scss";
import czIcon from "@/assets/images/cz_icon.png";
import Schedule from "./Schedule";
import NonDisclosure from "./NonDisclosure";
import { rootContext } from "@/context/rootContext";
import { isTW } from "@/common/utils/envData";

function Guide() {

  const { langState } = useContext(rootContext);
  const [page1End, setPage1End] = useState(false);
  const [page1Active, setPage1Active] = useState(true);
  const [page2Active, setPage2Active] = useState(false);
  const [task1Active, setTask1Active] = useState(false);
  const [task1Box, setTask1Box] = useState(false);
  const [comm_rule1_end, setComm_rule1_end] = useState(false);
  const [task2Box, setTask2Box] = useState(false);
  const [task2Active, setTask2Active] = useState(false);

  const ruleBox1 = React.createRef();

  useEffect(() => {
    setTimeout(() => {
      setPage1End(true);
    }, 3000);
    setTimeout(() => {
      setPage1Active(false);
      setPage2Active(true);
      setTask1Active(true);
      setTask1Box(true);
    }, 4500);
    if (isTW) {
      setComm_rule1_end(true);
    }
    const ru = ruleBox1.current;
    ru.addEventListener("scroll", rule1HandScroll);
    return () => ru.removeEventListener("scroll", rule1HandScroll);
  }, []);

  // useEffect(() => {
  //   // console.log(protocols);
  // }, [protocols]);

  // 协议一滑动到底
  const rule1HandScroll = () => {
    const dom = event.target;
    const sh = dom.scrollHeight;
    const ch = dom.clientHeight;
    const st = dom.scrollTop;
    if (sh - ch - st < 20) {
      setComm_rule1_end(true);
      dom.removeEventListener("scroll", rule1HandScroll);
    }
  };

  // 同意协议一 进入协议二
  const enterRule2 = () => {
    if (comm_rule1_end) {
      window.gtag("event", "m_community_rules");
      setTask2Active(true);
      setTask1Box(false);
      setTask2Box(true);
    }
  };

  return (
    <section>
      <div className={style.guide_main}>
        <div className={`${style.welcome} ${page1End ? style.hide : ""}`}
          style={{ display: page1Active ? "block" : "none" }}
        >
          <div className={style.cz_icon}>
            <img src={czIcon}></img>
          </div>
          <div className={style.welcome_text}>
            {
              langState.guideData.welcomeTitle.map(item => <><p>{item}</p></>)
            }
          </div>
          <div className={style.firework_left}></div>
          <div className={style.firework_right}></div>
        </div>
        <div className={style.task_box} style={{ display: page2Active ? "block" : "none" }}>
          <Schedule task1Active={task1Active} task2Active={task2Active} task3Active={false} />

          {/* Community Rules */}
          <div className={style.community_rules_box} style={{ "display": task1Box ? "block" : "none" }}>
            <div className={style.community_title}>{langState.guideData.commRuleTitle}</div>
            <div className={style.communit_detail} ref={ruleBox1}>
              { langState.guideData.commRuleDesc.map(item => <><p>{item}</p></>) }
            </div>
            <div className={`${style.agree_btn} ${comm_rule1_end ? style.active : ""}`} onClick={enterRule2}>
              { langState.guideData.agreeBtn }
            </div>
            <p className={style.agree_tip}>{ langState.guideData.commRuleTip }</p>
          </div>

          {/* Non-Disclosure Agreement */}
          <NonDisclosure task2Box={task2Box} />

          {/* 九宫格任务 */}
          {/* <Task task3Box={task3Box} task3List={task3List} /> */}
        </div>
      </div>
    </section >
  );
}

export default Guide;
