import { globalData } from "@/context/rootContext";

const errorCodeEn = {
  200001: "Correctly enter your UID and username",
  200002: "Upload up to 3 pictures",
  200003: "Please set 2-24 characters",
  200004: "Upload img only",
  200005: "Please enter First Name",
  200006: "Please enter Last Name",
  200007: "Please upload a screenshoot of you channel's dashboard",
  200008: "The end time must be greater than the start time",
  200009: "Currently selected event：N/A",
  200010: "Video Title Upload Error: Missing 'Farlight 84' Keyword",
  400236: "This is not the correct content url",
  200012: "Please complete Step1 first",
  200013: "Please upload 1 video related to Farlight 84",
  200014: "Please input correct in-game UID (numbers only).",
  200015: "The real name is not in the correct format",
  200016: "The verification code or password parameter is missing",
  200017: "Please enter your in-game UID for verification",
  200018: "Choose at least 1 game tag and correctly enter your in-game UID and username.",
  200019: "The verification code has been sent to your in-game mailbox. Please check and return to this page to enter it.",
  200020: "Updated Stats may take a few minutes to appear after refresh, please check back later",
  200021: "Please bind your social media account first",
  200022: "Account or password incorrect",
  200023: "Your application has been submitted,You will be notified of the message within 3 working days",
  200024: "The authorization page will be opened for authorization",
  200025: "Username not available (too short/long/already taken)",
  200026: "Same as current user name",
  200027: "Delete successed",
  200028: "Upload succeeded",
  200029: "You have already registered as a creator in another region. Do you continue to submit your application to become a creator?",
  200030: "If you have any questions please contact us through the creator disecord server of the related game.",
  200031: "Video Title Upload Error: Missing",
  200032: "Keyword",
  200033: "Setting succeeded",
  200034: "The new password establishes the success",
  200035: "Copy URL Successed",

  200101: "Your session has expired or an incorrect token was provided. Please log in again.",
  400100: "Please enter your region.",
  400101: "Account or password incorrect",
  400102: "Please enter a valid email address.",
  400103: "The chosen username is already taken; please select another.",
  400104: "Invalid password; please set one with at least 8 characters.",
  400105: "Incorrect verification code; please verify the code sent to your email.",
  400106: "Too many requests; please try again later.",
  400107: "Please read and agree to the Creator Zone Terms of Service & Privacy Policy first.",
  400108: "Please link to at least one social media account and upload the dashboard screenshot.",
  400109: "Authorization failed; please try again.",
  400110: "Please select at least one content type.",
  400111: "Please select at least one game and enter your in-game UID and username.",
  400112: "Incorrect in-game UID; please check and try again.",
  400113: "Please enter your birthdate.",
  400114: "Please enter your region.",
  400115: "Please enter your Discord ID.",
  400116: "Submitted",
  400117: "Video data cannot be retrieved; please enter your video data manually.",
  400118: "Password incorrect",
  400119: "New password must differ from your current one; please try again.",
  400120: "Modification request has been submitted; please await staff review.",
  400121: "Screenshot format or size doesn't meet requirements; please retry.",
  400122: "Network connection interrupted or timed out. Please log in again.",
  400123: "Data error. Please close and try again.",
  400124: "Too many requests; please try again in 3 minutes.",
  400125: "Verification code expired",
  400201: "The verification code or password is missing.",
  400202: "The email address has not been registered.",
  400203: "The email address is already taken.",
  400204: "The Discord ID is already taken.",
  400205: "Usernames can only be changed once every three months.",
  400206: "Please make sure you are over 18 years old.",
  400207: "You must first register as our creator.",
  400208: "Please don't make multiple requests.",
  400209: "Please apply for Creator Assets first.",
  400210: "Please enter your video link.",
  400211: "Upload failed, as the video link is already present in your account.",
  400212: "Upload failed",
  400213: "You have already become a registered Farlight 84 Creator!",
  400214: "To connect to a new game, real name verification is required.",
  400215: "Creator Asset does not exist",
  400216: "Creator Asset application failed",
  400217: "Please ensure the video you upload is public from your own linked social media platform.",
  400218: "Please ensure the video you upload is from your own platform. <br />(If your TikTok account name has changed, please re-connect your TikTok account in your profile page)",
  400219: "Please link to this social media platform before uploading.",
  400220: "In-game UID can only be modified once a month.",
  400221: "Submission limit reached. Thank you for participating the Creator Task.",
  400222: "Creator Task(s) does not exist.",
  400223: "You cannot delete this video as it has been submitted for Creator Task(s).",
  400224: "Upload failed. Title or subtitle must include 'farlight84' or 'farlight' keywords.",
  400234: "Your account has been blacklisted due to fraudulent activity. If you believe this is an error, please contact our support team (farlight84-service@farlightgames.com) for further assistance.",
  400235: "Contains sensitive characters, please re-enter",
  400237: "Please resend verification code",
};

const errorCodeTH = {
  200002: "อัปโหลดรูปไม่เกิน 3 รูป",
  200003: "โปรดตั้งอักขระ 2-24 ตัว",
  200004: "อัปโหลดรูปภาพเท่านั้น",
  200005: "โปรดใส่ชื่อ",
  200006: "โปรดใส่นามสกุล",
  200007: "โปรดอัปโหลดภาพถ่ายหน้าจอแดชบอร์ดจากช่องของท่าน",
  200008: "เวลาสิ้นสุดต้องมากกว่าเวลาเริ่ม",
  200009: "อีเวนต์ที่เลือกในขณะนี้: N/A",
  200010: "การอัปโหลดชื่อวิดีโอเกิดข้อผิดพลาด: ไม่พบคีย์เวิร์ด 'Farlight 84'",
  400236: "นี่ไม่ใช่ url เนื้อหาที่ถูกต้อง",
  200012: "โปรดทำขั้นตอนที่ 1 ก่อน",
  200013: "อนุญาตให้ผูกบัญชีโซเชียลมีเดียอย่างน้อยหนึ่งบัญชีและอัปโหลดภาพถ่ายหน้าจอแดชบอร์ดของท่าน",
  200015: "ชื่อจริงไม่ได้อยู่ในรูปแบบที่ถูกต้อง",
  200017: "โปรดใส่ UID ในเกมของท่านเพื่อยืนยันตน",
  200019: "รหัสยืนยันถูกส่งให้ท่านทางกล่องจดหมายในเกมแล้ว โปรดตรวจสอบและกลับมาที่หน้านี้เพื่อใส่รหัส",
  200020: "สถิติที่อัปเดตอาจใช้เวลาไม่กี่นาทีในการปรากฏหลังจากรีเฟรช โปรดกลับมาตรวจสอบภายหลัง",
  200021: "โปรดผูกบัญชีโซเชียลมีเดียของท่านก่อน",
  200023: "ท่านส่งใบสมัครแล้ว ท่านจะได้รับข้อความแจ้งเตือนภายใน 3 วันทำการ",
  200024: "หน้าการอนุญาตจะถูกเปิดสำหรับการอนุญาต",
  200025: "ชื่อผู้ใช้ไม่สามารถใช้ได้ (สั้นเกินไป/ยาวเกินไป/มีคนใช้แล้ว)",
  200027: "ลบสำเร็จ",
  200028: "อัปโหลดสำเร็จ",
  200014: "โปรดป้อน UID ที่ถูกต้องในเกม (เฉพาะตัวเลขเท่านั้น)",
  200016: "รหัสยืนยันหรือรหัสผ่านหายไป",
  200018: "โปรดเลือกเกมอย่างน้อยหนึ่งเกมและใส่ UID และชื่อผู้ใช้ในเกม",
  200022: "บัญชีหรือรหัสผ่านไม่ถูกต้อง",
  200031: "การอัปโหลดชื่อวิดีโอเกิดข้อผิดพลาด: ไม่พบคีย์เวิร์ด",
  200032: " ",

  200101: "เซสชันของท่านหมดเวลาหรือแจ้งโทเคนไม่ถูกต้อง โปรดล็อกอินอีกครั้ง",
  400100: "โปรดใส่ภูมิภาคของท่าน",
  400101: "บัญชีหรือรหัสผ่านไม่ถูกต้อง",
  400102: "Please enter a valid email address",
  400103: "ชื่อผู้ใช้ไม่สามารถใช้ได้ (สั้นเกินไป/ยาวเกินไป/มีคนใช้แล้ว)",
  400104: "รหัสผ่านไม่ถูกต้อง โปรดตั้งรหัสผ่านที่มีอักขระอย่างน้อย 8 ตัว",
  400105: "รหัสยืนยันไม่ถูกต้อง โปรดยืนยันรหัสที่ถูกส่งไปยังอีเมลของท่าน",
  400106: "คำขอมากเกินไป โปรดลองอีกครั้งภายหลัง",
  400107: "กรุณาอ่านและยอมรับข้อกำหนดและเงื่อนไขการให้บริการและนโยบายความเป็นส่วนตัวของโซนสร้างสรรค์ก่อน.",
  400108: "โปรดเชื่อมโยงบัญชีโซเชียลมีเดียอย่างน้อยหนึ่งบัญชีและอัปโหลดภาพถ่ายหน้าจอแดชบอร์ด",
  400109: "การอนุญาตล้มเหลว โปรดลองอีกครั้ง",
  400110: "โปรดเลือกประเภทคอนเทนต์อย่างน้อยหนึ่งอย่าง",
  400111: "โปรดเลือกเกมอย่างน้อยหนึ่งเกมและใส่ UID และชื่อผู้ใช้ในเกม",
  400112: "UID ในเกมไม่ถูกต้อง โปรดตรวจสอบและลองอีกครั้ง",
  400113: "โปรดใส่วันเกิดของท่าน",
  400114: "โปรดใส่ภูมิภาคของท่าน",
  400115: "โปรดใส่ Discord ID ของท่าน",
  400116: "Submitted",
  400117: "ไม่สามารถเรียกคืนข้อมูลวิดีโอได้ โปรดใส่ข้อมูลวิดีโอด้วยตัวเอง",
  400118: "รหัสผ่านไม่ถูกต้อง",
  400119: "รหัสผ่านใหม่ต้องแตกต่างจากรหัสผ่านปัจจุบัน โปรดลองอีกครั้ง",
  400120: "ส่งคำขอแก้ไขแล้ว โปรดรอพนักงานตรวจสอบ",
  400121: "รูปแบบหรือขนาดภาพถ่ายหน้าจอไม่ตรงตามความต้องการ โปรดลองอีกครั้ง",
  400122: "การเชื่อมต่อเครือข่ายถูกขัดจังหวะหรือหมดเวลา โปรดล็อกอินอีกครั้ง",
  400123: "ข้อมูลผิดพลาด โปรดปิดและลองอีกครั้ง",
  400124: "คำขอมากเกินไป โปรดลองอีกครั้งใน 3 นาที",
  400125: "รหัสยืนยันหมดอายุแล้ว",
  400201: "รหัสยืนยันหรือรหัสผ่านหายไป",
  400202: "ที่อยู่อีเมลไม่ได้ลงทะเบียน",
  400203: "ที่อยู่อีเมลถูกใช้ไปแล้ว",
  400204: "Discord ID มีคนใช้ไปแล้ว",
  400205: "ชื่อผู้ใช้สามารถเปลี่ยนได้แค่หนึ่งครั้งทุกสามเดือนเท่านั้น",
  400206: "โปรดให้แน่ใจว่าท่านมีอายุเกิน 18 ปี",
  400207: "ท่านต้องลงทะเบียนในฐานะครีเอเตอร์ก่อน",
  400208: "โปรดอย่าส่งคำขอหลายครั้ง",
  400209: "โปรดสมัครเพื่อรับแอสเซ็ตของครีเอเตอร์ก่อน",
  400210: "โปรดใส่ลิงก์วิดีโอของท่าน",
  400211: "อัปโหลดล้มเหลว เนื่องจากลิงก์วิดีโออยู่ในบัญชีของท่านอยู่แล้ว",
  400212: "อัปโหลดล้มเหลว",
  400213: "ท่านได้กลายเป็นครีเอเตอร์ของ Farlight 84 ที่ลงทะเบียนแล้ว!",
  400214: "จำเป็นต้องยืนยันชื่อจริงเพื่อเชื่อมต่อกับเกมใหม่",
  400215: "แอสเซ็ตของครีเอเตอร์ไม่มีอยู่",
  400216: "ใบสมัครแอสเซ็ตของครีเอเตอร์ล้มเหลว",
  400217: "โปรดแน่ใจว่าวิดีโอที่ท่านอัปโหลดจากแพลตฟอร์มโซเชียลมีเดียที่เชื่อมโยงของท่านเองเป็นแบบสาธารณะ",
  400218: "โปรดแน่ใจว่าวิดีโอที่ท่านอัปโหลดจากแพลตฟอร์ม YouTube/Twitch ของท่านเอง",
  400219: "โปรดเชื่อมโยงแพลตฟอร์มโซเชียลมีเดียนี้ก่อนอัปโหลด",
  400220: "สามารถแก้ไข UID ในเกมได้เดือนละครั้งเท่านั้น",
  400221: "ถึงขีดจำกัดการส่งแล้ว ขอบคุณที่เข้าร่วมในงานของครีเอเตอร์",
  400222: "งานของครีเอเตอร์ไม่มีอยู่",
  400223: "ท่านไม่สามารถลบวิดีโอนี้ได้เนื่องจากถูกส่งไปยังงานของครีเอเตอร์",
  400224: "อัปโหลดล้มเหลว ชื่อหรือชื่อรองต้องประกอบด้วยคีย์เวิร์ด \"farlight84\" หรือ \"farlight\"",
  400234: "บัญชีของคุณถูกเพิกถอนสิทธิ์เนื่องจากกิจกรรมที่เป็นการฉ้อโกง หากคุณเชื่อว่านี้เป็นข้อผิดพลาด โปรดติดต่อทีมสนับสนุนของเรา ([farlight84-service] สำหรับความช่วยเหลือเพิ่มเติม",
  400235: "มีตัวหนังสือที่ละเอียดอ่อน โปรดกรอกใหม่อีกครั้ง ",
  400237: "กรุณาส่งรหัสยืนยันอีกครั้ง",
};

const errorCodeID = {
  200002: "Unggah hingga 3 gambar",
  200003: "Buat dalam 2-24 karakter",
  200004: "Unggah gambar saja",
  200005: "Silakan masukkan Nama Depan",
  200006: "Silakan masukkan Nama Belakang",
  200007: "Unggah tangkapan layar dasbor saluran Anda",
  200008: "Waktu akhir harus lebih besar dari waktu mulai",
  200009: "Event yang dipilih saat ini：N/A",
  200010: "Kesalahan Pengunggahan Judul Video: Kata Kunci 'Farlight 84' hilang",
  400236: "Ini bukan url konten yang benar",
  200012: "Silakan selesaikan Langkah 1 terlebih dahulu",
  200013: "Otorisasi pengikatan setidaknya satu akun media sosial dan unggah tangkapan layar dasbor Anda.",
  200015: "Nama asli tidak dalam format yang benar",
  200017: "Silakan masukkan UID dalam game untuk verifikasi",
  200019: "Kode verifikasi telah dikirim ke kotak pesan dalam game. Silakan periksa dan kembali ke halaman ini untuk memasukkannya.",
  200020: "Statistik yang diperbarui mungkin memerlukan waktu beberapa menit untuk muncul setelah penyegaran, silakan periksa kembali nanti.",
  200021: "Harap tautkan akun media sosial Anda terlebih dahulu",
  200023: "Permohonan Anda telah dikirim, Anda akan menerima pemberitahuan dalam waktu 3 hari kerja.",
  200024: "Halaman otorisasi akan dibuka untuk otorisasi",
  200025: "Nama pengguna tidak tersedia (terlalu pendek/panjang/sudah dipakai)",
  200027: "Hapus berhasil",
  200028: "Unggahan berhasil",
  200014: "Mohon masukkan UID yang benar di dalam game (hanya angka).",
  200016: "Kode verifikasi atau kata sandi hilang.",
  200018: "Pilih setidaknya satu game dan masukkan UID dan nama pengguna dalam gamemu.",
  200022: "Akun atau kata sandi salah",
  200031: "Kesalahan Pengunggahan Judul Video: Kata Kunci",
  200032: "hilang",
  
  200101: "Sesimu telah kedaluwarsa atau token yang diberikan salah. Harap Login kembali.",
  400100: "Silakan masukkan wilayahmu.",
  400101: "Akun atau kata sandi salah",
  400102: "Masukkan alamat email yang valid.",
  400103: "Nama pengguna tidak tersedia (terlalu pendek/panjang/sudah dipakai)",
  400104: "Kata sandi tidak valid; harap tetapkan kata sandi dengan minimal 8 karakter.",
  400105: "Kode verifikasi salah; mohon verifikasi kode yang dikirimkan ke emailmu.",
  400106: "Terlalu banyak permintaan; silakan coba lagi nanti.",
  400107: "Mohon baca dan setujui syarat dan ketentuan Pengguna Zona Kreator 38 Kebijakan Privasi terlebih dahulu.",
  400108: "Tautkan ke setidaknya satu akun media sosial dan unggah tangkapan layar dasbor.",
  400109: "Otorisasi gagal. Cobalah lagi.",
  400110: "Pilih setidaknya satu jenis konten.",
  400111: "Pilih setidaknya satu game dan masukkan UID dan nama pengguna dalam gamemu.",
  400112: "UID dalam game salah; periksa dan coba lagi.",
  400113: "Masukkan tanggal lahirmu.",
  400114: "Silakan masukkan wilayahmu.",
  400115: "Silakan masukkan ID Discordmu.",
  400116: "Submitted",
  400117: "Data video tidak dapat diambil; silakan masukkan data videomu secara manual.",
  400118: "Kata sandi salah",
  400119: "Kata sandi baru harus berbeda dengan kata sandimu saat ini; coba lagi.",
  400120: "Permintaan modifikasi telah diajukan; mohon menunggu tinjauan staf.",
  400121: "Format atau ukuran tangkapan layar tidak memenuhi persyaratan; silakan coba lagi.",
  400122: "Koneksi jaringan terputus atau habis masa berlakunya. Harap Login kembali.",
  400123: "Kesalahan data. Silakan tutup dan coba lagi.",
  400124: "Terlalu banyak permintaan; coba lagi dalam 3 menit.",
  400125: "Kode verifikasi kedaluwarsa",
  400201: "Kode verifikasi atau kata sandi hilang.",
  400202: "Alamat email belum terdaftar.",
  400203: "Alamat email sudah diambil.",
  400204: "ID Discord sudah diambil.",
  400205: "Nama pengguna hanya dapat diubah setiap tiga bulan sekali.",
  400206: "Pastikan kamu sudah berusia di atas 18 tahun.",
  400207: "Kamu harus mendaftar terlebih dahulu sebagai kreator kami.",
  400208: "Mohon untuk tidak membuat banyak permintaan.",
  400209: "Silakan ajukan permohonan Aset Kreator terlebih dahulu.",
  400210: "Silakan masukkan tautan videomu.",
  400211: "Unggahan gagal, karena tautan video sudah ada di akunmu.",
  400212: "Unggahan gagal",
  400213: "Kamu telah menjadi Kreator Farlight 84 yang terdaftar!",
  400214: "Untuk terhubung ke game baru, diperlukan verifikasi nama asli.",
  400215: "Aset Kreator tidak ada",
  400216: "Permohonan Aset Kreator gagal",
  400217: "Pastikan video yang kamu unggah bersifat publik dari platform media sosial yang kamu tautkan.",
  400218: "Pastikan video yang kamu unggah berasal dari platform YouTube/Twitch milikmu sendiri.",
  400219: "Tautkan ke platform media sosial ini sebelum mengunggah.",
  400220: "UID dalam game hanya dapat dimodifikasi sebulan sekali.",
  400221: "Batas pengiriman telah tercapai. Terima kasih telah berpartisipasi dalam Tugas Kreator.",
  400222: "Tugas Kreator tidak ada.",
  400223: "Kamu tidak bisa menghapus video ini karena telah dikirimkan untuk Tugas Kreator.",
  400224: "Unggahan gagal. Judul atau sub-judul harus menyertakan kata kunci 'farlight84' atau 'farlight'.",
  400234: "Akun Anda telah dimasukkan dalam daftar hitam karena aktivitas penipuan. Jika Anda percaya ini adalah kesalahan, silakan hubungi tim dukungan kami ([farlight84-service] untuk bantuan lebih lanjut.",
  400235: "Ada karakter sensitif, harap masukkan kembali",
  400237: "Harap kirim ulang kode verifikasi",
};

const errorCodeVI = {
  200002: "Tải lên tối đa 3 ảnh",
  200003: "Vui lòng sử dụng 2-24 ký tự",
  200004: "Chỉ tải lên ảnh",
  200005: "Vui lòng nhập Tên",
  200006: "Vui lòng nhập Họ",
  200007: "Vui lòng tải lên ảnh chụp màn hình trang tổng quan kênh của bạn",
  200008: "Thời gian kết thúc phải sau thời gian bắt đầu",
  200009: "Sự kiện hiện đang được chọn：Không áp dụng",
  200010: "Lỗi Tải Lên Tiêu Đề Video: Thiếu Từ Khóa 'Farlight 84'",
  400236: "Liên kết url của nội dung này chưa đúng",
  200012: "Vui lòng hoàn thành Bước 1 trước",
  200013: "Cho phép liên kết ít nhất một tài khoản mạng xã hội và tải lên ảnh chụp màn hình trang tổng quan của bạn.",
  200015: "Tên thật không đúng định dạng",
  200017: "Vui lòng nhập UID trong trò chơi của bạn để tiến hành xác minh",
  200019: "Mã xác minh đã được gửi vào hộp thư trong trò chơi của bạn. Vui lòng kiểm tra thư và quay lại trang này để nhập mã.",
  200020: "Sau khi làm mới, có thể cần chờ vài phút để hệ thống hiển thị Chỉ Số Cập Nhật, vui lòng xem lại sau nhé.",
  200021: "Hãy liên kết tài khoản mạng xã hội của bạn trước",
  200023: "Yêu cầu gia nhập của bạn đã được gửi, Thông báo sẽ được gửi đến bạn trong vòng 3 ngày làm việc.",
  200024: "Trang cấp quyền sẽ được mở để tiến hành cấp quyền",
  200025: "Tên người dùng không khả dụng (quá ngắn/quá dài/đã được sử dụng)",
  200027: "Xóa thành công",
  200028: "Tải lên thành công",
  200014: "Vui lòng nhập đúng UID trong trò chơi (chỉ số).",
  200016: "Thiếu mã xác minh hoặc mật khẩu.",
  200018: "Vui lòng chọn ít nhất một trò chơi và nhập UID cũng như tên người dùng trong trò chơi của bạn.",
  200022: "Tài khoản hoặc mật khẩu không đúng",
  200031: "Lỗi Tải Lên Tiêu Đề Video: Thiếu Từ Khóa",
  200032: " ",

  200101: "Phiên của bạn đã hết hạn hoặc token đã nhập không chính xác. Xin vui lòng đăng nhập lại.",
  400100: "Vui lòng nhập khu vực của bạn.",
  400101: "Tài khoản hoặc mật khẩu không đúng",
  400102: "Please enter a valid email address",
  400103: "Tên người dùng không khả dụng (quá ngắn/quá dài/đã được sử dụng)",
  400104: "Mật khẩu không hợp lệ; vui lòng đặt mật khẩu có ít nhất 8 ký tự.",
  400105: "Mã xác minh không chính xác; vui lòng xác minh mã được gửi đến email của bạn.",
  400106: "Yêu cầu quá nhiều lần; vui lòng thử lại sau.",
  400107: "Vui lòng đọc và đồng ý các Điều khoản Dịch vụ và Chính sách Bảo mật của Creator Zone trước.",
  400108: "Vui lòng liên kết với ít nhất một tài khoản mạng xã hội và tải ảnh chụp màn hình trang tổng quan lên.",
  400109: "Cấp quyền không thành công; vui lòng thử lại.",
  400110: "Vui lòng chọn ít nhất một loại nội dung.",
  400111: "Vui lòng chọn ít nhất một trò chơi và nhập UID cũng như tên người dùng trong trò chơi của bạn.",
  400112: "UID trong trò chơi không chính xác; xin hãy kiểm tra và thử lại.",
  400113: "Vui lòng nhập ngày tháng năm sinh của bạn.",
  400114: "Vui lòng nhập khu vực của bạn.",
  400115: "Vui lòng nhập ID Discord của bạn.",
  400116: "Submitted",
  400117: "Không thể truy xuất dữ liệu video; vui lòng nhập dữ liệu video của bạn theo cách thủ công.",
  400118: "Mật khẩu không đúng",
  400119: "Mật khẩu mới phải khác với mật khẩu hiện tại của bạn; vui lòng thử lại.",
  400120: "Yêu cầu sửa đổi đã được gửi; vui lòng chờ nhân viên xem xét.",
  400121: "Định dạng hoặc kích thước ảnh chụp màn hình không đạt yêu cầu; vui lòng thử lại.",
  400122: "Kết nối mạng bị gián đoạn hoặc hết thời gian chờ. Xin vui lòng đăng nhập lại.",
  400123: "Lỗi dữ liệu. Vui lòng đóng và thử lại.",
  400124: "Yêu cầu quá nhiều lần; vui lòng thử lại sau 3 phút nữa.",
  400125: "Mã xác minh đã hết hạn",
  400201: "Thiếu mã xác minh hoặc mật khẩu.",
  400202: "Địa chỉ email chưa được đăng ký.",
  400203: "Địa chỉ email đã được sử dụng.",
  400204: "ID Discord đã được sử dụng.",
  400205: "Bạn chỉ có thể đổi tên người dùng ba tháng một lần.",
  400206: "Hãy đảm bảo bạn trên 18 tuổi.",
  400207: "Trước tiên bạn phải đăng ký làm nhà sáng tạo của chúng tôi.",
  400208: "Vui lòng không đưa ra nhiều yêu cầu.",
  400209: "Trước tiên hãy đăng ký Nội Dung của Nhà Sáng Tạo.",
  400210: "Vui lòng nhập liên kết video của bạn.",
  400211: "Tải lên không thành công vì liên kết video đã có trong tài khoản của bạn.",
  400212: "Tải lên không thành công",
  400213: "Bạn đã trở thành Nhà Sáng Tạo thuộc sở hữu của Farlight 84",
  400214: "Để kết nối với trò chơi mới, bạn cần xác minh tên thật.",
  400215: "Mã xác minh đã hết hạn",
  400216: "Vui lòng nhập đúng UID trong trò chơi (chỉ số).",
  400218: "Please ensure the video you upload is from your own platform. <br />(If your TikTok account name has changed, please re-connect your TikTok account in your profile page)",
  400234: "Tài khoản của bạn đã bị đưa vào danh sách đen do hoạt động gian lận. Nếu bạn tin rằng đây là một lỗi, vui lòng liên hệ với đội hỗ trợ của chúng tôi ([farlight84-service] để được trợ giúp thêm.",
  400235: "Chứa kí tự nhạy cảm, vui lòng nhập lại",
  400237: "Nhập lại mã xác thực",
};

const errorCodeJP = {
  200001: "UIDとユーザー名を正しく入力してください。",
  200002: "画像をアップロード（3点まで）",
  200003: "2～24文字で設定してください",
  200004: "img形式のみアップロード可",
  200005: "お名前（名）を入力してください",
  200006: "お名前（姓）を入力してください",
  200007: "ご自身のチャンネルのダッシュボードのスクリーンショットをアップロードしてください",
  200008: "終了時間は開始時間より大きい値でなければなりません",
  200009: "現在選択されているイベント：なし",
  200010: "動画タイトルのアップロードエラー：キーワード「Farlight 84」がありません",
  400236: "コンテンツURLが正しくありません",
  200012: "先にステップ1を完了してください",
  200013: "Farlight 84に関連する動画をこのチャンネルに1つアップロードしてください。",
  200014: "ご自身のUID（数字のみ）を正しく入力してください。",
  200015: "本名の形式が正しくありません",
  200016: "認証コードまたはパスワードがありません。",
  200017: "確認のためにゲーム内UIDを入力してください",
  200019: "認証コードはゲーム内のメールボックスに送信されました。コードを確認し、このページに戻って入力してください。",
  200020: "更新されたスタッツは、更新後に反映されるまでに数分かかる場合があります。後ほど再度ご確認ください。",
  200021: "まずソーシャルメディアアカウントを紐付けしてください",
  200022: "アカウントまたはパスワードが正しくありません",
  200023: "応募が送信されました。3営業日以内にメッセージでお知らせします。",
  200024: "認証のために、認証ページが開かれます",
  200025: "使用できないユーザー名です（短すぎ、長すぎ、またはすでに使用されています）",
  200026: "現在のユーザー名と同じ",
  200027: "削除成功",
  200028: "アップロード成功",
  200029: "既に他の地域でクリエイターとして登録されています。引き続きクリエイターの申請を提出しますか？",
  200030: "ご不明な点がございましたら、関連ゲームのクリエイターDiscordサーバーを通じてお問い合わせください。",
  200031: "動画タイトルのアップロードエラー：キーワード",
  200032: "がありません",

  200101: "セッションの有効期限が切れたか、正しくないトークンが入力されました。再度ログインしてください。",
  400100: "地域を入力してください｡",
  400101: "アカウントまたはパスワードが正しくありません",
  400102: "有効なメールアドレスを入力してください",
  400103: "このユーザー名はすでに使われています。別のユーザー名を選択してください。",
  400104: "無効なパスワードです。8文字以上のパスワードを設定してください。",
  400105: "認証コードが正しくありません。メールに送られたコードを確認してください。",
  400106: "リクエストが多すぎます。後ほど再度お試しください。",
  400107: "まず、クリエイターゾーンの利用規約とプライバシーポリシーをご確認の上、同意してください。",
  400108: "少なくとも1つのソーシャルメディアアカウントにリンクし、ダッシュボードのスクリーンショットをアップロードしてください。",
  400109: "認証に失敗しました。再度お試しください。",
  400110: "コンテンツタイプを1つ以上選択してください。",
  400111: "ゲームを1つ以上選択し、ゲーム内UIDとユーザー名を入力してください。",
  400112: "ゲーム内UIDが正しくありません。ご確認の上、再度お試しください。",
  400113: "誕生日を入力してください｡",
  400114: "地域を入力してください｡",
  400115: "Discord IDを入力してください。",
  400116: "送信済み",
  400117: "動画データが取得できません。動画データを手動で入力してください。",
  400118: "パスワードが正しくありません",
  400119: "新しいパスワードは現在のものと異なる必要があります。再度お試しください。",
  400120: "変更リクエストが送信されました。スタッフのレビューをお待ちください。",
  400121: "スクリーンショットの形式またはサイズが要件を満たしていません。再度お試しください。",
  400122: "ネットワークエラーです。更新して再度お試しください。",
  400123: "データエラーです。一旦終了して再度お試しください。",
  400124: "リクエストが多すぎます。3分後に再度お試しください。",
  400125: "認証コードの有効期限が切れています",
  400201: "認証コードまたはパスワードがありません。",
  400202: "メールアドレスが登録されていません。",
  400203: "すでに使われているメールアドレスです。",
  400204: "すでに使われているDiscord IDです。",
  400205: "ユーザー名は3カ月に1度しか変更できません。",
  400206: "18歳以上であることを確認してください。",
  400207: "まずクリエイター登録をしてください。",
  400208: "複数のリクエストはご遠慮ください。",
  400209: "まずクリエイターアセットに申請してください。",
  400210: "動画のリンクを入力してください。",
  400211: "動画リンクが既にアカウントに存在するため、アップロードに失敗しました。",
  400212: "アップロードに失敗しました",
  400213: "すでにFarlight 84クリエイターに登録されています！",
  400214: "新しいゲームに接続するには、実名認証が必要です。",
  400215: "クリエイターアセットが存在しません",
  400216: "クリエイターアセットの申請に失敗しました",
  400217: "アップロードする動画が、ご自身の紐付け済みソーシャルメディアプラットフォームから公開されたものであることをご確認ください。",
  400218: "アップロードする動画が、ご自身のYouTube/Twitchプラットフォームからのものであることをご確認ください。",
  400219: "アップロードする前に、ソーシャルメディアに紐付けてください。",
  400220: "ゲーム内UIDの変更は1カ月に1度のみ可能です。",
  400221: "投稿制限に達しました。クリエイタータスクにご参加いただきありがとうございました。",
  400222: "クリエイタータスクが存在しません。",
  400223: "この動画はクリエイタータスクに投稿されているため、削除できません。",
  400224: "アップロードに失敗しました。タイトルまたはサブタイトルに「farlight84」または「farlight」のキーワードが含まれている必要があります。",
  400234: "あなたのアカウントは不正行為によりブラックリストに登録されました。これが何かの間違いであると思われる場合は、弊社のサポートチーム（farlight84-service@farlightgames.com）までご連絡ください。",
  400235: "認識できない文字が検出されましたため、再入力をお願い致します。",
  400237: "認証コードの再送信をお試しください",
};

const errorCodeSK = {
  200001: "UID와 사용자 이름을 올바르게 입력하세요.",
  200002: "이미지 최대 3장 업로드",
  200003: "2~24자로 설정하세요",
  200004: "이미지만 업로드합니다",
  200005: "이름을 입력하세요",
  200006: "성을 입력하세요",
  200007: "채널 대시보드를 캡처하여 업로드하세요",
  200008: "종료 시간은 시작 시간보다 늦어야 합니다",
  200009: "현재 선택된 이벤트: N/A",
  200010: "동영상 제목 업로드 오류: 'AFK: 새로운 여정' 키워드 누락",
  200033: "정보 변경 완료하였습니다.",
  200012: "먼저 스텝 1을 완료해 주세요",
  200013: "Please upload 1 video related to Farlight 84",
  200014: "게임 UID(숫자)를 올바르게 입력하세요.",
  200015: "형식에 맞지 않는 실명",
  200016: "인증번호를 게임 우편함으로 발송했습니다. ",
  200017: "확인 후 본 페이지로 돌아와 입력하세요.",
  200018: "하나 이상의 게임을 선택하여 게임 UID와 사용자 이름을 입력하세요.",
  200019: "인증번호를 게임 우편함으로 발송했습니다. 확인 후 본 페이지로 돌아와 입력하세요.",
  200020: "업데이트된 통계 데이터는 몇 분이 지나야 새로고침 후 표시됩니다. 잠시 후 다시 확인하세요.",
  200021: "먼저 본인의 SNS 계정을 연동하세요",
  200022: "계정 또는 비밀번호가 올바르지 않습니다",
  200023: "신청 제출 완료, 영업일 기준으로 3일 이내에 메시지를 받아보실 수 있습니다.",
  200024: "권한 허용을 위해 권한 허용 페이지가 열립니다",
  200025: "사용자 이름은 사용할 수 없습니다(길이 부족/길이 초과/이미 사용됨)",
  200026: "현재 사용자 이름과 동일합니다",
  200027: "Delete successed",
  200028: "Upload succeeded",
  200030: "If you have any questions please contact us through the creator disecord server of the related game.",
  200031: "동영상 제목 업로드 오류:",
  200032: "키워드 누락",
  200034: "비밀번호 변경 완료하였습니다.",
  200101: "로그인 세션 만료, 다시 로그인하세요.",

  400100: "지역을 입력하세요.",
  400101: "계정 또는 비밀번호가 올바르지 않습니다",
  400102: "유효한 이메일 주소를 입력하세요.",
  400103: "이미 사용 중인 이름입니다. 다른 이름을 선택하세요.",
  400104: "유효하지 않은 비밀번호. 8자 이상의 비밀번호를 설정하세요.",
  400105: "인증번호 오류. 이메일로 발송된 인증번호를 확인하세요.",
  400106: "빈번한 조작. 잠시 후 다시 시도하세요.",
  400107: "인증번호 오류. 이메일로 발송된 인증번호를 확인하세요.",
  400108: "하나 이상의 SNS 계정을 연결하여 채널 대시보드를 캡처해 업로드하세요.",
  400109: "권한 부여 실패. 다시 시도하세요.",
  400110: "하나 이상의 콘텐츠 유형을 선택하세요.",
  400111: "하나 이상의 게임을 선택하여 게임 UID와 사용자 이름을 입력하세요.",
  400112: "올바르지 않은 게임 UID. 확인 후 다시 시도하세요.",
  400113: "생일을 입력하세요.",
  400114: "지역을 입력하세요.",
  400115: "디스코드 ID를 입력하세요.",
  400116: "Submitted",
  400117: "동영상 데이터를 연결할 수 없습니다. 동영상 데이터를 수동으로 입력하세요.",
  400118: "비밀번호 오류",
  400119: "새 비밀번호는 현재 비밀번호와 달라야 합니다. 다시 시도하세요.",
  400120: "수정 요청 완료. 심사가 완료될 때까지 기다리세요.",
  400121: "스크린샷 형식 또는 사이즈가 맞지 않습니다. 다시 시도하세요.",
  400122: "네트워크 연결이 끊겼거나 시간이 초과되었습니다. 다시 로그인하세요.",
  400123: "데이터 오류. 종료 후 다시 시도하세요.",
  400124: "빈번한 조작. 잠시 후 다시 시도하세요.",
  400125: "인증번호 시간이 초과되었습니다.",
  400201: "인증번호 또는 비밀번호 유실",
  400202: "이메일 주소가 등록되지 않았습니다.",
  400203: "이미 사용된 이메일 주소입니다.",
  400204: "이미 사용된 디스코드 ID입니다.",
  400205: "사용자 이름은 3개월마다 한 번씩 변경할 수 있습니다.",
  400206: "만 18세 이상이어야 합니다.",
  400207: "회원가입을 먼저 해야 합니다.",
  400208: "빈번한 요청.",
  400209: "먼저 크리에이터 자산을 신청하세요.",
  400210: "동영상 링크를 입력하세요.",
  400211: "업로드 실패, 해당 동영상 링크가 이미 계정에 있습니다.",
  400212: "업로드 실패",
  400213: "You have already become a registered Farlight 84 Creator!",
  400214: "게임 연결을 위해 실명 인증을 진행하세요.",
  400215: "크리에이터 자산이 존재하지 않습니다.",
  400216: "크리에이터 자산 신청 실패.",
  400217: "업로드한 동영상이 본인의 SNS에 공개된 영상인지 확인하세요.",
  400218: "Please ensure the video you upload is from your own platform. <br />(If your TikTok account name has changed, please re-connect your TikTok account in your profile page)",
  400219: "업로드 전에 해당 SNS에 연결하세요.",
  400220: "게임 UID는 매월 한 번만 변경할 수 있습니다.",
  400221: "최대 제출 횟수 도달. 크리에이터 작업에 참여해 주셔서 감사합니다.",
  400222: "크리에이터 작업이 존재하지 않습니다.",
  400223: "크리에이터 작업으로 제출하였기에 해당 동영상은 삭제할 수 있습니다.",
  400224: "업로드 실패. 제목 또는 부제목에 “AFK: 새로운 여정” 또는 “AFK” 키워드가 반드시 포함되어야 합니다.",
  400234: "부적절한 조작으로 인해 귀하의 계정이 일시적으로 중단되었습니다. 이에 대해 이의가 있을 경우 고객센터(afkjourney-cs@farlightgames.com)로 연락 주시면 필요하신 도움을 드리겠습니다.",
  400235: "민감한 내용이 포함되어 있으니 다시 입력하세요.",
  400236: "올바르지 않은 콘텐츠 주소입니다",
  400237: "인증 코드 다시 받기",
};

const errorCodeTW = {
  200001: "請再次確認你的 UID 和使用者名稱是否正確。",
  200002: "最多可上傳3張圖片",
  200003: "請輸入2-24個字元",
  200004: "僅能上傳圖片",
  200005: "請輸入名字",
  200006: "請輸入姓氏",
  200007: "請上傳你的頻道主頁截圖",
  200008: "結束時間必須晚於開始時間",
  200009: "目前選擇活動：不適用",
  200010: "影片標題上傳錯誤：缺少 「____ 」 關鍵字",
  400236: "網址錯誤",
  200012: "請先完成第一步",
  200014: "請輸入正確的遊戲 UID（僅限數字）。",
  200015: "真實姓名不符合格式",
  200016: "請輸入驗證碼或密碼。",
  200017: "請輸入你的遊戲 UID 進行驗證",
  200018: "請選擇至少一款遊戲，並輸入你的遊戲 UID 和使用者名稱。",
  200019: "驗證碼已寄送至遊戲內信箱。請前往查看並輸入至此頁面。",
  200020: "可能需要在幾分鐘後才會顯示出最新數據，請稍後再確認。",
  200021: "請先綁定你的社群帳號",
  200022: "帳號或密碼錯誤",
  200023: "你的申請已送出，你將在3個工作日內收到回覆。",
  200024: "即將打開授權頁面以進行授權",
  200025: "無法使用此使用者名稱，可能因為：名稱過短/過長/已被使用。",
  200026: "與目前使用者名稱相同",
  200027: "刪除成功",
  200028: "上傳成功",
  200029: "你已經在其他地區註冊成為創作者，請問你要再次提出申請嗎?",
  200031: "影片標題上傳錯誤：缺少  ",
  200032: "關鍵字",
  200033: "設定成功",
  200034: "新密碼修改成功",
  200035: "複製連結成功",

  200101: "授權過久，請重新登入。",
  400100: "請輸入所在地區。",
  400101: "帳號或密碼錯誤",
  400102: "無法使用此名稱，請再試一次。",
  400103: "使用者名稱已被使用，請輸入其他使用者名稱。",
  400104: "請再次確認密碼設定條件，密碼需為8個字元。",
  400105: "驗證碼錯誤；請確認你電子郵件中的驗證碼。",
  400106: "短時間內操作次數過多，請稍後再試，",
  400107: "請詳閱並同意創作者平台的隱私政策及服務條款。",
  400108: "請連結至少一個第三方帳號並上傳主頁截圖。",
  400109: "連結失敗；請再試一次。",
  400110: "請選擇至少一項。",
  400111: "請選擇至少一款遊戲，並輸入你的遊戲 UID 和使用者名稱。",
  400112: "遊戲UID 錯誤；請再次確認。",
  400113: "請輸入生日。",
  400114: "請輸入所在地區。",
  400115: "請輸入你的Discord ID。",
  400116: "送出",
  400117: "未找到影片數據；請輸入你的影片數據。",
  400118: "密碼錯誤",
  400119: "新密碼不可與舊密碼一致；請再試一次。",
  400120: "修改申請已送出；請等待工作人員審核。",
  400121: "截圖格式或大小不符合條件；請重試。",
  400122: "網路錯誤。請重新整理並再試一次。",
  400123: "數據錯誤。請先關閉後再試一次。",
  400124: "操作過快，請稍後再試，",
  400125: "驗證碼已過期",
  400201: "請輸入驗證碼或密碼。",
  400202: "此電子信箱尚未進行註冊。",
  400203: "電子信箱先前已被使用。",
  400204: "此Discord ID 已被使用。",
  400205: "使用者名稱每三個月只能更改一次。",
  400206: "請確認你年滿18歲。",
  400207: "需先註冊並成為我們的創作者。",
  400208: "無法再次送出申請。",
  400209: "請先申請創作者素材。",
  400210: "請輸入你的影片連結。",
  400211: "上傳失敗，因為該影片連結已存在於你的帳戶中。",
  400212: "上傳失敗",
  400213: "你已經成功註冊成為創作者！",
  400214: "請進行實名認證以連結至新遊戲。",
  400215: "未找到創作者素材。",
  400216: "創作者素材申請失敗。",
  400217: "請再次確認上傳影片來自已連結的影音平台。",
  400218: "請確保你上傳的影片來自你自己的平台。（如果你的 TikTok 帳號名稱已更改，請在個人資料頁面重新連接你的 TikTok 帳號。)",
  400219: "請在上傳前連結完成。",
  400220: "遊戲 UID 每月只能修改一次。",
  400221: "參加次數已達上限。感謝您參與創作者任務。",
  400222: "未找到此創作者任務。",
  400223: "由於此影片已申請創作者任務，故無法刪除。",
  400224: "Upload failed. Title or subtitle must include 'farlight84' or 'farlight' keywords.",
  400234: "由於涉及詐騙，你的帳戶已被列入黑名單。若有任何疑問，請聯繫客服（farlight84@origingames.com.tw ）以獲得進一步的協助。",
  400235: "含有敏感字元，請重新輸入",
  400237: "請重新發送驗證碼",
};




const errorCode = (code) => {
  switch (globalData.lang) {
    case "en": return errorCodeEn[code];
    case "id": return errorCodeID[code] || errorCodeEn[code];
    case "th": return errorCodeTH[code] || errorCodeEn[code];
    case "vi": return errorCodeVI[code] || errorCodeEn[code];
    case "jp": return errorCodeJP[code] || errorCodeEn[code];
    case "sk": return errorCodeSK[code] || errorCodeEn[code];
    case "zh-tw": return errorCodeTW[code] || errorCodeEn[code];
    default: return errorCodeEn[code];
  }
};

export default errorCode;