import React, { useContext, useEffect, useRef, useState } from "react";
import style from "./index.module.scss";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { Menu } from "antd";
import { rootContext, globalData } from "@/context/rootContext";
import { isFl84 } from "@/common/utils/envData";
import Api from "@/common/Api/message";


const { SubMenu } = Menu;
const isFirefox = navigator.userAgent.indexOf("Firefox") > 0;

function AdminNav(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [activePath, setActivePath] = useState(["home"]);
  const [isFix, setIsFix] = useState(false);
  const { scale, globalLang, readTapIndex, setReadTapIndex, langState } = useContext(rootContext);

  const navRef = useRef();
  
  const navList = [
    { name: langState.adminNavData.home, path: "home", subNav: [] },
    { name: isFl84 ? langState.adminNavData.announcement : langState.adminNavData.gameFeed, path: "gamefeed", subNav: [], tapIndex: 1 },
    { name: langState.adminNavData.artAssets, path: "artassets", subNav: [], tapIndex: 2 },
    {
      name: langState.adminNavData.myContent, path: "unpath", subNav: [
        { name: langState.adminNavData.uploadContent, path: "mycontent/upload", tapIndex: 3 },
        { name: langState.adminNavData.manageContent, path: "mycontent/manage" },
      ],
      isActive: false
    },
    { name: langState.adminNavData.viewEarnings, path: "viewearnings", subNav: [] }
  ];

  const scrollEven = () => {
    if (props.isFin || !props.taskHeight) {
      return;
    }
    const st = document.documentElement.scrollTop;
    if ((st + 80) > props.taskHeight * scale) {
      setIsFix(true);
    } else {
      setIsFix(false);
    }
  };

  const handleClick = e => {
    navigate(e.key);
    checkRead(e.key);
  };

  const checkRead = (path) => {
    if (path.indexOf("gamefeed") > -1 && readTapIndex[0] < 0) {
      readTapEven("info", 0);
    } else if (path.indexOf("artassets") > -1 && readTapIndex[1] < 0) {
      readTapEven("fodder", 1);
    } else if (path.indexOf("upload") > -1 && readTapIndex[2] < 0) {
      readTapEven("activity", 2);
    }
  };

  const readTapEven = async (tap, index) => {
    const res = await Api.checkedReminder(tap, globalData.activeGameData.tag);
    if (res.status === 200) {
      let newList = [...readTapIndex];
      newList[index] = 1;
      setReadTapIndex(newList);
    }
  };

  useEffect(() => {
    const path = location.pathname;
    const activePath = path.slice(path.indexOf("admin/") + 6);
    if (activePath.includes("detail")) {
      setActivePath([activePath.split("/")[0]]);
    } else {
      setActivePath([activePath]);
    }
    checkRead(path);
  }, [location]);

  useEffect(() => {
    const scrollFun = scrollEven;
    window.addEventListener("scroll", scrollFun);

    return () => { window.removeEventListener("scroll", scrollFun); };
  }, [props]);

  useEffect(() => {
    scrollEven();
  }, [scale]);

  return (
    <nav>
      <div className={`admin_nav ${style.admin_nav} ${isFix ? style.fix : ""} ${isFirefox ? style.fire : ""} ${props.isFin ? style.fix2 : ""}`}
        ref={navRef}
      >
        <Menu
          onClick={handleClick}
          defaultSelectedKeys={[navList[0].path]}
          selectedKeys={activePath}
          defaultOpenKeys={["3"]}
          mode="inline"
        >
          {
            navList.map((item, index) => {
              return (
                item.subNav.length ?
                  (
                    <SubMenu key={item.path.includes("unpath") ? index : item.path} title={item.name}>
                      {
                        item.subNav.map((subItem) => {
                          return (
                            <Menu.Item key={subItem.path}>
                              {subItem.name}
                              { subItem.tapIndex && readTapIndex[subItem.tapIndex - 1] < 0 && <i></i> }
                            </Menu.Item>
                          );
                        })
                      }
                    </SubMenu>
                  ) :
                  (
                    <Menu.Item key={item.path.includes("unpath") ? index : item.path}>
                      {item.name}
                      { item.tapIndex && readTapIndex[item.tapIndex - 1] < 0 && <i></i> }
                    </Menu.Item>
                  )
              );
            })
          }
        </Menu>

        <div className={`subLink ${style.subLink} ${globalLang}`}>
          <NavLink to="mycontent/upload">{ langState.adminNavData.uploadContent }</NavLink>
        </div>
      </div>
    </nav>
  );
}

const AdminNavMemo = React.memo(AdminNav);

export default AdminNavMemo;
