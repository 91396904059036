import React from "react";
import style from "./dislyteTable.module.scss";
import { isPC } from "@/common/utils/envData";

function DislyteTable() {
  return (
    <div className={`${style.table_box} ${isPC ? "" : style.m}`}>
      <table border={1}>
        <thead>
          <tr>
            <th>level</th>
            <th colSpan={2}>view start(range)</th>
            <th>view end(range)</th>
            <th>Crystal</th>
            <th>Cash</th>
            <th>Notes</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1(bonus)</td>
            <td colSpan={2}>1</td>
            <td>1,000</td>
            <td>500</td>
            <td>0</td>
            <td>/</td>
          </tr>
          <tr>
            <td>2</td>
            <td>1,000</td>
            <td>or 5 qualified works</td>
            <td>3,000</td>
            <td>1,500</td>
            <td>0</td>
            <td>/</td>
          </tr>
          <tr>
            <td>3</td>
            <td>3,000</td>
            <td>or 10 qualified works</td>
            <td>6,000</td>
            <td>3,000</td>
            <td>0</td>
            <td>/</td>
          </tr>
          <tr>
            <td>4</td>
            <td>6,000</td>
            <td>or 15 qualified works</td>
            <td>10,000</td>
            <td>4,500</td>
            <td>0</td>
            <td>/</td>
          </tr>
          <tr>
            <td>5</td>
            <td colSpan={2}>10,000</td>
            <td>30,000</td>
            <td>6,000</td>
            <td>0</td>
            <td>/</td>
          </tr>
          <tr>
            <td>6</td>
            <td colSpan={2}>30,000</td>
            <td>60,000</td>
            <td>6,000</td>
            <td>100</td>
            <td>/</td>
          </tr>
          <tr>
            <td>7</td>
            <td colSpan={2}>60,000</td>
            <td>100,000</td>
            <td>6,000</td>
            <td>200</td>
            <td>/</td>
          </tr>
          <tr>
            <td>8</td>
            <td colSpan={2}>100,000</td>
            <td>300,000</td>
            <td>6,000</td>
            <td>500</td>
            <td>/</td>
          </tr>
          <tr>
            <td>9</td>
            <td colSpan={2}>300,000</td>
            <td>500,000</td>
            <td>6,000</td>
            <td>800</td>
            <td>A chance for long-term<br /> contract with Lilith</td>
          </tr>
          <tr>
            <td>10</td>
            <td colSpan={2}>500,000</td>
            <td>∞</td>
            <td>6,000</td>
            <td>1200</td>
            <td>A chance for long-term<br /> contract with Lilith</td>
          </tr>
        </tbody>
      </table>
      <p className={style.table_title}>Short Video Task View-based reward 1.1</p>
      <table border={1}>
        <thead>
          <tr>
            <th>level</th>
            <th>view start(range)</th>
            <th>view end(range)</th>
            <th>Crystal</th>
            <th>Cash</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1(bonus)</td>
            <td>0</td>
            <td>100</td>
            <td>500</td>
            <td>0</td>
          </tr>
          <tr>
            <td>2</td>
            <td>100</td>
            <td>500</td>
            <td>800</td>
            <td>0</td>
          </tr>
          <tr>
            <td>3</td>
            <td>500</td>
            <td>1,000</td>
            <td>1,200</td>
            <td>0</td>
          </tr>
          <tr>
            <td>4</td>
            <td>1,000</td>
            <td>3,000</td>
            <td>2,000</td>
            <td>0</td>
          </tr>
          <tr>
            <td>5</td>
            <td>3,000</td>
            <td>5,000</td>
            <td>3,000</td>
            <td>0</td>
          </tr>
          <tr>
            <td>6</td>
            <td>5,000</td>
            <td>10,000</td>
            <td>5,000</td>
            <td>0</td>
          </tr>
          <tr>
            <td>7</td>
            <td>10,000</td>
            <td>30,000</td>
            <td>7,000</td>
            <td>50</td>
          </tr>
          <tr>
            <td>8</td>
            <td>30,000</td>
            <td>50,000</td>
            <td>7,000</td>
            <td>100</td>
          </tr>
          <tr>
            <td>9</td>
            <td>50,000</td>
            <td>100,000</td>
            <td>7,000</td>
            <td>300</td>
          </tr>
          <tr>
            <td>10</td>
            <td>100,000</td>
            <td>300,000</td>
            <td>7,000</td>
            <td>500</td>
          </tr>
          <tr>
            <td>11</td>
            <td>300,000</td>
            <td>500,000</td>
            <td>7,000</td>
            <td>800</td>
          </tr>
          <tr>
            <td>12</td>
            <td>500,000</td>
            <td>∞</td>
            <td>7,000</td>
            <td>1,200</td>
          </tr>
        </tbody>
      </table>
      <p className={style.table_title}>Long Video Monthly Total View-based reward 1.1</p>
      <table border={1}>
        <thead>
          <tr>
            <th>level</th>
            <th colSpan={2}>view start(range)</th>
            <th>view end(range)</th>
            <th>Crystal</th>
            <th>Cash</th>
            <th>Notes</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1(bonus)</td>
            <td colSpan={2}>1</td>
            <td>1,000</td>
            <td>600</td>
            <td>0</td>
            <td>/</td>
          </tr>
          <tr>
            <td>2</td>
            <td>1,000</td>
            <td>or 5 qualified works</td>
            <td>3,000</td>
            <td>2,000</td>
            <td>0</td>
            <td>/</td>
          </tr>
          <tr>
            <td>3</td>
            <td>3,000</td>
            <td>or 10 qualified works</td>
            <td>6,000</td>
            <td>4,000</td>
            <td>0</td>
            <td>/</td>
          </tr>
          <tr>
            <td>4</td>
            <td>6,000</td>
            <td>or 15 qualified works</td>
            <td>10,000</td>
            <td>6,000</td>
            <td>0</td>
            <td>/</td>
          </tr>
          <tr>
            <td>5</td>
            <td>10,000</td>
            <td>or 20 qualified works</td>
            <td>30,000</td>
            <td>8,000</td>
            <td>0</td>
            <td>/</td>
          </tr>
          <tr>
            <td>6</td>
            <td colSpan={2}>30,000</td>
            <td>60,000</td>
            <td>8,000</td>
            <td>100</td>
            <td>/</td>
          </tr>
          <tr>
            <td>7</td>
            <td colSpan={2}>60,000</td>
            <td>100,000</td>
            <td>8,000</td>
            <td>200</td>
            <td>/</td>
          </tr>
          <tr>
            <td>8</td>
            <td colSpan={2}>100,000</td>
            <td>300,000</td>
            <td>8,000</td>
            <td>500</td>
            <td>A chance for long-term<br /> contract with Lilith</td>
          </tr>
          <tr>
            <td>9</td>
            <td colSpan={2}>300,000</td>
            <td>500,000</td>
            <td>8,000</td>
            <td>800</td>
            <td>A chance for long-term<br /> contract with Lilith</td>
          </tr>
          <tr>
            <td>10</td>
            <td colSpan={2}>500,000</td>
            <td>∞</td>
            <td>8,000</td>
            <td>1,200</td>
            <td>A chance for long-term<br /> contract with Lilith</td>
          </tr>
        </tbody>
      </table>
      <p className={style.table_title}>Long Video Task View-based reward 1.1</p>
      <table border={1}>
        <thead>
          <tr>
            <th>level</th>
            <th>view start(range)</th>
            <th>view end(range)</th>
            <th>Crystal</th>
            <th>Cash</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1(bonus)</td>
            <td>0</td>
            <td>100</td>
            <td>600</td>
            <td>0</td>
          </tr>
          <tr>
            <td>2</td>
            <td>100</td>
            <td>500</td>
            <td>1,000</td>
            <td>0</td>
          </tr>
          <tr>
            <td>3</td>
            <td>500</td>
            <td>1,000</td>
            <td>1,500</td>
            <td>0</td>
          </tr>
          <tr>
            <td>4</td>
            <td>1,000</td>
            <td>3,000</td>
            <td>4,000</td>
            <td>0</td>
          </tr>
          <tr>
            <td>5</td>
            <td>3,000</td>
            <td>6,000</td>
            <td>6,000</td>
            <td>0</td>
          </tr>
          <tr>
            <td>6</td>
            <td>6,000</td>
            <td>10,000</td>
            <td>6,000</td>
            <td>50</td>
          </tr>
          <tr>
            <td>7</td>
            <td>10,000</td>
            <td>30,000</td>
            <td>8,000</td>
            <td>100</td>
          </tr>
          <tr>
            <td>8</td>
            <td>30,000</td>
            <td>50,000</td>
            <td>8,000</td>
            <td>200</td>
          </tr>
          <tr>
            <td>9</td>
            <td>50,000</td>
            <td>100,000</td>
            <td>8,000</td>
            <td>500</td>
          </tr>
          <tr>
            <td>10</td>
            <td>100,000</td>
            <td>300,000</td>
            <td>8,000</td>
            <td>800</td>
          </tr>
          <tr>
            <td>11</td>
            <td>300,000</td>
            <td>500,000</td>
            <td>8,000</td>
            <td>1,200</td>
          </tr>
          <tr>
            <td>12</td>
            <td>500,000</td>
            <td>∞</td>
            <td>8,000</td>
            <td>2.000</td>
          </tr>
        </tbody>
      </table>
      <p className={style.table_title}>Live Monthly Total View-based reward 1.1</p>
      <table border={1}>
        <thead>
          <tr>
            <th>level</th>
            <th colSpan={2}>view start(range)</th>
            <th>view end(range)</th>
            <th>Crystal</th>
            <th>Cash</th>
            <th>Notes</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1(bonus)</td>
            <td colSpan={2}>1</td>
            <td>1,000</td>
            <td>400</td>
            <td>0</td>
            <td>/</td>
          </tr>
          <tr>
            <td>2</td>
            <td>1,000</td>
            <td>or 5 qualified works</td>
            <td>3,000</td>
            <td>1,500</td>
            <td>0</td>
            <td>/</td>
          </tr>
          <tr>
            <td>3</td>
            <td>3,000</td>
            <td>or 10 qualified works</td>
            <td>6,000</td>
            <td>3,000</td>
            <td>0</td>
            <td>/</td>
          </tr>
          <tr>
            <td>4</td>
            <td>6,000</td>
            <td>or 15 qualified works</td>
            <td>10,000</td>
            <td>4,500</td>
            <td>0</td>
            <td>/</td>
          </tr>
          <tr>
            <td>5</td>
            <td>10,000</td>
            <td>or 20 qualified works</td>
            <td>30,000</td>
            <td>7,000</td>
            <td>0</td>
            <td>/</td>
          </tr>
          <tr>
            <td>6</td>
            <td colSpan={2}>30,000</td>
            <td>60,000</td>
            <td>7,000</td>
            <td>100</td>
            <td>/</td>
          </tr>
          <tr>
            <td>7</td>
            <td colSpan={2}>60,000</td>
            <td>100,000</td>
            <td>7,000</td>
            <td>200</td>
            <td>/</td>
          </tr>
          <tr>
            <td>8</td>
            <td colSpan={2}>100,000</td>
            <td>300,000</td>
            <td>7,000</td>
            <td>300</td>
            <td>A chance for long-term<br /> contract with Lilith</td>
          </tr>
          <tr>
            <td>9</td>
            <td colSpan={2}>300,000</td>
            <td>500,000</td>
            <td>7,000</td>
            <td>500</td>
            <td>A chance for long-term<br /> contract with Lilith</td>
          </tr>
          <tr>
            <td>10</td>
            <td colSpan={2}>500,000</td>
            <td>∞</td>
            <td>7,000</td>
            <td>1,000</td>
            <td>A chance for long-term<br /> contract with Lilith</td>
          </tr>
        </tbody>
      </table>
      <p className={style.table_title}>Live Task View-based reward 1.1</p>
      <table border={1}>
        <thead>
          <tr>
            <th>level</th>
            <th>view start(range)</th>
            <th>view end(range)</th>
            <th>Crystal</th>
            <th>Cash</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1(bonus)</td>
            <td>0</td>
            <td>100</td>
            <td>500</td>
            <td>0</td>
          </tr>
          <tr>
            <td>2</td>
            <td>100</td>
            <td>500</td>
            <td>800</td>
            <td>0</td>
          </tr>
          <tr>
            <td>3</td>
            <td>500</td>
            <td>1,000</td>
            <td>1,200</td>
            <td>0</td>
          </tr>
          <tr>
            <td>4</td>
            <td>1,000</td>
            <td>3,000</td>
            <td>2,000</td>
            <td>0</td>
          </tr>
          <tr>
            <td>5</td>
            <td>3,000</td>
            <td>5,000</td>
            <td>3,000</td>
            <td>0</td>
          </tr>
          <tr>
            <td>6</td>
            <td>6,000</td>
            <td>10,000</td>
            <td>4,000</td>
            <td>0</td>
          </tr>
          <tr>
            <td>7</td>
            <td>10,000</td>
            <td>30,000</td>
            <td>6,000</td>
            <td>50</td>
          </tr>
          <tr>
            <td>8</td>
            <td>30,000</td>
            <td>50,000</td>
            <td>6,000</td>
            <td>100</td>
          </tr>
          <tr>
            <td>9</td>
            <td>50,000</td>
            <td>100,000</td>
            <td>6,000</td>
            <td>300</td>
          </tr>
          <tr>
            <td>10</td>
            <td>100,000</td>
            <td>300,000</td>
            <td>6,000</td>
            <td>500</td>
          </tr>
          <tr>
            <td>11</td>
            <td>300,000</td>
            <td>500,000</td>
            <td>6,000</td>
            <td>800</td>
          </tr>
          <tr>
            <td>12</td>
            <td>500,000</td>
            <td>∞</td>
            <td>6,000</td>
            <td>1,200</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default DislyteTable;
