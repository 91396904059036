import React, { useContext, useRef, useState } from "react";
import style from "../index.module.scss";
import activeStyle from "./index.module.scss";
import Api from "@/common/Api/content";
// import bindApi from "@/common/Api/bindCreator";
// import PubSub from "pubsub-js";

import { rootContext, globalData } from "@/context/rootContext";
import { locationUtil, Abbreviation, locationTipMessage } from "@/common/utils";
import { Modal } from "antd";
import LoadMask from "@/components/loadMask";

// let loadNum = 0;

function UploadStep(props) {

  const { setDialogIndex, langState } = useContext(rootContext);
  const [analyze, setAnalyze] = useState(false);
  const [isAnalyze, setIsAnalyze] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [urlContent, setUrlContent] = useState("");
  // const [formId, setFormId] = useState("");
  // const [tikTokToken, setTikTokToken] = useState("");
  const [videoData, setVideoData] = useState({
    title: "",
    subTitle: "",
    videoId: "",
    uid: "",
    mTag: "",
    publishTime: "",
    viewNum: "",
    likeNum: "",
    commNum: "",
    snapshot: ""
  });
  const [homePageURL, setHomePageURL] = useState("");

  const urlRef = useRef();

  // const getMediaURL = async (formId) => {
  //   const res = await bindApi.getMediaURL({
  //     mInfoTag: "tiktok",
  //     formId: formId
  //   });
  //   if (res.status === 200) {
  //     loadNum = 0;
  //     window.open(res.data);
  //   } else {
  //     defaultErrorMessage(res, "uploadAuthorize");
  //   }
  // };

  // const getMediaUseData = async () => {
  //   const res = await bindApi.getBindMediaData({
  //     formId: formId
  //   });
  //   if (res && res.status === 200 && res.data) {
  //     refreshTikTokToken(res.data.refreshToken);
  //   } else if (loadNum++ < 5) {
  //     setTimeout(getMediaUseData, 1000);
  //   } else {
  //     defaultErrorMessage(res, "videoData");
  //     setShowLoading(false);
  //   }
  // };

  // const tiktokAuthorize = () => {
  //   const randomNum = [0, 0, 0].map(() => Math.floor(Math.random() * 10)).join("");
  //   const formId = `${new Date().getTime() % 1E3}${randomNum}`;
  //   setFormId(formId);
  //   getMediaURL(formId);
  // };

  const analyzeURL = async (url) => {
    window.gtag("event", "pc_upload_analyze");
    if (new RegExp("[\\u4E00-\\u9FFF]+", "g").test(url)) {
      locationTipMessage(400236);
      return;
    }
    setShowLoading(true);
    const id_val = urlRef.current.value;
    const url_val = id_val === "undefined" ? urlContent : id_val;
    const data = {
      url: url_val.trim(),
      auto: 0,
      gametag: globalData.activeGameData.tag
    };
    const res = await Api.analyzeURL(data);
    setShowLoading(false);
    // setFormId("");
    if (res && res.status === 200) {
      setVideoData({
        title: res.data.title,
        subTitle: res.data.subTitle,
        videoId: res.data.videoId,
        uid: res.data.uid,
        mTag: res.data.mTag,
        publishTime: res.data.publishTime,
        viewNum: res.data.viewCount,
        likeNum: res.data.starCount,
        commNum: res.data.commentCount,
        snapshot: res.data.snapshot
      });
      setHomePageURL(res.data.contentUrl);
      setIsAnalyze(true);
      setAnalyze(true);
    } else {
      Modal.error({
        title: "ERROR",
        content: (res.data && res.data.message) || "Data cannot be retrieved. Please select to manually enter data."
      });
      // setHandleFill(!handleFill);
    }
  };

  // const analyzeTikTok = async (accessToken) => {
  //   window.gtag("event", "pc_upload_analyze");
  //   const id_val = urlRef.current.value;
  //   const url_val = id_val === "undefined" ? urlContent : id_val;
  //   const url = url_val.trim();
  //   if (new RegExp("[\\u4E00-\\u9FFF]+", "g").test(url)) {
  //     locationTipMessage(400236);
  //     return;
  //   }
  //   setShowLoading(true);
  //   const data = {
  //     url,
  //     gametag: globalData.activeGameData.tag,
  //     auto: 0,
  //     tikTokAccessToken: accessToken
  //   };
  //   const res = await Api.analyzeURL(data);
  //   setShowLoading(false);
  //   setFormId("");
  //   if (res && res.status === 200) {
  //     setVideoData({
  //       title: res.data.title,
  //       subTitle: res.data.subTitle,
  //       videoId: res.data.videoId,
  //       uid: res.data.uid,
  //       mTag: res.data.mTag,
  //       publishTime: res.data.publishTime,
  //       viewNum: res.data.viewCount,
  //       likeNum: res.data.starCount,
  //       commNum: res.data.commentCount,
  //       snapshot: res.data.snapshot
  //     });
  //     setHomePageURL(res.data.contentUrl);
  //     setTikTokToken(accessToken);
  //     setIsAnalyze(true);
  //     setAnalyze(true);
  //   } else {
  //     Modal.error({
  //       title: "Error",
  //       content: (res.data && res.data.message) || "Data cannot be retrieved. Please select to manually enter data."
  //     });
  //   }
  // };

  // const refreshTikTokToken = async (refreshToken) => {
  //   const res = await Api.refreshTikTokToken(refreshToken);
  //   // console.log(res);
  //   if (res.status === 200) {
  //     analyzeTikTok(res.data.accessToken);
  //   } else {
  //     defaultErrorMessage(res, "tiktokToken");
  //     setShowLoading(false);
  //   }
  // };

  const submit = () => {
    const id_val = urlRef.current.value;
    const url_val = id_val === "undefined" ? urlContent : id_val;
    const url = url_val.trim();
    if (!props.finstep1) {
      locationTipMessage(200012);
      return;
    }
    if (!url) {
      locationTipMessage(400210);
      return;
    }
    if (new RegExp("[\\u4E00-\\u9FFF]+", "g").test(url)) {
      locationTipMessage(400236);
      return;
    }
    if (isAnalyze) {
      upLoadContent();
      return;
    }
    if (!isAnalyze) {
      if (allowAnalyze()) {
        // if (url.includes("tiktok")) {
        //   tiktokAuthorize();
        // } else {
        //   analyzeURL(url);
        // }
        analyzeURL(url);
      } else {
        setDialogIndex(4);
      }
    }
  };

  const upLoadContent = async () => {
    const id_val = urlRef.current.value;
    const url_val = id_val === "undefined" ? urlContent : id_val;
    const data = {
      title: (videoData.title || "").trim(),
      snapshot: videoData.snapshot,
      contentUrl: homePageURL || url_val.trim(),
      gameTag: globalData.activeGameData.tag,
      viewCount: videoData.viewNum,
      starCount: videoData.likeNum,
      commentCount: videoData.commNum,
      autoParse: false,
      subTitle: videoData.subTitle,
      mTag: videoData.mTag,
      videoId: videoData.videoId || "",
      publishTime: videoData.publishTime
    };
    if (data.contentUrl === "") {
      locationTipMessage(400210);
      return;
    }
    // if (!data.title || !data.snapshot || data.viewCount === "" || !data.starCount === "" || !data.commentCount === "") {
    //   message.error("Please enter all of the your content data.");
    //   return;
    // }
    if (!data.mTag) {
      const url = data.contentUrl;
      if (url.includes("youtube") || url.includes("youtu.be")) {
        data.mTag = "youtube";
      } else if (url.includes("tiktok")) {
        data.mTag = "tiktok";
      } else if (url.includes("twitch")) {
        data.mTag = "twitch";
      }
    }
    if (data.mTag.includes("youtube") || data.mTag.includes("twitch")) {
      data.uid = videoData.uid;
    }
    // data.uid = videoData.uid;
    // props.submit({data, tikTokToken});
    props.submit({data});
  };

  const allowAnalyze = () => {
    const lastAllow = locationUtil.getLocationStorage("authorize");
    if (lastAllow && lastAllow.time && new Date().toLocaleString().slice(0, 8) === lastAllow.time.slice(0, 8)) {
      return true;
    } else {
      return false;
    }
  };

  const enterUrl = (e) => {
    if (isAnalyze) {
      setUrlContent(e.target.value.replace(urlContent, ""));
      setVideoData({
        title: "",
        subTitle: "",
        videoId: "",
        uid: "",
        mTag: "",
        publishTime: "",
        viewNum: "",
        likeNum: "",
        commNum: "",
        snapshot: ""
      });
      setAnalyze(false);
      setIsAnalyze(false);
    } else {
      setUrlContent(e.target.value);
    }
  };

  // const setPlatFormSataus = (msg) => {
  //   if (msg && msg.data && formId) {
  //     try {
  //       const data = JSON.parse(msg.data);
  //       if (data.type === "authorization") {
  //         setTimeout(() => { getMediaUseData(); }, 1000);
  //         setShowLoading(true);
  //       }
  //     } catch (e) { null; }
  //   }
  // };


  // useEffect(() => {
  //   allowAnalyze();
  //   PubSub.subscribe("allowAuthorize", () => {
  //     const url = urlRef.current.value.trim() || urlContent.trim();
  //     if (url.includes("tiktok")) {
  //       tiktokAuthorize();
  //     } else {
  //       analyzeURL();
  //     }
  //   });
  //   // return () => { activeListRef.current.removeEventListener("DOMMouseScroll", checkScrollDirection); };
  // }, []);

  // useEffect(() => {
  //   if (formId) {
  //     const messageCallback = setPlatFormSataus;
  //     window.addEventListener("message", messageCallback);
  //     return () => { window.removeEventListener("message", messageCallback); };
  //   }
  // }, [formId]);

  return (
    <div className={`${style.upload_box} ${activeStyle.upload_box} upload_box`}>
      <div className={activeStyle.step_box}>
        <div className={style.url_upload}>
          <input
            placeholder={langState.upLoadContentData.upLoadPlace1}
            value={urlContent}
            onChange={(e) => { enterUrl(e); }}
            ref={urlRef}
          ></input>
          <div className={style.submit} onClick={submit}>
            {analyze ? langState.upLoadContentData.submitBtn[0] : langState.upLoadContentData.submitBtn[2]}
          </div>
        </div>
        {
          // 已上传窗口
          analyze && (
            <div className={style.analyze_data}>
              {
                videoData.publishTime && (
                  <p className={style.time}>{langState.upLoadContentData.time}:{videoData.publishTime.slice(0, 10)}</p>
                )
              }
              <div className={style.total_num}>
                <ul>
                  <li>
                    <p className={style.right}>{langState.upLoadContentData.rightNow}</p>
                  </li>
                  <li>
                    <p>{langState.upLoadContentData.itemTitle1}</p>
                    <p>{Abbreviation(videoData.viewNum || 0)}</p>
                  </li>
                  <li>
                    <p>{langState.upLoadContentData.itemTitle2}</p>
                    <p>{videoData.mTag === "twitch" ? "—" : Abbreviation(videoData.likeNum || 0)}</p>
                  </li>
                  <li>
                    <p>{langState.upLoadContentData.itemTitle3}</p>
                    <p>{videoData.mTag === "twitch" ? "—" : Abbreviation(videoData.commNum || 0)}</p>
                  </li>
                </ul>
              </div>
              <div className={style.screenshot}>
                <img src={videoData.snapshot}></img>
              </div>
            </div>
          )
        }
      </div>
      {
        showLoading && <LoadMask />
      }
    </div>
  );
}

export default UploadStep;
