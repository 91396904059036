import { isPC } from "@/common/utils/envData";
import { Modal } from "antd";
import React from "react";

export const fl84TransformTip = () => {
  Modal.info({
    title: "TIP",
    width: isPC ? "50vw" : "100vw",
    okText: "GO",
    content: (
      <>
        <p>Please visit the &quot;Farlight 84 Creator Zone&quot; to apply as a Farlight 84 Creator.</p>
        <p>&nbsp;</p>
        <p>Note: Your current account can still be used on the &quot;Farlight 84 Creator Zone&quot; website.</p>
      </>
    )
  });
  setTimeout(() => {
    const enter = document.getElementsByClassName("ant-btn-primary")[0];
    const a = document.createElement("a");
    a.setAttribute("href", "https://fl84.creator-zone.com/main/home");
    a.setAttribute("target", "_blank");
    a.setAttribute("rel", "opener");
    a.setAttribute("class", "primary-link");
    enter.appendChild(a);
  }, 100);
};