import React, { useState, useEffect, useContext, useMemo } from "react";
import style from "./index.module.scss";

import Api from "@/common/Api/message";
import { rootContext, globalData } from "@/context/rootContext";
import { paddingNum } from "@/common/utils";
import { DatePicker } from "antd";
import filterGameData from "@/assets/data/gameData";

function DefaultViewEarnings() {

  const [show2, setShow2] = useState(false);
  const [resources, setResources] = useState(0);
  const [cash, setCash] = useState(0);
  const [month, setMonth] = useState("");
  const { token, creatorData, gameTab, setDialogIndex, setTipContent, activeGame, langState } = useContext(rootContext);
  const [chooseMonth, setChooseMonth] = useState("");
  const filterEarningTip = useMemo(() => filterGameData("earningTip") || [], [activeGame, creatorData]);
  const thisMonth = `${new Date().getFullYear()}-${paddingNum(new Date().getMonth() + 1, 2)}`;

  const getTipDom = () => {
    if (gameTab.length) {
      const data = filterEarningTip;
      let tipData = Array.isArray(data) ? data : data[globalData.lang];
      const dom = `
        <div>
          ${tipData.map((item) => `<p class="reward_text">${item}</p>`).join("")}
        </div>
      `;
      setTipContent(dom);
      setDialogIndex(3);
    }
  };

  const getData = async () => {
    const res = await Api.getIncomeData({
      token,
      gametag: globalData.activeGameData.tag,
      month: chooseMonth || thisMonth
    });
    if (res.status === 200) {
      setResources(res.data.other || res.data.resources || "");
      setCash(+res.data.cash || 0);
      setMonth(chooseMonth || thisMonth);
    }
  };

  useEffect(() => {
    if (creatorData) {
      getData();
    }
  }, [creatorData]);

  useEffect(() => {
    if (chooseMonth) {
      if (chooseMonth === month) { return; }
      getData();
    }
  }, [chooseMonth]);

  return (
    <div className={`earnings_content ${style.earnings_content}`}>
      <p className={style.title1}>{ langState.earningData.earningTitle }</p>
      <div className={style.date_select}>
        <MonthRange choose={setChooseMonth} />
      </div>
      <p className={style.title1}>
        { langState.earningData.subTitle }
        <i className={style.question} onClick={getTipDom}>?</i>
        <i className={`${style.hide} ${show2 ? style.active : ""}`} onClick={() => { window.gtag("event", "m_earning_show"); setShow2(!show2); }}></i>
      </p>
      <ul>
        <li>
          <p className={style.total_earnings_subtitle}>{ langState.earningData.item1Name }</p>
          <p className={style.num}>
            {month}
          </p>
        </li>
        <li>
          <p className={style.total_earnings_subtitle}>{ langState.earningData.item2Name }</p>
          <p className={style.none}>{resources}</p>
        </li>
        <li>
          <p className={style.total_earnings_subtitle}>{ langState.earningData.item3Name }</p>
          <p className={style.num}>
            {show2 ? cash : "****"}
            <span className={style.subtxt2} style={{ display: show2 ? "inline" : "none" }}>$</span>
          </p>
        </li>
      </ul>
      <br /><br />
      <p className={style.tip} onClick={() => { setDialogIndex(17); }}><span>{ langState.earningData.rewards }</span></p>
    </div>
  );
}

class MonthRange extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startValue: null,
      endValue: null,
      endOpen: false,
    };
    this.chooseMonth = props.choose;
  }

  resetData = (current) => {
    const t = current.format("YYYY-MM");
    console.log(t);
    this.chooseMonth(t);
  };

  render() {
    const disabledDate = current => {
      // Can not select days before today and today
      return current && current > new Date();
    };
    const formatFun = current => {
      const t1 = current.format("YYYY-MM");
      const nextDate = current.clone();
      nextDate.add(1, "M");
      const t2 = nextDate.format("YYYY-MM");
      return `${t1} - ${t2}`;
    };
    return (
      <div>
        <DatePicker picker="month" onChange={this.resetData} format={formatFun} disabledDate={disabledDate} />
      </div>
    );
  }
}

export default DefaultViewEarnings;
