import React from "react";
import style from "./index.module.scss";
import { isPC } from "@/common/utils/envData";

function Farlight84Error() {
  return (
    <div className={`${style.box} ${isPC ? "" : style.m}`}>
      <p>The application cannot be submitted as the criteria have not been met.</p>
      <p className={style.slice}>&nbsp;</p>
      <p className={style.emit}>To become our official FCC member, you need to: </p>
      <p>1. Upload at least 1 Farlight 84 contents. </p>
      <p>(Note: The submitted video title must include the keyword &quot;Farlight 84&quot;, and description should contain #Farlight84 and #Farlight84fcc.)</p>
      <p>&nbsp;</p>
      <p>If you don&apos;t meet the follower requirement, don&apos;t be discouraged! You can still be one part of our Rising Creators!
        Join our Creator Zone Server: <a href="https://discord.gg/9xPvwkrVSm" rel="noreferrer" target="_blank">https://discord.gg/9xPvwkrVSm</a></p>
    </div>
  );
}

export default Farlight84Error;
