import React, { useEffect, useState } from "react";
import style from "./index.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { globalData } from "@/context/rootContext";
import Api from "@/common/Api/content";
import { defaultErrorMessage } from "@/common/utils";


function Detail() {
  const location = useLocation();
  const navigate = useNavigate();
  const [mainData, setMainData] = useState({});

  const getGameFeedData = async (id) => {
    window.gtag("event", "pc/gamefeed/detail");
    const res = await Api.getGameFeedDetail({
      gametab: globalData.activeGameData.tag,
      id
    });
    if (res.status === 200) {
      setMainData(res.data);
    } else {
      defaultErrorMessage(res, "gameFeedDeatil");
    }
  };

  const getArtAssetsData = async (id) => {
    window.gtag("event", "pc/artassets/detail");
    const res = await Api.getArtAssetDetail({
      gametab: globalData.activeGameData.tag,
      id
    });
    if (res.status === 200) {
      setMainData(res.data);
    } else {
      defaultErrorMessage(res, "artAssetsDeatil");
    }
  };

  useEffect(() => {
    const id = +location.state.activeId;
    if (id) {
      const path = location.pathname;
      if (path.includes("gamefeed")) {
        getGameFeedData(id);
      } else if (path.includes("artasset")) {
        getArtAssetsData(id);
      }
    }
  }, []);

  const back = () => {
    const state = location.state;
    navigate(-1, { state: state || {} });
  };
  return(
    <div className={`detail_box ${style.detail_box}`}>
      <div className={style.back} onClick={back}></div>
      <p className={style.title}>{mainData.title || ""}</p>
      <p className={style.title}>{mainData.subTitle || ""}</p>
      <p className={style.time}>{mainData.createdTime || ""}</p>
      <div className={style.contain} dangerouslySetInnerHTML={{__html: mainData.content || ""}}></div>
    </div>
  );
}

export default Detail;
