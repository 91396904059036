import React, { useContext, useEffect, useState } from "react";
import { Route, Routes, Navigate, useNavigate, useLocation } from "react-router-dom";

// import { saveAs } from "file-saver";
import Detail from "@/views/pc/Admin/Detail";
import style from "./index.module.scss";
import Api from "@/common/Api/content";
import { rootContext, globalData } from "@/context/rootContext";
import { mainOfRegion } from "@/common/utils/envData";
import { Pagination } from "antd";
import { defaultErrorMessage, locationTipMessage } from "@/common/utils";
// import PubSub from "pubsub-js";

function ArtAssets() {

  const location = useLocation();
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const [key, setKey] = useState("");
  const [isReset, setIsReset] = useState(false);
  const [noTip, setNoTip] = useState(false);
  const { gameTab, token, setDialogIndex, globalLang, langState } = useContext(rootContext);


  const keyDown1 = (e) => {
    if (e.keyCode === 13) { getArtData(); }
  };

  const getArtData = async (item) => {
    const data = {
      gametag: globalData.activeGameData.tag,
      key: key,
      page: (item && item.page) || current,
      limit: 6
    };
    const res = await Api.getArtAsset(data);
    // console.log(res);
    if (res.status === 200 && res.data.items) {
      setTotal(res.data.meta.totalItems);
      resetBtnType(res.data.items);
      setNoTip(res.data.meta.totalItems <= 0);
    }
  };

  const resetBtnType = async (data) => {
    const list = data;
    for (const item of list) {
      if (item.isCommon || item.canDownload) { item.type = 1; }
      else if (item.needApply && !item.onApply) { item.type = 2; }
      else if (item.onApply) { item.type = 4; }
      else item.type = 2;
      item.load = 0;
    }
    setList(list);
  };

  const waitApply = () => {
    locationTipMessage(400208, "info");
  };

  const waitDown = () => {
    locationTipMessage(400208, "info");
  };

  const applyAfter = (id) => {
    const data = [...list];
    for (const item of data) {
      if (item.id === id) {
        item.onApply = true;
      }
    }
    resetBtnType(data);
  };

  const applyDown = async (id) => {
    const res = await Api.applyDownLoad({ id, gametag: globalData.activeGameData.tag });
    if (res.status === 200) {
      setDialogIndex(5);
      setTimeout(getArtData, 500);
      applyAfter();
    } else {
      defaultErrorMessage(res, "applyDown");
    }
  };

  const down = async (id) => {
    Api.downArt({ id });
    // const newList = list;
    // for (const item of newList) {
    //   if (item.id === id) {
    //     item.type = 3;
    //     item.load = 0;
    //     break;
    //   }
    // }
    // const res = await Api.downArt({ id });
    // if (res.status === 200) {
    //   const link = res.data.links;
    //   if (link && link.length) {
    //     window.open(link[0]);
    //   } else {
    //     Modal.error({
    //       title: "ERROR",
    //       content: "NO DATA",
    //     });
    //   }
    // } else {
    //   Modal.error({
    //     title: "ERROR",
    //     content: (res.data && res.data.message) || res.data || res,
    //   });
    // }
    // let blob = new Blob([res.data], { type: res.headers["content-type"] });
    // let fileName = title + new Date().getTime();
    // saveAs(blob, fileName);
  };

  const enterDetail = (id) => {
    navigate(`detail/${id}`, {
      state: {
        current: current,
        key: key,
        list,
        total,
        activeId: id
      }
    });
  };

  useEffect(() => {
    if (token && gameTab && gameTab.length) {
      if (isReset) {
        getArtData();
      } else {
        const state = location.state || {};
        // console.log(state);
        setCurrent(state.current || 1);
        setKey(state.key || "");
        setList(state.list || []);
        setTotal(state.total || 0);
        setIsReset(true);
      }
    }
  }, [token, gameTab, current, isReset]);

  // useEffect(() => {
  //   PubSub.subscribe("downloading", (topic, data) => {
  //     const newList = list;
  //     for (const item of newList) {
  //       if (+item.id === +data.id) {
  //         item.load = data.load;
  //         if (+data.load >= 100) {
  //           setTimeout(() => { item.type = 1; setList([...newList]); }, 500);
  //         } else {
  //           item.type = 3;
  //           setList([...newList]);
  //         }
  //         break;
  //       }
  //     }
  //   });
  // }, [list]);

  const selectWord = () => {
    window.gtag("event", "pc_artassets_select");
    setCurrent(1);
    getArtData({ page: 1 });
  };


  return (
    <div className={`art_assets_box ${style.art_assets_box}`}>
      <p className={style.title1}>{langState.artAssetsData.artAssetsTitle}</p>
      <div className={style.query}>
        <input value={key} onChange={(e) => { setKey(e.target.value); }} onKeyDown={(e) => keyDown1(e)} placeholder={langState.artAssetsData.selectPlace}></input>
        <i onClick={selectWord}></i>
      </div>
      <ul className={ mainOfRegion ? style[globalLang] : "" }>
        {
          list.map((item, index) => {
            return (
              <li key={index}>
                <div className={style.title} onClick={() => { enterDetail(item.id); }}>{item.title}</div>
                <div className={style.download_data}>
                  <span className={style.time}>{item.releaseTime ? item.releaseTime : item.createdTime}</span>
                  {/* <span className={`${style.type} ${style[(item.fodderType || "").toLowerCase()]}`}>{item.fodderType}</span> */}
                  <>
                    {
                      (item.type === 1 && <a href={item.location} onClick={() => { down(item.id); }} target="_blank" rel="noreferrer" className={style.download_btn2}>{langState.artAssetsData.googleLinkBtn}</a>) ||
                      (item.type === 2 && <div onClick={() => { applyDown(item.id); }} className={style.download_btn1}>{langState.artAssetsData.request}</div>) ||
                      (item.type === 3 && (
                        <div className={style.download_btn3} onClick={waitDown}>
                          {langState.artAssetsData.download}
                          <div className={style.line}>
                            <div className={style.activeLine} style={{ width: item.load + "%" }}></div>
                          </div>
                        </div>
                      )) ||
                      (item.type === 4 && <div className={style.download_btn2} onClick={waitApply}>{langState.artAssetsData.requested}</div>)
                    }
                  </>
                </div>
              </li>
            );
          })
        }
        {
          noTip && (
            <p className={style.none_tip}>
              {langState.artAssetsData.noTip}
            </p>
          )
        }
      </ul>
      <div className={style.page}>
        <Pagination
          size="small"
          total={total}
          defaultPageSize={6}
          defaultCurrent={1}
          current={current}
          showSizeChanger={false}
          onChange={(page) => setCurrent(page)}
        />
      </div>
    </div>
  );
}

function Page() {
  return (
    <Routes>
      <Route path="" element={<ArtAssets />}></Route>
      <Route path="/detail/:id" element={<Detail />}></Route>
      <Route path="*" element={<Navigate to="/" />}></Route>
    </Routes>
  );
}

export default Page;

