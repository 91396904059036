import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import style from "../index.module.scss";
import activeStyle from "./index.module.scss";
import detailStyle from "@/views/pc/Admin/Detail/index.module.scss";

import Api from "@/common/Api/content";
import { rootContext, globalData } from "@/context/rootContext";
import { mainOfGame } from "@/common/utils/envData";
import { Checkbox, Modal, message } from "antd";
import { Abbreviation, defaultErrorMessage, fl84Check, locationTipMessage } from "@/common/utils";

import UploadStep from "./uploadStep2";
import { useNavigate } from "react-router-dom";
import errorCode from "@/assets/data/errorCode";

const activityTypeMap = {
  1: "coming soon",
  2: "ongoing",
  3: "end"
};

function UploadConent() {

  const navigate = useNavigate();
  const { activeContentList, langState } = useContext(rootContext);
  const [activityList, setActivityList] = useState([]);
  const [checkValue, setCheckValue] = useState([]);
  const [videoData, setVideoData] = useState([true]);
  const [activeTask, setActiveTask] = useState(null);
  const [unActivity, setUnActivity] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [detailData, setDetailData] = useState(null);

  const activeListRef = useRef();

  const addField = () => {
    if (!unActivity && !activeTask) {
      locationTipMessage(200009);
      return;
    }
    if (activeTask && +activeTask.personWorks >= 0 && videoData.length >= +activeTask.personWorks) {
      locationTipMessage(400221, "info");
      return;
    }
    setVideoData([
      ...videoData,
      true
    ]);
  };

  const openInformation = (index, e) => {
    // console.log(activityList[index]);
    setDetailData(activityList[index]);
    setShowDetail(true);
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  const submit = (vidoData, index) => {
    const { data, tikTokToken } = vidoData;
    if (unActivity) {
      // 不参加投稿活动
      saveVideoData(data, index, tikTokToken);
      return;
    }
    saveVideoData({ ...data, activityId: +activeTask.id }, index, tikTokToken);
  };

  const saveVideoData = async (data, index, token) => {
    const tit = data.title.toLowerCase();
    const isFl84Game = globalData.activeGameData.tag === "farlight84";
    if (isFl84Game) {
      if (!fl84Check(tit)) {
        locationTipMessage(200010);
        return;
      }
    }

    if (activeTask && (activeTask.keyword)) {
      if (!tit.includes(activeTask.keyword.toLowerCase())) {
        message.error(`${errorCode(200031)} '${activeTask.keyword || ((isFl84Game && "Farlight 84") || "")}' ${errorCode(200032)}`);
        return;
      }
    }


    const res = await Api.savaContents({ gametag: globalData.activeGameData.tag, data }, token);
    // console.log(res);
    if (res.status === 200) {
      window.gtag("event", "pc_upload_content");
      locationTipMessage(200028, "success");
      const newVideoData = videoData;
      newVideoData[index] = false;
      if (newVideoData.filter(item => item).length <= 0) {
        navigate("/admin/mycontent/manage");
        return;
      }
      setVideoData([...newVideoData]);
      if (newVideoData.length === 1) {
        setTimeout(() => setVideoData([true]), 0);
      }
    } else {
      if (res.data && +res.data.code === 400218) {
        Modal.error({
          title: "ERROR",
          content: <p dangerouslySetInnerHTML={{ __html: errorCode(400218) }}></p>
        });
        return;
      }
      defaultErrorMessage(res, "uploadContent");
    }
  };

  const checkedActivityTask = (index, status) => {
    if (status !== 2 || unActivity) {
      return;
    }
    const checked = !checkValue[index];
    const newCheck = checkValue.map(() => false);
    newCheck[index] = checked;
    setCheckValue(newCheck);
    if (checked) {
      setActiveTask(activityList[index]);
    } else {
      setActiveTask(null);
    }
    setVideoData(videoData.splice(0, +activityList[index].personWorks > 0 ? +activityList[index].personWorks : 1));
  };

  const checkUnActuvuty = (value) => {
    const checked = value.target.checked;
    setUnActivity(checked);
    if (checked) {
      const newCheck = checkValue.map(() => false);
      setCheckValue(newCheck);
      setActiveTask(null);
      // setVideoData(videoData.splice(0, 1));
    }
  };

  const checkScrollDirection = (event) => {
    var delta = 0;
    if (!event) event = window.event;
    if (event.wheelDelta) {//IE、chrome浏览器使用的是wheelDelta，并且值为“正负120”
      delta = event.wheelDelta / 120;
      if (window.opera) delta = -delta;//因为IE、chrome等向下滚动是负值，FF是正值，为了处理一致性，在此取反处理
    } else if (event.detail) {//FF浏览器使用的是detail,其值为“正负3”
      delta = -event.detail / 3;
    }
    if (delta) {
      const activieList = activeListRef.current;
      const { scrollLeft, clientWidth, scrollWidth } = activieList;

      if (scrollLeft <= 0 && delta > 0) {
        // 贴左 不处理
        return;
      }
      if ((scrollLeft + clientWidth) >= scrollWidth && delta < 0) {
        // 贴右 不处理
        return;
      }
      activieList.scrollTo(scrollLeft - delta * 50, 0);
      event.preventDefault();
    }
  };

  const bindActivityScroll = () => {
    // 拦截鼠标竖向滑动，转横向滑动
    const list = activeListRef.current;
    if (list.addEventListener) {
      list.addEventListener("DOMMouseScroll", checkScrollDirection, false);
    }
    list.onmousewheel = list.onmousewheel = checkScrollDirection;
  };

  const initActivityList = (list) => {
    if (list.length > 3) {
      bindActivityScroll();
    }
    const list1 = list.filter(item => +item.status === 2).sort((a, b) => {
      return new Date(a.endTime.slice(0, 10)) > new Date(b.endTime.slice(0, 10)) ? 1 : -1;
    });
    const list2 = list.filter(item => +item.status === 1).sort((a, b) => {
      return new Date(a.startTime.slice(0, 10)) > new Date(b.startTime.slice(0, 10)) ? 1 : -1;
    });
    const list3 = list.filter(item => +item.status === 3).sort((a, b) => {
      return new Date(a.endTime.slice(0, 10)) < new Date(b.endTime.slice(0, 10)) ? 1 : -1;
    });
    const newList = [...list1, ...list2, ...list3];
    setActivityList(newList);
    setCheckValue(list.map(() => false));
  };

  useEffect(() => {
    const key = mainOfGame ? `${globalData.activeGameData.tag}-${globalData.activeGameData.regionName}` : globalData.activeGameData.tag;
    const list = activeContentList[key];
    initActivityList(list.filter(item => item.enable) || []);
  }, [activeContentList]);

  return (
    <div className={`${style.upload_box} upload_box`}>
      <div style={{ display: showDetail ? "none" : "block" }}>
        <p className={style.title1}>{langState.upLoadContentData.uploadTitle1}</p>
        <div className={activeStyle.step_box}>
          <p className={activeStyle.step_title} dangerouslySetInnerHTML={{ __html: langState.upLoadContentData.step1 }}></p>
          <div className={activeStyle.step1_list}>
            <div className={activeStyle.active_list} ref={activeListRef}>
              {
                activityList.map((item, index) => (
                  <div key={item.id} className={activeStyle.active_item} onClick={() => checkedActivityTask(index, +item.status)}>
                    <Checkbox disabled={unActivity || +item.status !== 2} checked={checkValue[index]}>{item.name}</Checkbox>
                    <div className={activeStyle.step_data}>
                      <div className={activeStyle.img_box}>
                        <img src={item.link}></img>
                        <p>
                          <span className={activeStyle[`type${item.status}`]}><i></i>{activityTypeMap[item.status]}</span>
                          <span className={activeStyle.num}><i></i><em>{Abbreviation(item.participants)}</em> Participants</span>
                        </p>
                      </div>
                      <div className={activeStyle.tip_box}>
                        <div className={activeStyle.step_messsagebox}>
                          <p className={activeStyle.desc}>{item.propaganda}</p>
                          <p className={activeStyle.subtitle}>{langState.upLoadContentData.taskTitle[0]}</p>
                          <p className={activeStyle.desc2}>{item.subject}</p>
                          <p className={activeStyle.subtitle}>{langState.upLoadContentData.taskTitle[1]}<span>（UTC0）</span></p>
                          <p className={activeStyle.desc2}>{item.startTime.slice(0, 10)} - {item.endTime.slice(0, 10)}</p>
                          <p className={activeStyle.subtitle}>{langState.upLoadContentData.taskTitle[2]}</p>
                          <div className={activeStyle.desc} dangerouslySetInnerHTML={{ __html: item.reward }}></div>
                        </div>
                        <p className={activeStyle.tip}>···</p>
                      </div>
                      <span className={activeStyle.more} onClick={(e) => openInformation(index, e)}>{langState.upLoadContentData.taskDetail}</span>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
          <div className={activeStyle.unJoin}>
            <Checkbox checked={unActivity} onChange={(value) => { checkUnActuvuty(value); }}>{langState.upLoadContentData.normalUpload}</Checkbox>
          </div>
        </div>

        <div className={activeStyle.step_box}>
          <p className={activeStyle.step_title} dangerouslySetInnerHTML={{ __html: langState.upLoadContentData.step2 }}></p>
          {
            globalData.activeGameData.tag === "farlight84" && (
              <p className={activeStyle.upLoadTip}>
                Note: The submitted video title must include the keyword &quot;<strong>Farlight 84
                {
                  (activeTask && (activeTask.keyword) && <>{`, ${activeTask.keyword}`}</>) || ""
                }
                </strong>&quot;, and description should contain #Farlight84 and #FCC.
              </p>
            )
          }
          {
            !unActivity && activeTask && (
              <p className={activeStyle.step_tip}>
                {langState.upLoadContentData.taskEvent}<em>{activeTask.name}  </em>
                {
                  +activeTask.personWorks >= 0 && (
                    <>
                      {
                        +activeTask.personWorks === 1 ? (
                          <span> (<em>{activeTask.personWorks}</em> video submitted)</span>
                        ) : (
                          <span> (<em>{activeTask.personWorks}</em> videos submitted)</span>
                        )
                      }
                    </>
                  )
                }

              </p>
            )
          }
          {
            videoData.map((item, index) => (
              <Fragment key={index}>
                {item && <UploadStep submit={(data) => submit(data, index)} finstep1={unActivity || activeTask} />}
                {videoData.length > 1 && item && index !== (videoData.length - 1) && <hr color="#c7c7c7" />}
              </Fragment>

            ))
          }

        </div>

        <div onClick={addField} className={activeStyle.add_Field}><p>{langState.upLoadContentData.addUrl}</p></div>
      </div>
      {showDetail && <ContentInformation data={detailData} back={() => { setShowDetail(false); }} />}

    </div>
  );
}


function ContentInformation(props) {
  return (
    <div className={`detail_box ${detailStyle.detail_box}`}>
      <div className={detailStyle.back} onClick={props.back}></div>
      <p className={detailStyle.title}>{props.data.name || ""}</p>
      <p className={detailStyle.title}>{props.data.startTime.slice(0, 10)} - {props.data.endTime.slice(0, 10)}</p>
      <div className={detailStyle.contain} dangerouslySetInnerHTML={{ __html: props.data.desc || "" }}></div>
    </div>
  );
}

export default UploadConent;
