import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import { mainOfGame, mainOfRegion, isFl84 } from "@/common/utils/envData";

import Application from "@/views/m/Application/index"; // 申请页
import SignUp from "@/views/m/SignUp/index"; // 申请页
// import SignupLanguage from "@/views/m/SignUpLanguage/index"; // 注册语言选择
import Guide from "@/views/m/Guide/index"; // 任务页
import Admin from "@/views/m/Admin/index"; // 管理页
import Information from "@/views/m/Information/index"; // 个人信息页
import Sign from "@/views/m/Sign/index"; // 社区协议

import AdminHome from"@/views/m/Admin/Home/index"; // 主页
import Upload from"@/views/m/Admin/Upload/index"; // 上传
import Growth from"@/views/m/Admin/Growth/index"; // 游戏推送
import ManageContent from"@/views/m/Admin/ManageContent/index"; // 作品管理
import ViewEarnings from"@/views/m/Admin/ViewEarnings/index"; //收益管理

const BaseHome = lazy(() => import("@/views/m/Home/Base/index")); // 默认主页
const Fl84Home = lazy(() => import("@/views/m/Home/Fl84/index")); // fl84主页
const AKFHome = lazy(() => import("@/views/m/Home/Afk/index")); // fl84主页

const homeChack = () => {
  if (!(mainOfGame || mainOfRegion)) { return BaseHome; }
  switch (true) {
    case isFl84: {
      return Fl84Home;
    }
    case mainOfRegion : {
      return AKFHome;
    }
    default: {
      return BaseHome;
    }
  }
};

const Home = homeChack();

const adminRouter = [
  { path: "home", element: <AdminHome /> },
  { path: "growth/*", element: <Growth /> },
  { path: "upload", element: <Upload /> },
  { path: "mycontent", element: <ManageContent /> },
  { path: "viewearnings", element: <ViewEarnings /> },
  { path: "", element: <Navigate to="home" /> },
  { path: "*", element: <Navigate to="home" /> },
];

const routes = [
  { path: "", element: <Home /> },
  { path: "application", element: <Application /> },
  { path: "signup", element: <SignUp /> },
  // { path: "signuplanguage", element: <SignupLanguage /> },
  { path: "guide", element: <Guide /> },
  { path: "admin/*", element: <Admin />, children: adminRouter },
  { path: "sign", element: <Sign /> },
  { path: "Information", element: <Information /> },
  { path: "", element: <Navigate to="" /> },
  { path: "*", element: <Navigate to="" /> },
];

export default routes;
