import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import style from "./index.module.scss";
import PubSub from "pubsub-js";

import lv1Icon from "@/assets/images/lv1.png";
import lv2Icon from "@/assets/images/lv2.png";
import lv3Icon from "@/assets/images/lv3.png";

import Api from "@/common/Api/message";

import { filterJoinTime, filterGameTagInfo } from "@/common/filter";
import { rootContext, globalData } from "@/context/rootContext";
import { isFl84 } from "@/common/utils/envData";
import filterGameData from "@/assets/data/gameData";

function UserDetali(props) {

  const navigate = useNavigate();
  const lvIcon = [
    { lv: 1, icon: lv3Icon },
    { lv: 2, icon: lv2Icon },
    { lv: 3, icon: lv1Icon }
  ];
  const todoType = {
    0: { path: "", close: true }, // 生日祝福
    1: { path: "/admin/growth/artassets", close: true }, // 游戏信息/游戏素材更新
    2: { path: "", close: true }, // 等级更新
    3: { path: "/admin/viewearnings", close: true }, // 收益更新
    4: { path: "/admin/growth/artassets", close: true }, // 游戏素材下载�?
    5: { path: "", close: true }, // 发出游戏下载申请
    6: { path: "", close: true }, // 游戏申请未通过
    7: { path: "/sign", close: false }, // 前往签署协议
    8: { path: "discord", close: true }, // discord
    9: { path: "tier", close: true }, // 游戏等级提示
    10: { path: "dialog", close: true }, // 游戏申请不通过
    12: { path: "dialog", close: true }
  };
  
  const {
    langState, creatorData, token, activeGame, gameTab, guideTask, readTapIndex,
    setCreatorData, setDialogIndex, setTipContent, setActiveGame, setGuideTask
  } = useContext(rootContext);
  const [todoList, setTodoList] = useState([]);
  const joinTime = useMemo(() => {
    return filterJoinTime();
  }, [activeGame, creatorData]);

  // 查看星级弹窗
  const tiersExplained = (gameTag) => {
    if (globalData.activeGameData.tag !== gameTag) {
      const { gameItem, gameIndex } = filterGameTagInfo(gameTab, creatorData.games);
      globalData.activeGameData = gameItem;
      setActiveGame(gameIndex);
      setTimeout(() => {
        setDialogIndex(14);
      }, 100);
    } else {
      setDialogIndex(14);
    }
    if (!guideTask[6].done) {
      finishTask(guideTask[6].taskId);
    }
  };

  const finishTask = async (id) => {
    const res = await Api.finishTask({ id });
    if (res.status === 200) {
      guideTask[6].done = true;
      setGuideTask([...guideTask]);
    }
  };

  const enterDiscord = () => {
    const link = filterGameData("discordLink");
    return link || "javascript:;";
  };

  const clickDiscordLink = () => {
    Api.clickDiscordLink({ gametag: globalData.activeGameData.tag });
    const cData = creatorData;
    for (const item of cData.games) {
      if (item.tag === globalData.activeGameData.tag && +item.regionId === +globalData.activeGameData.regionId) {
        item.alreadyJoinDiscord = true;
        break;
      }
    }
    globalData.activeGameData.alreadyJoinDiscord = true;
    setCreatorData({ ...cData });
  };

  const enterOtherPage = (item, path) => {
    if (item.gameTag && globalData.activeGameData.tag !== item.gameTag) {
      const { gameItem, gameIndex } = filterGameTagInfo(item.gameTag, creatorData.games, item.regionId);
      globalData.activeGameData = gameItem;
      setActiveGame(gameIndex);
    }
    setTimeout(() => { navigate(path); }, 100);
  };

  const getTodoList = async () => {
    const res = await Api.getTodo();
    if (res.status === 200 && res.data) {
      if (!Array.isArray(res.data)) { res.data = [res.data]; }
      // const todoList = res.data.filter(item => item.read && +item.type !== 11);
      const todoList = res.data.filter(item => !item.read && +item.type !== 11);
      const dealTodoList = todoList.map((item) => {
        if (+item.type === 1) {
          item.title = item.title.replace("new", "<em>new</em>");
        }
        return item;
      });
      setTodoList(dealTodoList);
      const gameTipList = res.data.filter(item => +item.type === 11 && !item.read);
      if (gameTipList.length && gameTab.includes(gameTipList[0].gameTag)) {
        PubSub.publish("changeGameTip", null);
        readTodo(gameTipList[0].id, -1, null, null);
      }
    }
  };

  const finishTode = (item, path) => {
    const type = +item.type;
    // gamefeed/art assets
    if (type === 1) {
      if (item.desc) {
        if (item.desc.includes("game")) {
          enterOtherPage(item, "/admin/growth/gamefeed");
        }
        if (item.desc.includes("art")) {
          enterOtherPage(item, "/admin/growth/artassets");
        }
      } else {
        enterOtherPage(item, path);
      }
      return;
    }
    // discord
    if (type === 8) {
      return;
    }
    // tier
    if (type === 9) {
      tiersExplained(item.gameTag);
      return;
    }
    // dialog
    if (type === 10 || type === 12) {
      setDialogIndex(3);
      setTipContent(item.desc || item.title);
      return;
    }
    enterOtherPage(item, path);
  };

  const readTodo = async (id, index, path, type) => {
    if (type === 7) {
      // 签约任务 在签约页完成
      navigate(path);
      return;
    }
    await Api.readTodo({ id });
    if (index > -1) {
      const list = todoList;
      const item = list[index];
      item.hide = true;
      setTodoList([...list]);
      if (path) {
        finishTode(item, path);
      }
    }
  };

  useEffect(() => {
    if (token) {
      getTodoList();
    }
  }, [token]);

  useEffect(() => {
    if (readTapIndex[0] < 0 || readTapIndex[1] < 0) {
      getTodoList();
    }
  }, [readTapIndex]);

  return (
    <div className={`user_welcome ${style.user_welcome}`}>
      {
        props.isFin && (
          <div className={style.guide_enter} onClick={() => { setDialogIndex(13); }}></div>
        )
      }
      <div className={style.level_box}>
        {
          globalData.activeGameData.accountLv && (
            <img src={lvIcon[globalData.activeGameData.accountLv - 1].icon} />
          )
        }
      </div>
      <div className={`welcome_txt ${style.welcome_txt}`}>
        {
          globalData.activeGameData.tag === "farlight84" && (
            <p className={`lv_name ${style.lv_name}`}>
              {langState.adminHomeData.userDetail.fl84StarLevel[globalData.activeGameData.accountLv - 1]}
            </p>
          )
        }
        <p>{langState.adminHomeData.userDetail.welcome}<span>{creatorData ? (creatorData.nickName || creatorData.loginName || "") : ""}!</span>{langState.adminHomeData.userDetail.welcome2 || ""}</p>
        <p>
          {
            (isFl84 && globalData.activeGameData.tag === "farlight84") ? <>{langState.adminHomeData.userDetail.timeTextFl84}</> : <>{langState.adminHomeData.userDetail.timeText}</>
          }
          <span> {joinTime || 0}</span> {(+(joinTime || 0)) > 1 ? langState.adminHomeData.userDetail.timeUnit2 : langState.adminHomeData.userDetail.timeUnit1}
        </p>
      </div>
      <div className={`comm_icons ${style.comm_icons}`}>
        <ul>
          {
            creatorData && creatorData.mInfos.filter(item => !item.regionId || (+item.regionId === +globalData.activeGameData.regionId)).map(item => {
              return (<li key={item.tag}>
                <a href={item.channelUrl}>
                  <i className={style[item.tag]} onClick={() => { window.gtag("event", `m_home_${item.tag}`); }}></i>
                </a>
              </li>);
            })
          }
          <li><i className={`${style.hr} hr`}></i></li>
          {
            creatorData && (
              <li onClick={() => { window.gtag("event", "m_home_discord"); }}>
                <a href={enterDiscord()} target="_blank" rel="noreferrer">
                  <i onClick={() => { clickDiscordLink(); }} className={`${style.creator} ${!globalData.activeGameData.alreadyJoinDiscord ? style.on : ""}`}></i>
                </a>
              </li>
            )}
        </ul>
      </div>
      {
        todoList.length && (
          <div className={style.notice_box}>
            <ul>
              {
                todoList.length && todoList.map((item, index) => {
                  return (
                    <li key={index} className={`${item.hide ? style.hide : ""}`}>
                      {
                        todoType[item.type] && todoType[item.type].path === "discord" ? (
                          <a href={item.desc} target="_blank" rel="noreferrer">
                            <p>{item.title}</p>
                          </a>
                        ) : (
                          <p onClick={() => { readTodo(item.id, index, todoType[item.type].path, item.type); }} dangerouslySetInnerHTML={{ __html: item.title }}></p>
                        )
                      }
                      <div className={style.todo_icons}>
                        {
                          todoType[item.type] ? (
                            <>
                              {
                                todoType[item.type].path && (
                                  todoType[item.type].path === "discord" ? (
                                    <a href={enterDiscord(item.gameTag)} target="_blank" rel="noreferrer">
                                      <i onClick={() => { clickDiscordLink(); readTodo(item.id, index, todoType[item.type].path, item.type); }} className={style.next}></i>
                                    </a>
                                  ) : (
                                    <i onClick={() => { readTodo(item.id, index, todoType[item.type].path, item.type); }} className={style.next}></i>
                                  )
                                )
                              }
                              {todoType[item.type].close && <i onClick={() => { readTodo(item.id, index, null); }} className={style.close}></i>}
                            </>
                          ) : (
                            <i onClick={() => { readTodo(item.id, index, null); }} className={style.close}></i>
                          )
                        }

                      </div>
                    </li>
                  );
                })
              }
            </ul>
          </div>
        )
      }
    </div>
  );
}

const UserDetaliMemo = React.memo(UserDetali);

export default UserDetaliMemo;
