import React, { useEffect, useReducer, useState } from "react";
import { rootContext } from "@/context/rootContext";
import { preInitRegion } from "@/common/utils/envData";
import { filterPageData } from "@/assets/data/pageData";

function GlobalContextBox(props) {
  
  const [enterRegion, setEnterRegion] = useState(preInitRegion); // 进入的地区
  const [isLogin, setIsLogin] = useState(false); // 是否登录
  const [token, setToken] = useState(""); // 网络请求token
  const [refreshToken, setRefreshToken] = useState(""); // 长时间登录时更新token的凭证(大概率不用，token有效期已延长)
  const [activeGame, setActiveGame] = useState(0); // 活动gamelist index，确定当前gamelist中第几个是活动态
  const [gameTab, setGameTab] = useState([]); // 可用的游戏标签列表(用户已绑定+已实名)
  const [gameList, setGameList] = useState([]); // 用户绑定的游戏列表(不一定实名)
  const [dialogIndex, setDialogIndex] = useState(0); // 活动的弹窗id
  const [nextDialog, setNextDialog] = useState(0); // 重叠打开弹窗的记录
  const [tipContent, setTipContent] = useState(""); // 提示弹窗内的动态html
  const [creatorData, setCreatorData] = useState(null); // 用户账号数据
  const [headFixed, setHeadFixed] = useState(false); // 内页样式定位 head固定
  const [verify, setVerify] = useState(false); // 验证账号密码标识 使用登录接口验证，使用该标识避免走重新登录流程
  const [protocols, setProtocols] = useState([]); // 用户已实名数据
  const [scale, setScale] = useState(1); // 部分页面缩放比例
  const [guideTask, setGuideTask] = useState([]); // 新人九宫格任务数据
  const [gamePlatform, setGamePlatform] = useState(null); // 各游戏平台实名情况
  const [ableMedia, setAbleMedia] = useState([]); // 可用的媒体数据
  const [loginStatus, setLogionStatus] = useState([]); // 登录数据
  const [ableLanguage, setAbleLanguage] = useState([]);// 可用的语言
  const [ablePos, setAblePos] = useState([]); // 可用的创作者定位(直播/长视频/短视频)
  const [refreshVideo, setRefreshVideo] = useState(false); // 刷新上传的视频数据标识
  const [refreshVideoTime, setRefreshVideoTime] = useState(0); // 新上传的视频数据时间 避免过量请求
  const [activeContentList, setActiveContentList] = useState({}); // 上传活动/任务标签列表
  const [globalLang, setGlobalLang] = useState("en"); // 当前页面语言
  const [readTapIndex, setReadTapIndex] = useState([1, 1, 1]); // 资讯/素材/活动推送的更新标识
  const [langState, langDispatch] = useReducer(langDispatchReducer, filterPageData()); // 页面文案数据

  const globalContext = {
    isLogin, setIsLogin,
    token, setToken,
    gameTab, setGameTab,
    gameList, setGameList,
    activeGame, setActiveGame,
    dialogIndex, setDialogIndex,
    creatorData, setCreatorData,
    tipContent, setTipContent,
    headFixed, setHeadFixed,
    verify, setVerify,
    protocols, setProtocols,
    scale, setScale,
    nextDialog, setNextDialog,
    guideTask, setGuideTask,
    gamePlatform, setGamePlatform,
    refreshToken, setRefreshToken,
    ableMedia, setAbleMedia,
    enterRegion, setEnterRegion,
    loginStatus, setLogionStatus,
    ableLanguage, setAbleLanguage,
    ablePos, setAblePos,
    refreshVideo, setRefreshVideo,
    refreshVideoTime, setRefreshVideoTime,
    activeContentList, setActiveContentList,
    globalLang, setGlobalLang,
    readTapIndex, setReadTapIndex,
    langState
  };

  useEffect(() => {
    langDispatch({ type: "update" });
  }, [globalLang]);

  return (
    <rootContext.Provider value={globalContext}>
      {props.children}
    </rootContext.Provider>
  );
}

export default GlobalContextBox;





const langDispatchReducer = (state, action) => {
  if (action.type === "update") {
    return filterPageData();
  }
};