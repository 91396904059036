import { locationUtil } from "@/common/utils";
import { globalData } from "@/context/rootContext";
import { isSea, isAfkjourney, fl84RegionId } from "@/common/utils/envData";

/**
 * 区域数据
 * region 区域简称
 * regionConfigId 区域id
 * XCreatorRegion 网络请求参数
 * gamehost 各独立区内的网络请求参数
 * XCreatorRegionOnly 原3区(en/mult/sea)合并区的网络请求参数
 */
const regionSettingData = {
  "en": {
    region: "en",
    regionConfigId: 1,
    lang: "en",
    gamehost: "afkjourney.creator-zone.com",
    XCreatorRegion: "https://www.creator-zone.com/main/home",
    XCreatorRegionOnly: "https://afkjourney.creator-zone.com/"
  },
  "mult": {
    region: "mult",
    lang: "en",
    regionConfigId: 2,
    XCreatorRegion: "https://www.creator-zone.com/mult/main/home"
  },
  "sea": {
    region: "sea",
    regionConfigId: 3,
    lang: "en",
    gamehost: "afkjourney.creator-zone.com",
    // gamehost: "sea.creator-zone.com",
    XCreatorRegion: "https://www.creator-zone.com/sea/main/home",
    XCreatorRegionOnly: "https://sea.creator-zone.com/"
  },
  "farlight84": {
    region: "farlight84",
    regionConfigId: fl84RegionId,
    lang: "en",
    gamehost: "farlight84.creator-zone.com",
    XCreatorRegion: "https://farlight84.creator-zone.com/main/home",
  },
  "sk": {
    region: "SK",
    regionConfigId: 9,
    lang: "sk",
    gamehost: "afkjourney.creator-zone.com",
    XCreatorRegion: "kr.creator-zone.com"
  },
  "tw": {
    region: "Tw",
    regionConfigId: 10,
    lang: "zh-tw",
    gamehost: "afkjourney.creator-zone.com",
    XCreatorRegion: "https://ori.creator-zone.com/"
  },
  "ja": {
    region: "ja",
    regionConfigId: 11,
    lang: "jp",
    gamehost: "afkjourney.creator-zone.com",
    XCreatorRegion: "https://jp.creator-zone.com/"
  }
};

// 初始化区域请求数据
const setRegionDeafultData = (region) => {
  if (!regionSettingData[region]) { return; }
  const regionData = getRegionSettingData(region);
  if (!globalData.region) {
    globalData.region = regionData.region;
  }
  if (!globalData.regionConfigId) {
    globalData.regionConfigId = regionData.regionConfigId;
  }
  if (globalData.lang === "en" && regionData.lang !== "en") {
    globalData.lang = regionData.lang;
  }
  if (regionData.gamehost) {
    globalData.gamehost = regionData.gamehost;
  }
  if (isAfkjourney || isSea) {
    globalData.XCreatorRegion = regionData.XCreatorRegionOnly;
  } else {
    globalData.XCreatorRegion = regionData.XCreatorRegion;
  }
};

// 获取区域数据
export const getRegionSettingData = (region) => {
  switch(region) {
    case "en": return regionSettingData["en"];
    case "afkjourney": return regionSettingData["en"];
    case "sk": return regionSettingData["sk"];
    case "kr": return regionSettingData["sk"];
    case "ja": return regionSettingData["ja"];
    case "jp": return regionSettingData["ja"];
    default: return regionSettingData[region];
  }
};

// 检查上次登录环境
export const checkPreLoginRegion = () => {
  const reginStatus = locationUtil.getLocationStorage("lilacCreatorRegion") || {};
  const perLoginRegion = reginStatus.region;
  return perLoginRegion && (perLoginRegion === (globalData.region || "en"));
};

// 保存登录环境
export const saveLoginRegion = (region) => {
  locationUtil.setLocationStorage("lilacCreatorRegion", { region: region || "en" });
};

// 获取地区参数
export const getRegionData = async (region, saveLoginState = true) => {
  setRegionDeafultData((region && region.toLowerCase()) || region);
  if (saveLoginState) {
    saveLoginRegion(region);
  }
};