// Bahasa Indonesia印尼语
// 所有数据会进入assets/data/pageData进行过滤

// 顶部nav
const topNavData = {
  signUp: "DAFTAR",
  logIn: "LOGIN",
  application: "FORMULIR APLIKASI",
  home: "HOME",
  back: "Kembali",
  profile: "Lihat profil",
  contact: "Hubungi kami",
  logOut: "Log Out"
};

// 底部版权
const footerData = {
  service: "KETENTUAN LAYANAN",
  policy: "KEBIJAKAN PRIVASI",
  footerText: "Zona Kreator adalah platform kreator konten resmi yang dibuat oleh Lilith & Farlight Games.",
};

// 首页文案 iGame
const homeData = {
  
};

// 注册页-选择地区
const signUpLanguageData = {
  title: "Perjalananmu Dimulai di Sini",
  desc: "Dalam bahasa apa kamu membuat konten",
};

// 注册页
const signData = {
  title: "Perjalananmu Dimulai di Sini",
  title2: "DAFTAR",
  desc1: "Untuk menjadi anggota Zona Kreator, ",
  desc2: "lihatlah persyaratan berikut",
  signRule1_1: "Mematuhi ",
  signRule1_link: "panduan komunitas ",
  signRule1_2: "kami dan ramah pada Creator lain juga Manajer Komunitas.",
  signRule2: "Berusia minimal 18 tahun.",
  signOtherText1: "Jika tidak memenuhi persyaratan di atas,",
  signOtherText2: "kirim permohonan pendaftaran di",
  signOtherLink: "sini",
  signPlace1: "Masukkan alamat emai",
  signPlace2: "Buat Nama Pengguna",
  signPlace3: "Buat Kata Sandi",
  signNeed1: "Kata sandi harus terdiri dari:",
  signNeed2: "8 hingga 20 karakter",
  signNeed3: "Huruf, Angka, dan Karakter Khusus",
  signNeed4_1: "Kamu sudah membaca",
  signNeed4_2: "Ketentuan Layanan",
  signNeed4_3: "dan",
  signNeed4_4: "Kebijakan Privasi",
  signNeed4_5: "dari Zona Kreator.",
  accept: "Terima",
  next: "BERIKUTNYA",
  send: "KIRIM KODE",
  codeTip: "Masukkan kode 6--digit",
  accountTip1: "Sudah punya akun?",
  accountTip2: "masuk",
};

// 个人信息页
const informationData = {
  title1: "Edit Informasi Akun",
  accountTitle: "Informasi Akun",
  mediaTitle: "Media Sosial",
  gameTitle: "Permainan",
  birthdayTitle: "Ulang Tahun",
  realNameTitle: "Nama Aslimu",
  countryTitle: "Negara atau Wilayah",
  languageTitle: "Bahasa",
  discordTitle: "ID Discord-mu",
  discordDesc: "Agar kami dapat menghubungi dan berkomunikasi denganmu",
};

// 欢迎/引导页
const guideData = {
  welcomeTitle: ["Selamat datang", "di Zona Kreator!"],
  commRuleTitle: "Aturan Komunitas",
  commRuleDesc: [
    "1. Menghargai,",
    "Kami ingin menciptakan lingkungan yang ramah bagi semua orang. Kebebasan berpendapat sangat dihargai, tetapi bahasa yang menyinggung atau perilaku (pasif-)agresif dapat berujung pada peringatan yang pada akhirnya di-ban dari Zona Kreator.,",
    "2. Diskriminasi,",
    "Perundungan atau perilaku diskriminatif apa pun tidak diperbolehkan. Komentar yang merendahkan tentang hal-hal seperti ras, agama, budaya, orientasi seksual, jenis kelamin, atau identitas tidak akan ditoleransi dan dapat mengakibatkan ban dari Zona Kreator.,",
    "3. Perdagangan Akun,",
    "Dilarang menjual, memperdagangkan, atau memberikan boosting (berbayar) pada akun. Setiap Creator yang terlibat dalam jenis kegiatan ini dapat di-ban dari Zona Kreator.,",
    "4. Pornografi atau konten yang mengganggu",
    "Penyebaran konten pornografi atau konten yang mengganggu dapat mengakibatkan ban dari Zona Kreator.,",
    "5. Plagiarisme,",
    "Semua konten yang dibuat harus asli. Plagiarisme dapat mengakibatkan ban dari Zona Kreator.,",
    "6. Kata terakhir,",
    "Hanya karena sebuah peraturan tidak tercantum di sini, tidak berarti kami akan menoleransi peraturan tersebut dari para Creator. Lilith Games dan Farlight Games memiliki semua hak atas interpretasi akhir dari peraturan ini.",
  ],
  agreeBtn: "SAYA SETUJU",
  commRuleTip: "Harap selesaikan membaca Peraturan Komunitas",
  nonRuleTitle: "Perjanjian Kerahasiaan",
  nonRuleTitle2: "KEBIJAKAN PRIVASI",
  nonRuleDescSub: [
    "1. Semua aset art, pratinjau patch, dan aset lain yang kami sediakan melalui platform ini dianggap rahasia hingga dirilis secara resmi. Membocorkan atau menampilkan materi apa pun tanpa izin dari staf resmi tidak akan ditoleransi dalam keadaan apa pun.",
    "2. Dilarang menyebarkan informasi atau konten rahasia apa pun dari platform ini.",
    "&nbsp;",
    "KERAHASIAAN",
  ],
  nonRuleDesc: [
    "1.1 Kreator Konten mengakui bahwa hubungan Kreator Konten dengan Klien adalah hubungan yang penuh dengan kepercayaan dan keyakinan dan bahwa selama Layanan Kreator Konten kepada Klien, kreator konten akan memiliki akses dan kontak dengan informasi tertentu (\"Informasi Rahasia\"). kreator konten tidak akan mengungkapkan Informasi Rahasia apa pun kepada orang atau entitas mana pun selain karyawan Klien atau menggunakannya untuk tujuan apa pun (selain dalam pelaksanaan Layanan) tanpa persetujuan tertulis dari petugas Klien, baik selama atau setelah Pengakhiran, kecuali dan hingga Informasi Rahasia tersebut menjadi pengetahuan umum tanpa kesalahan oleh Kreator Konten,",
    "1.2 Untuk tujuan Perjanjian ini, Informasi Rahasia berarti, sebagai ilustrasi dan tidak terbatas pada, semua informasi, baik secara tertulis maupun tidak, baik yang dapat dipatenkan maupun tidak dan baik yang tidak dapat dipatenkan maupun tidak, yang bersifat pribadi, rahasia atau rahasia, yang dimiliki, dikuasai, atau digunakan oleh Klien, terkait dengan bisnis Klien, hubungan bisnis, atau urusan keuangan Klien, termasuk, namun tidak terbatas pada, Penemuan apa pun, formula, informasi penjual, informasi pelanggan, peralatan, peralatan, rahasia dagang, proses, penelitian, laporan, data teknis atau penelitian, data klinis, pengetahuan, program komputer, perangkat lunak, dokumentasi perangkat lunak, desain perangkat keras, teknologi, produk, proses, metode, teknik, formula, senyawa, proyek, pengembangan, pemasaran atau rencana bisnis, perkiraan, laporan keuangan yang tidak dipublikasikan, anggaran, lisensi, harga, biaya, pelanggan, pemasok atau informasi personalia atau daftar karyawan yang dikomunikasikan kepada, dipelajari, dikembangkan atau diperoleh oleh Kreator Konten dalam proses Layanan Kreator Konten sebagai Kreator Konten untuk Klien.,",
    "1.3 Kewajiban kreator konten berdasarkan Bagian 6 ini tidak berlaku untuk informasi apa pun yang (i) diketahui oleh masyarakat umum dalam keadaan yang tidak melibatkan pelanggaran oleh kreator konten atau orang lain terhadap persyaratan Bagian 6 ini, (ii) secara umum diungkapkan kepada pihak ketiga oleh Klien tanpa batasan pada pihak ketiga tersebut, atau (iii) disetujui untuk dirilis dengan otorisasi tertulis dari seorang pejabat Klien.,",
    "1.4 Kreator Konten setuju bahwa semua file, dokumen, surat, memorandum, laporan, catatan, sketsa data, gambar, model, buku catatan laboratorium, daftar program, peralatan atau perangkat komputer, program komputer, atau materi tertulis, foto, atau materi berwujud lainnya yang berisi Informasi Rahasia, baik yang dibuat oleh kreator konten maupun pihak lain, yang akan berada di bawah pengawasan atau kepemilikan kreator konten, akan menjadi dan merupakan milik eksklusif Klien yang akan digunakan oleh kreator konten hanya dalam pelaksanaan tugas-tugas kreator konten untuk Klien dan tidak akan disalin atau dipindahkan dari tempat Klien kecuali untuk kepentingan bisnis Klien. Semua Materi atau salinannya dan semua properti berwujud dari Klien yang berada di bawah pengawasan atau kepemilikan kreator konten harus diserahkan kepada Klien, pada saat yang lebih awal dari (i) permintaan Klien atau (ii) pengakhiran Perjanjian ini. Setelah penyerahan tersebut, kreator konten tidak akan menyimpan Materi atau salinannya atau properti berwujud apa pun..,",
    "1.5 kreator konten setuju bahwa kewajiban kreator konten untuk tidak mengungkapkan atau menggunakan informasi dan Materi dengan jenis yang disebutkan pada ayat (2) dan (4) di atas, dan kewajiban kreator konten untuk mengembalikan Materi dan properti berwujud yang disebutkan pada ayat (4) di atas meluas ke jenis informasi, Materi, dan properti berwujud pelanggan Klien atau pemasok kepada Klien atau pihak ketiga lainnya yang mungkin telah mengungkapkan atau mempercayakan hal yang sama kepada Klien atau kreator konten.,",
    "1.6 kreator konten mengakui bahwa Klien dari waktu ke waktu mungkin memiliki perjanjian dengan orang lain atau dengan pemerintah, atau agensinya, yang membebankan kewajiban atau pembatasan kepada Klien terkait penemuan yang dibuat selama pekerjaan di bawah perjanjian tersebut atau terkait sifat rahasia pekerjaan tersebut. kreator konten setuju untuk terikat oleh semua kewajiban dan pembatasan tersebut yang diketahui oleh kreator konten dan untuk mengambil semua tindakan yang diperlukan untuk memenuhi kewajiban Klien berdasarkan perjanjian tersebut."
  ],
  nonSignText: "Saya telah membaca dan menyetujui Perjanjian Kerahasiaan",
  configBtn: "KONFIRMASI",
  completeTitle: "Tutorial Lengkap",
  finishTip: [
    "Selamat, Anda telah menyelesaikan tutorial!",
    "Selamat datang di Zona Kreator!"
  ],
  guideTask: {
    "1": "Menjadi Anggota Zona Kreator",
    "2": "Selesaikan Login",
    "3": "Pemberitahuan Kreator",
    "4": "Perjanjian Kerahasiaan",
    "5": "Mengisi informasi pribadi",
    "6": "Mengikat Akun Media Sosial",
    "7": "Penjelasan Tier Kreator ",
    "8": "Video Penyambutan",
    "9": "Bergabunglah dengan Saluran Discord Kreator",
  }
};

// 申请表单页
const applicationData = {
  applicationTitle: "Isi Formulir Pendaftaran",
  welcome: "Selamat datang",
  applicationDesc: [
    "Harap isi formulir aplikasi dengan informasi yang benar,",
    "Kamu akan mendapatkan akses ke Zona Kreator setelah permohonan disetujui!"
  ],
  mediaModeTitle: "1. Pilih Platform Media Sosial-mu",
  mediaModeDesc: "Setidaknya tautkan salah satu akun media sosialmu untuk memastikan bahwa itu milikmu.",
  mediaUploadText: "Unggah tangkapan layar dasbor saluranmu",
  createTypeTitle: "2. Jenis Konten apa yang kamu buat?",
  createTypeDesc: "Pilih setidaknya 1 dari hal berikut ini",
  mainGameTitle: "3. Pilih game utama-mu",
  mainGameDesc: [
    "Pilih salah satu dari game berikut yang kamu buat kontennya,",
    "kamu juga bisa menambahkan atau menyesuaikan game yang dipilih setelah menjadi anggota Zona Kreator"
  ],
  uidPlace: "UID milikmu (hanya angka)",
  userNamePlace: "Nama Pengguna Milikmu",
  verifiCodPlace: "Kode verifikasi",
  userDetailTitle: "Bantu kami untuk mengenalmu lebih baik lagi",
  userDetailSubTitle1: "Masukkan Tanggal Ulang Tahunmu",
  userDetailSubDesc1: "*Informasi ini tidak dapat diubah setelah permohonan disetujui",
  day: "Hari",
  month: "Bulan",
  year: "Tahun",
  userDetailSubTitle2: "Harap isi nama aslimu",
  firstName: "Nama depan",
  laseName: "Nama belakang",
  userDetailSubTitle3: "Bahasa",
  userDetailSubTitle4: "Negara atau Wilayah",
  userDetailSubTitle5: "Negara atau Wilayah",
  discordTitle: "ID Discord-mu",
  discordDesc: "Agar kami dapat menghubungi dan berkomunikasi denganmu",
  submitBtn: "KIRIM",
  confirm: "KONFIRMASI",
};

// 管理内页-导航
const adminNavData = {
  home: "HOME",
  gameFeed: "FEED GAME",
  artAssets: "ASET SENI",
  myContent: "KONTEN SAYA",
  uploadContent: "Unggah Konten",
  manageContent: "Kelola Konten",
  viewEarnings: "LIHAT PENGHASILAN",

  // m
  growth: "PERTUMBUHAN & PERKEMBANGAN",
};

// 管理内页-首页
const adminHomeData = {
  // 数据总浏
  overview: {
    title: "Ringkasan Konten",
    navItem1: "Ringkasan Data",
    navItem2: "Ringkasan Konten Bulanan",
    totalCount: "Jumlah Total Konten",
    totalView: "Total Dilihat",
    totalLike: "Total Suka",
    totalComments: "Total Komentar"
  },
  // 推送
  message: {
    more: "lainnya"
  },
  // 收益
  earning: {
    title: "Penghasilan Saya",
    item1Name: "Bulan",
    item2Name: "Hadiah Dalam Game",
    item3Name: "TUNAI",
    settionLoad: "Penyelesaian Dalam Proses"
  },
  userDetail: {
    welcome: "Selamat datang kembali ",
    timeText: "Kamu telah menjadi anggota di platform ini selama ",
    timeUnit1: "hari",
    timeUnit2: "hari",
  }
};

// 管理内页-素材推送
const artAssetsData = {
  artAssetsTitle: "ASET SENI",
  selectPlace: "Pilih berdasarkan Judul/Kata kunci",
  googleLinkBtn: "tautan google drive",
  request: "Permintaan untuk Mengunduh",
  download: "downloading",
  requested: "DIMINTA",
  noTip: "TIDAK ADA DATA"
};

// 管理内页-游戏资讯
const gameFeedData = {
  gameFeedTitle: "FEED GAME",
  selectPlace: "Pilih berdasarkan Judul/Kata kunci",
  noTip: "TIDAK ADA DATA"
};

// 管理内页-收益
const earningData = {
  earningTitle: "LIHAT PENGHASILAN",
  subTitle: "Pendapatan Bulanan",
  item1Name: "Bulan",
  item2Name: "Hadiah Dalam Game",
  item3Name: "TUNAI",
  rewards: "PENJELASAN TENTANG HADIAH",

  detailTitle1: "PENJELASAN TENTANG HADIAH",
  detailSubTitle1: "Daftar Video Milikmu",
  detailTimeTitle: "Periode Penyelesaian:",
  detailDesc: "Hanya video yang dipublikasikan selama periode ini yang akan dihitung.",
  noData: "Tidak ada data",
  detailRewards: "Hadiah Berlian Periode :",
  detailRewardsUnit: "DIAMOND",
  detailRewardsDescTitle: "Tanggal Penyelesaian:",
  detailRewardsDesc1_1: "Data penayangan berdasarkan data yang direkam oleh platform media sosial pada hari penyelesaian, jika kamu ragu mengenai detail hadiah ini, silakan hubungi kapten / layanan pelanggan di Discord.",
  detailRewardsDesc1_2: " ",
  detailRewardsDesc1_3: " ",
  detailUnit1: "Judul Konten",
  detailUnit2: "Dilihat",
  detailUnit3: "Tanggal Terbit",
  detailUnit4: "Saluran",
};

// 管理内页-上传
const upLoadContentData = {
  uploadTitle1: "UNGGAH KONTEN",
  upLoadPlace1: "Masukkan URL konten di sini",
  submitBtn: ["KONFIRMASI", "UNGGAH KONTEN", "SUBMIT"],
  time: "FORMAT WAKTU",
  rightNow: "SAAT INI",
  itemTitle1: "TOTAL DILIHAT",
  itemTitle2: "TOTAL SUKA",
  itemTitle3: "TOTAL KOMENTAR",
  step1: "LANGKAH 1: <span>Pilih Unggah Tugas Kreator atau Unggah Normal?</span>",
  step2: "LANGKAH 2: <span>MENGUNGGAH KONTEN</span>",
  taskDetail: "detail lebih lanjut",
  taskTitle: ["TEMA", "WAKTU", "HADIAH"],
  normalUpload: "Unggahan Konten Normal",
  taskEvent: "Event Pengajuan Saat Ini:",
  addUrl: "Tambahkan URL konten",
};

// 管理内页-视频管理
const uploadManageData = {
  title: "KELOLA KONTEN SAYA",
  selectPlace: "Pilih berdasarkan Judul/Kata kunci",
  eventPlace: "event pengiriman video",
  totalCount: "Jumlah Total Konten",
  totalView: "Total Dilihat",
  totalLike: "Total Suka",
  totalComments: "Total Komentar",
  delete: "HAPUS",
  sTime: "Tanggal mulai",
  eTime: "Tanggal akhir"
};

// 弹窗文案
const dialogText = {
  login: "LOGIN",
  enterYourEmail: "Masukkan Emailmu",
  enterYourPassword: "Masukkan Kata Sandi",
  password: "Kata sandi",
  forgotPassword: "LUPA KATA SANDI?",
  emailQuickLogin: "LOGIN CEPAT MELALUI EMAIL",
  signTip: "Belum punya akun?",
  signIn: "Daftar",
  codeTip: "Masukkan kode 6--digit",
  send: "KIRIM KODE",
  set: "BUAT KATA SANDI BARU",
  next: "BERIKUTNYA",
  setNew: "Silakan buat kata sandi baru",
  confirmPassword: "Konfirmasi Kata Sandi",
  confirm: "KONFIRMASI",
  allowAuthorize: "APAKAH MENGIZINKAN PENGAMBILAN DATA",
  allow: "IZINKAN",
  refuse: "TOLAK",
  applicationText1: "Permohonanmu telah diajukan,",
  applicationText2: "kamu akan menerima umpan balik melalui email dalam waktu 5 hari kerja.",
  verify: "VERIFIKASI KATA SANDI SAAT INI",
  signAgreement: "TANDA TANGAN PERJANJIAN",
  upLoadTip: "Unggah tangkapan layar dasbor saluranmu",
  save: "SIMPAN",
  setNewName: "BUAT NAMA PENGGUNA BARU",
  setNameNeed: "Nama dapat diubah setiap tiga bulan sekali",
  userNameSet: "Tentukan nama pengguna",
  userNameVerify: "Buat dalam 2-24 karakter",
  gameTag: "GAME TAG"
};

// IGame首页文案
const iGameHomeData = {
  enterYourEmail: "Masukkan Emailmu",
  password: "Kata sandi",
  forgotPassword: "LUPA KATA SANDI?",
  emailLogin: "LOGIN CEPAT MELALUI EMAIL",
  pwdLogin: "kembali menggunakan kata sandi",
  signUp1: "Tidak memiliki akun？",
  signUp2: "Daftar",
  send: "KIRIM KODE",
  codeTip: "Masukkan kode 6--digit",
  desc1: "Satu-satunya cara untuk berkolaborasi secara resmi dengan kami untuk mendapatkan intipan eksklusif, alat, dan dukungan untuk membuat konten terbaik!",
  desc2_1: "Dapatkan keuntungan eksklusif, kami akan memberimu alat yang diperlukan untuk membawa kreasi konten-mu jadi lebih baik!",
  desc2_2: "Terhubung dengan staf kami untuk dapatkan dukungan dan tips. Kami menyediakan bantuan untuk mengembangkan saluranmu dengan mengekspos konten-mu di platform resmi kami!",
  desc2_3: "Jadilah bagian dari Discord Kreator tepercaya kami, tempat di mana kamu bisa terhubung dengan kreator konten dari seluruh dunia!",
  tier1_1: "Undangan ke server Discord kreator konten video eksklusif kami.",
  tier1_2: "Sistem tiket eksklusif di server Discord creator untuk menerima bantuan langsung dari staff.",
  tier1_3: "Akses ke aset art resmi untuk membantumu membuat konten.",
  tier1_4: "Peluang untuk mendapatkan hadiah dalam game atau kompensasi uang.",
  tier2_1: "Semua keuntungan dari tier Apprentice.",
  tier2_2: "Peluang untuk membuat konten milikmu ditampilkan di platform media sosial resmi kami.",
  tier3_1: "Semua keuntungan dari tier sebelumnya.",
  tier3_2: "Kode hadiah terkustomisasi untuk giveaway bagi viewer.",
  tier3_3: "Peluang untuk diundang ke event AFK Journey resmi.",
  tier3_4: "Peluang untuk membuat konten bersama dengan Tim AFK Journey.",
  updateText: "Terakhir diperbarui"
};

// 提交申请表单成功
const signTip = "Selamat! Permohonan Anda untuk Zona Kreator telah disetujui. Harap tanda tangani perjanjian kreator yang dikirim ke email sebelum Anda bisa mendapatkan akses penuh ke platform kami.";

// 注册协议弹窗
const communityTip = [
  "1. Menghargai,",
  "Kami ingin menciptakan lingkungan yang ramah bagi semua orang. Kebebasan berpendapat sangat dihargai, tetapi bahasa yang menyinggung atau perilaku (pasif-)agresif dapat berujung pada peringatan yang pada akhirnya di-ban dari Zona Kreator.,",
  "2. Diskriminasi,",
  "Perundungan atau perilaku diskriminatif apa pun tidak diperbolehkan. Komentar yang merendahkan tentang hal-hal seperti ras, agama, budaya, orientasi seksual, jenis kelamin, atau identitas tidak akan ditoleransi dan dapat mengakibatkan ban dari Zona Kreator.,",
  "3. Perdagangan Akun,",
  "Dilarang menjual, memperdagangkan, atau memberikan boosting (berbayar) pada akun. Setiap Creator yang terlibat dalam jenis kegiatan ini dapat di-ban dari Zona Kreator.,",
  "4. Pornografi atau konten yang mengganggu",
  "Penyebaran konten pornografi atau konten yang mengganggu dapat mengakibatkan ban dari Zona Kreator.,",
  "5. Plagiarisme,",
  "Semua konten yang dibuat harus asli. Plagiarisme dapat mengakibatkan ban dari Zona Kreator.,",
  "6. Kata terakhir,",
  "Hanya karena sebuah peraturan tidak tercantum di sini, tidak berarti kami akan menoleransi peraturan tersebut dari para Creator. Lilith Games dan Farlight Games memiliki semua hak atas interpretasi akhir dari peraturan ini.",
];

export default {
  topNavData,
  footerData,
  homeData,
  signUpLanguageData,
  signData,
  informationData,
  guideData,
  applicationData,
  adminNavData,
  adminHomeData,
  artAssetsData,
  gameFeedData,
  earningData,
  upLoadContentData,
  uploadManageData,
  dialogText,
  iGameHomeData,
  signTip,
  communityTip
};