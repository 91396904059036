import React, { useContext, useEffect, useState } from "react";
import style from "./index.module.scss";
import { useNavigate } from "react-router-dom";
import Api from "@/common/Api/content";
import { rootContext, globalData } from "@/context/rootContext";
import { isFl84 } from "@/common/utils/envData";

function GameMessage() {

  const [messageList1, setMessageList1] = useState(null);
  const [messageList2, setMessageList2] = useState(null);
  const navigate = useNavigate();
  const { gameTab, token, globalLang, langState } = useContext(rootContext);

  const getArtData = async () => {
    const data = {
      token,
      gametag: globalData.activeGameData.tag,
      key: "",
      page: 1,
      limit: 5
    };
    const res = await Api.getArtAsset(data);
    if (res.status === 200 && res.data.items.length) {
      setMessageList2(res.data.items);
    }
  };

  const getGameFeed = async () => {
    const data = {
      token,
      gametag: globalData.activeGameData.tag,
      key: "",
      page: 1,
      limit: 5
    };
    const res = await Api.getGameFeed(data);
    setMessageList1(res.data.items);
  };

  const enterDetail = (path, id) => {
    navigate(path, {
      state: {
        activeId: id
      }
    });
  };

  useEffect(() => {
    if (token && gameTab && gameTab.length) {
      getArtData();
      getGameFeed();
    }
  }, [token, gameTab]);

  return (
    <div className={style.message_box}>
      <div className={style.message_item}>
        <div className={`message_title ${style.message_title}`}>
          <p>{isFl84 ? <>{langState.gameFeedData.gameFeedTitle2}</> : <>{langState.gameFeedData.gameFeedTitle}</>}</p>
          <span onClick={() => { window.gtag("event", "m_home_gamefeed_more"); navigate("/admin/growth/gamefeed"); }}>{langState.adminHomeData.message.more} &gt;</span>
        </div>
        <ul>
          {
            messageList1 && messageList1.length ? (
              messageList1.map((item, index) => {
                return (
                  <li key={index} onClick={() => { enterDetail(`/admin/growth/gamefeed/detail/${item.id}`, item.id); }}>
                    <p><span>{index + 1}.&nbsp;</span>{item.title}</p>
                    <span>{item.createdTime.slice(0, 10)}</span>
                  </li>
                );
              })
            ) : (<li className={`${style.none_message} none_message ${globalLang}`}>{langState.artAssetsData.noTip}</li>)
          }
        </ul>
      </div>
      <div className={style.message_item}>
        <div className={`message_title ${style.message_title}`}>
          <p>{langState.artAssetsData.artAssetsTitle}</p>
          <span onClick={() => { window.gtag("event", "m_home_artassets_more"); navigate("/admin/growth/artassets"); }}>{langState.adminHomeData.message.more} &gt;</span>
        </div>
        <ul>
          {
            messageList2 && messageList2.length ? (
              messageList2.map((item, index) => {
                return (
                  <li key={index} onClick={() => { enterDetail(`/admin/growth/artassets/detail/${item.id}`, item.id); }}>
                    <p><span>{index + 1}.&nbsp;</span>{item.title}</p>
                    <span>{item.createdTime.slice(0, 10)}</span>
                  </li>
                );
              })
            ) : (<li className={`${style.none_message} none_message ${globalLang}`}>{langState.artAssetsData.noTip}</li>)
          }
        </ul>
      </div>
    </div>
  );
}

const GameMessageMemo = React.memo(GameMessage);

export default GameMessageMemo;
