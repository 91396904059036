import React, { useContext, useEffect } from "react";
import { getRegionData } from "@/common/utils/reginUtil";
import { globalData, rootContext } from "@/context/rootContext";
import Api from "@/common/Api/accountManage";
import { locationTipMessage } from "@/common/utils";

let loadTime = 0;

// 定制化数据对应 assets/data/gameDataSK
function TwEnter(props) {
  const { setEnterRegion, setGlobalLang, loginStatus } = useContext(rootContext);

  const setRegionData = async () => {
    getRegionData("tw", false);
    setGlobalLang(globalData.lang);
    const res = await Api.getRegionData();
    if (res.status === 200) {
      globalData.regionConfigId = res.data.regionConfigId;
      globalData.region = res.data.abbreviation;
      setEnterRegion(res.data.abbreviation);
    } else if (loadTime++ < 5) {
      setTimeout(() => { () => setRegionData(); }, 1000);
    }
  };

  useEffect(() => {
    setRegionData();
    document.documentElement.classList.add("lang_zh-tw");
    document.title = "創作者專區";
    document.getElementsByName("description")[0].content = "找的就是你！你是靈感源源不絕不分享會死星人嗎？現在就加入《劍與遠征：啟程》創作者專區，滿足你的分享欲還有獎勵可以拿！";
    document.getElementsByName("Keywords")[0].content = "afk,劍與遠征,劍與遠征啟程,啟程,放置遊戲,實況主,直播,輕鬆放置";
  }, []);

  // 初始化登录数据
  useEffect(() => {
    // 加载区域数据
    if (!loginStatus.length) { return; } // 无数据 结束
    const regionData = loginStatus.filter(item => item.region.regionAbbreviation.toLowerCase() === "tw")[0];
    if (regionData) {
      getRegionData("tw", true);
      props.savaLoginState(regionData || loginStatus[0]);
    } else {
      locationTipMessage(400202);
    }
  }, [loginStatus]);

  return <>{props.children}</>;
}

export default TwEnter;