import React, { useContext, useEffect, useState } from "react";
import style from "./index.module.scss";
import { NavLink, useLocation } from "react-router-dom";
import { globalData, rootContext } from "@/context/rootContext";
import Api from "@/common/Api/message";

function AdminNav() {
  const location = useLocation();
  const { readTapIndex, setReadTapIndex, langState } = useContext(rootContext);
  const [activeNav, setActiveNav] = useState(-1);
  const navList = [
    { name: langState.adminNavData.home, path: "home" },
    { name: langState.adminNavData.growth, path: "growth" },
    { name: langState.adminNavData.artAssets, path: "artassets" },
    { name: langState.adminNavData.myContent, path: "mycontent" },
    { name: langState.adminNavData.viewEarnings, path: "viewearnings" }
  ];

  const swipetHeaderType = (_pathname) => {
    switch (true) {
      case _pathname.includes(navList[0].path):
        setActiveNav(0);
        break;
      case _pathname.includes(navList[1].path):
        setActiveNav(1);
        break;
      case _pathname.includes(navList[3].path):
        setActiveNav(3);
        break;
      case _pathname.includes(navList[4].path):
        setActiveNav(4);
        break;
      default: setActiveNav(-1);
    }
  };

  const readTapEven = async () => {
    if (readTapIndex[2] > 0) { return; }
    const res = await Api.checkedReminder("activity", globalData.activeGameData.tag);
    if (res.status === 200) {
      let newList = [...readTapIndex];
      newList[2] = 1;
      setReadTapIndex(newList);
    }
  };

  useEffect(() => {
    swipetHeaderType(location.pathname);
  }, [location]);

  const reSetPage = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className={`admin_nav ${style.admin_nav}`}>
      <ul onClick={reSetPage}>
        <li className={`${activeNav === 0 ? `active ${style.active}` : ""}`}><NavLink to="home">{langState.adminNavData.home}</NavLink></li>
        <li className={`${activeNav === 1 ? `active ${style.active}` : ""}`}>
          <NavLink to="growth">
            {langState.adminNavData.growth}
            { (readTapIndex[0] < 0 || readTapIndex[1] < 0) ? <i></i> : "" }
          </NavLink>
        </li>
        <li className={style.more} onClick={readTapEven}><NavLink to="upload"></NavLink>{ readTapIndex[2] < 0 ? <i></i> : "" }</li>
        <li className={`${activeNav === 3 ? `active ${style.active}` : ""}`}><NavLink to="mycontent">{langState.adminNavData.myContent}</NavLink></li>
        <li className={`${activeNav === 4 ? `active ${style.active}` : ""}`}><NavLink to="viewearnings">{langState.adminNavData.viewEarnings}</NavLink></li>
      </ul>
    </div>
  );
}

const AdminNavMemo = React.memo(AdminNav);

export default AdminNavMemo;
