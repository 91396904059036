import React from "react";
import tierStyle from "@/components/Dialog/TiersExplained/index.module.scss";
import salaryStyle from "@/components/Dialog/RewardsExplained/index.module.scss";

// COD-Fl84
const CODTierExplainedFl84 = () => (
  <div className={tierStyle.desc}>
    <p className={tierStyle.t1}>Summary:</p>
    <p>&nbsp;</p>
    <p>As a content creator, you will receive in-game rewards and cash, have the potential to have your content featured on our channels and in-game, access to the SEA Content Creator Discord Channel, exclusive sneak peeks, early update previews, and monetary compensation. </p>
    <p>&nbsp;</p>
    <p>Normal CSCC (Call of Dragons SEA Content Creator)</p>
    <p>Requirement:</p>
    <p>- Publish at least 2+ Call of Dragons related content in the last 30 days.</p>
    <p>Perks:</p>
    <p>- Access to the CSCC Discord Channel</p>
    <p>- Access to art assets</p>
    <p>- Official support</p>
    <p>- The possibility of receiving small in-game rewards or monetary compensation for your contributions</p>
    <p>&nbsp;</p>
    <p>Star CSCC</p>
    <p>Requirement:</p>
    <p>- Reach a large amount of subscribers/followers and have influence on the community.</p>
    <p>Perks:</p>
    <p>- All the benefits of normal CSCC members.</p>
    <p>- Exclusive content creator program/contract.</p>
    <p>- Official Promotion of your channel.</p>
  </div>
);

const CODExplainedFl84 = () => (
  <div className={salaryStyle.desc}>
    <p>1. Two types of rewards:</p>
    <p>Cash: Cash will be sent through a specific payment channel.</p>
    <p>CDK: CoD Community Team will be responsible for finalizing the list of qualified creators, submitting the application to the devs team and aligning the gift codes with these qualified creators.</p>
    <p>2. All views are calculated and based on natural and organic views shown on your channel. (Multiple platforms will be calculated separately)</p>
    <p>3. View tools (tools that increase views) are prohibited; we encourage free and natural creation. (If view tools are detected, you might not be able to get the reward according to the standard. )</p>
    <p>4. For CSCC who reaches Cash Reward tier, you must provide the Receiving Account to proceed with the reward sending. </p>
    <p>5. Calculating will be done and rewards be sent within the first 10 days of next month. (for example, Rewards for March will be issued between April 1st to 10th); Cash rewards within 15 days.</p>
    <p>6. The standard for long videos, streams and short videos will be calculated separately.</p>
  </div>
);

// 星级描述 东南亚区
const tierExplainedListFl84 = {
  COD: <CODTierExplainedFl84 />
};

// 收益解释 东南亚区
const explainedListFl84 = {
  COD: <CODExplainedFl84 />
};

// 收入提示 东南亚区
const earningTipFl84 = {
  COD: [
    "How do we determine tiers?",
    "We take into account several factors when determining tiers for our content creators, including things like average views in the recent period, subscriber count, editing skills, knowledge, presentation abilities, community likability, professionalism, cooperation and helpfulness on Call of Dragons projects. "
  ]
};

const ExtraApplyFl84 = () => {
  return (
    <p>
      *If you don&apos;t meet the above requirements, <br />
      please submit your application <a target="_blank" rel="noreferrer" onClick={() => { window.gtag("event", "fl84_extra_sign"); }} href="https://docs.google.com/forms/d/e/1FAIpQLSfRcP0ohfrLJtQKbPcnxxuiC1stmFnjNontGmJ-hfRClt45DA/viewform?usp=sf_link">here</a>
    </p>
  );
};

export const regionStoreFl84 = {
  tierExplainedList: tierExplainedListFl84,
  explainedList: explainedListFl84,
  earningTip: earningTipFl84,
  extraApplyLink: <ExtraApplyFl84 />,
  region: "farlight84"
};