import React, { useContext, useState } from "react";
import style from "./index.module.scss";

import Task from "@/views/m/Guide/Task";
import Schedule from "@/views/m/Guide/Schedule";

import { isPC } from "@/common/utils/envData";
import { rootContext } from "@/context/rootContext";

function AdminTask(props) {

  const [finishGuide, setFinishGuide] = useState(false);
  const { langState } = useContext(rootContext);


  const finTip = () => {
    setTimeout(() => {
      setFinishGuide(true);
    }, 2000);
    setTimeout(() => {
      props.endTask();
    }, 8000);
  };

  return (
    <div className={`${style.task_box} ${isPC ? "" : style.m}`}>
      <Schedule task1Active={true} task2Active={true} task3Active={true} />
      <Task finFun={finTip} />
      
      <div className={style.gudie_fin_box} style={{ display: finishGuide ? "block" : "none" }} >
        <div className={style.guide_fin_light}></div>
        <div className={style.gudie_fin_txt}>
          {langState.guideData.finishTip.map((item, index) => <p key={index}>{item}</p>)}
        </div>
      </div>
    </div>
  );
}

const AdminTaskMemo = React.memo(AdminTask);

export default AdminTaskMemo;