import React, { useState, useContext, useEffect, useMemo } from "react";
import { Outlet } from "react-router-dom";
import style from "./index.module.scss";

import Nav from "./AdminNav/index";
import AdminTask from"./AdminTask/index"; //九宫格任务

import Api from "@/common/Api/message";
import { rootContext, globalData } from "@/context/rootContext";
import { mainOfGame } from "@/common/utils/envData";
import filterGameData from "@/assets/data/gameData";

// 需要加载活动/任务管理页的区域游戏
// const activeContentGame = ["farlight84"]; // 全区域游戏通用
// const activeContentRegion = ["mult"]; // 全区域通用

function Admin() {

  const [isFin, setIsFin] = useState(true);
  const { guideTask, activeContentList, setActiveContentList, gameTab, activeGame, enterRegion, creatorData, readTapIndex } = useContext(rootContext);
  const blockTask = useMemo(() => filterGameData("blockTask"), [enterRegion, creatorData]);

  const getActiveList = async (gametag) => {
    const res = await Api.getActivitiesList({ gametag: globalData.activeGameData.tag });
    if (res && res.status === 200) {
      const newData = {};
      newData[gametag] = res.data;
      setActiveContentList({
        ...activeContentList,
        ...newData
      });
    }
  };

  // 获取 活动/标签 列表
  useEffect(() => {
    if (!gameTab.length) {
      return;
    }
    const key = mainOfGame ? `${globalData.activeGameData.tag}-${globalData.activeGameData.regionName}` : globalData.activeGameData.tag;
    if (activeContentList[key]) {
      return;
    }
    // const region = filterGameData("region");
    // if (activeContentRegion.includes(region) || activeContentGame.includes(key) || mainOfGame) {
    //   getActiveList(key);
    // }
    getActiveList(key);
  }, [activeContentList, activeGame, creatorData]);

  useEffect(() => {
    if (readTapIndex[2] && readTapIndex[2] < 0) {
      const key = mainOfGame ? `${globalData.activeGameData.tag}-${globalData.activeGameData.regionName}` : globalData.activeGameData.tag;
      getActiveList(key);
    }
  }, [readTapIndex]);

  useEffect(() => {
    if (guideTask.length) {
      const doneList = guideTask.map(item => item.done);
      if (doneList.reduce((total, item) => total + (item ? 1 : 0)) >= guideTask.length - blockTask.length) {
        setIsFin(true);
        return;
      }
      for (const item of guideTask) {
        if (!item.done) {
          setIsFin(false);
          return;
        }
      }
    }
  }, [guideTask]);

  return (
    <section>
      <div className={`admin_contain ${style.admin_contain}`}>
        { !isFin && <AdminTask endTask={() => { setIsFin(true); }} /> }
        <div className={style.admin_wrap}>
          <Outlet context={{ isFin }} />
        </div>
        <Nav />
      </div>
    </section>
  );
}

export default Admin;
