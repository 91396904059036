import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { isPC } from "./common/utils/envData";
import { BrowserRouter } from "react-router-dom";
// import { HashRouter } from "react-router-dom";

import GlobalContext from "@/context/globalContextBox";
import BaseRouter from "@/router";

import "@/style/normalize.css";
import "swiper/swiper-bundle.min.css";
import "@/style/global.scss";

// 初始化部分页面数据
function initPageBaseInfo () {
  // 适配M端新开页面
  if (!isPC) {
    const base = document.createElement("base");
    base.setAttribute("target", "_blank");
    document.head.append(base);
  }
}
initPageBaseInfo();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GlobalContext>
    <BrowserRouter>
      <Suspense>
        <BaseRouter />
      </Suspense>
    </BrowserRouter>
  </GlobalContext>
);
