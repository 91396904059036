import { mainOfGame, mainOfRegion } from "../utils/envData";
import instance from "./webApi";

/**
 * 社媒列表
 */
const getMediaList = async function() {
  return instance({
    url: "/common/filter/media",
    method: "GET"
  });
};

/**
 * 语言列表
 */
const getLanguageList = async function() {
  return instance({
    url: "/common/filter/language",
    method: "GET"
  });
};

/**
 * 游戏列表 { loginName, email, password }
 * retutrn { code: 200 }
 */
const gamaList = async function() {
  return instance({
    url: "/common/filter/games",
    method: "GET",
    extraParams: {
      needGameHost: mainOfRegion,
    }
  });
};

/**
 * 全区域游戏列表 获取discord列表
 */
const regionGameList = async function() {
  return instance({
    url: "/c/region/games",
    method: "GET"
  });
};

/**
 * 创作者定位列表
 */
const getActivePosList = async function() {
  return instance({
    url: "/common/filter/pos",
    method: "GET"
  });
};

/**
 * 活动/任务标签列表
 */
const getActivitiesList = async function(data) {
  return instance({
    url: "/c/activities",
    method: "GET",
    headers: {
      gametag: data.gametag
    },
    extraParams: {
      needRegionId: mainOfGame,
    }
  });
};

/**
 * 修改参加的活动任务
 */
const exchangeActivityTask = async function(gametag, data) {
  return instance({
    url: "/c/activities/bindContent",
    method: "PUT",
    headers: {
      gametag
    },
    params: data
  });
};

/**
 * 查询创作者基本信息
 */
const getCreatorProfile = async function(gameTag, regionId) {
  const params = {};
  if (gameTag && regionId) {
    params.gameTag = gameTag;
    params.regionId = regionId;
  }
  return instance({
    url: "/c/account/profile",
    method: "GET",
    params
  });
};

/**
 * 查询签署协议模板列表
 */
const getSignList = async function() {
  return instance({
    url: "/c/protocol",
    method: "GET"
  });
};

/**
 * 签署协议
 */
const singRule = async function(data) {
  return instance({
    url: "/c/protocol/sign",
    method: "POST",
    headers: {
      gametag: data.gametag
    },
    data: {
      protocolIds: data.protocolIds
    },
    extraParams: {
      needRegionId: mainOfGame,
    }
  });
};

/**
 * 新手任务情况
 */
const guideTask = async function() {
  return instance({
    url: "/c/account/myTask",
    method: "GET"
  });
};

/**
 * 完成任务
 */
const finishTask = async function(data) {
  return instance({
    url: `/c/account/doTask/${data.id}`,
    method: "POST"
  });
};

/**
 * 收入统计
 */
const getIncomeData = async function(data) {
  return instance({
    url: "/c/wage/ss",
    method: "GET",
    headers: {
      gametag: data.gametag
    },
    params: {
      month: data.month || null
    },
    extraParams: {
      needRegionId: mainOfGame,
    }
  });
};

/**
 * 收入明细
 */
const getRewardDetails = async function(billDate) {
  return instance({
    url: "/c/wage/week",
    method: "GET",
    headers: {
      gametag: "farlight84"
    },
    params: {
      billDate
    }
  });
};

/**
 * 查询待办事项
 */
const getTodo = async function() {
  return instance({
    url: "/c/todo",
    method: "GET",
    extraParams: {
      needRegionId: mainOfGame,
    }
  });
};

/**
 * 阅读代办事项
 */
const readTodo = async function(data) {
  return instance({
    url: `/c/todo/${data.id}`,
    method: "PUT"
  });
};

/**
 * 更新discoord点击状态
 */
const clickDiscordLink = async function(data) {
  return instance({
    url: "/c/account/joinDiscord",
    method: "PUT",
    headers: {
      gametag: data.gametag
    }
  });
};

/**
 * 查询是否有未触发的新素材
 */
const checkReminder = async function(target, gametag) {
  return instance({
    url: "/c/reminder/show",
    method: "GET",
    headers: {
      gametag: gametag
    },
    params: {
      target
    }
  });
};

/**
 * 触发新素材已读取
 */
const checkedReminder = async function(target, gametag) {
  return instance({
    url: "/c/reminder/read",
    method: "POST",
    headers: {
      gametag: gametag
    },
    params: {
      target
    }
  });
};

export default {
  getMediaList,
  gamaList,
  getCreatorProfile,
  getSignList,
  singRule,
  getIncomeData,
  getTodo,
  readTodo,
  guideTask,
  finishTask,
  getActivePosList,
  getLanguageList,
  getActivitiesList,
  clickDiscordLink,
  exchangeActivityTask,
  regionGameList,
  getRewardDetails,
  checkReminder,
  checkedReminder
};