import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import style from "../index.module.scss";
import detailStyle from "@/views/m/Admin/Detail/index.module.scss";
import ClipboardJS from "clipboard";
import Api from "@/common/Api/content";
import messageApi from "@/common/Api/message";
// import bindApi from "@/common/Api/bindCreator";
import { rootContext, globalData } from "@/context/rootContext";
import { mainOfGame } from "@/common/utils/envData";
import { message, Pagination, DatePicker, Radio, Select, Spin } from "antd";
import { Abbreviation, formatMediaName, locationTipMessage } from "@/common/utils";

function ManageContent() {
  const {
    langState, gameTab, globalLang,
    ableMedia, setAbleMedia,
    refreshVideo, setRefreshVideo,
    refreshVideoTime, setRefreshVideoTime, activeContentList } = useContext(rootContext);
  const navigate = useNavigate();
  const [key, setKey] = useState("");
  const [current, setCurrent] = useState(1);
  const [contentCurrent, setContentCurrent] = useState({});
  const [selectTime, setSelectTime] = useState("");
  const [selectTime1, setSelectTime1] = useState(null);
  const [selectTime2, setSelectTime2] = useState(null);
  const [total, setTotal] = useState(-1);
  const [list, setList] = useState([]);
  const [choseMedia, setChoseMedia] = useState("");
  const [activityList, setActivityList] = useState([]);
  const [checkActivity, setCheckActivity] = useState(null);
  const [activeDetail, setActiveDetail] = useState({});
  const [showDetail, setShowDetail] = useState(false);
  const [activityIdList, setActivityIdList] = useState([]);
  const limit = 5;

  const clipboard = new ClipboardJS(".copy_line");


  const getData = async (item) => {
    if (!ableMedia.length) {
      return;
    }
    const data = {
      publishTimeRange: selectTime || null,
      key,
      page: current,
      limit,
      mTag: choseMedia || ableMedia.join(","),
      ...item
    };
    if (checkActivity) {
      data.activityId = +checkActivity;
    } else {
      data.activityId = null;
    }
    console.log(data);
    const res = await Api.getContentList({ gametag: globalData.activeGameData.tag, data });
    if (res.status === 200) {
      for (item of res.data.items) {
        item.snapshot = item.snapshot ? item.snapshot.split(",") : "";
      }
      setTotal(res.data.meta.totalItems);
      setList(res.data.items);
    } else {
      setTotal(0);
    }
  };

  const getMonthContent = async (m_mTag) => {
    const publishTimeRange = selectTime || null;
    const tag = m_mTag || choseMedia || ableMedia.join(",");
    const res = await Api.getMediaData(tag, publishTimeRange, globalData.activeGameData.tag, +checkActivity || null);
    if (res && res.status === 200) {
      setContentCurrent(res.data);
    }
  };

  const refreshAllVideo = async () => {
    if (checkLastRefresh()) {
      setRefreshVideo(true);
      locationTipMessage(200020, "info");
      await Api.updateAllContent();
      await getData();
      setRefreshVideo(false);

      window.gtag("event", "refresh_all_video");
    }
  };

  const checkLastRefresh = () => {
    if (!refreshVideoTime) {
      setRefreshVideoTime(new Date().getTime());
      return true;
    }
    if (new Date().getTime() - refreshVideoTime <= 60000) {
      locationTipMessage(400106, "warn");
      return false;
    }
    setRefreshVideoTime(new Date().getTime());
    return true;
  };

  const deleteData = async id => {
    window.gtag("event", "m_manage_delete");
    const res = await Api.deleteContent(globalData.activeGameData.tag.gameTab, id);
    if (res.status === 200) {
      locationTipMessage(200027, "success");
      setTimeout(() => {
        if (current > 1 && list.length === 1) {
          setCurrent(current - 1);
        } else {
          getData();
        }
      }, 1800);
    }
  };

  const dateChange1 = date => {
    setSelectTime1(date);
  };

  const dateChange2 = date => {
    setSelectTime2(date);
  };

  const getMediaList = async () => {
    const res = await messageApi.getMediaList();
    if (res.status === 200) {
      const mediaList = res.data.map(item => item.name.toLowerCase()).filter(item => formatMediaName(item));
      setAbleMedia(mediaList);
    }
  };

  const handleMediaChange = (item) => {
    const newTag = item.target.value;
    if (newTag !== choseMedia) {
      setChoseMedia(newTag);
      getMonthContent(newTag);
      if (current !== 1) {
        setCurrent(1);
      } else {
        getData({
          mTag: newTag
        });
      }
    }
  };

  // 初始化活动/任务列表
  const initActivityList = (list) => {
    const option1 = list.map(item => ({
      value: item.id,
      label: item.name,
      data: item
    }));
    const option2 = option1.map(item => item.value);
    setActivityList(option1);
    setActivityIdList(option2);
  };

  // 切换选中的活动/任务
  const changeCheckActivity = (value) => {
    setCheckActivity(value || null);
  };

  // 查看活动详情
  const chackActivityDetail = (item) => {
    const id = item.activityId;
    const data = activityList.filter(item => item.value === id)[0];
    setActiveDetail(data.data);
    setShowDetail(true);
  };

  // 修改绑定的活动/任务
  const exchangeActivity = async (value, index) => {
    const res = await messageApi.exchangeActivityTask(globalData.activeGameData.tag, { activityId: value, contentId: list[index].id });
    console.log(res);
    if (res.status === 200) {
      const newList = [...list];
      newList[index].activityId = value;
      setList([...newList]);
      message.success("Change Success");
    } else {
      message.error(res.data.message);
    }
  };

  useEffect(() => {
    let selectDate = "";
    if (selectTime1 && selectTime2) {
      const diff = selectTime1.diff(selectTime2);
      if (diff > 0) {
        locationTipMessage(200008);
        return;
      }
      selectDate = [selectTime1.format("YYYY-MM-DD"), selectTime2.format("YYYY-MM-DD")].join(",");
    }
    setSelectTime(selectDate);
    if (current === 1) {
      getData({
        publishTimeRange: selectDate
      });
    } else {
      setCurrent(1);
    }
  }, [selectTime1, selectTime2]);

  useEffect(() => {
    getMonthContent();
  }, [selectTime, checkActivity]);

  useEffect(() => {
    if (gameTab.length && ableMedia.length) {
      getData();
    }
  }, [current, checkActivity, gameTab, ableMedia]);

  useEffect(() => {
    clipboard.on("success", function (e) {
      locationTipMessage(200035, "success");
      e.clearSelection();
    });

    clipboard.on("error", function () {
      message.error("Copy Failed");
    });

    return () => clipboard.destroy();
  }, [clipboard]);

  useEffect(() => {
    if (!ableMedia.length) {
      getMediaList();
    } else {
      setChoseMedia(ableMedia.join(","));
    }
  }, [ableMedia]);

  // 获取活动/任务列表
  useEffect(() => {
    const key = mainOfGame ? `${globalData.activeGameData.tag}-${globalData.activeGameData.regionName}` : globalData.activeGameData.tag;
    const list = activeContentList[key];
    initActivityList(list || []);
  }, [activeContentList]);

  const keyDown1 = (e) => {
    if (e.keyCode === 13) { getData(); }
  };

  const selectData = () => {
    if (current !== 1) {
      setCurrent(1);
    } else {
      getData();
    }
  };


  return (
    !showDetail ? (
      <>
        <div className={`manage_content ${style.manage_content}`}>
          <div className={style.title1}>
            <span>{langState.uploadManageData.title}</span>
            <div className={style.query}>
              <input value={key} onChange={e => setKey(e.target.value)} onKeyDown={keyDown1} placeholder={langState.uploadManageData.selectPlace}></input>
              <i onClick={selectData}></i>
            </div>
            <div className={`${style.date_select} ${style.date_select_between}`}>
              <DatePicker inputReadOnly onChange={dateChange1} value={selectTime1} />
              <span>to</span>
              <DatePicker inputReadOnly onChange={dateChange2} value={selectTime2} />
              <Select
                options={activityList}
                style={{ width: "2.3rem", marginLeft: "0.1rem" }}
                size="large"
                allowClear={true}
                placeholder={langState.uploadManageData.eventPlace}
                value={checkActivity}
                onChange={(value) => changeCheckActivity(value)}
              ></Select>
              {
                checkActivity && (
                  <div className={style.clear} onClick={() => { changeCheckActivity(null); }}>
                    <span className="ant-select-clear">
                      <span role="img" className="anticon anticon-close-circle">
                        <svg
                          viewBox="64 64 896 896"
                          focusable="false"
                          data-icon="close-circle"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 01-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z"></path>
                        </svg>
                      </span>
                    </span>
                  </div>
                )
              }
            </div>
          </div>
          <div className={style.media_list}>
            <Radio.Group size="default" value={choseMedia} onChange={handleMediaChange}>
              <Radio.Button value={ableMedia.join(",")}>ALL</Radio.Button>
              {
                ableMedia.map(item => {
                  return (
                    <Radio.Button key={item} value={item}>{formatMediaName(item)}</Radio.Button>
                  );
                })
              }
            </Radio.Group>
            <div className={`${style.update} ${refreshVideo ? style.on : ""}`} onClick={refreshAllVideo}></div>
          </div>
          <div className={style.content_data}>
            <ul className={style.data_list}>
              <li>
                <p className={`content_subtitle ${style.content_subtitle}`}>{langState.uploadManageData.totalCount}</p>
                <p className={style.num}>{(contentCurrent.contents && Abbreviation(+contentCurrent.contents)) || 0}</p>
              </li>
              <li>
                <p className={`content_subtitle ${style.content_subtitle}`}>{langState.uploadManageData.totalView}</p>
                <p className={style.num}>{(contentCurrent.viewCount && Abbreviation(+contentCurrent.viewCount)) || 0}</p>
              </li>
              <li>
                <p className={`content_subtitle ${style.content_subtitle}`}>{langState.uploadManageData.totalLike}</p>
                <p className={style.num}>{(contentCurrent.starCount && Abbreviation(+contentCurrent.starCount)) || 0}</p>
              </li>
              <li>
                <p className={`content_subtitle ${style.content_subtitle}`}>{langState.uploadManageData.totalComments}</p>
                <p className={style.num}>{(contentCurrent.commentCount && Abbreviation(+contentCurrent.commentCount)) || 0}</p>
              </li>
            </ul>
          </div>
          <ul className={style.manage_list}>
            {
              list.map((item, index) => {
                return (
                  <li key={index}>
                    <div className={style.img_box}>
                      <img src={item.snapshot && (item.snapshot.length ? item.snapshot[0] : item.snapshot)}></img>
                    </div>
                    <p className={`${style.title} ${"copy_line"}`} data-clipboard-text={item.contentUrl || ""}>{item.title || " "}</p>
                    <p className={style.pushTime}>{item.pushTime && item.pushTime.slice(0, 10)}</p>
                    {
                      item.activityId && activityIdList.includes(item.activityId) && (
                        <div className={style.select_box} onClick={() => chackActivityDetail(item)}>
                          <Select
                            showArrow={false}
                            open={false}
                            options={activityList}
                            size="large"
                            style={{ width: "1.8rem" }}
                            placeholder={item.activityId ? "" : "Select video submission event"}
                            value={item.activityId || null}
                            onChange={(value) => exchangeActivity(value, index)}
                          ></Select>
                        </div>
                      )
                    }
                    <p className={style.desc}>
                      <span>{item.createTime && item.createTime.slice(0, 10)}</span>
                      {
                        item.mTag === "twitch" && (
                          <>
                            <span><i className={style.view}></i>{item.viewCount}</span>
                            <span><i className={style.like}></i>—</span>
                            <span><i className={style.comm}></i>—</span>
                          </>
                        )
                      }
                      {
                        item.mTag === "youtube" && (
                          <>
                            <span>{item.viewCount} views</span>
                            <span><i className={style.youtube_like}></i>{item.starCount}</span>
                            <span><i className={style.comm}></i>{item.commentCount}</span>
                          </>
                        )
                      }
                      {
                        item.mTag === "tiktok" && (
                          <>
                            <span><i className={style.view}></i>{item.viewCount}</span>
                            <span><i className={style.like}></i>{item.starCount}</span>
                            <span><i className={style.comm}></i>{item.commentCount}</span>
                          </>
                        )
                      }
                    </p>
                    <div className={`manage_more ${style.more}`}>
                      <div className={style.more_item}>
                        <div onClick={() => { deleteData(item.id); }}>{langState.uploadManageData.delete}</div>
                      </div>
                    </div>
                  </li>
                );
              })
            }
          </ul>
          {
            !list.length && total >= 0 && (
              <div className={`${style.upLoad_tip} upLoad_tip ${globalLang}`} onClick={() => { navigate("/admin/upload"); }}></div>
            )
          }
          {
            total < 0 && (
              <div className={style.load_box}>
                <Spin size="large" />
              </div>
            )
          }
          <div className={style.page}>
            <Pagination
              size="small"
              total={total}
              defaultPageSize={limit}
              defaultCurrent={1}
              current={current}
              showSizeChanger={false}
              onChange={(page) => setCurrent(page)}
            />
          </div>
        </div>
      </>
    ) : (
      <>
        <ContentInformation data={activeDetail} back={() => { setShowDetail(false); }} />
      </>
    )

  );
}

function ContentInformation(props) {
  return (
    <div className={`${detailStyle.detail_box} ${style.detail_box}`}>
      <div className={detailStyle.back} onClick={props.back}></div>
      <p className={detailStyle.title}>{props.data.name || ""}</p>
      <p className={detailStyle.title}>{props.data.startTime.slice(0, 10)} - {props.data.endTime.slice(0, 10)}</p>
      <div className={detailStyle.contain} dangerouslySetInnerHTML={{ __html: props.data.desc || "" }}></div>
    </div>
  );
}

export default ManageContent;
