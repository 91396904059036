// 环境参数整合

// PC环境判断
export const isPC = !/iPhone|iPad|iPod|Android/ig.test(navigator.userAgent);

// 游戏专区判断
// FL84
export const isFl84 = window.location.host.indexOf("fl84") > -1 || window.location.href.split("#")[0].indexOf("fl84") > -1;
export const fl84RegionId = 6;

// 区域专区判断
// 欧美
export const isAfkjourney = window.location.host.indexOf("afkjourney") > -1 || window.location.href.split("#")[0].indexOf("afkjourney") > -1;
// 东南亚专区
export const isSea = window.location.host.indexOf("sea") > -1 || window.location.href.split("#")[0].indexOf("sea") > -1;
// 日
export const isJP = window.location.host.indexOf("jp") > -1 || window.location.href.split("#")[0].indexOf("jp") > -1 || window.location.host.indexOf("ja") > -1 || window.location.href.split("#")[0].indexOf("ja") > -1;
// 韩
export const isSK = window.location.host.indexOf("kr") > -1 || window.location.href.split("#")[0].indexOf("kr") > -1;
// 台
export const isTW = window.location.host.indexOf("ori") > -1 || window.location.href.split("#")[0].indexOf("ori") > -1;

// 是否以游戏为主体
export const mainOfGame = isFl84;

// 是否为独立区域站点
export const mainOfRegion = isAfkjourney || isSea || isJP || isSK || isTW;

// 预定义区域参数
export const preInitRegion = mainOfGame ?
  (
    (isFl84 && "farlight84") ||
    (isAfkjourney && "en") ||
    (isSea && "sea") ||
    (isJP && "ja") ||
    (isSK && "sk") ||
    (isTW && "tw") ||
    (null)
  )
  : null;

// 是否为测试/本地环境
const locationHost = window.location.host;
export const isDev = locationHost.indexOf("dev.test.gdinsight.com") > -1 || locationHost.indexOf("localhost") > -1 || locationHost.indexOf("127.0.0.1") > -1;


// 多区域下 检测区域登录数据混淆 (避免测试环境登录错误)
export const checkRegionLoginData = (preLoginRegion) => {
  if (!mainOfRegion) { return preLoginRegion === "en" || preLoginRegion === "sea" || preLoginRegion === "mult"; } // 非区域特区
  if (isSea) { return preLoginRegion === "sea"; }
  if (isAfkjourney) { return preLoginRegion === "en"; }
  if (isJP) { return preLoginRegion === "jp" || preLoginRegion === "ja"; }
  if (isJP) { return preLoginRegion === "jp" || preLoginRegion === "ja"; }
  if (isSK) { return preLoginRegion === "sk" || preLoginRegion === "kr"; }
  if (isTW) { return preLoginRegion === "tw"; }
  return true;
};