import React, { useState, useEffect, useContext, useMemo } from "react";
import style from "./index.module.scss";

import RewardDetails from "./rewardDetails";

import Api from "@/common/Api/message";
import { rootContext, globalData } from "@/context/rootContext";
import { defaultErrorMessage, getWeekDay } from "@/common/utils";
import { DatePicker, Modal } from "antd";
import { isDev } from "@/common/utils/envData";
import filterGameData from "@/assets/data/gameData";
import moment from "moment";

let thisDate = getWeekDay(3);

function Fl84ViewEarnings() {

  const [show2, setShow2] = useState(false);
  const [resources, setResources] = useState("");
  const [cash, setCash] = useState(0);
  const [month, setMonth] = useState("");
  const { creatorData, gameTab, setDialogIndex, setTipContent, activeGame, langState } = useContext(rootContext);
  const [chooseMonth, setChooseMonth] = useState("");
  const [showDetail, setShowDetail] = useState(false);
  const [rewardData, setRewardData] = useState(null);
  const filterEarningTip = useMemo(() => filterGameData("earningTip") || [], [activeGame, creatorData]);

  const getTipDom = () => {
    if (gameTab.length) {
      const data = filterEarningTip;
      let tipData = Array.isArray(data) ? data : data[globalData.lang];
      const dom = `
        <div>
          ${tipData.map((item) => `<p class="reward_text">${item}</p>`).join("")}
        </div>
      `;
      setTipContent(dom);
      setDialogIndex(3);
    }
  };

  const getData = async () => {
    const res = await Api.getIncomeData({
      gametag: globalData.activeGameData.tag,
      month: chooseMonth || thisDate
    });
    if (res.status === 200) {
      if (moment(chooseMonth) < moment("2023-09-06")) {
        setResources("NO DATA");
      } else {
        setResources(res.data.other || res.data.resources || (res.data.resources === null && langState.earningData.settionLoad) || "0");
      }
      setCash(+res.data.cash || 0);
      setMonth(chooseMonth || thisDate);
    } else {
      defaultErrorMessage(res, "earnings");
    }
  };

  const getReward = async (openPage = false) => {
    const res = await Api.getRewardDetails(chooseMonth || thisDate);
    if (res && res.status === 200) {
      setRewardData(res.data);
      if (openPage) {
        setShowDetail(true);
      }
    } else {
      defaultErrorMessage(res, "Reward Details");
    }
  };

  const showDetailPage = () => {
    if (isDev && resources === langState.earningData.settionLoad) {
      Modal.info({
        title: "TIP",
        content: langState.earningData.settionLoad
      });
      return;
    }
    if (rewardData) {
      setShowDetail(true);
    } else {
      getReward(true);
    }
  };

  useEffect(() => {
    if (creatorData) {
      getData();
      getReward();
    }
  }, [creatorData]);

  useEffect(() => {
    if (chooseMonth) {
      if (chooseMonth === month) { return; }
      getData();
      getReward();
      setRewardData(null);
    }
  }, [chooseMonth]);

  return showDetail ? <RewardDetails back={() => { setShowDetail(false); }} setTime={chooseMonth || thisDate} data={rewardData} /> : (
    <div className={`earnings_content ${style.earnings_content}`}>
      <p className={style.title1}>{langState.earningData.earningTitle}</p>
      <div className={style.date_select}>
        <MonthRange choose={setChooseMonth} />
      </div>
      <p className={style.title1}>
        {langState.earningData.subTitle}
        <i className={style.question} onClick={getTipDom}>?</i>
        <i className={`${style.hide} ${show2 ? style.active : ""}`} onClick={() => { window.gtag("event", "m_earning_show"); setShow2(!show2); }}></i>
      </p>
      <ul>
        <li>
          <p className={style.total_earnings_subtitle}>{langState.earningData.fl84Item1Name}</p>
          <p className={style.num}>
            {month}
          </p>
        </li>
        <li>
          <p className={style.total_earnings_subtitle}>{langState.earningData.item2Name}</p>
          <p className={style.none}>{resources}</p>
        </li>
        <li>
          <p className={style.total_earnings_subtitle}>{langState.earningData.item3Name}</p>
          <p className={style.num}>
            {show2 ? cash : "****"}
            <span className={style.subtxt2} style={{ display: show2 ? "inline" : "none" }}>$</span>
          </p>
        </li>
      </ul>
      <br /><br />
      <p className={style.tip} onClick={showDetailPage}><span>{langState.earningData.fl84Rewards}</span></p>
    </div>
  );
}

const openTime = new Date("2023-09-05");

class MonthRange extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startValue: null,
      endValue: null,
      endOpen: false,
    };
    this.chooseMonth = props.choose;
  }

  resetData = (current) => {
    const t = current.format("YYYY-MM-DD");
    thisDate = t;
    this.chooseMonth(t);
  };

  render() {
    const disabledDate = current => {
      // Can not select days before today and today
      return (current && (current > new Date()) || current.day() !== 3) || current < openTime;
    };
    const formatFun = current => {
      return current.format("YYYY-MM-DD");
    };
    return (
      <div>
        <DatePicker defaultValue={moment(thisDate)} picker="date" onChange={this.resetData} format={formatFun} disabledDate={disabledDate} />
      </div>
    );
  }
}

export default Fl84ViewEarnings;
