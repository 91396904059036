import React, { useContext, useEffect } from "react";
import { getRegionData } from "@/common/utils/reginUtil";
import { globalData, rootContext } from "@/context/rootContext";
import Api from "@/common/Api/accountManage";
import { locationTipMessage } from "@/common/utils";


let loadTime = 0;

// 定制化数据对应 assets/data/gameDataEn
function AfkjourneyEnter(props) {
  const { setEnterRegion, setGlobalLang, loginStatus } = useContext(rootContext);

  const setRegionData = async () => {
    getRegionData("en", false);
    setGlobalLang(globalData.lang);
    const res = await Api.getRegionData();
    if (res.status === 200) {
      globalData.regionConfigId = res.data.regionConfigId;
      globalData.region = res.data.abbreviation;
      setEnterRegion(res.data.abbreviation);
    } else if (loadTime++ < 5) {
      setTimeout(() => { () => setRegionData(); }, 1000);
    }
  };

  useEffect(() => {
    setRegionData();
  }, []);

  // 初始化登录数据
  useEffect(() => {
    // 加载区域数据
    if (!loginStatus.length) { return; } // 无数据 结束
    const regionData = loginStatus.filter(item => item.region.regionAbbreviation.toLowerCase() === "en")[0];
    if (regionData) {
      props.savaLoginState(regionData || loginStatus[0]);
      getRegionData("en", true);
    } else {
      locationTipMessage(400202);
    }
  }, [loginStatus]);

  return <>{props.children}</>;
}

export default AfkjourneyEnter;