import React, { useContext, useRef, useState, useEffect } from "react";
import PubSub from "pubsub-js";
import style from "./index.module.scss";

import Api from "@/common/Api/content";
import bindApi from "@/common/Api/bindCreator";
import { rootContext, globalData } from "@/context/rootContext";
import { locationUtil, Abbreviation, formatMediaName, fl84Check, locationTipMessage } from "@/common/utils";
import { Modal } from "antd";
import LoadMask from "@/components/loadMask";
import errorCode from "@/assets/data/errorCode";

// let loadNum = 0;
// let preFormId = 0;

function MediaData(props) {
  const { setDialogIndex, langState } = useContext(rootContext);
  const [isConfirm, setIsConfirm] = useState(false);
  const [checkError, setCheckError] = useState(false);
  const [showLoad, setShowLoad] = useState(false);
  // const [formId, setFormId] = useState("");
  const [mediaData, setMediaData] = useState(null);
  

  const urlRef = useRef();

  const dealAnalyzeData = (data, accessToken) => {
    const url = urlRef.current.value.trim();
    const result = data.data;
    const videData = {
      title: result.title,
      snapshot: result.snapshot,
      contentUrl: result.contentUrl || url,
      gameTag: globalData.activeGameData.tag,
      viewCount: result.viewCount,
      starCount: result.starCount,
      commentCount: result.commentCount,
      autoParse: false,
      subTitle: result.subTitle,
      mTag: result.mTag,
      videoId: result.videoId,
      publishTime: result.publishTime
    };
    if (accessToken) {
      videData.accessToken = accessToken;
    }
    if (videData.mTag.includes("youtube") || videData.mTag.includes("twitch")) {
      videData.uid = result.uid;
    }
    if (!checkMedia(videData.mTag)) {
      // 传入错误平台视频
      return;
    }
    // videData.uid = result.uid;
    setCheckError(!fl84Check(videData.title));
    if (!fl84Check(videData.title)) {
      videData.errFlag = true;
    }
    checkVideoBelone(url);
    setMediaData(videData);
    setIsConfirm(true);
    props.saveMedia(val => {
      return {
        ...val,
        [result.mTag]: videData
      };
    });
  };

  const checkMedia = (media) => {
    if (props.platform[props.index].name === media) {
      return true;
    } else {
      Modal.error({
        title: "ERROR",
        content: `Please upload a ${formatMediaName(props.platform[props.index].name)} video`
      });
      return false;
    }
  };

  const submitMedia = () => {
    const url = urlRef.current.value.trim();
    if (!url) {
      locationTipMessage(400210);
      return;
    }
    if (new RegExp("[\\u4E00-\\u9FFF]+", "g").test(url)) {
      locationTipMessage(400236);
      return;
    }
    const isAllow = allowAnalyze();
    if (!isAllow) {
      setDialogIndex(4);
      return;
    }
    // if (url.includes("tiktok")) {
    //   tiktokAuthorize();
    // } else {
    //   analyzeURL();
    // }
    analyzeURL();
  };

  const analyzeURL = async () => {
    const url = urlRef.current.value.trim();
    if (!url) { return; }
    if (new RegExp("[\\u4E00-\\u9FFF]+", "g").test(url)) {
      locationTipMessage(400236);
      return;
    }
    const data = {
      url,
      auto: 0,
      gametag: globalData.activeGameData.tag
    };
    setShowLoad(true);
    const res = await Api.analyzeURL(data);
    setShowLoad(false);
    if (res && res.status === 200) {
      dealAnalyzeData(res);
    } else {
      Modal.error({
        title: "ERROR",
        content: (res.data && res.data.message) || "Data cannot be retrieved. Please select to manually enter data."
      });
    }
  };

  const allowAnalyze = () => {
    const lastAllow = locationUtil.getLocationStorage("authorize");
    if (lastAllow && lastAllow.time && new Date().toLocaleString().slice(0, 8) === lastAllow.time.slice(0, 8)) {
      return true;
    } else {
      return false;
    }
  };

  // const tiktokAuthorize = () => {
  //   const randomNum = [0, 0, 0].map(() => Math.floor(Math.random() * 10)).join("");
  //   const formId = `${new Date().getTime() % 1E3}${randomNum}`;
  //   setFormId(formId);
  //   getMediaURL(formId);
  // };

  // const analyzeTikTok = async (accessToken) => {
  //   const url = urlRef.current.value.trim();
  //   if (new RegExp("[\\u4E00-\\u9FFF]+", "g").test(url)) {
  //     locationTipMessage(400236);
  //     return;
  //   }
  //   const data = {
  //     url,
  //     gametag: globalData.activeGameData.tag,
  //     auto: 0,
  //     tikTokAccessToken: accessToken
  //   };
  //   setShowLoad(true);
  //   const res = await Api.analyzeURL(data);
  //   setShowLoad(false);
  //   if (res && res.status === 200) {
  //     dealAnalyzeData(res, accessToken);
  //   } else {
  //     Modal.error({
  //       title: "Error",
  //       content: (res.data && res.data.message) || "Data cannot be retrieved. Please select to manually enter data."
  //     });
  //   }
  // };

  // const getMediaUseData = async () => {
  //   if (preFormId === formId) {
  //     return;
  //   }
  //   const res = await bindApi.getBindMediaData({
  //     formId: formId
  //   });
  //   if (res && res.status === 200 && res.data) {
  //     refreshTikTokToken(res.data.refreshToken);
  //     preFormId = formId;
  //   } else if (loadNum++ < 5) {
  //     setTimeout(getMediaUseData, 1000);
  //   } else {
  //     defaultErrorMessage(res, "mediaData");
  //     setShowLoad(false);
  //   }
  // };

  // const refreshTikTokToken = async (refreshToken) => {
  //   const res = await Api.refreshTikTokToken(refreshToken);
  //   // console.log(res);
  //   if (res.status === 200) {
  //     analyzeTikTok(res.data.accessToken);
  //   } else {
  //     defaultErrorMessage(res, "tiktokToken");
  //     setShowLoad(false);
  //   }
  // };

  const checkVideoBelone = async (contentUrl) => {
    const { platform, index } = props;
    const item = platform[index];
    const formId1 = item.formId;
    if (!formId1) { return; } // step1未授权 不继续校验 等待后续处理
    const res = await bindApi.getBindMediaData({ formId: formId1 });
    if (res.status !== 200) { return; } // 获取失败 不继续校验 等待后续处理
    const bindMediaData = {
      tag: item.name.toLowerCase(),
      nickName: "",
      followers: 0,
      prove: item.prove,
      ...res.data
    };
    item.bindMediaData = bindMediaData;
    const checkData = bindMediaData.tag === "tiktok" ? {
      // tiktokToken: bindMediaData.accessToken,
      homePageUrl: bindMediaData.channelUrl,
      contentUrl
    } : {
      uid: bindMediaData.uid,
      contentUrl
    };
    const res2 = await Api.checkVideoBelong(globalData.activeGameData.tag, checkData);
    if (res2.status === 200 && !res2.data.isOwn) {
      Modal.error({
        title: "ERROR",
        content: errorCode(400217)
      });
    } else {
      props.setPlatform([...props.platform]);
    }
    return; // 默认等待后续处理
  };

  // const setPlatFormSataus = () => {
  //   setTimeout(() => { getMediaUseData(); }, 1000);
  //   setShowLoad(true);
  // };

  // const getMediaURL = async (formId) => {
  //   const res = await bindApi.getMediaURL({
  //     mInfoTag: "tiktok",
  //     formId: formId
  //   });
  //   if (res.status === 200) {
  //     loadNum = 0;
  //     Modal.info({
  //       title: "TIP",
  //       content: (
  //         <div>
  //           <p>{errorCode(200024)}</p>
  //         </div>
  //       ),
  //       onOk() { },
  //     });
  //     setTimeout(() => {
  //       const enter = document.getElementsByClassName("ant-btn-primary")[0];
  //       const a = document.createElement("a");
  //       a.setAttribute("href", res.data);
  //       a.setAttribute("target", "_blank");
  //       a.setAttribute("rel", "opener");
  //       enter.appendChild(a);
  //     }, 100);
  //   } else {
  //     defaultErrorMessage(res, "mediaData");
  //   }
  // };

  const cleanMediaData = () => {
    setIsConfirm(false);
    setCheckError(false);
    setShowLoad(false);
    setMediaData({});
  };

  useEffect(() => {
    allowAnalyze();
    PubSub.subscribe("allowAuthorize", () => {
      const url = urlRef.current.value.trim();
      // if (url.includes("tiktok")) {
      //   tiktokAuthorize();
      // } else {
      //   analyzeURL();
      // }
      analyzeURL(url);
    });
  }, []);

  // useEffect(() => {
  //   const messageCallback = setPlatFormSataus;
  //   window.addEventListener("message", () => {
  //     if (!formId) {
  //       return;
  //     }
  //     messageCallback();
  //   });
  //   return () => { window.removeEventListener("message", messageCallback); };
  // }, [formId]);

  return (
    <div className={style.platform_mediaData} style={{ display: props.platform[props.index].isActive ? "block" : "none" }}>
      <div className={style.step1}><span>02</span></div>
      <p className={style.mediaDataT1} dangerouslySetInnerHTML={{ __html: langState.applicationData.mediaUploadVideoTitle }} ></p>
      <p className={style.desc1} dangerouslySetInnerHTML={{ __html: langState.applicationData.mediaUploadVideoDesc }} ></p>
      <div className={style.input_box}>
        <input placeholder={ langState.applicationData.mediaUploadPlace } ref={urlRef} />
        {
          !isConfirm && <a className={style.confirm} onClick={submitMedia}>{langState.applicationData.confirm}</a>
        }
      </div>
      {
        checkError && (
          <p className={style.tip}>
            <i></i>
            We have detected that the { formatMediaName(mediaData.mTag || "") } video you submitted does not meet the requirements,<br /> and this channel will not be approved.
          </p>
        )
      }
      {
        isConfirm && (
          <div className={style.meidaData}>
            <a className={style.clean} onClick={cleanMediaData}></a>
            <img src={mediaData.snapshot} />
            <p className={style.title}>{mediaData.title}</p>
            <p className={style.time}>TIME STAMP: {mediaData.publishTime.slice(0, 10)}</p>
            <ul>
              <li>
                <p>TOTAL VIEWS</p>
                <p>{Abbreviation(+mediaData.viewCount)}</p>
              </li>
              <li>
                <p>TOTAL LIKES</p>
                <p>{Abbreviation(+mediaData.starCount)}</p>
              </li>
              <li>
                <p>TOTAL COMMENTS</p>
                <p>{Abbreviation(+mediaData.commentCount)}</p>
              </li>
            </ul>
          </div>
        )
      }
      {
        showLoad && <LoadMask />
      }
    </div>
  );
}

export default MediaData;