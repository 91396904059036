import React, { useContext } from "react";

import { rootContext, globalData } from "@/context/rootContext";
import { mainOfGame } from "@/common/utils/envData";

import DefaultManageContent from "./DefaultManageContent";
import ActiveManageContent from "./ActivitiesManageContent";


function ManageContent() {
  const { activeContentList } = useContext(rootContext);
  const key = mainOfGame ? `${globalData.activeGameData.tag}-${globalData.activeGameData.regionName}` : globalData.activeGameData.tag;
  const isActive = Array.isArray(activeContentList[key]) && activeContentList[key].filter(item => +item.enable).length > 0;
  return isActive ? <ActiveManageContent /> : <DefaultManageContent />;
}

export default ManageContent;
