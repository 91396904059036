// 所有数据会进入assets/data/pageData进行过滤
// 越南语

// 顶部nav
const topNavData = {
  signUp: "ĐĂNG KÝ",
  logIn: "ĐĂNG NHẬP",
  application: "ĐƠN YÊU CẦU GIA NHẬP",
  home: "TRANG CHỦ",
  back: "Quay Lại",
  profile: "Xem hồ sơ",
  contact: "Liên Hệ với CHÚNG TÔI",
  logOut: "Đăng Xuất"
};

// 底部版权
const footerData = {
  service: "ĐIỀU KHOẢN DỊCH VỤ",
  policy: "CHÍNH SÁCH QUYỀN RIÊNG TƯ",
  footerText: "Creator Zone là nền tảng sáng tạo nội dung chính thức do Lilith & Farlight Games sáng lập và phát triển.",
};

// 首页文案
const homeData = {
};

// 注册页-选择地区
const signUpLanguageData = {
  title: "Hành Trình của Bạn - Bắt Đầu tại Đây",
  desc: "Ngôn ngữ bạn dùng để sáng tạo nội dung"
};

// 注册页
const signData = {
  title: "Hành Trình của Bạn - Bắt Đầu tại Đây",
  title2: "ĐĂNG KÝ",
  desc1: "Để trở thành thành viên của Creator Zone, ",
  desc2: "vui lòng đọc các yêu cầu sau",
  signRule1_1: "Tôn trọng ",
  signRule1_link: "nguyên tắc cộng đồng ",
  signRule1_2: "của chúng tôi và cư xử thân thiện với Nhà Sáng Tạo cũng như các Quản Lý Viên Cộng Đồng khác.",
  signRule2: "Phải từ 18 tuổi trở lên.",
  signOtherText1: "*Nếu bạn không đáp ứng các yêu cầu trên,",
  signOtherText2: "vui lòng gửi đơn đăng ký tại",
  signOtherLink: "đây",
  signPlace1: "Nhập email của bạn",
  signPlace2: "Đặt Tên Người Dùng",
  signPlace3: "Đặt Mật Khẩu",
  signNeed1: "Mật khẩu của bạn phải có:",
  signNeed2: "8 đến 20 ký tự",
  signNeed3: "Chữ cái, Chữ số và các Ký hiệu đặc biệt",
  signNeed4_1: "Bạn đã đọc",
  signNeed4_2: "Điều Khoản Dịch Vụ",
  signNeed4_3: "và",
  signNeed4_4: "Chính Sách Quyền Riêng",
  signNeed4_5: "Tư của Creator Zone.",
  accept: "Chấp nhận",
  next: "TIẾP THEO",
  send: "GỬI MÃ",
  codeTip: "Nhập mã gồm 6 chữ số",
  accountTip1: "Bạn chưa có tài khoản?",
  accountTip2: "Đăng nhập",
};

// 个人信息页
const informationData = {
  title1: "Sửa thông tin tài khoản",
  accountTitle: "Thông tin tài khoản",
  mediaTitle: "Trang mạng xã hội",
  gameTitle: "Game",
  birthdayTitle: "Ngày Sinh",
  realNameTitle: "Tên thật",
  countryTitle: "Quốc Gia hoặc Khu Vực",
  languageTitle: "Ngôn Ngữ",
  discordTitle: "ID Discord của bạn",
  discordDesc: "Nhằm mục đích liên hệ và trao đổi với bạn",
};

// 欢迎/引导页
const guideData = {
  welcomeTitle: ["Chào mừng bạn đến với", "Creator Zone!"],
  commRuleTitle: "Quy Tắc Cộng Đồng",
  commRuleDesc: [
    "1. Tôn trọng,",
    "Chúng tôi mong muốn tạo ra một môi trường thân thiện dành cho tất cả mọi người. Chúng tôi tôn trọng quyền tự do ngôn luận của mỗi cá nhân, nhưng các ngôn từ mang tính xúc phạm hoặc những hành vi hung hăng (tiêu cực) có thể bị cảnh cáo, và mức độ hình phạt cao nhất là bị cấm tham gia Creator Zone.",
    "2. Phân biệt đối xử,",
    "Nghiêm cấm các hành vi bắt nạt hoặc mọi kiểu hành vi phân biệt đối xử. Chúng tôi tuyệt đối không chấp nhận các bình luận mang tính hạ nhục liên quan đến chủng tộc, tôn giáo, văn hóa, khuynh hướng tính dục, giới tính hoặc bản dạng giới. Các hành vi này có thể sẽ bị cấm tham gia Creator Zone.",
    "3. Giao Dịch Tài Khoản,",
    "Nghiêm cấm các dịch vụ buôn bán, giao dịch hoặc boost tài khoản (có trả phí). Bất kỳ Nhà Sáng Tạo nào có liên quan đến những loại hoạt động này đều có thể bị cấm tham gia Creator Zone.",
    "4. Nội dung khiêu dâm hoặc gây phiền toái,",
    "Hành vi phân phối các nội dung khiêu dâm hoặc gây phiền toái bất kỳ đều có thể khiến nhà sáng tạo bị cấm tham gia Creator Zone.,",
    "5. Đạo văn/Sao chép,",
    "Tất cả nội dung được tạo phải là tác phẩm gốc, hành vi đạo văn có thể khiến nhà sáng tạo bị cấm tham gia Creator Zone.",
    "6. Lời Cuối,",
    "Chỉ vì một quy tắc không được liệt kê ở đây không đồng nghĩa rằng Nhà Sáng Tạo của chúng tôi được phép làm điều đó. Lilith Games và Farlight Games bảo lưu mọi quyền đối với cách giải thích cuối cùng về các quy tắc này.",
  ],
  agreeBtn: "TÔI ĐỒNG Ý",
  commRuleTip: "Vui lòng đọc hết Quy Tắc Cộng Đồng",
  nonRuleTitle: "Thỏa Thuận Không Tiết Lộ",
  nonRuleTitle2: "CHÍNH SÁCH QUYỀN RIÊNG TƯ",
  nonRuleDescSub: [
    "1. Tất cả nội dung sáng tạo, bản xem trước, bản thử nghiệm và mọi nội dung khác được chúng tôi cung cấp qua nền tảng đều được coi là tài liệu mật cho đến thời điểm phát hành chính thức. Hành vi tiết lộ hoặc trình chiếu bất kỳ tài liệu nào khi chưa có sự cho phép từ nhân viên chính thức sẽ không được chấp nhận trong mọi trường hợp.",
    "2. Nghiêm cấm chia sẻ bất kỳ thông tin hoặc nội dung bí mật nào của nền tảng này.",
    "&nbsp;",
    "BẢO MẬT",
  ],
  nonRuleDesc: [
    "1.1 Nhà Sáng Tạo Nội Dung xác nhận rằng mối quan hệ giữa Nhà Sáng Tạo Nội Dung và Khách Hàng là mối quan hệ có độ tin cậy và bảo mật cao, đồng thời trong quá trình cung cấp Dịch Vụ của Nhà Sáng Tạo Nội Dung cho Khách Hàng, Nhà Sáng Tạo Nội Dung sẽ có quyền truy cập và tiếp xúc với một số thông tin nhất định (\"Thông Tin Mật\"). Nhà Sáng Tạo Nội Dung không được tiết lộ bất kỳ Thông Tin Mật nào cho bất kỳ cá nhân hoặc tổ chức nào ngoài nhân viên của Khách Hàng hoặc sử dụng thông tin đó cho bất kỳ mục đích nào (ngoài mục đích thực hiện Dịch Vụ) khi chưa có văn bản phê duyệt từ đại diện Khách Hàng, trong hoặc sau khi Chấm Dứt Thỏa Thuận, trừ khi và cho đến khi Thông Tin Mật đó được công khai mà không phải do lỗi từ Nhà Sáng Tạo Nội Dung.",
    "1.2 Theo mục đích của Thỏa Thuận này, Thông Tin Mật dưới dạng hình minh họa và không giới hạn ở tất cả mọi dạng thông tin - bất kể bằng văn bản hay không, có được cấp bằng sáng chế hay không, và có bản quyền hay không - đều có tính chất riêng tư, bí mật hoặc tuyệt mật. Những tính chất này do Khách Hàng chiếm dụng, sở hữu hoặc sử dụng trong các tình huống liên quan đến hoạt động kinh doanh, quan hệ kinh doanh hoặc giao dịch tài chính của Khách Hàng - bao gồm nhưng không giới hạn ở mọi loại Sáng Chế, công thức, thông tin nhà cung cấp, thông tin khách hàng, đồ dùng, thiết bị, bí mật thương mại, quy trình, nghiên cứu, báo cáo, dữ liệu kỹ thuật hoặc nghiên cứu, dữ liệu lâm sàng, bí quyết, chương trình máy tính, phần mềm, tài liệu phần mềm, thiết kế phần cứng, công nghệ, sản phẩm, quy trình, phương pháp, kỹ thuật, công thức, tổ hợp, dự án, thành tích, kế hoạch tiếp thị hoặc kinh doanh, dự báo, báo cáo tài chính chưa công bố, ngân sách, giấy phép , giá cả, chi phí, thông tin về khách hàng, nhà cung cấp hoặc nhân sự hoặc danh sách nhân viên liên hệ với Nhà Sáng Tạo Nội Dung, bài học, thành tựu hoặc mọi thông tin thu được trong quá trình sử dụng Dịch Vụ của Nhà Sáng Tạo Nội Dung với tư cách là nhà sáng tạo nội dung cho Khách Hàng.,",
    "1.3 Nghĩa vụ của Nhà Sáng Tạo Nội Dung theo Mục 6 này sẽ không áp dụng cho bất kỳ thông tin nào (i) được công khai rộng rãi trong các trường hợp không liên quan đến hành vi Nhà Sáng Tạo Nội Dung hoặc những người khác vi phạm điều khoản tại Mục 6 này, (ii) được Khách Hàng tiết lộ chung cho các bên thứ ba và không áp đặt hạn chế đối với các bên thứ ba đó, hoặc (iii) được đại diện Khách Hàng ban hành văn bản cho phép để được phát hành.,",
    "1.4 Nhà Sáng Tạo Nội Dung đồng ý rằng: tất cả tập tin, tài liệu, thư từ, bản ghi nhớ, báo cáo, hồ sơ, phác thảo dữ liệu, bản vẽ, mô hình, sổ ghi chép trong phòng thí nghiệm, danh sách chương trình, trang thiết bị máy tính, chương trình máy tính hoặc tài liệu bằng văn bản, ảnh hoặc tài liệu hữu hình khác có chứa Thông Tin Mật - bất kể do Nhà Sáng Tạo Nội Dung tạo ra hay do người khác sáng tạo nhưng thuộc quyền lưu giữ hoặc sở hữu của Nhà Sáng Tạo Nội Dung - đều là tài sản độc quyền của Khách Hàng và Nhà Sáng Tạo Nội Dung chỉ sử dụng để thực hiện nghĩa vụ của Nhà Sáng Tạo Nội Dung đối với Khách Hàng và không được sao chép hoặc đưa ra khỏi cơ sở của Khách Hàng, ngoại trừ các hành động phục vụ mục đích kinh doanh của Khách Hàng. Tất cả Tài Liệu hoặc bản sao đó và tất cả tài sản hữu hình của Khách Hàng do Nhà Sáng Tạo Nội Dung lưu giữ hoặc sở hữu sẽ được giao cho Khách Hàng theo (i) yêu cầu của Khách Hàng hoặc (ii) quyết định chấm dứt Thỏa Thuận này. Sau khi giao tài liệu, Nhà Sáng Tạo Nội Dung không được giữ lại bất kỳ Tài Liệu hoặc bản sao nào hay mọi tài sản hữu hình bất kỳ nêu trên.,",
    "1.5 Nhà Sáng Tạo Nội Dung đồng ý rằng Nhà Sáng Tạo Nội Dung có nghĩa vụ không tiết lộ hoặc sử dụng thông tin và Tài Liệu thuộc các loại đã nêu trong đoạn (2) và (4) ở trên, đồng thời, nghĩa vụ của Nhà Sáng Tạo Nội Dung là gửi lại Tài Liệu và tài sản hữu hình đã nêu trong đoạn (4) ở trên với phạm vi mở rộng đến các loại thông tin, Tài Liệu và tài sản hữu hình cho khách của Khách Hàng, hoặc nhà cung cấp của Khách Hàng, hoặc các bên thứ ba khác có thể đã được biết hoặc được ủy thác thông tin tương tự cho Khách Hàng hoặc Nhà Sáng Tạo Nội Dung.,",
    "1.6 Nhà Sáng Tạo Nội Dung xác nhận rằng Khách Hàng - tùy từng thời điểm, có thể lập thỏa thuận với người khác, hoặc với chính phủ, hoặc với các cơ sở của Khách Hàng - để áp đặt nghĩa vụ hoặc hạn chế đối với Khách Hàng về các tác phẩm được tạo ra trong quá trình làm việc theo các thỏa thuận đó hoặc liên quan đến tính chất bí mật của những tác phẩm đó. Nhà Sáng Tạo Nội Dung đồng ý tuân thủ tất cả các nghĩa vụ và hạn chế mà Nhà Sáng Tạo Nội Dung đã biết và thực hiện mọi hành động cần thiết để thực hiện nghĩa vụ của Khách Hàng theo các thỏa thuận đó."
  ],
  nonSignText: "Tôi đã đọc và đồng ý với Thỏa Thuận Không Tiết Lộ",
  configBtn: "XÁC NHẬN",
  completeTitle: "Hoàn Thành Hướng Dẫn",
  finishTip: [
    "Chúc mừng bạn đã hoàn thành hướng dẫn!",
    "Chào mừng đến Creator Zone!"
  ],
  guideTask: {
    "1": "Trở Thành Thành Viên của Creator Zone",
    "2": "Hoàn Tất Đăng Nhập",
    "3": "Thông Báo của Nhà Sáng Tạo",
    "4": "Thỏa Thuận Không Tiết Lộ",
    "5": "Điền đầy đủ thông tin cá nhân",
    "6": "Liên Kết Tài Khoản Mạng Xã Hội",
    "7": "Giải Thích về Bậc của Nhà Sáng Tạo ",
    "8": "Video Chào Mừng",
    "9": "Tham gia Kênh Discord dành cho Nhà Sáng Tạo",
  }
};

// 申请表单页
const applicationData = {
  applicationTitle: "Vui Lòng Điền Thông Tin vào Đơn Đăng Ký",
  welcome: "Chào mừng",
  applicationDesc: [
    "Vui lòng điền thông tin đáng tin cậy vào đơn,",
    "Bạn sẽ có quyền truy cập vào Creator Zone của chúng tôi sau khi đơn đăng ký của bạn được duyệt!"
  ],
  mediaModeTitle: "1. Chọn Nền Tảng Mạng Xã Hội của Bạn",
  mediaModeDesc: "vui lòng liên kết với ít nhất một trong các tài khoản mạng xã hội của bạn để xác minh đó là tài khoản của bạn.",
  mediaUploadText: "Tải lên một tấm ảnh chụp màn hình trang tổng quan kênh của bạn",
  createTypeTitle: "2. Bạn tạo loại Nội Dung nào?",
  createTypeDesc: "Chọn ít nhất 1 trong các lựa chọn sau",
  mainGameTitle: "3. Chọn trò chơi chính của bạn",
  mainGameDesc: [
    "Vui lòng chọn một trong những trò chơi ở bên dưới, đó nên là trò chơi mà bạn dựa vào để sáng tạo nội dung,",
    "bạn cũng có thể thêm hoặc điều chỉnh trò chơi đã chọn sau khi nhận được tư cách thành viên của Creator Zone"
  ],
  uidPlace: "UID của bạn (chỉ nhập số)",
  userNamePlace: "Tên Người Dùng của Bạn",
  verifiCodPlace: "Mã xác thực",
  userDetailTitle: "Hãy để chúng tôi hiểu rõ hơn về bạn",
  userDetailSubTitle1: "Nhập Ngày Sinh của Bạn",
  userDetailSubDesc1: "*Bạn không thể điều chỉnh lại thông tin này sau khi đơn đăng ký của bạn được phê duyệt",
  day: "Ngày",
  month: "Tháng",
  year: "Năm",
  userDetailSubTitle2: "Vui lòng điền tên thật của bạn",
  firstName: "Tên",
  laseName: "Họ",
  userDetailSubTitle3: "Ngôn Ngữ",
  userDetailSubTitle4: "Quốc Gia hoặc Khu Vực",
  userDetailSubTitle5: "Quốc Gia hoặc Khu Vực",
  discordTitle: "ID Discord của bạn",
  discordDesc: "Nhằm mục đích liên hệ và trao đổi với bạn",
  submitBtn: "GỬI",
  confirm: "XÁC NHẬN",
};

// 管理内页-导航
const adminNavData = {
  home: "TRANG CHỦ",
  gameFeed: "BẢNG TIN",
  artAssets: "NỘI DUNG NGHỆ THUẬT",
  myContent: "NỘI DUNG CỦA TÔI",
  uploadContent: "Tải Lên Nội Dung",
  manageContent: "Quản Lý Nội Dung",
  viewEarnings: "XEM THU NHẬP",

  // m
  growth: "TĂNG TRƯỞNG & PHÁT TRIỂN",
};

// 管理内页-首页
const adminHomeData = {
  // 数据总浏
  overview: {
    title: "Tổng Quan về Nội Dung",
    navItem1: "Tổng Quan về Dữ Liệu",
    navItem2: "Tổng Quan về Nội Dung Hằng Tháng",
    totalCount: "Tổng Số Nội Dung",
    totalView: "Tổng Lượt Xem",
    totalLike: "Tổng Lượt Thích",
    totalComments: "Tổng Bình Luận"
  },
  // 推送
  message: {
    more: "thêm"
  },
  // 收益
  earning: {
    title: "Thu Nhập Của Tôi",
    item1Name: "Tháng",
    item2Name: "Phần Thưởng Trong Trò Chơi",
    item3Name: "TIỀN MẶT",

    settionLoad: "Quá Trình Xử Lý Đang Được Tiến Hành"
  },
  userDetail: {
    welcome: "Chào mừng quay trở lại",
    timeText: "Bạn đã là thành viên trên nền tảng này được",
    timeUnit1: "ngày",
    timeUnit2: "ngày",
  }
};

// 管理内页-素材推送
const artAssetsData = {
  artAssetsTitle: "NỘI DUNG NGHỆ THUẬT",
  selectPlace: "Chọn theo Tiêu Đề/Từ Khóa",
  googleLinkBtn: "liên kết google drive",
  request: "Yêu Cầu Bản Tải Xuống",
  download: "downloading",
  requested: "ĐÃ YÊU CẦU",
  noTip: "KHÔNG CÓ DỮ LIỆU"
};

// 管理内页-游戏资讯
const gameFeedData = {
  gameFeedTitle: "BẢNG TIN TRÒ CHƠI",
  selectPlace: "Chọn theo Tiêu Đề/Từ Khóa",
  noTip: "KHÔNG CÓ DỮ LIỆU",
};

// 管理内页-收益
const earningData = {
  earningTitle: "XEM THU NHẬP",
  subTitle: "Thu nhập hàng tháng",
  item1Name: "Tháng",
  item2Name: "Phần Thưởng Trong Trò Chơi",
  item3Name: "TIỀN MẶT",
  rewards: "GIẢI THÍCH VỀ PHẦN THƯỞNG",

  detailTitle1: "GIẢI THÍCH VỀ PHẦN THƯỞNG",
  detailSubTitle1: "Danh Sách Video Của Bạn",
  detailTimeTitle: "Kỳ Thanh Toán:",
  detailDesc: "Chỉ những video được phát hành trong thời gian này mới được tính.",
  noData: "Không có dữ liệu",
  detailRewards: "Phần Thưởng Kim Cương Theo Kỳ:",
  detailRewardsUnit: "KIM CƯƠNG",
  detailRewardsDescTitle: "Ngày Thanh Toán:",
  detailRewardsDesc1_1: "Dữ liệu về lượt xem dựa trên dữ liệu do nền tảng mạng xã hội ghi lại vào ngày thanh toán. Nếu bạn có thắc mắc về thông tin chi tiết của phần thưởng này, vui lòng liên hệ với đội trưởng / dịch vụ khách hàng trên Discord.",
  detailRewardsDesc1_2: " ",
  detailRewardsDesc1_3: " ",
  detailUnit1: "Tiêu Đề Nội Dung",
  detailUnit2: "Lượt Xem",
  detailUnit3: "Ngày Đăng Tải",
  detailUnit4: "Kênh",
};


// 管理内页-上传
const upLoadContentData = {
  uploadTitle1: "TẢI NỘI DUNG LÊN",
  upLoadPlace1: "Chèn URL nội dung ở đây",
  submitBtn: ["CONFIRM", "UPLOAD CONTENT", "SUBMIT"],
  time: "BIỂU THỊ THỜI GIAN:",
  rightNow: "NGAY LẬP TỨC",
  itemTitle1: "TỔNG LƯỢT XEM",
  itemTitle2: "TỔNG LƯỢT THÍCH",
  itemTitle3: "TỔNG BÌNH LUẬN",
  step1: "BƯỚC 1: <span>Chọn Nhiệm Vụ Nhà Sáng Tạo hay Tải Lên Thông Thường?</span>",
  step2: "BƯỚC 2: <span>TẢI NỘI DUNG LÊN</span>",
  taskDetail: "thêm chi tiết",
  taskTitle: ["CHỦ ĐỀ", "THỜI GIAN", "PHẦN THƯỞNG"],
  normalUpload: "Tải Lên Nội Dung Thông Thường",
  taskEvent: "Sự Kiện Dự Thi Hiện Tại:",
  addUrl: "Thêm URL nội dung",
};

// 管理内页-视频管理
const uploadManageData = {
  title: "QUẢN LÝ NỘI DUNG CỦA TÔI",
  selectPlace: "Chọn theo Tiêu Đề/Từ Khóa",
  eventPlace: "sự kiện dự thi bằng video",
  totalCount: "Tổng Số Nội Dung",
  totalView: "Tổng Lượt Xem",
  totalLike: "Tổng Lượt Thích",
  totalComments: "Tổng Bình Luận",
  delete: "XÓA",
  sTime: "Ngày bắt đầu",
  eTime: "Ngày hoàn thành"
};


// 弹窗文案
const dialogText = {
  login: "ĐĂNG NHẬP",
  enterYourEmail: "Nhập Email của Bạn",
  enterYourPassword: "Nhập Mật Khẩu Của Bạn",
  password: "Mật khẩu",
  forgotPassword: "QUÊN MẬT KHẨU?",
  emailQuickLogin: "ĐĂNG NHẬP NHANH BẰNG EMAIL",
  signTip: "Bạn chưa có tài khoản?",
  signIn: "Đăng ký",
  codeTip: "Nhập mã gồm 6 chữ số",
  send: "GỬI MÃ",
  set: "ĐẶT MẬT KHẨU MỚI",
  next: "TIẾP THEO",
  setNew: "Vui lòng đặt mật khẩu mới",
  confirmPassword: "Xác Nhận Mật Khẩu",
  confirm: "XÁC NHẬN",
  allowAuthorize: "CHO PHÉP TÌM NẠP DỮ LIỆU HAY KHÔNG",
  allow: "CHO PHÉP",
  refuse: "TỪ CHỐI",
  applicationText1: "Đơn đăng ký của bạn đã được gửi. ",
  applicationText2: "Bạn sẽ nhận được phản hồi qua email trong vòng 5 ngày làm việc.",
  verify: "XÁC MINH MẬT KHẨU HIỆN TẠI",
  signAgreement: "KÝ THỎA THUẬN",
  upLoadTip: "Tải lên một tấm ảnh chụp màn hình trang tổng quan kênh của bạn",
  save: "LƯU",
  setNewName: "ĐẶT TÊN NGƯỜI DÙNG MỚI",
  setNameNeed: "Bạn có thể sửa đổi tên ba tháng một lần",
  userNameSet: "Đặt tên người dùng",
  userNameVerify: "Vui lòng sử dụng 2-24 ký tự",
  gameTag: "THẺ TRÒ CHƠI"
};

// IGame首页文案
const iGameHomeData = {
  enterYourEmail: "Nhập Email của Bạn  ",
  password: "Mật khẩu",
  forgotPassword: "QUÊN MẬT KHẨU?",
  emailLogin: "ĐĂNG NHẬP NHANH BẰNG EMAIL",
  pwdLogin: "Chọn lại đăng nhập bằng mật khẩu",
  signUp1: "Bạn chưa có tài khoản？",
  signUp2: "Đăng ký",
  send: "GỬI MÃ",
  codeTip: "Nhập mã gồm 6 chữ số",
  desc1: "Bạn sẽ được nhận các thông tin chi tiết, công cụ và hỗ trợ độc quyền từ quan hệ hợp tác chính thức với chúng tôi, giúp bạn tạo ra những nội dung hay ho nhất!",
  desc2_1: "Các lợi ích độc quyền là cơ hội để bạn nhận được các công cụ cần thiết, từ đó đưa khả năng sáng tạo nội dung của bạn lên một đẳng cấp mới!",
  desc2_2: "Hãy liên hệ với nhân viên của chúng tôi để được hỗ trợ và tư vấn. Chúng tôi sẽ hiển thị nội dung của bạn trên nền tảng chính thức của trò chơi để hỗ trợ bạn phát triển kênh!",
  desc2_3: "Gia nhập cộng đồng Nhà Sáng Tạo uy tín trên Discord của chúng tôi để thỏa sức kết nối với mạng lưới Nhà Sáng Tạo Nội Dung trên toàn cầu!",
  tier1_1: "Lời mời tham gia máy chủ Discord dành cho nhà sáng tạo nội dung video độc quyền của chúng tôi.",
  tier1_2: "Hệ thống tư vấn độc quyền trên máy chủ Discord dành cho nhà sáng tạo, cung cấp dịch vụ hỗ trợ 1-1 từ nhân viên. ",
  tier1_3: "Truy cập vào các nội dung hình ảnh chính thức để thỏa sức sáng tạo nội dung của chính mình. ",
  tier1_4: "Cơ hội nhận phần thưởng trong trò chơi và hoặc các khoản trả thưởng bằng tiền",
  tier2_1: "Tất cả quyền lợi ở Bậc Tập SựCơ hội hiển thị nội dung của bạn trên các nền tảng mảng xã hội chính thức của chúng tôi",
  tier2_2: " ",
  tier3_1: "Toàn bộ quyền lợi ở các bậc trướcMã quà tặng tùy chỉnh dùng để tổ chức sự kiện tặng quà cho người xem của bạnCơ hội nhận lời mời tham gia các sự kiện AFK Journey chính thứcCơ hội hợp tác sáng tạo nội dung cùng Đội Ngũ AFK Journey",
  tier3_2: " ",
  tier3_3: " ",
  tier3_4: " ",
  updateText: "Cập nhật lần cuối vào ngày"
};


// 提交申请表单成功
const signTip = "Xin chúc mừng! Yêu cầu gia nhập Creator Zone của bạn đã được phê duyệt. Vui lòng ký bản thỏa thuận nhà sáng tạo đã được gửi tới e-mail của bạn để nhận được toàn quyền truy cập vào nền tảng của chúng tôi.";

// 注册协议弹窗
const communityTip = [
  "1. Tôn trọng,",
  "Chúng tôi mong muốn tạo ra một môi trường thân thiện dành cho tất cả mọi người. Chúng tôi tôn trọng quyền tự do ngôn luận của mỗi cá nhân, nhưng các ngôn từ mang tính xúc phạm hoặc những hành vi hung hăng (tiêu cực) có thể bị cảnh cáo, và mức độ hình phạt cao nhất là bị cấm tham gia Creator Zone.",
  "2. Phân biệt đối xử,",
  "Nghiêm cấm các hành vi bắt nạt hoặc mọi kiểu hành vi phân biệt đối xử. Chúng tôi tuyệt đối không chấp nhận các bình luận mang tính hạ nhục liên quan đến chủng tộc, tôn giáo, văn hóa, khuynh hướng tính dục, giới tính hoặc bản dạng giới. Các hành vi này có thể sẽ bị cấm tham gia Creator Zone.",
  "3. Giao Dịch Tài Khoản,",
  "Nghiêm cấm các dịch vụ buôn bán, giao dịch hoặc boost tài khoản (có trả phí). Bất kỳ Nhà Sáng Tạo nào có liên quan đến những loại hoạt động này đều có thể bị cấm tham gia Creator Zone.",
  "4. Nội dung khiêu dâm hoặc gây phiền toái,",
  "Hành vi phát tán, phân phối các nội dung khiêu dâm hoặc bất kì hành vi nào tương tự đều có thể khiến nhà sáng tạo bị cấm tham gia Creator Zone.",
  "5. Đạo văn/Sao chép,",
  "Tất cả nội dung được tạo phải là tác phẩm gốc, hành vi đạo văn có thể khiến nhà sáng tạo bị cấm tham gia Creator Zone.",
  "6. Lời Cuối,",
  "Quy tắc không được liệt kê ở đây không đồng nghĩa rằng Nhà Sáng Tạo của chúng tôi được phép làm điều đó. Lilith Games và Farlight Games bảo lưu mọi quyền đối với cách giải thích cuối cùng về các quy tắc này.",
];

export default {
  topNavData,
  footerData,
  homeData,
  signUpLanguageData,
  signData,
  guideData,
  applicationData,
  adminNavData,
  adminHomeData,
  artAssetsData,
  gameFeedData,
  earningData,
  upLoadContentData,
  uploadManageData,
  signTip,
  communityTip,
  dialogText,
  iGameHomeData,
  informationData
};