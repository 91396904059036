import React, { useState } from "react";

import { mainOfGame } from "@/common/utils/envData";

import DefaultGame from "./defaultGame";
import GameMedia from "./gameMedia";

function Game(props) {
  const [activeGame, setActiveGame] = useState("");

  return (
    <>
      <DefaultGame
        setActiveGame={setActiveGame}
        game={props.game}
        platform={props.platform}
        setGame={props.setGame}
        setGameUID={props.setGameUID}
        setGameUserName={props.setGameUserName}
        saveGameMedia={props.setGameMediaData}
        setGameUIDCheck={props.setGameUIDCheck}
      />
      {
        !mainOfGame && activeGame === "farlight84" && (
          <GameMedia saveMedia={props.saveGameMedia} platform={props.platform} />
        )
      }
    </>
  );
}

const GameMemo = React.memo(Game);

export default GameMemo;
