import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import style from "../index.module.scss";
import ClipboardJS from "clipboard";
import Api from "@/common/Api/content";
import messageApi from "@/common/Api/message";
// import bindApi from "@/common/Api/bindCreator";
import { rootContext, globalData } from "@/context/rootContext";
import { message, Pagination, DatePicker, Radio, Popover } from "antd";
import { Abbreviation, formatMediaName, locationTipMessage } from "@/common/utils";
const { RangePicker } = DatePicker;

// let loadNum = 0;

function ManageContent() {

  const {
    langState, gameTab, globalLang,
    ableMedia, setAbleMedia,
    refreshVideo, setRefreshVideo,
    refreshVideoTime, setRefreshVideoTime
  } = useContext(rootContext);
  const navigate = useNavigate();
  const [key, setKey] = useState("");
  const [current, setCurrent] = useState(1);
  const [contentCurrent, setContentCurrent] = useState({});
  const [selectTime, setSelectTime] = useState([]);
  const [total, setTotal] = useState(0);
  const [list, setList] = useState([]);
  // const [formId, setFormId] = useState("");
  // const [tikTokVideoId, setTikTokVideoId] = useState("");
  const [choseMedia, setChoseMedia] = useState("");
  const limit = 5;

  const clipboard = new ClipboardJS(".copy_line");


  const getData = async (item) => {
    if (!ableMedia.length) {
      return;
    }
    const data = {
      key,
      page: current,
      limit,
      mTag: choseMedia || ableMedia.join(","),
      ...item
    };
    if (selectTime[0] && selectTime[1]) {
      data.publishTimeRange = [selectTime[0].format("YYYY-MM-DD"), selectTime[1].format("YYYY-MM-DD")].join(",");
    }
    const res = await Api.getContentList({ gametag: globalData.activeGameData.tag, data });
    if (res.status === 200) {
      for (item of res.data.items) {
        item.snapshot = item.snapshot ? item.snapshot.split(",") : "";
      }
      setTotal(res.data.meta.totalItems);
      setList(res.data.items);
    }
  };

  const getMonthContent = async (m_mTag) => {
    let publishTimeRange = null;
    if (selectTime[0] && selectTime[1]) {
      publishTimeRange = [selectTime[0].format("YYYY-MM-DD"), selectTime[1].format("YYYY-MM-DD")].join(",");
    }
    const tag = m_mTag || choseMedia || ableMedia.join(",");
    const res = await Api.getMediaData(tag, publishTimeRange, globalData.activeGameData.tag);
    if (res && res.status === 200) {
      setContentCurrent(res.data);
    }
  };

  const refreshAllVideo = async () => {
    if (checkLastRefresh()) {
      setRefreshVideo(true);
      locationTipMessage(200020, "info");
      await Api.updateAllContent();
      await getData();
      setRefreshVideo(false);

      window.gtag("event", "refresh_all_video");
    }
  };

  const checkLastRefresh = () => {
    if (!refreshVideoTime) {
      setRefreshVideoTime(new Date().getTime());
      return true;
    }
    if (new Date().getTime() - refreshVideoTime <= 60000) {
      locationTipMessage(400106, "warn");
      return false;
    }
    setRefreshVideoTime(new Date().getTime());
    return true;
  };

  // const update = async (item) => {
  //   window.gtag("event", "pc_manage_update");
  //   if (item.contentUrl.includes("tiktok.com")) {
  //     setTikTokVideoId(item.id);
  //     tiktokAuthorize();
  //   } else {
  //     defauleUpdata(item);
  //   }
  // };

  // const refreshTikTokToken = async (refreshToken) => {
  //   const res = await Api.refreshTikTokToken(refreshToken);
  //   // console.log(res);
  //   if (res.status === 200) {
  //     updateTiktok(res.data.accessToken);
  //   } else {
  //     message.error("Network error. Refresh and try again.");
  //   }
  // };

  // const updateTiktok = async (accessToken) => {
  //   const res = await Api.updateContent(tikTokVideoId, globalData.activeGameData.tag, accessToken);
  //   if (res.status !== 200) {
  //     message.error((res.data && res.data.message) || res.data);
  //   } else {
  //     message.success("Update succeeded");
  //     setTimeout(() => getData(), 500);
  //   }
  // };

  // const defauleUpdata = async (item) => {
  //   const res = await Api.updateContent(item.id, globalData.activeGameData.tag);
  //   if (res.status !== 200) {
  //     message.error((res.data && res.data.message) || res.data);
  //   } else {
  //     message.success("Update succeeded");
  //     setTimeout(() => getData(), 500);
  //   }
  // };

  // const tiktokAuthorize = () => {
  //   const randomNum = [0, 0, 0].map(() => Math.floor(Math.random() * 10)).join("");
  //   const formId = `${new Date().getTime() % 1E3}${randomNum}`;
  //   setFormId(formId);
  //   getMediaURL(formId);
  // };

  // const getMediaURL = async (formId) => {
  //   const res = await bindApi.getMediaURL({
  //     mInfoTag: "tiktok",
  //     formId: formId
  //   });
  //   if (res.status === 200) {
  //     loadNum = 0;
  //     window.open(res.data);
  //   } else {
  //     message.error(res.data.message || res.data || "network error");
  //   }
  // };

  // const getMediaUseData = async () => {
  //   const res = await bindApi.getBindMediaData({
  //     formId: formId
  //   });
  //   if (res && res.status === 200 && res.data) {
  //     refreshTikTokToken(res.data.refreshToken);
  //   } else if (loadNum++ < 5) {
  //     setTimeout(getMediaUseData, 1000);
  //   } else {
  //     message.error("Network error. Refresh and try again.");
  //   }
  // };

  const deleteData = async id => {
    window.gtag("event", "pc_manage_delete");
    const res = await Api.deleteContent(globalData.activeGameData.tag.gameTab, id);
    if (res.status === 200) {
      locationTipMessage(200027, "success");
      setTimeout(() => {
        if (current > 1 && list.length === 1) {
          setCurrent(current - 1);
        } else {
          getData();
        }
      }, 1800);
    }
  };

  const dateChange = (date) => {
    if (date && date[0] && date[1]) {
      setSelectTime(date);
    }
  };

  // const setPlatFormSataus = (msg) => {
  //   if (msg && msg.data) {
  //     try {
  //       const data = JSON.parse(msg.data);
  //       if (data.type === "authorization") {
  //         setTimeout(() => { getMediaUseData(); }, 1000);
  //       }
  //     } catch (e) { null; }
  //   }
  // };

  const getMediaList = async () => {
    const res = await messageApi.getMediaList();
    if (res.status === 200) {
      const mediaList = res.data.map(item => item.name.toLowerCase()).filter(item => formatMediaName(item));
      setAbleMedia(mediaList);
    }
  };

  const handleMediaChange = (item) => {
    const newTag = item.target.value;
    if (newTag !== choseMedia) {
      setChoseMedia(newTag);
      if (current !== 1) {
        setCurrent(1);
      } else {
        getData({
          mTag: newTag
        });
      }
    }
  };

  // useEffect(() => {
  //   const messageCallback = setPlatFormSataus;
  //   window.addEventListener("message", messageCallback);
  //   return () => { window.removeEventListener("message", messageCallback); };
  // }, [formId]);

  // 翻页/游戏tab切换/媒体切换/时间切换 时，更新视频数据
  useEffect(() => {
    if (gameTab.length) {
      getData();
    }
  }, [current, gameTab, choseMedia, selectTime]);

  // 游戏tab切换/媒体切换/时间切换 时，更新收益
  useEffect(() => {
    if (gameTab.length) {
      getMonthContent();
    }
  }, [gameTab, choseMedia, selectTime]);

  // 复制标题事件
  useEffect(() => {
    clipboard.on("success", function (e) {
      locationTipMessage(200035, "success");
      e.clearSelection();
    });

    clipboard.on("error", function () {
      message.error("Copy Failed");
    });

    return () => clipboard.destroy();
  }, [clipboard]);

  // 初始化媒体数据
  useEffect(() => {
    if (!ableMedia.length) {
      getMediaList();
    } else {
      setChoseMedia(ableMedia.join(","));
    }
  }, [ableMedia]);

  // 获取近30天日期 
  // useEffect(() => {
  //   const day_30 = new Date(new Date() - 1000 * 60 * 60 * 24 * 30);
  //   setSelectTime([moment(formatTime(day_30).slice(0, 10), dateFormat), moment(formatTime().slice(0, 10), dateFormat)]);
  // }, []);

  const keyDown1 = (e) => {
    if (e.keyCode === 13) { getData(); }
  };

  const selectData = () => {
    if (current !== 1) {
      setCurrent(1);
    } else {
      getData();
    }
  };


  return (
    <div className={`manage_content ${style.manage_content}`}>
      <div className={style.title1}>
        <span>{langState.uploadManageData.title}</span>
      </div>
      <div className={style.query}>
        <input value={key} onChange={e => setKey(e.target.value)} onKeyDown={keyDown1} placeholder={langState.uploadManageData.selectPlace}></input>
        <i onClick={selectData}></i>
      </div>
      <div className={style.query_list}>
        <Radio.Group size="large" value={choseMedia} onChange={handleMediaChange}>
          <Radio.Button value={ableMedia.join(",")}>ALL</Radio.Button>
          {
            ableMedia.map(item => {
              return (
                <Radio.Button key={item} value={item}>{formatMediaName(item)}</Radio.Button>
              );
            })
          }
        </Radio.Group>
        <RangePicker onChange={dateChange} value={selectTime} allowClear={false} size="large" placeholder={[langState.uploadManageData.sTime, langState.uploadManageData.eTime]} />
        <div className={`${style.update} ${refreshVideo ? style.on : ""}`} onClick={refreshAllVideo}></div>
      </div>
      <div className={style.content_data}>
        <ul className={style.data_list}>
          <li>
            <p className={`content_subtitle ${style.content_subtitle}`}>{langState.uploadManageData.totalCount}</p>
            <p className={style.num}>{(contentCurrent.contents && Abbreviation(+contentCurrent.contents)) || 0}</p>
          </li>
          <li>
            <p className={`content_subtitle ${style.content_subtitle}`}>{langState.uploadManageData.totalView}</p>
            <p className={style.num}>{(contentCurrent.viewCount && Abbreviation(+contentCurrent.viewCount)) || 0}</p>
          </li>
          <li>
            <p className={`content_subtitle ${style.content_subtitle}`}>{langState.uploadManageData.totalLike}</p>
            <p className={style.num}>{(contentCurrent.starCount && Abbreviation(+contentCurrent.starCount)) || 0}</p>
          </li>
          <li>
            <p className={`content_subtitle ${style.content_subtitle}`}>{langState.uploadManageData.totalComments}</p>
            <p className={style.num}>{(contentCurrent.commentCount && Abbreviation(+contentCurrent.commentCount)) || 0}</p>
          </li>
        </ul>
      </div>
      <ul className={style.gamefeed_list}>
        {
          list.map((item, index) => {
            return (
              <li key={index}>
                <div className={style.img_box}>
                  <img src={item.snapshot && (item.snapshot.length ? item.snapshot[0] : item.snapshot)}></img>
                </div>
                <Popover content={item.title} trigger="hover">
                  <p className={`${style.title} ${"copy_line"}`} data-clipboard-text={item.contentUrl || ""}>{item.title || " "}</p>
                </Popover>
                <p className={style.desc}>
                  <span>{item.pushTime && item.pushTime.slice(0, 10)}</span>
                  {
                    item.mTag === "twitch" && (
                      <>
                        <span><i className={style.view}></i>{item.viewCount}</span>
                        <span><i className={style.like}></i>—</span>
                        <span><i className={style.comm}></i>—</span>
                      </>
                    )
                  }
                  {
                    item.mTag === "youtube" && (
                      <>
                        <span><i className={style.youtube_view}></i>{item.viewCount} views</span>
                        <span><i className={style.youtube_like}></i>{item.starCount}</span>
                        <span><i className={style.comm}></i>{item.commentCount}</span>
                      </>
                    )
                  }
                  {
                    item.mTag === "tiktok" && (
                      <>
                        <span><i className={style.view}></i>{item.viewCount}</span>
                        <span><i className={style.like}></i>{item.starCount}</span>
                        <span><i className={style.comm}></i>{item.commentCount}</span>
                      </>
                    )
                  }
                </p>
                <p className={style.creatieTime}>{item.createdTime && item.createdTime.slice(0, 10)}</p>
                {/* <Popover content={(<p>refresh data</p>)} trigger="hover">
                  <div className={style.update} onClick={() => { update(item); }}></div>
                </Popover> */}
                <div className={`manage_more ${style.more}`}>
                  <div className={style.more_item}>
                    <div onClick={() => { deleteData(item.id); }}>{langState.uploadManageData.delete}</div>
                  </div>
                </div>
              </li>
            );
          })
        }
      </ul>
      {
        !list.length && (
          <div className={`${style.upLoad_tip} upLoad_tip ${globalLang}`} onClick={() => { navigate("/admin/mycontent/upload"); }}></div>
        )
      }
      <div className={style.page}>
        <Pagination
          size="small"
          total={total}
          defaultPageSize={limit}
          defaultCurrent={1}
          current={current}
          showSizeChanger={false}
          onChange={(page) => setCurrent(page)}
        />
      </div>
    </div>
  );
}

export default ManageContent;
